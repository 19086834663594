/**
 * ATTENTION!!!
 *
 * DO NOT MODIFY THIS FILE BECAUSE IT WAS GENERATED AUTOMATICALLY,
 * Instead, run 'yarn api' to regenerate this file.
 */

import { ApiRequestConfig, PaginationResponse } from 'providers/ApiProvider/client';

export interface Error {
  status?: number;
  name?: string;
  message?:
    | string
    | {
        [k: string]: any;
      };
  [k: string]: any;
}

export interface Article {
  /**
   * ID of the article
   */
  id?: number;
  key?: string;
  /**
   * Name of the article
   */
  articleName?: string;
  /**
   * Second ID for special references
   */
  articleRef?: number;
  /**
   * The html content to display
   */
  displayContent?: string;
  /**
   * The main html content
   */
  mainContent?: string;
  /**
   * The custom html content for doctors
   */
  doctorCustomContent?: string;
  /**
   * The custom html content for patients
   */
  patientCustomContent?: string;
}

export interface ArticleMainContent {
  /**
   * ID of the general information piece
   */
  id?: number;
  key?: string;
  /**
   * The general information proper (html markup)
   */
  htmlContent?: string;
  /**
   * The (second) article ID -> ll_toc.id2
   */
  articleRef?: number;
}

export interface ArticleCustomContent {
  /**
   * ID of the custom information piece
   */
  id?: number;
  /**
   * ID of the lab -> b_labor.lid
   */
  lid?: number;
  /**
   * The (second) article ID -> ll_toc.id2
   */
  articleRef?: number;
  /**
   * User type (ARZ|PAT)
   */
  targetUser?: string;
  /**
   * The custom information proper (html markup)
   */
  htmlContent?: string;
}

export interface ArticleAnalyses {
  /**
   * ID of the test key to article assignment
   */
  id?: number;
  /**
   * Test key -> a_untersuchungen.untid
   */
  analysisKey?: string;
  /**
   * The (second) article ID -> ll_toc.id2
   */
  articleRef?: number;
  /**
   * ID of the lab -> b_labor.lid
   */
  lid?: number;
}

export interface Hint {
  /**
   * ID of the requirement information
   */
  id?: number;
  /**
   * ID of
   */
  lid?: number;
  importPreanalyticId?: string;
  /**
   * Name of the requirement information
   */
  hintName?: string;
  /**
   * The information proper (html markup)
   */
  htmlContent?: string;
  /**
   * Text color to be used for the information (#RRGGBB)
   */
  htmlColor?: string;
  onlyForAid?: number;
}

export interface HintRequirements {
  /**
   * ID of the requirement information to requirement assignment
   */
  id?: number;
  /**
   * ID of the requirement information
   */
  hintId?: number;
  /**
   * Short key of the requirement -> a_anforderungen.anfid
   */
  requirementKey?: string;
  /**
   * Optional short key of a doctor -> b_arzt.kurzform; the empty string for all doctors
   */
  submitter?: string;
  /**
   * ID of the lab -> b_labor.lid
   */
  lid?: number;
}

export interface PushNotification {
  /**
   * Push ID
   */
  puid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Number of report in laboratory
   */
  tnr?: string;
  /**
   * Creation date
   */
  created_at?: string;
  /**
   * Process date
   */
  processed_at?: string;
  /**
   * Push Token
   */
  pushToken?: string;
  /**
   * Device Type
   */
  deviceType?: string;
  /**
   * Sent date
   */
  sent_at?: string;
  /**
   * Foreign key Notification ID
   */
  notification_id?: number;
  target?: string;
  title?: string;
  body?: string;
  data?: {
    [k: string]: any;
  };
  result?: PushResult;
}

export interface GuidReportNotification {
  /**
   * Report Globally Unique Identifier
   */
  guid?: string;
  /**
   * Device ID or email address
   */
  deviceId?: string;
  /**
   * Mail template group ID, i.a. manages sendsettings
   */
  mailTemplateGroupId?: number;
}

export interface SendMail {
  /**
   * ML Entry ID
   */
  smid?: number;
  lid?: number;
  /**
   * mail address of the receiver
   */
  receiverMail?: string;
  /**
   * name of the receiver
   */
  receiverName?: string;
  /**
   * subject
   */
  subject?: string;
  /**
   * mail content
   */
  body?: string;
  /**
   * true if did not null, previous for filename of already generated file
   */
  attachment?: string;
  /**
   * date of send order
   */
  date?: string;
  /**
   * mail already sent flag
   */
  sent?: boolean;
  /**
   * Reply Address
   */
  reply?: string;
  /**
   * ID (FK) of b_druck table
   */
  printId?: number;
  /**
   * Mail template group ID, i.a. manages sendsettings
   */
  mailTemplateGroupId?: number;
  /**
   * Foreign key Notification ID
   */
  notification_id?: number | null;
  type?: SendMailType;
  encoding?: SendMailEncoding;
}

export interface SendmailSettings {
  /**
   * Primary key
   */
  id?: number;
  lid?: number;
  mtgid?: number | null;
  /**
   * default sender field for outgoing mails
   */
  default_sender?: string;
  /**
   * url of the mail server
   */
  server: string;
  user: string;
  password: string;
  port: number;
  use_ssl?: boolean;
  bcc?: string | null;
  domain?: string | null;
  maildisplayname?: string | null;
  mailTemplateGroup?: MailTemplateGroup;
}

export interface MailTemplateGroup {
  /**
   * Mail Template Group ID
   */
  mtgid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  name?: string;
  reply?: string;
  /**
   * If the x-target header is set in a request, this mailtemplate group will be chosen automatically
   */
  target?: string | null;
  www_url?: string | null;
  logo?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  highlightColor?: string | null;
}

export interface MailLanguageEntry {
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Mail Template Group ID
   */
  mtgid?: number | null;
  subject?: string;
  body?: string;
  mailData?: {
    [k: string]: any;
  };
  locale?: LanguageCode;
  type?: MailTemplateType;
}

export interface MailTemplate {
  /**
   * Mail Template ID
   */
  mtid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  groups?: string;
  is_html?: boolean;
  has_bg?: boolean;
  type?: MailTemplateType;
}

export interface QuickAccessNotification {
  /**
   * Report ID
   */
  bid?: number;
  email?: string;
  target?: string;
  /**
   * Mail template group ID, i.a. manages sendsettings
   */
  mailTemplateGroupId?: number;
}

export interface OrderPool {
  /**
   * ID of order pool
   */
  id?: number;
  tnr?: string;
  displayName?: string;
  /**
   * DateTime of the creation of the pool
   */
  created_at?: string;
  updated_at?: string;
  materialsString?: string;
  requirementsString?: string;
  costUnitsString?: string;
  journalString?: string;
  orderFormNamesString?: string;
  /**
   * Is soft deleted
   */
  isSoftDeleted?: boolean;
  status?: OrderStatus[];
  doctor?: Doctor;
  orders?: Order[];
  logs?: OrderChangeLog[];
  meta_strings?: ComposedOrderStringsProperties | null;
}

export interface Order {
  /**
   * ID of laboratory
   */
  lid?: number;
  /**
   * Is pool order
   */
  isPool?: boolean;
  /**
   * Is pool order
   */
  isPoct?: boolean;
  /**
   * Is soft deleted
   */
  isSoftDeleted?: boolean | null;
  freeText?: string;
  /**
   * "Nachricht ans Labor"
   */
  message?: string;
  infectious?: boolean;
  urgent?: string;
  pregnancy?: boolean;
  checkup?: boolean;
  /**
   * Cost unit (e.g. Privat/Kasse)
   */
  costUnit?: string;
  insuranceName?: string;
  controlCheckupKnownInfection?: boolean;
  dz116b?: boolean | null;
  dz16b?: boolean;
  accident?: boolean;
  accidentTime?: string;
  documentReferenceNumber?: string;
  anamnesticInfo?: string;
  printedMaterials?: string | null;
  printQuickReportBarcode?: boolean;
  urgentNotificationPhone?: string | null;
  urgentNotificationFax?: string | null;
  urgentNotificationMail?: string | null;
  endo?: boolean;
  /**
   * ID of order
   */
  id?: number;
  /**
   * Order Pool ID
   */
  poolId?: number;
  /**
   * ID of doctor
   */
  aid?: number;
  /**
   * number of the report/order in the laboratory, also called barcode or laboratory number
   */
  tnr?: string;
  externalId2?: string;
  lanr?: string;
  bsnr?: string;
  selectedDoctor?: string;
  apid?: number | null;
  /**
   * DateTime of execution (not exported)
   */
  executed_at?: string;
  scheduled_at?: string | null;
  sampleDate?: string;
  sampleTime?: string;
  /**
   * if set, an employee has to validate and confirm the order first before it can be exported
   */
  mustValidate?: boolean;
  hospitalStation?: string;
  requirementDiagnosisFreeText?: string;
  externalOrderInfo?: string | null;
  selectedDiagnoses?: {
    [k: string]: any;
  };
  selectedDiagnosesChecked?: boolean;
  workstationChecked?: boolean;
  sample_in_lab?: boolean;
  clientname?: string;
  printerAliasName?: string;
  overridePrinterName?: string | null;
  overrideBarcodePrinterName?: string | null;
  reportCopyAid?: number | null;
  aisIdentifier?: string | null;
  patientAisNumber?: string;
  /**
   * DateTime of transportation
   */
  transported_at?: string;
  /**
   * DateTime of the creation of the order
   */
  created_at?: string;
  manufacturer?: string;
  initialWorkplaceId?: number;
  lastWorkplaceId?: number;
  materialsString?: string;
  requirementsString?: string;
  costUnitsString?: string;
  journalString?: string;
  orderFormNamesString?: string;
  /**
   * ID of first order for splitted orders
   */
  splitId?: number;
  quickScanPdfPassword?: string | null;
  /**
   * Whether there is an attachment file to this order
   */
  hasAttachment?: boolean;
  case_id?: number;
  caseNumber?: string;
  exportFlag?: boolean;
  statusExport?: number;
  statusStornoExport?: number;
  comment?: string;
  isCancelable?: boolean;
  /**
   * Property to keep the actually printed materials info
   */
  actuallyPrintedMaterials?: string;
  hasReportCopyInfo?: boolean;
  urgentNotificationMode?: UrgentNotificationMode | null;
  status?: OrderStatus;
  executionStatus?: OrderStatus;
  patient?: OrderPatientMetaProperties;
  requirements?: OrderWizardRequirement[];
  anamnesis?: OrderAnamnesis[];
  animal?: OrderAnimalProperties;
  switzerland?: OrderSwitzerlandProperties;
  hungary?: OrderHungaryProperties;
  covid?: KbvCovidFormProperties;
  splitProperties?: OrderWizardSplitProperties[];
  beforeAfterDialysis?: BeforeAfterDialysis | null;
  reportCopyDoctor?: Doctor | null;
  bloodCollectionType?: BloodCollectionType | null;
  professionalAssociation?: ProfessionalAssociationProperties;
  digitalSignature?: OrderDigitalSignature;
  doctor?: Doctor;
  report?: Report;
  pool?: OrderPool;
  samples?: OrderSample[];
  workstation?: Workstation;
  realReRequestConditions?: OrderRealReRequestConditionsProperties;
  meta_strings?: ComposedOrderStringsProperties | null;
}

export interface RequirementCategory {
  /**
   * ID of the requirement category
   */
  id?: number;
  /**
   * Name of the requirement category
   */
  name?: string;
}

export interface RequirementField {
  /**
   * ID of requirement field
   */
  id?: number;
  /**
   * Key of requirement field
   */
  key?: string;
  /**
   * Output with price
   */
  withPrice?: boolean;
  /**
   * Output with sub-requirements
   */
  withSubRequirements?: boolean;
  /**
   * Render target
   */
  renderTarget?: boolean;
  /**
   * Requirement assigned
   */
  requirementAssigned?: boolean;
  name?: RequirementFieldName;
  layout?: RequirementFieldLayout;
}

export interface RequirementFieldSetting {
  /**
   * ID of requirement field setting
   */
  id?: number;
  /**
   * ID of requirement field
   */
  fieldId?: number;
  /**
   * Short name of requirement
   */
  shortName?: string;
  laboratoryGroup?: string;
  /**
   * Render mode of requirement field setting
   */
  renderMode?: boolean;
  /**
   * Key of requirement field
   */
  fieldKey?: string;
  /**
   * Name of requirement field
   */
  fieldName?: string;
}

export interface Indication {
  /**
   * ID of indication
   */
  id?: number;
  name?: string;
}

export interface RequirementProfile {
  /**
   * ID of the requirement category
   */
  id?: number;
  /**
   * ID of the doctor
   */
  aid?: number;
  formId?: number;
  /**
   * Name of the requirement category
   */
  name?: string;
  /**
   * Display order for the profile
   */
  order?: number;
  costUnit?: string | null;
  color?: string | null;
  /**
   * Diagnosis which should be auto filled out
   */
  diagnosis?: string | null;
  freeText?: string | null;
  /**
   * If the order profile is for microbiologic analyses
   */
  isMicrobiological?: boolean;
  /**
   * either a digit or an empty string (digits mean: 1=cure, 2=preventive, 3=ESS, 4=receipt)
   */
  orderReason?: string;
  selectedDiagnoses?: {
    [k: string]: any;
  };
}

export interface RequirementGlobalProfile {
  /**
   * ID of the requirement category
   */
  id?: number;
  lid?: number;
  formId?: number;
  /**
   * Name of the requirement category
   */
  name?: string;
  public?: boolean;
  laboratoryGroup?: string;
  /**
   * Display order for the profile
   */
  order?: number;
  costUnit?: string;
  /**
   * either a digit or an empty string (digits mean: 1=cure, 2=preventive, 3=ESS, 4=receipt)
   */
  orderReason?: string;
}

export interface PinnedRequirement {
  /**
   * ID of the pinned requirement entry
   */
  id?: number;
  /**
   * ID of the doctor
   */
  aid?: number;
  type?: RequirementType;
}

export interface ClinicalMaterial {
  /**
   * ID of material
   */
  id?: string;
  /**
   * Name of material
   */
  name?: string;
  /**
   * Execution limit
   */
  limit?: number | null;
  /**
   * Type of sample
   */
  sampleType?: string;
  /**
   * List of analyses
   */
  analyses?: string[];
}

export interface ClinicalAnalysis {
  shortName?: string;
  longName?: string;
  ebmGop?: string | null;
  /**
   * Minimal amount
   */
  amount?: number;
  isDummy?: boolean;
  materialId?: string;
  /**
   * Type of sample
   */
  sampleType?: string;
}

export interface ClinicalOrderRequirement {
  /**
   * ID of order
   */
  orderId?: number;
  /**
   * ID of from
   */
  formId?: number;
  /**
   * ID of profile
   */
  profileId?: number | null;
  shortName?: string;
  /**
   * External name of requirements
   */
  externalName?: string;
  longName?: string;
  /**
   * If the requirement is private and we have therefore to calculate prices
   */
  isPrivate?: boolean;
  /**
   * Price of ordered requirement
   */
  price?: string | null;
  /**
   * Is price charged
   */
  isCharged?: boolean;
  /**
   * billing type of the order, who will pay for it / get invoice for it (for example patient, doctor or insurance
   */
  invoiceTo?: string | null;
  /**
   * Laboratory group
   */
  laboratoryGroup?: string;
  /**
   * Free text value
   */
  freeText?: string;
  /**
   * Free text date value
   */
  freeTextDate?: string | null;
  /**
   * Free text allowed
   */
  freeTextAllowed?: boolean | null;
  /**
   * Free text mandatroy
   */
  freeTextMandatory?: boolean | null;
  /**
   * Free text as date
   */
  freeTextAsDate?: boolean | null;
  /**
   * Added by Real Re-Request feature
   */
  byRealReRequest?: boolean;
  cancelled_at?: string | null;
  uncancelled_at?: string | null;
  missingAggregation?: boolean;
  form?: OrderForm;
  requirement?: ClinicalRequirement;
  selectedDiagnoses?: AggregatedDiagnosisProperties[];
  profileType?: AggregatedProfileType | null;
  dynamicMaterials?: OrderRequirementDynamicMaterial[];
}

export interface RequirementAdministrativeProperties {
  /**
   * ID of order requirement
   */
  id?: number;
  /**
   * Short name of requirements
   */
  shortName?: string;
  longName?: string;
  /**
   * External name of requirements
   */
  externalName?: string;
  duration?: string;
  /**
   * Alias name of requirements
   */
  alias?: string;
  /**
   * Execution limit
   */
  limit?: number | null;
  /**
   * Priority
   */
  priority?: number | null;
  /**
   * LIS Export
   */
  lisExport?: boolean;
  /**
   * Is price charged
   */
  isCharged?: boolean;
  /**
   * Is EBM price charged
   */
  isEbmCharged?: boolean;
  /**
   * Free text allowed
   */
  freeTextAllowed?: boolean;
  /**
   * Free text mandatory
   */
  freeTextMandatory?: boolean;
  /**
   * Free text as date
   */
  freeTextAsDate?: boolean;
  /**
   * Free text LDT key
   */
  freeTextKey?: number | null;
  /**
   * Free text preappend value
   */
  freeTextPreappendValue?: string | null;
  laboratoryGroup?: string;
  /**
   * Self defined
   */
  selfDefined?: boolean;
  /**
   * Requirement short name is editable regarding by dependencies
   */
  shortNameEditable?: boolean;
  /**
   * Requirement is deletable regarding to dependencies
   */
  deletable?: boolean;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup1?: number;
  /**
   * Price within PriceGroup 2 for this requirement if privately billed
   */
  priceGroup2?: number;
  /**
   * Price within PriceGroup 3 for this requirement if privately billed
   */
  priceGroup3?: number;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup4?: number;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup5?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup1?: number | null;
  /**
   * Maximum price within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup2?: number | null;
  /**
   * Maximum price within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup3?: number | null;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup4?: number | null;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup5?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup1?: string;
  /**
   * Maximum price comment within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup2?: string;
  /**
   * Maximum price comment within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup3?: string;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup4?: string;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup5?: string;
  /**
   * EBM price
   */
  ebmPrice?: number;
  infoUrl?: string | null;
  additionalText?: string | null;
  hint?: string | null;
  /**
   * Duration of the examination in seconds
   */
  examinationDuration?: number | null;
  requirementFieldSettings?: RequirementFieldSetting[];
  examinationDurationInterval?: Interval;
}

export interface OrderAdminRequirement {
  /**
   * ID of order requirement
   */
  id?: number;
  /**
   * Short name of requirements
   */
  shortName?: string;
  longName?: string;
  /**
   * External name of requirements
   */
  externalName?: string;
  duration?: string;
  /**
   * Alias name of requirements
   */
  alias?: string;
  /**
   * Execution limit
   */
  limit?: number | null;
  /**
   * Priority
   */
  priority?: number | null;
  /**
   * LIS Export
   */
  lisExport?: boolean;
  /**
   * Is price charged
   */
  isCharged?: boolean;
  /**
   * Is EBM price charged
   */
  isEbmCharged?: boolean;
  /**
   * Free text allowed
   */
  freeTextAllowed?: boolean;
  /**
   * Free text mandatory
   */
  freeTextMandatory?: boolean;
  /**
   * Free text as date
   */
  freeTextAsDate?: boolean;
  /**
   * Free text LDT key
   */
  freeTextKey?: number | null;
  /**
   * Free text preappend value
   */
  freeTextPreappendValue?: string | null;
  laboratoryGroup?: string;
  /**
   * Self defined
   */
  selfDefined?: boolean;
  /**
   * Requirement short name is editable regarding by dependencies
   */
  shortNameEditable?: boolean;
  /**
   * Requirement is deletable regarding to dependencies
   */
  deletable?: boolean;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup1?: number;
  /**
   * Price within PriceGroup 2 for this requirement if privately billed
   */
  priceGroup2?: number;
  /**
   * Price within PriceGroup 3 for this requirement if privately billed
   */
  priceGroup3?: number;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup4?: number;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup5?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup1?: number | null;
  /**
   * Maximum price within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup2?: number | null;
  /**
   * Maximum price within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup3?: number | null;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup4?: number | null;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup5?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup1?: string;
  /**
   * Maximum price comment within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup2?: string;
  /**
   * Maximum price comment within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup3?: string;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup4?: string;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup5?: string;
  /**
   * EBM price
   */
  ebmPrice?: number;
  infoUrl?: string | null;
  additionalText?: string | null;
  hint?: string | null;
  /**
   * Duration of the examination in seconds
   */
  examinationDuration?: number | null;
  requirementFieldSettings?: RequirementFieldSetting[];
  examinationDurationInterval?: Interval;
}

export interface ClinicalRequirement {
  /**
   * ID of order requirement
   */
  id?: number;
  formId?: number;
  /**
   * Short name of requirements
   */
  shortName?: string;
  /**
   * External name of requirements
   */
  externalName?: string;
  /**
   * Alias name of requirements
   */
  alias?: string;
  longName?: string;
  /**
   * Execution limit
   */
  limit?: number | null;
  /**
   * Re-request material limit
   */
  materialLimitOverrule?: number | null;
  /**
   * Is super requirement of a requirement set
   */
  superRequirement?: boolean | null;
  subRequirements?: string;
  infoUrl?: string | null;
  additionalText?: string | null;
  hint?: string | null;
  analysesString?: string;
  /**
   * Occurrence of the requirement, generated value
   */
  occurrence?: number;
  /**
   * List of indications
   */
  indicationsString?: string[];
  pinnedByDoctors?: number[];
  topFromDoctors?: number[];
  firstImportDate?: string;
  laboratoryGroup?: string;
  submitter?: string;
  duration?: string;
  /**
   * Self defined
   */
  selfDefined?: boolean;
  /**
   * Requirement is deletable regarding to dependencies
   */
  deletable?: boolean;
  /**
   * Requirement is blocked
   */
  blocked?: boolean;
  /**
   * Requirement short name is editable regarding by dependencies
   */
  shortNameEditable?: boolean;
  /**
   * EBM price
   */
  ebmPrice?: number;
  /**
   * Priority
   */
  priority?: number | null;
  /**
   * LIS Export
   */
  lisExport?: boolean;
  /**
   * Is price charged
   */
  isCharged?: boolean;
  /**
   * Is EBM price charged
   */
  isEbmCharged?: boolean;
  /**
   * Free text allowed
   */
  freeTextAllowed?: boolean;
  /**
   * Free text mandatory
   */
  freeTextMandatory?: boolean;
  /**
   * Free text as date
   */
  freeTextAsDate?: boolean;
  /**
   * Free text LDT key
   */
  freeTextKey?: number | null;
  /**
   * Free text preappend value
   */
  freeTextPreappendValue?: string | null;
  /**
   * Duration of the examination in seconds
   */
  examinationDuration?: number | null;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup1?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup1?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup1?: string;
  /**
   * Price within PriceGroup 2 for this requirement if privately billed
   */
  priceGroup2?: number;
  /**
   * Maximum price within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup2?: number | null;
  /**
   * Maximum price comment within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup2?: string;
  /**
   * Price within PriceGroup 3 for this requirement if privately billed
   */
  priceGroup3?: number;
  /**
   * Maximum price within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup3?: number | null;
  /**
   * Maximum price comment within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup3?: string;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup4?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup4?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup4?: string;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup5?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup5?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup5?: string;
  analyses?: ClinicalAnalysis[];
  samples?: Sample[];
  materials?: ClinicalMaterial[];
  forms?: OrderForm[];
  categories?: RequirementCategory[];
  profiles?: RequirementProfile[];
  globalProfiles?: RequirementGlobalProfile[];
  requirementFieldSettings?: RequirementFieldSetting[];
  examinationDurationInterval?: Interval;
}

export interface MicrobiologicalMaterial {
  /**
   * ID of material
   */
  matid?: string;
  externalMaterialId?: string | null;
  /**
   * Name of material
   */
  name?: string;
  /**
   * Name of origin
   */
  origin?: string;
  /**
   * Order
   */
  order?: number;
  /**
   * Type of sample
   */
  sampleType?: string;
  /**
   * Count of barcodes to be printed
   */
  barcodeCount?: number;
  askIntraoperative?: boolean;
  askLeftRight?: boolean;
  askOrigin?: number;
  additionalText?: string | null;
  hint?: string | null;
  infoUrl?: string | null;
  alias?: string | null;
  analyses?: MicrobiologicalAnalysis[];
  form?: OrderForm;
  profiles?: RequirementProfile[];
  globalProfiles?: RequirementGlobalProfile[];
  localizations?: MicrobiologicalLocalization[];
}

export interface MicrobiologicalAnalysis {
  /**
   * ID of form
   */
  asid?: number;
  shortName?: string;
  longName?: string;
  externalId?: string | null;
  askAnalysisFreeText?: number | null;
  alias?: string | null;
  order?: number;
  hint?: string | null;
  form?: OrderForm;
}

export interface MicrobiologicalOrderRequirement {
  /**
   * ID of order
   */
  orderId?: number;
  /**
   * ID of from
   */
  formId?: number;
  /**
   * ID of material
   */
  materialId?: string;
  /**
   * ID of profile
   */
  profileId?: number | null;
  origin?: string;
  analysisFreeText?: string | null;
  intraoperative?: boolean;
  selectedAnalyses?: string[];
  shortName?: string;
  longName?: string;
  externalId?: string | null;
  externalMaterialId?: string | null;
  material?: string;
  herkunft?: string;
  sampleType?: string;
  entityId?: number;
  missingAggregation?: boolean;
  /**
   * billing type of the order, who will pay for it / get invoice for it (for example patient, doctor or insurance
   */
  invoiceTo?: string | null;
  cancelled_at?: string | null;
  uncancelled_at?: string | null;
  profileType?: AggregatedProfileType | null;
  leftRight?: MicrobiologicalLeftRight;
  form?: OrderForm;
  requirement?: MicrobiologicalRequirement;
  selectedLocalizations?: MicrobiologicalLocalization[];
  dynamicMaterials?: OrderRequirementDynamicMaterial[];
}

export interface MicrobiologicalRequirement {
  id?: number;
  lid?: number;
  asid?: number;
  matid?: string;
  pinnedByDoctors?: number[];
  occurrence?: number;
  formId?: number;
}

export interface MicrobiologicalLocalization {
  id?: number;
  lid?: number;
  lokid?: number;
  formId?: number;
  kuerzel?: string;
  bereich?: string;
  name1?: string;
  name2?: string;
  sort?: number;
  level?: number;
  exportId?: string;
}

export interface MicrobiologicalText {
  lid?: number;
  matid?: string;
  untid?: string;
  text?: string;
  type?: BakTextType;
}

export interface RequirementRules {
  /**
   * ID of the requirement rule
   */
  id?: number;
  /**
   * ID of laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * Requirement key the rule is attached to -> a_anforderungen.anfid
   */
  requirementId?: string;
  /**
   * Comma separated list of keys of not compatible requirement
   */
  excReqs?: string;
  /**
   * Comma separated list of keys of additionally needed requirements
   */
  reqReqs?: string;
  /**
   * Count of <teinheit> that lays down the timeframe for selecting the requirement
   */
  timeFrameMax?: string;
  /**
   * Maximal count of the requirement in the timeframe of <tanz> <teinheit>
   */
  timeFrameCount?: string;
  /**
   * Informational text to be displayed if the requirement is selected
   */
  infoText?: string;
  /**
   * ID of the order form the rule is attached to -> a_scheine.asid
   */
  formId?: number;
  /**
   * Comma separated list of keys of requirements zu be added if a not compatible requirement was selected
   */
  insteadReqs?: string;
  /**
   * If the amount AND the time the test material was taken are to be filled out
   */
  materialMandatory?: boolean;
  zuseitdruck?: number;
  /**
   * If a diagnosis is to be filled out
   */
  diagnosisMandatory?: boolean;
  /**
   * The doctor has to confirm that the patient was informed about special issues about the requriement
   */
  confirmationMandatory?: boolean;
  ein?: string;
  /**
   * ID of an additional page to be printed if the requirement is selected -> a_scheine.asid
   */
  additionalPageID?: number;
  /**
   * Operator to be used with <patalter>: = <= >=
   */
  patAgeOp?: string;
  /**
   * Age limit, to be used with <patalter_op>
   */
  patAge?: string;
  /**
   * 1: the pregnancy week is to be filled out (empty string otherwise)
   */
  pregnancyWeekMandatory?: boolean;
  /**
   * If the body height is to be filled out
   */
  patHeightMandatory?: boolean;
  /**
   * If the body weight is to be filled out
   */
  patWeightMandatory?: boolean;
  /**
   * Comma separated list of custom field names
   */
  customFields?: string;
  /**
   * Comma separated list of order reasons: 1:cure, 2:preventive, 3:ESS, 4:receipt (i.e. 1,3,4)
   */
  dzOrderReasons?: string;
  /**
   * If the amount of the test material is to be filled out
   */
  sampleAmountMandatory?: boolean;
  /**
   * If the time the test material was taken is to be filled out
   */
  samplePeriodMandatory?: boolean;
  printQuickReportBarcode?: boolean;
  printQuickReportBarcodeCovid?: boolean;
  timeFrameUnit?: TimeFrameUnit;
  patGender?: Gender;
}

export interface RequirementBlacklist {
  id?: number;
  aid?: number;
  shortName?: string;
}

export interface RequirementWhitelist {
  id?: number;
  aid?: number;
  shortName?: string;
}

export interface Diagnosis {
  /**
   * ID of diagnosis
   */
  id?: number;
  key?: string;
  text?: string;
  costUnit?: string;
  /**
   * if true only adult females will have this diagnosis
   */
  onlyAdultFemale?: boolean;
}

export interface Ebm {
  /**
   * ID of ebm entry
   */
  id?: number;
  version?: string;
  gop?: string;
  bereich?: string;
  kapitel?: string;
  abschnitt?: string;
  name?: string;
  bewertung?: string;
}

export interface OrderSample {
  /**
   * ID of sample
   */
  sample_id?: number;
  /**
   * Count of samples
   */
  count?: number;
  /**
   * Count of samples before factor multiplication
   */
  originalCount?: number | null;
  /**
   * Count of samples stored in the database
   */
  storedCount?: number;
  /**
   * Is dynamic probetype
   */
  dynamic?: boolean;
  /**
   * Original Sample Type
   */
  originalSampleType?: string | null;
  /**
   * This is a reprinted entry of a dynamic type probe
   */
  reprinted?: boolean;
  /**
   * Sample Name
   */
  name?: string;
  /**
   * Small Image Content
   */
  imageLarge?: string;
  /**
   * Large Image Content
   */
  imageSmall?: string;
  /**
   * Unit
   */
  unit?: string;
  /**
   * Quantity
   */
  quantity?: number;
  /**
   * Order
   */
  sortingOrder?: number;
  /**
   * Short Code of the Laboratory Group
   */
  laboratoryGroupShortCode?: string;
  /**
   * Sample Type
   */
  sampleType?: string;
  /**
   * Sample Type on Label
   */
  sampleTypeLabel?: string;
  /**
   * ID of relevant requirements
   */
  requirements?: any[];
  comment?: string;
  notice?: string;
}

export interface Sample {
  /**
   * ID of sample
   */
  id?: number;
  /**
   * Manufacturer
   */
  manufacturer?: string;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Notice
   */
  notice?: string;
  /**
   * Sample Name
   */
  name?: string;
  /**
   * Small Image Content
   */
  imageLarge?: string;
  /**
   * Large Image Content
   */
  imageSmall?: string;
  /**
   * Unit
   */
  unit?: string;
  /**
   * Quantity
   */
  quantity?: number;
  /**
   * Order
   */
  sortingOrder?: number;
  /**
   * Short Code of the Laboratory Group
   */
  laboratoryGroupShortCode?: string;
  /**
   * Sample Type
   */
  sampleType?: string;
  /**
   * Sample Type on Label
   */
  sampleTypeLabel?: string;
  /**
   * Large Image Upload
   */
  imageLargeFile?: {
    [k: string]: any;
  } | null;
  /**
   * Small Image Upload
   */
  imageSmallFile?: {
    [k: string]: any;
  } | null;
  /**
   * Substitute Sample Type List
   */
  substituteSampleTypes?: string | null;
  laboratoryGroup?: LaboratoryGroup;
}

export interface OrderAustriaFields {
  /**
   * Field ID in LDT File for insured title
   */
  title?: string;
  /**
   * Field ID in LDT File for insured name
   */
  firstName?: string;
  /**
   * Field ID in LDT File for insured last name
   */
  lastName?: string;
  /**
   * Field ID in LDT File for insured birthday
   */
  birthday?: string;
  /**
   * Field ID in LDT File for insured insurance number
   */
  insuranceNumber?: string;
  /**
   * Field ID in LDT File for insured country code
   */
  country?: string;
  /**
   * Field ID in LDT File for insured postal code
   */
  zipCode?: string;
  /**
   * Field ID in LDT File for insured city
   */
  city?: string;
  /**
   * Field ID in LDT File for insured street
   */
  street?: string;
  /**
   * Field ID in LDT File for insured house number
   */
  houseNumber?: string;
  /**
   * Field ID in LDT File for insured address additional
   */
  addressAdditional?: string;
  /**
   * Field ID in LDT File for insured employer
   */
  employer?: string;
  /**
   * Field ID in LDT File for insured employer place
   */
  employerPlace?: string;
  /**
   * Field ID in LDT File for charge type
   */
  chargeType?: string;
}

export interface OrderExportSettings {
  /**
   * Export path on server fs to save generated LDT/HL7 files
   */
  path?: string;
  /**
   * Delay in minutes, how many minutes a order is waiting till it will be exported
   */
  delay?: number;
  ch_bags?: {
    [k: string]: any;
  };
  shortCodeMode?: ShortCodeMode;
  shortCodeOAAnswerMode?: ShortCodeMode;
  exportTrigger?: OrderExportTrigger;
}

export interface OrderFreeTextFields {
  /**
   * Field ID in LDT File for free text
   */
  fieldId?: string;
  /**
   * Pre append value in LDT File for for free text
   */
  preValue?: string;
  /**
   * default request
   */
  defaultRequest?: string;
}

export interface OrderFurtherFields {
  patientPhone?: OrderFieldWithPreValueProperties;
  patientEmail?: OrderFieldWithPreValueProperties;
  crownRumpLength?: OrderFieldWithPreValueProperties;
  nuchalTranslucency?: OrderFieldWithPreValueProperties;
  cycleLength?: OrderFieldWithPreValueProperties;
  anamnesticInfo?: OrderFieldWithPreValueProperties;
  bmi?: OrderFieldWithPreValueProperties;
  cycleDay?: OrderFieldWithPreValueProperties;
}

export interface OrderMicrobiologyFields {
  leftRight?: OrderFieldWithPreValueProperties;
  operative?: OrderFieldWithPreValueProperties;
  notes?: OrderFieldWithPreValueProperties;
  examination?: OrderFieldWithPreValueProperties;
  examinationId?: OrderFieldWithPreValueProperties;
  examinationText?: OrderFieldWithPreValueProperties;
  material?: OrderFieldWithPreValueProperties;
  materialId?: OrderFieldWithPreValueProperties;
  materialText?: OrderFieldWithPreValueProperties;
  origin?: OrderFieldWithPreValueProperties;
  originText?: OrderFieldWithPreValueProperties;
  originSite?: OrderFieldWithPreValueProperties;
}

export interface OrderOptionalFields {
  /**
   * Field ID in LDT File
   */
  fieldId?: string;
  /**
   * flag for exporting corresponding ldt field ID
   */
  export?: boolean;
}

export interface CentralBarcode {
  /**
   * Primary Key
   */
  cbid?: number;
  /**
   * ID of assigned order
   */
  orderId: number;
  barcode?: string;
  created_at?: string;
}

export interface OrderCostUnitMandatoryFields {
  id?: number;
  costUnit?: string;
  /**
   * ID of the order form -> a_schein.asid
   */
  orderFormID?: number;
  formType?: FormType;
}

export interface PrinterMapping {
  /**
   * ID of doctor
   */
  aid?: number;
  alias?: string;
  override_printer_name?: string | null;
  override_barcode_printer_name?: string | null;
  is_default?: boolean;
}

export interface FormLabelPrinterMapping {
  id?: number;
  aid?: number | null;
  formId?: number;
  workstationId?: number;
  printerName?: string;
  form?: OrderForm;
}

export interface TransportationHistory {
  /**
   * Primary Key
   */
  tid?: number;
  /**
   * DateTime of the creation of the transportation
   */
  created_at?: string;
}

export interface PatientGDT {
  /**
   * GDT Patient ID
   */
  apgid?: number;
  /**
   * Last Name
   */
  lastName?: string;
  /**
   * First Name
   */
  firstName?: string;
  /**
   * Title
   */
  title?: string;
  /**
   * Prefix
   */
  prefix?: string;
  /**
   * Suffix
   */
  suffix?: string;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Address
   */
  address?: string;
  /**
   * House Number
   */
  houseNumber?: string;
  /**
   * Additional Address Fields
   */
  addressAdditional?: string;
  /**
   * Zip Code
   */
  zipCode?: string;
  /**
   * City
   */
  city?: string;
  /**
   * Country Code
   */
  country?: string;
  /**
   * Insurance Number
   */
  insuranceNumber?: string;
  /**
   * Birthday
   */
  birthday?: string;
  /**
   * Phone Number
   */
  phone?: string;
  /**
   * Phone Number
   */
  email?: string;
  updated_at?: string;
  costUnit?: string;
  height?: string;
  pregnancyWeek?: string;
  pregnancyWeekDay?: string;
  costUnitIdentification?: string;
  medication?: string;
  cycleDay?: string;
  weight?: string;
  diagnosis?: string;
  exttnr?: string | null;
  /**
   * LANR (German Doctor's ID) of the primary care physician ("Erstveranlasser", The healthcare provider that holds primary responsibility for the overall care of a patient)
   */
  primaryCarePhysicianLanr?: string;
  primaryCarePhysicianBsnr?: string;
  /**
   * DMP
   */
  diseaseManagementProgram?: string;
  dmpSpecialGroup?: string;
  insuranceStart?: string;
  insuranceEnd?: string;
  insuranceType?: string;
  exceptionalIndication?: string;
  /**
   * SKT (Sonstiger Kostenträger)
   */
  sktIdentifier?: string;
  /**
   * WOP (Wohnortprinzip)
   */
  wopIdentifier?: string;
  accountingArea?: string | null;
  hvcode?: string;
  vkat?: string;
  secondName?: string | null;
  canton?: string | null;
  ahvNr?: string | null;
  aisNumber?: string;
  insurance?: number | null;
  bag?: string | null;
  cardNumber?: string | null;
  patientIdentification?: string;
  globalLocationNumber?: string | null;
  chTreatmentReason?: string | null;
  externalOrderInfo?: string | null;
  hospitalBedNumber?: string;
  hospitalRoomNumber?: string;
  hospitalProfession?: string | null;
  /**
   * Case ID
   */
  caseId?: number | null;
  caseNumber?: string;
  insuranceName?: string;
  invoiceTo?: string;
  dz116b?: boolean | null;
  autInsuranceTitle?: string;
  autInsuranceFirstName?: string;
  autInsuranceLastName?: string;
  autInsuranceInsuranceNumber?: string;
  autInsuranceBirthday?: string;
  autInsuranceAddress?: string;
  autInsuranceHouseNumber?: string;
  autInsuranceCountry?: string;
  autInsuranceZip?: string;
  autInsuranceCity?: string;
  gender?: Gender;
  doctor?: Doctor;
  orderReason?: OrderReason;
  caseEntity?: Case;
}

export interface CustomFieldForm {
  /**
   * ID of the order form to field assignment
   */
  id?: number;
  /**
   * ID of laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * ID of the order form -> a_schein.asid
   */
  orderFormID?: number;
  /**
   * ID of a custom field associated with the form -> a_custom_fields.id
   */
  formCustomFieldID?: number;
  /**
   * ID of a custom field to be associated with a requirement rule -> a_custom_fields.id
   */
  reqCustomFieldID?: number;
}

export interface CustomFields {
  /**
   * ID of the custom field
   */
  id?: number;
  /**
   * ID of laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * Name of the custom field
   */
  customFieldName?: string;
  /**
   * LDT code
   */
  ldtCode?: string;
  /**
   * Default text value
   */
  defaultText?: string;
  /**
   * Use state: live|del
   */
  useState?: string;
  customFieldType?: CustomFieldType;
}

export interface CustomFieldValues {
  /**
   * ID of the custom field value
   */
  id?: number;
  /**
   * ID of the order -> a_auftrag.id
   */
  orderID?: number;
  /**
   * ID of laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * ID of the custom field -> a_custom_fields.id
   */
  customFieldID?: number;
  /**
   * Value: textfields: default_text; checkbox: X if a checkbox was checked; 1 if if was drawn by a requirement rule
   */
  value?: string;
  /**
   * Origin of the value: S: it was drawn by the form; R: it was drawn by a rule; A: it was as rule type but is only drawn by the form
   */
  origin?: string;
  /**
   * ID of the order form -> a_schein.asid
   */
  orderFormID?: number;
}

export interface OrderForm {
  /**
   * ID of the order form
   */
  id?: number;
  /**
   * ID of the doctors
   */
  aid?: number[];
  /**
   * ID of the laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * Name of the order form
   */
  name?: string;
  costUnit?: string;
  /**
   * If the patient or the doctor will pay the costs
   */
  invoiceToChangeable?: boolean;
  /**
   * If the order form is private and we have therefore to calculate prices
   */
  isPrivate?: boolean;
  isDynamicMaterial?: boolean;
  isPoct?: boolean;
  isDefault?: boolean;
  isFilterHidden?: boolean;
  isDiagnosesSelect?: boolean;
  displayEbmPrice?: boolean;
  /**
   * A bite to the order form
   */
  formNote?: string;
  /**
   * If a graphic form pops up on selecting the order form within the order process
   */
  isGraphic?: boolean;
  /**
   * Barcode related comment to be saved with the order
   */
  barcodeComment?: string;
  /**
   * Export information to be saved with the order
   */
  exportInfo?: string;
  /**
   * If the order form is for microbiologic analyses
   */
  isBak?: boolean;
  satzart?: string;
  directscheindruckmode?: number;
  /**
   * Timestamp when the for was last changed; in the format YmdHis
   */
  lastChanged?: string;
  /**
   * If the form is for pool orders
   */
  isPool?: boolean;
  /**
   * If set the maximal count for requirements to be selected for one order
   */
  maximumRequirements?: number | null;
  /**
   * Base price to be added to the prices of the requirements
   */
  basePrice?: string;
  /**
   * If the order form is using dynamic probe types
   */
  hasDynProbeType?: boolean;
  /**
   * If the order form is soft deleted
   */
  isDeleted?: boolean;
  /**
   * Factor to multiply the net price to get the gross price
   */
  priceFactor?: string;
  /**
   * ID of the form group the order form is into -> a_schein_grp.sgid
   */
  formGroupID?: number | null;
  /**
   * Prefix to be prepended to the barcode
   */
  bcPrefix?: string;
  exportAsFile?: string;
  exportType?: number;
  /**
   * Short key of the labgroup; the empty string if no labgroup is assigned
   */
  laboratoryGroupShortCode?: string;
  /**
   * References a ZPL definition -> a_zpl.zpl_id
   */
  zplID?: number | null;
  defaultInvoiceTo?: StdPayer;
  splitMode?: SplitMode;
  digitalSignatureMode?: DigitalSignatureMode;
  formType?: FormType;
  laboratoryGroup?: LaboratoryGroup | null;
  requirements?: OrderFormRequirement[];
}

export interface OrderFormGroup {
  /**
   * ID of the order form group
   */
  id?: number;
  /**
   * ID of the laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * Name of the order form group
   */
  orderFormGroupName?: string;
  export?: string;
  comment?: string;
  sids?: string;
}

export interface OrderFormPage {
  /**
   * ID of the form page
   */
  id?: number;
  /**
   * ID of the form
   */
  orderFormID?: number;
  img?: string;
  /**
   * Orientation of the page (@todo)
   */
  orientation?: number;
  /**
   * Height of the page in mm
   */
  height?: number;
  /**
   * Width of the page in mm
   */
  width?: number;
  /**
   * Page number, unique within a certificate and regular pages or additional pages
   */
  pageNumber?: number;
  mode?: number;
  /**
   * 1: additional page; 0: regular page
   */
  isAdditionalPage?: boolean;
  pageName?: string;
  /**
   * Base 64 encoded model for order forms to be digitally signed
   */
  digitalModel?: string;
  /**
   * ID of the image -> a_image.image_id
   */
  imageID?: number;
}

export interface OrderFormRequirement {
  /**
   * ID of the requirement
   */
  requirementId?: number;
  /**
   * ID of the order form
   */
  formId?: number;
  counter?: number;
  /**
   * Form level price of the requirement
   */
  price?: number;
}

export interface OrderRule {
  /**
   * Primary Key
   */
  id?: number;
  name?: string;
  group?: string | null;
  description?: string;
  created_at?: string;
  autoGenerated?: boolean;
  printQuickReportBarcode?: boolean;
  printQuickReportBarcodeCovid?: boolean;
  conditions?: OrderRuleConditionProperties[];
  actions?: OrderRuleActionProperties[];
}

export interface OrderFile {
  /**
   * ID of file
   */
  stoid?: number;
  /**
   * File content, Base64
   */
  content?: string;
  /**
   * File name
   */
  filename?: string;
}

export interface OrderFileInfo {
  /**
   * ID of file info to order connection
   */
  id?: number;
  aid?: number;
  /**
   * ID of file
   */
  stoid: number;
  /**
   * File name
   */
  filename?: string;
  /**
   * Import date
   */
  importedAt?: string;
  /**
   * File status
   */
  status?: string;
  /**
   * File type
   */
  type?: string;
}

export interface OrderImageData {
  /**
   * ID of the image
   */
  id?: number;
  /**
   * The file name of the image
   */
  filename?: string;
  /**
   * The type of the image
   */
  type: string;
  /**
   * The size of the image in bytes
   */
  size?: number;
  /**
   * The width of the image
   */
  width?: number;
  /**
   * The height of the image
   */
  height?: number;
  /**
   * The type of the usage the image
   */
  useType: string;
  /**
   * Base64 content
   */
  content?: string | null;
  /**
   * Base64 content of the thumbnail
   */
  thumbnail?: string | null;
  /**
   * Timestamp of the last modification
   */
  lastModified?: string;
  laboratoryGroup?: LaboratoryGroup;
}

export interface OrderAttachment {
  /**
   * ID of attachment
   */
  anid?: number;
  /**
   * ID of assigned order
   */
  orderId: number;
  /**
   * File content, Base64
   */
  content?: string;
  /**
   * File name
   */
  fileName: string;
  /**
   * File path
   */
  filePath?: string;
  /**
   * File extension
   */
  fileExtension?: string;
  /**
   * File thumbnail, Base64
   */
  thumbnailContent?: string;
  /**
   * File extension
   */
  description?: string;
}

export interface GroupForm {
  id?: number;
  name?: string;
  costUnit?: string;
  columns?: number | null;
  order?: number;
  categories?: GroupFormCategory[];
}

export interface GroupFormCategory {
  id?: number;
  name?: string;
  groupFormId?: number;
  order?: number;
  subCategories?: GroupFormSubCategory[];
}

export interface GroupFormSubCategory {
  id?: number;
  name?: string;
  groupCategoryId?: number;
  bgColor?: string;
  order?: number;
  doubleWidth?: boolean;
  withTitle?: boolean;
  requirements?: GroupFormRequirement[];
}

export interface GroupFormRequirement {
  id?: number;
  formId?: number;
  shortName?: string;
  subCategoryId?: number;
  order?: number;
  entityId?: number;
  selectedAnalyses?: string[];
  entityType?: RequirementType;
  mibiRequirement?: MicrobiologicalRequirement;
}

export interface GroupFormOrder {
  id?: number;
  lid?: number;
  aid?: number;
  groupFormId?: number;
  order?: number;
}

export interface OrderAustriaHvCode {
  id?: number;
  code?: string;
  costUnit?: string;
  title?: string;
}

export interface OrderAustriaInsuranceCategory {
  id?: number;
  code?: string;
  title?: string;
}

export interface OrderCoronaWarnAppAssignment {
  /**
   * Order ID
   */
  orderId?: number;
  /**
   * Report ID
   */
  reportId?: number;
  guid?: string;
  result?: boolean;
  submitted?: boolean;
  /**
   * DateTime of submit
   */
  submitted_at?: string;
  salt?: string;
  prefix?: string;
  /**
   * Corona Warn App hash
   */
  cwaHash?: string;
  dccCompleted?: boolean;
  /**
   * Test ID hash
   */
  testIdHash?: string;
}

export interface ReRequestHistory {
  /**
   * ID of the real re-request history entry
   */
  id?: number;
  /**
   * Opened at timestamp
   */
  openedAt?: string;
  /**
   * Trace information of real re-request in JSON format
   */
  reRequestInfo?: string;
  /**
   * Trace information of requirements in JSON format
   */
  requirementInfo?: string;
  /**
   * Closed at timestamp
   */
  closedAt?: string;
  /**
   * Applied at timestamp
   */
  appliedAt?: string;
  order?: Order;
}

export interface ReRequestOrderRequirement {
  /**
   * ID of re-requested requirement to order connection
   */
  id?: number;
  /**
   * ID of order
   */
  orderId?: number;
  /**
   * ID of from
   */
  formId?: number;
  /**
   * ID of requirement
   */
  requirementId?: number;
  /**
   * Name of form
   */
  formName?: string;
  shortName?: string;
  /**
   * External name of requirements
   */
  externalName?: string;
  longName?: string;
  insurance?: string;
  /**
   * If the requirement is private and we have therefore to calculate prices
   */
  isPrivate?: boolean;
  /**
   * Price of ordered requirement
   */
  price?: string | null;
  /**
   * Free text value
   */
  freeText?: string;
  /**
   * Free text allowed
   */
  freeTextAllowed?: boolean;
  /**
   * Free text mandatroy
   */
  freeTextMandatory?: boolean;
  /**
   * Detailed data of requirement in JSON format
   */
  data?: string;
  /**
   * Comment for the requirement
   */
  comment?: string;
  /**
   * ID of re-request history entry
   */
  historyId?: number;
  /**
   * Indicates the accept / reject state of requirement
   */
  accept?: boolean | null;
  state?: RequirementRealReRequestState;
  order?: Order;
  form?: OrderForm;
  history?: ReRequestHistory;
}

export interface AnamnesisAnswer {
  id?: number;
  name?: string;
  text?: string;
  questions?: AnamnesisQuestion[];
}

export interface AnamnesisField {
  id?: number;
  maxlength?: number;
  gridSize?: number;
  name?: string;
  placeholder?: string;
  text?: string;
  complist?: string[];
}

export interface AnamnesisHeader {
  id?: number;
  name?: string;
  text?: string;
  horizontal?: boolean;
  backgroundColor?: string;
  weight?: number;
}

export interface AnamnesisQuestion {
  id?: number;
  name?: string;
  text?: string;
  weight?: number;
  ldt?: string;
  mandatory?: boolean;
  backgroundColor?: string;
  usePreviousAnswer?: boolean;
  infoText?: string;
  infoTextStyle?: string;
  type?: AnamnesisQuestionType;
  header?: AnamnesisHeader;
  answers?: AnamnesisAnswer[];
  field?: AnamnesisField;
  previous?: OrderAnamnesis;
}

export interface AnamnesisQuestionResponse {
  id?: number;
  questionId?: number;
  answerId?: number | null;
  fieldId?: number | null;
  order?: number;
  checked?: boolean;
}

export interface AnamnesisRequirementTrigger {
  id?: number;
  shortName?: string | null;
  laboratoryGroup?: string | null;
  formId?: number | null;
  questionId?: number;
  groupFormId?: number;
  form?: OrderForm;
  question?: AnamnesisQuestion;
}

export interface AnamnesisTriggerQuestion {
  id?: number;
  answerId?: number;
  questionId?: number;
  order?: number;
}

export interface OrderAnamnesis {
  id?: number;
  orderId?: number;
  formId?: number | null;
  shortName?: string | null;
  ldt?: string;
  questionId?: number;
  parentQuestionId?: number | null;
  answerId?: number | null;
  fieldId?: number | null;
  fieldValue?: string | null;
  questionText?: string;
  answerText?: string | null;
}

export interface OrderChangeLog {
  id?: number;
  xuser_id?: number;
  user_agent?: string;
  /**
   * Normalized name to display
   */
  user_name?: string;
  lid?: number;
  /**
   * Additional info
   */
  info?: string;
  ip?: string;
  created_at?: string;
  pool_id?: number;
  data?: {
    [k: string]: any;
  };
  action?: OrderChangeAction;
}

export interface OrderGuidAssignment {
  id?: number;
  orderId?: number;
  created_at?: string;
  guid?: string;
  tnr?: string;
  laboratoryId?: number;
  manualCode?: string | null;
}

export interface OrderSwitzerlandBag {
  id?: number;
  code?: string;
  name?: string;
  abbreviation?: string;
}

export interface OrderSwitzerlandBagToGln {
  id?: number;
  bag_code?: string;
  gln?: string;
}

export interface WebshopOrder {
  /**
   * ID of webshop order
   */
  id?: number;
  /**
   * Patient's email address
   */
  email: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  sex?: number;
  phoneNumber?: string;
  linkId?: number;
  country?: string;
  billingAddress?: string;
  billingZipCode?: string;
  billingCity?: string;
  billingCountry?: string;
  orderDate?: string;
  billingNumber?: string;
  deviceId?: string;
  productId?: number;
  order?: Order;
  orderPool?: OrderPool;
  paymentMethod?: PaymentMethod;
}

export interface WebshopOrderValidation {
  /**
   * ID of webshop order
   */
  id?: number;
  blinkIdDocumentCountry?: number;
  blinkIdDocumentRegion?: number;
  blinkIdDocumentType?: number;
  idcard?: WebshopOrderIdcardProperties;
}

export interface WebshopProduct {
  id?: number;
  /**
   * Product name
   */
  name?: string;
  dailyLimit?: number;
  doctorId?: number;
  /**
   * Certificate/form ID
   */
  formId?: number;
  requirementId?: number;
  /**
   * Form ID
   */
  privateFormId?: number;
  privateRequirementId?: number;
  priceInCents?: number;
  privatePriceInCents?: number;
  userSelectable?: boolean;
}

export interface PaymentVoucher {
  /**
   * The code itself
   */
  voucherCode: string;
  /**
   * True if the code has been consumed.
   */
  consumed?: boolean;
  purpose?: MvzMartinsriedVoucherPurpose | null;
  option?: MvzMartinsriedVoucherOption | null;
}

export interface Report {
  /**
   * Report ID
   */
  bid?: number;
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Parent report ID
   */
  parent_bid?: number | null;
  /**
   * Case ID
   */
  caseId?: number | null;
  /**
   * Number of report in laboratory
   */
  tnr?: string;
  /**
   * eingangsdatum
   */
  created_at?: string;
  /**
   * befunddatum
   */
  imported_at?: string;
  registered_at?: string;
  validated_at?: string;
  /**
   * date of taking the blood from the patient YYYYMMDDhhmm or YYYYMMDDhhmmss or different string like o.Ang.
   */
  sampled_at?: string;
  /**
   * Diagnosis of the report
   */
  diagnosis?: string;
  /**
   * Validation doctors name , to display in detail view
   */
  validatingDoctor?: string;
  /**
   * additionial line to validiera
   */
  validatingDoctorAddition?: string;
  /**
   * Formatted patient name
   */
  patientName?: string;
  /**
   * view of the report is locked for patient
   */
  is_locked?: boolean;
  /**
   * The user has not opened the report.
   */
  is_unread?: boolean;
  /**
   * The current user has pinned the report
   */
  is_pinned?: boolean;
  has_base64?: boolean;
  /**
   * Indicates if there is also an additional elpho graphic file
   */
  has_elpho?: boolean;
  /**
   * Indicates if this is a multi report
   */
  has_subreports?: boolean;
  /**
   * Indicates if the report has cumulative view
   */
  has_history?: boolean;
  /**
   * Count of attachments
   */
  attachment_count?: number;
  /**
   * Count of comments
   */
  comment_count?: number;
  /**
   * complete name of Doctor at the time of the report import
   */
  arzname?: string;
  /**
   * complete name of Laboratory at the time of the report import
   */
  labname?: string;
  accountingType?: string;
  /**
   * shortcode of laboratory group , display information of that group if it is set
   */
  laborgruppe?: string;
  /**
   * additional number of report , display if it is there
   */
  exttnr?: string;
  /**
   * KH Mode fields
   */
  lebensnummer?: string;
  /**
   * KH Mode fields
   */
  zimmer?: string;
  /**
   * timestamp with timezone ??
   */
  lastupdate?: string;
  /**
   * if not empty, display in report detail view
   */
  orderComment?: string;
  /**
   * if different backgroundcolor in table necessary, column contains hexcode
   */
  overwritecolor?: string | null;
  /**
   * highlighting color will be overwritten by overwritecolor
   */
  highlightColor?: string | null;
  /**
   * Url for report pdf
   */
  pdfSrc?: string;
  /**
   * Show in BDW, also important for reportdesigner
   */
  material?: string;
  /**
   * KH Mode fields
   */
  caseNumber?: string;
  insuranceNumber?: string;
  showBdw?: boolean;
  showCumulative?: boolean;
  showPdf?: boolean;
  showOriginal?: boolean;
  showOriginalExclusive?: boolean;
  showCumulativePdf?: boolean;
  showCumulativeAllPdf?: boolean;
  showSend?: boolean;
  showRerequest?: boolean;
  showSendReportApi?: boolean;
  originalPdfIds?: any[];
  /**
   * Report Globally Unique Identifier
   */
  guid?: string;
  /**
   * Report External Globally Unique Identifier
   */
  externalGuid?: string;
  /**
   * Manual QR Code for guid check
   */
  manualCode?: string;
  /**
   * Unique Report Import Identifier, 128-bit UUID as defined in RFC 4122, currenly only used in Idexx Import Logic
   */
  uuid?: string;
  status?: ReportStatus;
  pathological?: ReportCharacteristic;
  mibi?: ReportMibiType;
  doctor?: Doctor;
  patient?: Patient;
  laboratory?: Laboratory;
  laboratoryGroup?: LaboratoryGroup;
  laboratoryGroupFilter?: LaboratoryGroupFilter;
  values?: ReportValue[];
  subreports?: Report[];
  orders?: Order[];
  attachments?: ReportAttachment[];
  comments?: ReportComment[];
  settlementType?: SettlementType;
}

export interface ReportAttachment {
  /**
   * Attachment ID
   */
  anid?: number;
  /**
   * Report ID
   */
  bid?: number;
  /**
   * Laboratory ID
   */
  blid?: number;
  /**
   * File name
   */
  filename: string;
  /**
   * Description for the file
   */
  description?: string;
  /**
   * Base64 content
   */
  banh: string;
  /**
   * Upload time
   */
  created_at?: string;
  /**
   * Report tnr
   */
  btnr?: string;
}

export interface ReportBase64 {
  /**
   * Unique ID
   */
  id: number;
  /**
   * Base64 code of the PDF report (original report imported from LIS)
   */
  base64?: string;
}

export interface ReportBase64Hl7 {
  /**
   * Report ID
   */
  bid: number;
  /**
   * Base64 code of the HL7 report (original report imported from LIS)
   */
  base64hl7?: string;
}

export interface ReportValueContext {
  withPrevious?: any;
}

export interface ReportValue {
  /**
   * ID of laboratory
   */
  blid?: number;
  /**
   * ID of patient
   */
  bpid?: number;
  /**
   * Case ID
   */
  caseId?: number | null;
  /**
   * Short code
   */
  name?: string;
  /**
   * Group name
   */
  group?: string;
  /**
   * Description
   */
  description?: string;
  /**
   * ID of report
   */
  bid?: number;
  caseNumber?: string | null;
  /**
   * Number of report in laboratory
   */
  btnr?: string;
  /**
   * laboratory group of report
   */
  blg?: string;
  /**
   * Laboratory date and time of report
   */
  date?: string;
  /**
   * Value of Result
   */
  result?: string;
  /**
   * Is used for longer text results, triggers a different result line (without columns from_to_display, pathologic_code and without reference area graphic) in report rendering
   */
  textResult?: string;
  /**
   * Unit of Result
   */
  unit?: string;
  /**
   * if not null or not empty, then the current result is pathological. contains the pathological code
   */
  pathological?: string;
  /**
   * POCT value
   */
  poct?: boolean | null;
  /**
   * Number of line of value in the report
   */
  line?: number;
  /**
   * Shortcode of ResultGroup
   */
  catalog?: string;
  /**
   * analysed material
   */
  material?: string;
  /**
   * machine the analyse was made on
   */
  messgeraet?: string;
  /**
   * working place
   */
  arbeitsplatz?: string;
  /**
   * Additional Information (NTE in HL7)
   */
  meta?: string[];
  showResultOnly?: boolean;
  /**
   * Gets rendered in the antibiogram comment column
   */
  comment?: string;
  /**
   * Table index for mibi reports. Combines (groups) all results for each microbiologic table, just needed for microbiologic reports. For example, all results with 0 will be displayed in a single table, all 1 results are displayed in the next (separate) table.
   */
  mibiTableIndex?: number;
  /**
   * Reference text for the result-footnote, e.g. "2)" or "2),3)"
   */
  commentReferenceResult?: string;
  /**
   * Reference text for the result_group footnote, e.g. "1)"
   */
  commentReferenceResultGroup?: string;
  /**
   * Short code of the material
   */
  materialId?: string;
  /**
   * Whether the test is finished yet
   */
  status?: string;
  /**
   * is associated with an assay and should be displayed next to the assay name
   */
  methodology?: string;
  /**
   * Whether the test has been cancelled
   */
  cancel?: boolean;
  /**
   * Whether the test is part of the additional order
   */
  addOn?: boolean;
  isRtfConverted?: boolean;
  reference?: ReportValueReference;
  graphicFormat?: ResultGraphicFormat;
  format?: ResultFormat;
  previous?: ReportValueProperties;
  case?: Case;
  mibi?: ReportValueMibiType;
  commentType?: ReportValueCommentType;
}

export interface ReportComment {
  /**
   * Primary key
   */
  id?: number;
  /**
   * Report ID
   */
  bid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  xuser_id?: number | null;
  /**
   * Normalized name to display
   */
  user_name?: string;
  /**
   * Comment
   */
  data?: string;
  /**
   * Creation timestamp
   */
  created_at?: string;
}

export interface FilterProfile {
  /**
   * Primary key
   */
  pid?: number;
  /**
   * Filter name
   */
  name?: string;
  /**
   * Creation timestamp
   */
  created_at?: string;
  /**
   * User ID
   */
  id?: number;
  /**
   * User Type
   */
  typ?: string;
  settings?: FilterProfileProperties;
}

export interface ReportElpho {
  /**
   * Number of report in laboratory
   */
  tnr?: string;
  /**
   * Laboratory date and time of report
   */
  date: string;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Report ID (relation to b_befund)
   */
  bid: number;
  /**
   * PK, unique id
   */
  elphoid?: number;
  /**
   * Numerical values between 0 and 1000, X axis
   */
  x_value: number;
  /**
   * Numerical values between 0 and 1000, Y axis
   */
  y_value: number;
  line?: ReportElphoLine;
  type?: ReportElphoType;
}

export interface LanrSearch {
  alsid?: number;
  aid?: number;
  lanr?: string;
  lanrsynonym?: string;
  sort?: number;
  default?: boolean;
}

export interface ReportPrintJob {
  /**
   * ID of the print job
   */
  did?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * The ID of the doctor of the report
   */
  aid?: number;
  /**
   * The path of the print result
   */
  filepath?: string;
  /**
   * Laboratory ID of the report
   */
  blid?: number;
  /**
   * Report date
   */
  bdatum?: string;
  /**
   * Report tnr
   */
  btnr?: string;
  /**
   * The ID of the doctor of the print job
   */
  daid?: number;
  /**
   * The ID of the printer
   */
  drucker?: number;
  /**
   * The order of the print job
   */
  order?: number;
  /**
   * The name of the sender of the mail
   */
  sendername?: string;
  /**
   * The language to use for the job
   */
  sprache?: string;
  /**
   * The password to encrypt the print result
   */
  pdfpass?: string;
  /**
   * Report ID
   */
  bid?: number;
  drucktyp?: ReportPrintJobTypes;
  status?: ReportPrintJobStatus;
}

export interface Blacklist {
  bbid?: number;
  value?: string;
  lid?: number;
  exact?: boolean;
}

export interface CustomPush {
  cpid?: number;
  value?: string;
  lid?: number;
}

export interface CustomSort {
  lid?: number;
  /**
   * Methode
   */
  methode?: string;
  /**
   * Description
   */
  description?: string;
  /**
   * Sorting group
   */
  group?: string;
  /**
   * Sorting group
   */
  laboratoryGroup?: string;
  /**
   * Number of line of value in the report
   */
  line?: number;
}

export interface ReportDetails {
  /**
   * Insuranced lastname
   */
  vname?: string;
  /**
   * Insuranced firstname
   */
  vvorname?: string;
  /**
   * Insuranced health id
   */
  vvernnr?: string;
  /**
   * Insuranced titel
   */
  vtitel?: string;
}

export interface ReportPin {
  /**
   * Report ID
   */
  bid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  xuser_id?: number | null;
  /**
   * Creation timestamp
   */
  created_at?: string;
}

export interface ReportNote {
  /**
   * Number of report in laboratory
   */
  tnr: string;
  /**
   * Laboratory date and time of report
   */
  date: string;
  /**
   * Laboratory ID
   */
  lid: number;
  line: number;
  textContent?: string;
  /**
   * Reference text for the footnote, shown at the reference, e.g. "2)" or "2),3)"
   */
  referenceText?: string;
  /**
   * Report ID
   */
  bid?: number;
  type?: ReportNoteType;
}

export interface ReportBudget {
  /**
   * Number of report in laboratory
   */
  tnr: string;
  /**
   * Laboratory date and time of report
   */
  date: string;
  /**
   * Laboratory ID
   */
  lid: number;
  /**
   * Report ID
   */
  bid?: number;
  /**
   * Line index, e.g. 1; higher indices are rendered below
   */
  line: number;
  /**
   * Position shorttext, e.g. "PK" (opt.)
   */
  billingPositionId?: string;
  /**
   * Position name as displayed, e.g. "Bakteriologische Untersuchung"
   */
  billingPositionName?: string;
  /**
   * Position price as displayed, excl. VAT, e.g. "28.28"
   */
  billingPositionPrice?: string;
  /**
   * VAT rate as displayed, e.g. "19.00" (on report level only, not position-level)
   */
  vatPercentage?: string;
  /**
   * Total amount excl. VAT as displayed, e.g. "111.86" (on report level only, not position-level)
   */
  billingSumExclVat?: string;
  /**
   * Total amount incl. VAT as displayed, e.g. "133.11" (on report level only, not position-level)
   */
  billingSumInclVat?: string;
  /**
   * VAT amount as displayed, e.g. "21.25" (on report level only, not position-level)
   */
  billingVatSum?: string;
  /**
   * Currency symbol or shortcode as displayed, e.g. "EUR" (on report level only, not position-level)
   */
  billingCurrency?: string;
}

export interface ReportReferenceComment {
  /**
   * Report ID
   */
  bid: number;
  /**
   * Line index, e.g. 1; higher indices are rendered below, i.e. position_number 2 comes after position_number 1
   */
  positionNumber: number;
  /**
   * Text content for this comment
   */
  content: string;
}

export interface ReportTriggerAction {
  /**
   * ID of the trigger action
   */
  sltaid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Number of report in laboratory
   */
  tnr?: string;
  /**
   * Laboratory date and time of report
   */
  date: string;
  /**
   * Standard flag
   */
  standard?: number;
  /**
   * Fax flag
   */
  fax?: number;
  /**
   * Mail flag
   */
  mail?: number;
  /**
   * Filename of the recipe to be inserted
   */
  recipeFile?: string;
  /**
   * Current ldtaction level
   */
  level?: number;
  /**
   * Datetime of import in format yyyMMddHHmmss
   */
  importTime?: string;
  /**
   * Unique Transaction Identifier, 128-bit UUID as defined in RFC 4122, currenly only used in Idexx Import Logic
   */
  uuid?: string;
  pdfMode?: FileGenerationMode;
  ldtMode?: FileGenerationMode;
  elphoMode?: FileGenerationMode;
}

export interface ReportAccessLog {
  id?: number;
  xuser_id?: number | null;
  user_agent?: string;
  /**
   * Normalized name to display
   */
  user_name?: string;
  lid?: number;
  /**
   * Additional info
   */
  info?: string;
  ip?: string;
  created_at?: string;
  /**
   * Report ID
   */
  bid?: number;
  action?: ReportAccessAction;
}

export interface ReportChangeLog {
  id?: number;
  xuser_id?: number;
  user_agent?: string;
  /**
   * Normalized name to display
   */
  user_name?: string;
  lid?: number;
  /**
   * Additional info
   */
  info?: string;
  ip?: string;
  created_at?: string;
  /**
   * Report ID
   */
  bid?: number;
  /**
   * Log data
   */
  data?: string;
  action?: ReportChangeAction;
}

export interface ReportApilog {
  /**
   * Report ID
   */
  bid: number;
  /**
   * Doctor ID
   */
  aid: number;
  /**
   * Upload time
   */
  access?: string;
}

export interface DesignerSettings {
  /**
   * Designer Setting ID
   */
  settingId?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Flag for showing Patient PDF Export Button
   */
  patPdf?: boolean;
  /**
   * Flag for showing cumulative PDF button
   */
  showCumulativePdf?: boolean;
  /**
   * Flag for showing cumulative all PDF button
   */
  showCumulativeAllPdf?: boolean;
}

export interface DoctorGroupContext {
  withMeta?: any;
}

export interface DoctorGroup {
  /**
   * DoctorGroup ID
   */
  gid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Group Name
   */
  name?: string;
  /**
   * Flag indicating if the group is also an Online Order Gruppenarztpraxis
   */
  oagrp?: boolean;
  /**
   * Flag indicating if the group is also an Labstore Gruppenarztpraxis
   */
  lsgrp?: boolean;
  members_count?: number;
  owners_count?: number;
  total_count?: number;
  /**
   * Is Group Admin
   */
  isAdmin?: boolean;
  /**
   * Is Group Member
   */
  isMember?: boolean;
  members?: DoctorGroupMember[];
}

export interface DoctorGroupMember {
  /**
   * DoctorGroup ID
   */
  gid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * Is Group Admin
   */
  isAdmin?: boolean;
  /**
   * Is Group Member
   */
  isMember?: boolean;
  /**
   * Notify report info
   */
  bengrp?: boolean;
  /**
   * Shares printers to group
   */
  sharesPrinters?: boolean;
  doctor?: Doctor;
  group?: DoctorGroup;
}

export interface DoctorGroupOffice {
  /**
   * DoctorGroupOffice ID
   */
  pid: number;
  /**
   * Doctor ID (Group office owner)
   */
  aid?: number;
  /**
   * Group office Name
   */
  name?: string;
  /**
   * Flag indicating if the group is deletable
   */
  deletable?: boolean;
  lanr?: string;
  bsnr?: string;
  kurzform?: string;
}

export interface LaboratoryGroup {
  /**
   * LaboratoryGroup ID
   */
  grpid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * ShortCode of laboratory group
   */
  kurz?: string;
  /**
   * Titel or Name of laboratory group
   */
  name?: string;
  /**
   * Phone number
   */
  telefon?: string;
  /**
   * Fax number
   */
  fax?: string;
  /**
   * Website adress
   */
  website?: string;
  /**
   * Street adress include house number
   */
  adresse?: string;
  /**
   * town
   */
  stadt?: string;
  /**
   * mail adress
   */
  email?: string;
  /**
   * mail for notification over report detail view
   */
  rerequestMail?: string;
  /**
   * additional information line 1
   */
  zusatz1?: string;
  /**
   * additional information line 2
   */
  zusatz2?: string;
  /**
   * additional information line 3
   */
  zusatz3?: string;
  /**
   * postal code
   */
  plz?: string;
  /**
   * Enable PDF export for reports of laboratory group
   */
  showPdfExport?: boolean;
  laboratoryGroupBarcodeSettings?: LaboratoryGroupBarcodeSettings;
  advancedAlphanumericBarcodeSettings?: AdvancedAlphanumericBarcode;
}

export interface LaboratoryGroupFilter {
  /**
   * Laboratory Group Filter ID
   */
  id?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Laboratory Group Filter Name
   */
  name: string;
  /**
   * Laboratory Group Filter Color
   */
  color?: string | null;
  /**
   * Laboratory Group
   */
  group?: string | null;
}

export interface LabFeature {
  /**
   * Laboratory Feature ID
   */
  labfeature_id?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  feature?: Feature;
  product?: Product;
  arz_meth?: FeatureSetting;
  pat_meth?: FeatureSetting;
}

export interface LabProduct {
  lpid?: number;
  lid?: number;
  doctorsEnabled?: boolean;
  patientsEnabled?: boolean;
  appEnabled?: boolean;
  explicitLock?: boolean;
  product?: Product;
}

export interface LegalInfo {
  /**
   * Laboratory ID
   */
  lid?: number;
  text?: string;
  locale?: LanguageCode;
  type?: LegalInfoType;
}

export interface Link {
  xlinksid?: number;
  lid?: number;
  position?: number;
  linkname: string;
  linkhref: string;
  product?: LinkProduct;
  platform?: LinkPlatform;
}

export interface ReportDisplaySettings {
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Email Reports
   */
  emailReports?: boolean;
  /**
   * Export Reports
   */
  exportReports?: boolean;
  /**
   * Contact Laboratory
   */
  contactLaboratory?: boolean;
  /**
   * Report Attachments
   */
  reportAttachments?: boolean;
  /**
   * Rerequests
   */
  rerequests?: boolean;
  /**
   * Print Orders
   */
  printOrders?: boolean;
  showPathoColumn?: boolean;
  hidePathoColumnPat?: boolean;
  showLaboratoryGroup?: boolean;
  rerequestTimeFrame?: number;
  alwaysShowDateAndTimeInCumulativeDisplay?: boolean;
  pathoHighlightType?: ReportPathoHighlight;
}

export interface ServerConfig {
  value?: string;
  key?: ServerConfigKey;
}

export interface UserFeature {
  /**
   * User Feature ID
   */
  ufeature_id?: number;
  lid?: number;
  aid?: number;
  pid?: number;
  xuser_id?: number;
  feature?: Feature;
  product?: Product;
  assign_type?: UserFeatureSetting;
}

export interface UserPreference {
  id?: number;
  user_id?: number;
  user_type?: string;
  pref_name?: string;
  pref_value?: string | null;
}

export interface AdvancedAlphanumericBarcode {
  /**
   * Barcode ID
   */
  id?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * LaboratoryGroup ID
   */
  grpid?: number;
  /**
   * ShortCode of laboratory group
   */
  group?: string;
  /**
   * Current print position 1
   */
  printPosition1?: string;
  /**
   * Print range minimum 1
   */
  printRangeMin1?: string;
  /**
   * Print range maximum 1
   */
  printRangeMax1?: string;
  /**
   * Current print position 2
   */
  printPosition2?: string;
  /**
   * Print range minimum 2
   */
  printRangeMin2?: string;
  /**
   * Print range maximum 2
   */
  printRangeMax2?: string;
  /**
   * Current print position 3
   */
  printPosition3?: string;
  /**
   * Print range minimum 3
   */
  printRangeMin3?: string;
  /**
   * Print range maximum 3
   */
  printRangeMax3?: string;
  useState?: AdvancedAlphanumericBarcodeUseState;
}

export interface LaboratoryGroupBarcodeSettings {
  /**
   * Barcode setting ID
   */
  id?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * LaboratoryGroup ID
   */
  grpid?: number;
  /**
   * ShortCode of laboratory group
   */
  group?: string;
  /**
   * Barcode prefix
   */
  printPrefix?: string;
  /**
   * Current print position
   */
  printPosition?: string;
  /**
   * Print range minimum
   */
  printRangeMin?: string;
  /**
   * Print range maximum
   */
  printRangeMax?: string;
}

export interface ContactInfo {
  id?: number;
  lid?: number;
  title?: string;
  short?: string;
  phone?: string;
  whatsapp?: string | null;
  fax?: string | null;
  www?: string | null;
  address?: string;
  city?: string;
  email?: string;
}

export interface SitePreference {
  id?: number;
  lid?: number;
  user_type?: string;
  pref_name?: string;
  pref_value?: string | null;
}

export interface PdfLink {
  id?: number;
  lid?: number;
  position?: number;
  name: string;
  pdfName?: string;
  /**
   * Uploaded PDF
   */
  pdfFile?: {
    [k: string]: any;
  };
  product?: LinkProduct;
}

export interface Laboratory {
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Normalized salutation to display
   */
  salutation?: string;
  /**
   * Normalized email
   */
  email?: string;
  userDebugEnabled?: boolean;
  /**
   * Product OA explicitly enabled/disabled
   */
  oa?: boolean;
  /**
   * Product OB explicitly enabled/disabled
   */
  ob?: boolean;
  /**
   * Product OC explicitly enabled/disabled
   */
  of?: boolean;
  /**
   * Product LL explicitly enabled/disabled
   */
  ll?: boolean;
  /**
   * Product LE explicitly enabled/disabled
   */
  le?: boolean;
  /**
   * Product LS explicitly enabled/disabled
   */
  ls?: boolean;
  /**
   * Product LC explicitly enabled/disabled
   */
  lc?: boolean;
  /**
   * Product DD explicitly enabled/disabled
   */
  dd?: boolean;
  /**
   * Product MC explicitly enabled/disabled
   */
  mc?: boolean;
  /**
   * Product NC explicitly enabled/disabled
   */
  nc?: boolean;
  /**
   * Product LW explicitly enabled/disabled
   */
  lw?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  admin?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  dashboard?: boolean;
  /**
   * { [feature key]: enabled }
   */
  features?: {
    [k: string]: any;
  };
  /**
   * HL7-Name (short code for import / export)
   */
  hl7Name?: string;
  /**
   * App target id (de.labuniq....)
   */
  target?: string | null;
  companyName?: string;
  /**
   * Address
   */
  address?: string | null;
  /**
   * Zip + City
   */
  city?: string | null;
  /**
   * Phone number
   */
  phone?: string | null;
  /**
   * Whatsapp kontakt
   */
  whatsapp?: string | null;
  /**
   * Fax number
   */
  fax?: string | null;
  /**
   * Website
   */
  www?: string | null;
  /**
   * Email for OrderRequests
   */
  rerequestMail?: string | null;
  autoLockReport?: boolean;
  class0?: number;
  class1?: number;
  class2?: number;
  class3?: number;
  class4?: number;
  class5?: number;
  /**
   * First Name
   */
  firstName?: string | null;
  /**
   * Title
   */
  title?: string | null;
  /**
   * Suffix
   */
  suffix?: string | null;
  /**
   * Login Site
   */
  loginSite?: string | null;
  /**
   * Additional Detail 1
   */
  additional1?: string | null;
  /**
   * Additional Detail 2
   */
  additional2?: string | null;
  /**
   * Additional Detail 3
   */
  additional3?: string | null;
  /**
   * Postal Code
   */
  zipCode?: string | null;
  /**
   * Contact Information
   */
  contactInfo?: string | null;
  /**
   * whether to verify IKs or not
   */
  verifyIKs?: boolean;
  displayText?: string | null;
  /**
   * maximal number of parallel logins
   */
  concurrentLoginLimit?: number;
  /**
   * which barcode algorithm to use e.g. I25, EAN8, POSTNET...
   */
  barcodeAlgorithm?: string;
  /**
   * length of the barcode
   */
  barcodeLength?: number;
  /**
   * width of barcode sticker in mm
   */
  labelWidth?: number;
  /**
   * height of barcode sticker in mm
   */
  labelHeight?: number;
  /**
   * width of barcode in mm
   */
  barcodeWidth?: number;
  /**
   * height of barcode in mm
   */
  barcodeHeight?: number;
  /**
   * Base number for generating dynamic probe types
   */
  dynamicProbeTypeBase?: number;
  /**
   * whether the activated patients can view partial results per default
   */
  defaultPatientCanViewPartialResults?: boolean;
  /**
   * whether the activated patients can view preliminary results per default
   */
  defaultPatientCanViewPreliminaryResults?: boolean;
  /**
   * Current print position
   */
  printPosition?: string | null;
  /**
   * Print range minimum
   */
  printRangeMin?: string | null;
  /**
   * Print range maximum
   */
  printRangeMax?: string | null;
  /**
   * Print prefix
   */
  printPrefix?: string | null;
  overwrite?: boolean;
  resetCentralBarcodes?: boolean;
  mtlMail?: string;
  /**
   * Use laboratory group mail address if a different lab group is set on the form
   */
  useLgMail?: boolean;
  /**
   * Limit in days from order created date
   */
  orderTimeframe?: number;
  /**
   * Limit in hours from order executed timestamp
   */
  reRequestLimit?: number;
  /**
   * The buffer in minutes after begin of the procedure within the doctor may make the additional requests
   */
  reRequestBuffer?: number;
  /**
   * Option to approve the additional requests by the laboratory; if false the they will be turned directly into regular requests
   */
  reRequestApprove?: boolean;
  /**
   * No further export due to real re-requests
   */
  reRequestNoExport?: boolean;
  /**
   * Calculate the real re-request time limit based on the sample date
   */
  reRequestLimitSampleDate?: boolean;
  quickScanPdfMode?: number;
  quickScanWalletMode?: number;
  quickScanAskFirstName?: boolean;
  quickScanAskPassport?: boolean;
  logo?: string | null;
  fontColor?: string | null;
  backgroundColor?: string | null;
  highlightColor?: string | null;
  status?: UserStatus;
  locale?: LanguageCode;
  user?: User;
  showPdf?: OriginalReportDisplayValue;
  showOriginal?: OriginalReportDisplayValue;
  showBdw?: OriginalReportDisplayValue;
  reportDisplay?: ReportDisplaySettings;
  mailTemplateGroup?: MailTemplateGroup;
  emailsBlock?: EmailsBlockType[];
  orderLocalization?: OrderWizardLocalization;
}

export interface Doctor {
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Mail Template Group id
   */
  mtgid?: number | null;
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Salutation
   */
  salutation?: string | null;
  email?: string | null;
  userDebugEnabled?: boolean;
  /**
   * Product OA explicitly enabled/disabled
   */
  oa?: boolean;
  /**
   * Product OB explicitly enabled/disabled
   */
  ob?: boolean;
  /**
   * Product OC explicitly enabled/disabled
   */
  of?: boolean;
  /**
   * Product LL explicitly enabled/disabled
   */
  ll?: boolean;
  /**
   * Product LE explicitly enabled/disabled
   */
  le?: boolean;
  /**
   * Product LS explicitly enabled/disabled
   */
  ls?: boolean;
  /**
   * Product LC explicitly enabled/disabled
   */
  lc?: boolean;
  /**
   * Product DD explicitly enabled/disabled
   */
  dd?: boolean;
  /**
   * Product MC explicitly enabled/disabled
   */
  mc?: boolean;
  /**
   * Product NC explicitly enabled/disabled
   */
  nc?: boolean;
  /**
   * Product LW explicitly enabled/disabled
   */
  lw?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  admin?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  dashboard?: boolean;
  /**
   * { [feature key]: enabled }
   */
  features?: {
    [k: string]: any;
  };
  /**
   * External Doctor ID (unique identification for laboratory system)
   */
  externalId?: string | null;
  externalId2?: string;
  /**
   * Physician identifier in Germany - "Lebenslange Arztnummer"
   */
  lanr?: string | null;
  /**
   * Title
   */
  title?: string | null;
  /**
   * Last Name
   */
  lastName?: string;
  /**
   * First Name
   */
  firstName?: string | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  houseNumber?: string | null;
  countryCode?: string | null;
  /**
   * Phone Number
   */
  phoneNumber?: string | null;
  /**
   * Fax Number
   */
  faxNumber?: string | null;
  /**
   * Phone Number
   */
  smsNumber?: string | null;
  /**
   * Website
   */
  www?: string | null;
  welcomeLabel?: string | null;
  logoutLabel?: string | null;
  /**
   * is allowed to activate patients
   */
  canActivatePatients?: boolean;
  /**
   * whether the user is superdoctor or not; superdoctors can view reports from other doctors
   */
  isSuperDoctor?: boolean;
  /**
   * Setting for where to print "Scheine"; 0=inaktiv, 1=kundenmodus AN (upload to special api), 2=kundenmodus LZ (upload to sftp)
   */
  sendReportApi?: number;
  /**
   * whether the view of diagnoses is disabled for the user
   */
  disableViewDiagnosis?: boolean;
  /**
   * doctor receives emails for different events
   */
  emailNotificationEnabled?: boolean;
  /**
   * values the doctor receives emails for: 0=all, 1=path, 2=extreme, 3=deltacheck. e.g. "0,2,3"
   */
  emailNotificationsCharacteristics?: string;
  /**
   * reports the doctor receives emails for: a=Vorbefund, e=partial result, t=final result. e.g. "a,t"
   */
  emailNotificationsStatus?: string;
  /**
   * when active, doctor can send reports via email
   */
  emailReports?: boolean;
  /**
   * Doctor´s group office name
   */
  officeName?: string;
  /**
   * BSNR from OA
   */
  bsnr?: string | null;
  /**
   * Manufacturer
   */
  manufacturer?: string;
  /**
   * allows urgent orders
   */
  urgent?: boolean;
  /**
   * allows emergency orders
   */
  emergency?: boolean;
  /**
   * allows marking orders as contagious
   */
  infectious?: boolean;
  /**
   * minimal status for oaanswer trigger
   */
  oaanswertrigger?: string;
  /**
   * can delete orders
   */
  oaSoftDelete?: boolean;
  /**
   * OA setting - "Blankodruck" (0) or "Vorlagendruck" (1)
   */
  printMode?: number;
  /**
   * Setting for where to print barcodes; 0=no barcodeprint, 1=client, 2=server
   */
  barcodePrintSettings?: number;
  /**
   * name of the barcode printer (from CUPS or windows print management) - OA printsettings
   */
  printername?: string;
  /**
   * Setting for where to print "Scheine"; 0=no Scheindruck, 1=client, 2=server
   */
  scheinPrintSettings?: number;
  /**
   * name of the "schein" printer (from CUPS or windows print management) - OA printsettings
   */
  printernameSchein?: string;
  socketPrint?: boolean;
  internalPrintUser?: boolean;
  /**
   * custom doctor "Stempel"
   */
  customDoctorHeading?: boolean;
  /**
   * custom "Arztstempel" line 1
   */
  customDoctorHeadingLine1?: string;
  /**
   * custom "Arztstempel" line 2
   */
  customDoctorHeadingLine2?: string;
  /**
   * custom "Arztstempel" line 3
   */
  customDoctorHeadingLine3?: string;
  /**
   * custom "Arztstempel" line 4
   */
  customDoctorHeadingLine4?: string;
  /**
   * custom "Arztstempel" line 5
   */
  customDoctorHeadingLine5?: string;
  /**
   * custom "Arztstempel" line 6
   */
  customDoctorHeadingLine6?: string;
  /**
   * custom "Arztstempel" line 7
   */
  customDoctorHeadingLine7?: string;
  /**
   * custom "Arztstempel" line 8
   */
  customDoctorHeadingLine8?: string;
  /**
   * custom "Arztstempel" line 9
   */
  customDoctorHeadingLine9?: string;
  /**
   * doctor can choose requests / orders graphically
   */
  graphicalRequestChooser?: boolean;
  /**
   * enable/disable IP check on login
   */
  ipConstraint?: boolean;
  /**
   * automatically print "Schein" on order execution
   */
  autoprint?: boolean;
  /**
   * Field of the physician, e.g. surgery
   */
  speciality?: string | null;
  /**
   * number of reports of the physician
   */
  reportCount?: number;
  doctorGroupOfficeName?: string;
  pid?: number;
  editKisMode?: boolean;
  /**
   * Current print position
   */
  printPosition?: string | null;
  /**
   * Print range minimum
   */
  printRangeMin?: string | null;
  /**
   * Print range maximum
   */
  printRangeMax?: string | null;
  /**
   * Print prefix
   */
  printPrefix?: string | null;
  useOwnNummernkreis?: boolean;
  useLgNummernkreis?: boolean;
  exportMe?: boolean;
  exportMeReceiver?: string | null;
  exportMeStatusString?: string;
  exportMeCharacteristicString?: string;
  status?: UserStatus;
  user?: User;
  hierarchyStatus?: DoctorHierarchyStatus | null;
  hierarchyAssignments?: DoctorHierarchyPathProperties[];
  hierarchyTree?: DoctorHierarchyPathProperties[];
  localisation?: OrderWizardLocalization | null;
  laboratoryLocalisation?: OrderWizardLocalization;
  defaultInvoiceTo?: StdPayer | null;
  reportsLanguageSetting?: LanguageCode | null;
  forms?: OrderForm[];
  profiles?: RequirementProfile[];
  globalProfiles?: RequirementGlobalProfile[];
  groups?: DoctorGroup[];
  groupForms?: GroupForm[];
  devices?: DoctorDevice[];
  doctorGroupOffices?: DoctorGroupOffice[];
  laboratory?: Laboratory;
  barcodeMode?: DoctorBarcodeMode;
  exportMeSettingsStatusFlags?: ExportMeSettingsStatusFlags;
  exportMeSettingsCharacteristicFlags?: ExportMeSettingsCharacteristicFlags;
}

export interface LaboratoryUser {
  /**
   * LaboratoryUser ID
   */
  luid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Normalized salutation to display
   */
  salutation?: string;
  /**
   * Normalized email
   */
  email?: string;
  userDebugEnabled?: boolean;
  /**
   * Courier service type user
   */
  courierService?: boolean;
  status?: UserStatus;
  locale?: LanguageCode;
  user?: User;
  privilegeMode?: LaboratoryUserPrivilegeMode;
}

export interface Administrator {
  /**
   * Administrator ID
   */
  suid?: number;
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Normalized salutation to display
   */
  salutation?: string;
  userDebugEnabled?: boolean;
  /**
   * E-Mail
   */
  email?: string;
  status?: UserStatus;
  locale?: LanguageCode;
  type?: AdministratorType;
  user?: User;
}

export interface Patient {
  /**
   * Patient ID
   */
  pid?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Mail Template Group id
   */
  mtgid?: number;
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Normalized salutation to display
   */
  salutation?: string;
  /**
   * Patient Email Address
   */
  email?: string;
  userDebugEnabled?: boolean;
  /**
   * Patient Last Name
   */
  lastName?: string;
  /**
   * Patient First Name
   */
  firstName?: string;
  /**
   * Suffix
   */
  suffix?: string;
  /**
   * Patient Title
   */
  title?: string;
  /**
   * Patient Birthday
   */
  birthday?: string;
  /**
   * Patient City
   */
  city?: string;
  /**
   * Patient Zip Code
   */
  zipCode?: string;
  /**
   * street name
   */
  address?: string;
  /**
   * street number
   */
  houseNumber?: string;
  addressAdditional?: string;
  /**
   * country code, e.g. AT, DE
   */
  country?: string;
  /**
   * Patient Insurance Number
   */
  insuranceNumber?: string;
  /**
   * Patient Phone Number
   */
  phone?: string;
  costUnit?: string;
  /**
   * Product OB explicitly enabled/disabled
   */
  ob?: boolean;
  /**
   * Product OC explicitly enabled/disabled
   */
  of?: boolean;
  /**
   * Product LL explicitly enabled/disabled
   */
  ll?: boolean;
  /**
   * Product LE explicitly enabled/disabled
   */
  le?: boolean;
  /**
   * Product LS explicitly enabled/disabled
   */
  ls?: boolean;
  /**
   * Product MC explicitly enabled/disabled
   */
  mc?: boolean;
  /**
   * Product NC explicitly enabled/disabled
   */
  nc?: boolean;
  /**
   * Product LW explicitly enabled/disabled
   */
  lw?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  dashboard?: boolean;
  /**
   * { [feature key]: enabled }
   */
  features?: {
    [k: string]: any;
  };
  sonstiges1?: string;
  sonstiges2?: string;
  /**
   * patient ID
   */
  patientIdentification?: string;
  emailNotificationEnabled?: boolean;
  smsNotificationEnabled?: boolean;
  smsNumber?: string;
  /**
   * whether the patient can view partial results
   */
  canViewPartialResults?: boolean;
  /**
   * whether the patient can view preliminary results
   */
  canViewPreliminaryResults?: boolean;
  /**
   * "befundsprache"
   */
  resultLanguage?: string;
  oa?: boolean;
  aids?: number[];
  latest_report_date?: string;
  dateScore?: number;
  passportNumber?: string;
  idCardNumber?: string;
  status?: UserStatus;
  locale?: LanguageCode;
  user?: User;
  gender?: Gender;
}

export interface User {
  /**
   * User ID
   */
  id?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * UserID for specific Meta Data Table
   */
  entityId?: number;
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Normalized salutation to display
   */
  salutation?: string;
  /**
   * Normalized email
   */
  email?: string;
  userDebugEnabled?: boolean;
  alternative_kennung?: string | null;
  acceptedTocAt?: string | null;
  acceptedMaintenanceAt?: string | null;
  enabled2fa?: boolean;
  rejected2faAt?: string | null;
  apiDocs?: boolean;
  type?: UserType;
  status?: UserStatus;
  locale?: LanguageCode;
}

export interface UserLog {
  id?: number;
  xuser_id?: number;
  user_agent?: string;
  /**
   * Normalized name to display
   */
  user_name?: string;
  lid?: number;
  /**
   * Additional info
   */
  info?: string;
  ip?: string;
  created_at?: string;
  entityId?: number;
  initiatorId?: number;
  entityType?: UserType;
  initiator?: User;
  action?: UserLogAction;
}

export interface UserAgent {
  /**
   * Primary Key
   */
  id?: number;
  /**
   * Hashed User Agent
   */
  hash?: string;
  /**
   * User Agent
   */
  agent?: string;
  /**
   * Created at
   */
  created_at?: string;
}

export interface Device {
  id?: number;
  name?: string;
  userId?: number | null;
  /**
   * Device UUID
   */
  uuid?: string;
  target?: string;
  note?: string;
  userAgentId?: number | null;
  createdAt?: string;
  approvedAt?: string;
  revokedAt?: string | null;
  deniedAt?: string;
  deletedAt?: string;
  lastUsedAt?: string;
  token?: string;
  shortToken?: string;
  platform?: Platform;
  status?: DeviceStatus;
  user?: User;
}

export interface DoctorDevice {
  id?: number;
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * Device ID
   */
  deviceId?: string;
  active?: boolean;
  /**
   * Date of registration
   */
  registeredAt?: string;
  /**
   * Device Note
   */
  note?: string;
  user_agent_id?: number;
}

export interface DoctorHierarchy {
  /**
   * Hierarchy ID
   */
  id?: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * Parent Hierarchy ID
   */
  parent?: number | null;
  /**
   * Date of registration
   */
  created_at?: string;
  name?: string;
  type?: DoctorHierarchyType;
  doctor?: Doctor;
  owner?: Doctor;
}

export interface PatientExtra {
  id?: number;
  /**
   * Patient ID
   */
  pid?: number;
  zs1?: string;
  zs2?: string;
  zs3?: string;
  zs4?: string;
  zs5?: string;
  zs6?: string;
  zs7?: string;
  zs8?: string;
}

export interface Appointment {
  /**
   * Appointment ID
   */
  id?: number;
  /**
   * Message ID
   */
  message_id?: number;
  /**
   * Date
   */
  date?: string;
  /**
   * Time
   */
  time?: string;
  /**
   * UID of recipient
   */
  recipient_id?: number;
  created_at?: string;
  updated_at?: string;
  is_confirmed?: AppointmentConfirmStatus;
}

export interface MessageThread {
  /**
   * Thread ID
   */
  id?: number;
  /**
   * Thread Subject
   */
  subject?: string;
  created_at?: string;
  updated_at?: string;
  /**
   * Starred by user
   */
  starred?: boolean;
  last_message_read_by?: number;
  type?: MessageThreadType;
  lastMessage?: Message;
  report?: Report;
  order?: Order;
  users?: UserShortInfoProperties[];
}

export interface Message {
  /**
   * Message ID
   */
  id?: number;
  /**
   * Thread ID
   */
  thread_id: number;
  /**
   * Text
   */
  text: string;
  created_at?: string;
  /**
   * Read by current user
   */
  is_read?: boolean;
  /**
   * IDs of users read
   */
  readBy?: number[];
  /**
   * UID of sender
   */
  sender_id?: number;
  /**
   * Avatar of sender
   */
  sender_avatar?: string;
  context_read_by?: any[];
  appointment?: Appointment;
}

export interface UserMessage {
  /**
   * User - Message ID
   */
  id?: number;
  /**
   * User ID
   */
  user_id?: number;
  /**
   * Message ID
   */
  message_id?: number;
  /**
   * Read by user
   */
  is_read?: boolean;
}

export interface UserThread {
  /**
   * User - Thread ID
   */
  id?: number;
  /**
   * User ID
   */
  user_id?: number;
  /**
   * Thread ID
   */
  thread_id?: number;
  /**
   * News ID
   */
  news_id?: number;
  /**
   * Starred by user
   */
  is_starred?: boolean;
  /**
   * Deleted
   */
  is_deleted?: boolean;
}

export interface Case {
  id?: number;
  lid?: number;
  aid?: number;
  pid?: number;
  caseNumber?: string;
  diagnosis?: string;
  room?: string;
  /**
   * short name for clinic (cases)
   */
  msh3?: string | null;
  /**
   * short name for house (cases)
   */
  msh4?: string | null;
  created_at?: string;
  updated_at?: string;
  status?: CaseStatus;
  patient?: Patient;
  doctor?: Doctor;
  events?: CaseEvent[];
  reports?: Report[];
  orders?: ComposedOrderProperties[];
}

export interface CaseEvent {
  id?: number;
  caseId?: number;
  caseNumber?: string;
  created_at?: string;
  effective_at?: string;
  type?: CaseEventType;
}

export interface DigitalSignature {
  id?: number;
  lid?: number;
  aid?: number;
  aufid?: number;
  base64?: string;
  base64signed?: string;
  hash?: string;
  hashSigned?: string;
  createdAt?: string;
  updatedAt?: string;
  status?: DigitalSignatureStatus;
}

export interface Workstation {
  id?: number;
  aid?: number;
  name?: string;
  location?: string;
  default?: boolean;
  clientVersion?: string;
  status?: WorkstationStatus;
  printers?: Printer[];
}

export interface Printer {
  id?: number;
  workstationId?: number;
  workstationClientId?: number;
  aid?: number;
  lid?: number;
  barcodePrinterName?: string;
  formPrinterName?: string;
  barcodePrintType?: number;
  printType?: number;
  socketPrint?: boolean;
  pageA5ToA4?: boolean;
  lpInstruction?: string;
  barcodeAutoPrintOccasion?: boolean;
  autoPrintOccasion?: boolean;
}

export interface ExchangeFile {
  id?: number;
  lid?: number;
  filename?: string;
  tnr?: string;
  lg?: string;
  lanr?: string | null;
  additionalKey?: string | null;
  ldtVersion?: number | null;
  patientName?: string;
  doctorName?: string;
  base64?: string;
  isExported?: boolean;
  isUnlocked?: boolean;
  isRepeatedExported?: boolean;
  insert_by?: string;
  last_update_by?: string;
  importedAt?: string;
  exportedAt?: string;
  deletedAt?: string;
  doctor?: Doctor;
  fileType?: ExchangeFileType;
  status?: ExchangeFileStatus;
}

export interface ExchangeStorage {
  id?: number;
  filename?: string;
  content?: string;
}

export interface ExchangeDoctorGroup {
  id?: number;
  lid?: number;
  name?: string;
  isOrderGroup?: boolean;
}

export interface ExchangeExportSettings {
  id?: number;
  lid?: number;
  /**
   * Doctor ID for which this settings belong to
   */
  aid?: number;
  /**
   * Doctor ID which will download files for the doctor in field aid
   */
  aidOwner?: number;
  /**
   * fixed filename for all LA files
   */
  filenameLA?: string;
  /**
   * fixed filename for all LG files
   */
  filenameLG?: string;
  /**
   * directory where LA files are saved
   */
  directoryLA?: string;
  /**
   * directory where LG files are saved
   */
  directoryLG?: string;
  /**
   * directory where SO files are saved
   */
  directorySO?: string;
  /**
   * directory where PDF files are saved
   */
  directoryPDF?: string;
  doctor?: Doctor;
  encoding?: ExchangeEncodings;
}

export interface ExchangeImportDirectories {
  id?: number;
  lid?: number;
  /**
   * Subdirectory to search for ldt files under the main import directory
   */
  dir: string;
}

export interface ExchangeImportSettingsLdt {
  lid?: number;
  importDirectory?: string;
  archiveDirectory?: string;
  ldt2ModeLaboratoryGroupIdentificationValueList?: string;
  tnrFieldIdentification?: string;
  ldt3ModeLaboratoryGroupIdentificationValueList?: string;
  ldt3Mode2ObjectAndField?: string;
  encoding?: ExchangeEncodings;
  ldt2ModeLaboratoryGroupIdentification?: LDT2ModeLaboratoryGroupIdentificationTypes;
  fileUpdateMode?: ExchangeFileUpdateModes;
  ldt3ModeLaboratoryGroupIdentification?: LDT3ModeLaboratoryGroupIdentificationTypes;
}

export interface ExchangeImportSettingsPdf {
  lid?: number;
  importDirectory?: string;
  archiveDirectory?: string;
  fileUpdateMode?: ExchangeFileUpdateModes;
}

export interface StoreCategory {
  id?: number;
  lid?: number;
  parentId?: number | null;
  name?: string;
  isPublic?: boolean;
  path?: StoreCategoryPathReferenceDto[];
  children?: StoreCategoryPathReferenceDto[];
  doctors?: Doctor[];
}

export interface StoreCategoryPermission {
  category_id?: number;
  aid?: number;
}

export interface StoreManufacturer {
  id?: number;
  lid?: number;
  name: string;
  link?: string | null;
  isActive?: boolean;
  createdAt?: string;
}

export interface StoreProduct {
  id?: number;
  lid?: number;
  name: string;
  pzn?: string;
  articleNumber: string;
  introduction?: string;
  description?: string;
  price?: number;
  unitAmount?: number;
  manufacturerId: number;
  isPublic?: boolean;
  isActive?: boolean;
  isInStock?: boolean;
  isPinned?: boolean;
  needsDoctorManufacturer?: boolean;
  createdAt?: string;
  unitType?: StoreProductUnitType;
  manufacturer?: StoreManufacturer;
  categories?: StoreCategory[];
  doctors?: Doctor[];
  images?: StoreProductImage[];
}

export interface StoreProductImage {
  id?: number;
  productId?: number;
  content?: string;
  contentFile?: {
    [k: string]: any;
  };
  thumbnail?: string;
  order?: number;
}

export interface StoreProductPermission {
  product_id?: number;
  aid?: number;
}

export interface StoreProductFavorites {
  id?: number;
  aid?: number;
}

export interface StoreOrder {
  id?: number;
  lid?: number;
  userId?: number;
  aid?: number;
  total?: number;
  freeText?: string | null;
  orderNumber?: string;
  createdAt?: string;
  user?: Doctor;
  doctor?: Doctor;
  status?: StoreOrderStatus;
  items?: StoreOrderProduct[];
}

export interface StoreOrderProduct {
  orderId?: number;
  productId?: number;
  amount?: number;
  total?: number;
  product?: StoreProduct;
}

export interface SendSmsSettings {
  id?: number;
  lid?: number;
  apiId?: string;
  apiKey?: string;
  url?: string;
  method?: string;
}

export interface Notification {
  id?: number;
  lid?: number;
  uid?: number;
  title?: string;
  body?: string;
  data?: any;
  createdAt?: string;
  readAt?: string;
  processedAt?: string;
  type?: NotificationType;
  status?: NotificationStatus;
  user?: User;
  sms?: SendSms;
  push?: PushNotification;
  mail?: SendMail;
}

export interface SendSms {
  id?: number;
  lid?: number;
  nid?: number;
  /**
   * SMS Number
   */
  number?: string;
  messente_id?: string;
  messente_status?: string;
  /**
   * SMS Text
   */
  text?: string;
  /**
   * Sender
   */
  sender?: string;
  createdAt?: string;
  deliveredAt?: string | null;
  failedAt?: string | null;
  status?: SendSmsStatus;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ClusterType.Server | server |
 * | ClusterType.Worker | worker |
 */

export enum ClusterType {
  Server = 'server',
  Worker = 'worker'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ElasticSearchIndex.Patient | patient |
 * | ElasticSearchIndex.Doctor | doctor |
 * | ElasticSearchIndex.Requirements | requirements |
 */

export enum ElasticSearchIndex {
  Patient = 'patient',
  Doctor = 'doctor',
  Requirements = 'requirements'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | EventType.SseConnected | sse_connected |
 * | EventType.NewNotification | new_notification |
 * | EventType.UpdateNotification | update_notification |
 * | EventType.CurrentStoreOrderChanged | current_store_order_changed |
 */

export enum EventType {
  SseConnected = 'sse_connected',
  NewNotification = 'new_notification',
  UpdateNotification = 'update_notification',
  CurrentStoreOrderChanged = 'current_store_order_changed'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LanguageCode.EN | en |
 * | LanguageCode.DE | de |
 * | LanguageCode.IT | it |
 * | LanguageCode.HU | hu |
 * | LanguageCode.FR | fr |
 * | LanguageCode.NL | nl |
 */

export enum LanguageCode {
  EN = 'en',
  DE = 'de',
  IT = 'it',
  HU = 'hu',
  FR = 'fr',
  NL = 'nl'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LegacyLocales.DE | german.php |
 * | LegacyLocales.EN | english.php |
 * | LegacyLocales.IT | italian.php |
 * | LegacyLocales.HU | hungarian.php |
 * | LegacyLocales.FR | french.php |
 * | LegacyLocales.NL | dutch.php |
 */

export enum LegacyLocales {
  DE = 'german.php',
  EN = 'english.php',
  IT = 'italian.php',
  HU = 'hungarian.php',
  FR = 'french.php',
  NL = 'dutch.php'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LogLevel.Error | error |
 * | LogLevel.Warn | warn |
 * | LogLevel.Info | info |
 * | LogLevel.Debug | debug |
 * | LogLevel.Verbose | verbose |
 */

export enum LogLevel {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Debug = 'debug',
  Verbose = 'verbose'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LogTags.Server | server |
 * | LogTags.Cache | cache |
 * | LogTags.Handler | handler |
 * | LogTags.Job | job |
 * | LogTags.DataBase | database |
 * | LogTags.ElasticSearch | elasticsearch |
 * | LogTags.SlowQuery | slow_query |
 * | LogTags.SlowHandler | slow_handler |
 * | LogTags.Profiler | profiler |
 */

export enum LogTags {
  Server = 'server',
  Cache = 'cache',
  Handler = 'handler',
  Job = 'job',
  DataBase = 'database',
  ElasticSearch = 'elasticsearch',
  SlowQuery = 'slow_query',
  SlowHandler = 'slow_handler',
  Profiler = 'profiler'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | Platform.IOS | ios |
 * | Platform.ANDROID | android |
 * | Platform.WEB | web |
 * | Platform.API | api |
 * | Platform.LC | lc |
 */

export enum Platform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
  API = 'api',
  LC = 'lc'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | NODE_ENV.Development | development |
 * | NODE_ENV.Testing | testing |
 * | NODE_ENV.Production | production |
 */

export enum NODE_ENV {
  Development = 'development',
  Testing = 'testing',
  Production = 'production'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | Trigger.NewReportComment | new_report_comment |
 * | Trigger.NotificationCreated | x_notification_crated_notify |
 * | Trigger.SendSmsCreated | b_sendsms_created |
 * | Trigger.AutoActivatePatient | b_patient_status_queued |
 * | Trigger.DoctorChange | doctor_change_notify |
 * | Trigger.LaboratoryChange | laboratory_changed_notify |
 * | Trigger.PatientChange | patient_change_notify |
 * | Trigger.SitePreferenceChanged | sitepref_changed_notify |
 * | Trigger.PreferenceChanged | pref_changed_notify |
 * | Trigger.ServerConfigChanged | server_config_changed_notify |
 * | Trigger.LaboratoryProductsChanged | laboratory_products_changed_notify |
 * | Trigger.UserFeaturesChanged | user_features_changed_notify |
 * | Trigger.LaboratoryFeaturesChanged | laboratory_features_changed_notify |
 * | Trigger.MailtTemplateGroupChanged | mail_template_group_changed_notify |
 * | Trigger.DoctorGroupsChanged | doctor_groups_changed |
 * | Trigger.DoctorGroupsMembersChange | doctorgroup_change_notify |
 * | Trigger.CustomSortChanged | custom_sort_changed_notify |
 * | Trigger.RequirementsChanged | requirements_changed_notify |
 * | Trigger.AnalysesChanged | analyses_changed_notify |
 * | Trigger.RequirementRulesChanged | requirement_rules_changed_notify |
 * | Trigger.SamplesChanged | sample_changed_notify |
 * | Trigger.MibiMaterialsChange | mibi_materialien_change_notify |
 * | Trigger.CostUnitMandatoryFieldsChanged | cost_unit_mandatory_fields_changed_notify |
 * | Trigger.AnamnesisQuestionChanged | anamnesis_question_changed_notify |
 * | Trigger.AnamnesisTriggerChanged | anamnesis_trigger_changed_notify |
 * | Trigger.OrderFormsChanged | order_form_changed_notify |
 * | Trigger.GroupFormsDoctorsChanged | group_forms_doctors_changed_notify |
 * | Trigger.GroupFormChanged | group_form_changed_notify |
 * | Trigger.GroupFormRequirementsChanged | group_form_requirements_changed_notify |
 * | Trigger.GroupFormSubGroupChanged | group_form_subgroup_changed_notify |
 * | Trigger.GroupFormCategoryChanged | group_form_category_changed_notify |
 * | Trigger.OrderAggregatorRequirementsChanged | oa_aggregator_requirements_changed |
 * | Trigger.OrderAggregatorFavoritesChanged | oa_aggregator_favorites_changes |
 * | Trigger.OrderAggregatorProfilesChanged | oa_aggregator_profiles_changes |
 * | Trigger.LaboratoryGroupBarcodeSettingsChanged | oa_aggregator_barcode_changed |
 * | Trigger.LabstoreCategoriesChanged | ls_categories_changed_notify |
 * | Trigger.LabstoreCategoryPermissionsChanged | ls_category_permissions_changed_notify |
 * | Trigger.LabstoreManufacturersChanged | ls_manufacturers_changed_notify |
 * | Trigger.LabstoreProductsChanged | ls_products_changed_notify |
 * | Trigger.LabstoreProductCategoriesChanged | ls_product_categories_changed_notify |
 * | Trigger.LabstoreProductPermissionsChanged | ls_product_permissions_changed_notify |
 * | Trigger.LabstoreOrdersChanged | ls_orders_changed_notify |
 * | Trigger.LabstoreOrderProductsChanged | ls_order_products_changed_notify |
 */

export enum Trigger {
  NewReportComment = 'new_report_comment',
  NotificationCreated = 'x_notification_crated_notify',
  SendSmsCreated = 'b_sendsms_created',
  AutoActivatePatient = 'b_patient_status_queued',
  DoctorChange = 'doctor_change_notify',
  LaboratoryChange = 'laboratory_changed_notify',
  PatientChange = 'patient_change_notify',
  SitePreferenceChanged = 'sitepref_changed_notify',
  PreferenceChanged = 'pref_changed_notify',
  ServerConfigChanged = 'server_config_changed_notify',
  LaboratoryProductsChanged = 'laboratory_products_changed_notify',
  UserFeaturesChanged = 'user_features_changed_notify',
  LaboratoryFeaturesChanged = 'laboratory_features_changed_notify',
  MailtTemplateGroupChanged = 'mail_template_group_changed_notify',
  DoctorGroupsChanged = 'doctor_groups_changed',
  DoctorGroupsMembersChange = 'doctorgroup_change_notify',
  CustomSortChanged = 'custom_sort_changed_notify',
  RequirementsChanged = 'requirements_changed_notify',
  AnalysesChanged = 'analyses_changed_notify',
  RequirementRulesChanged = 'requirement_rules_changed_notify',
  SamplesChanged = 'sample_changed_notify',
  MibiMaterialsChange = 'mibi_materialien_change_notify',
  CostUnitMandatoryFieldsChanged = 'cost_unit_mandatory_fields_changed_notify',
  AnamnesisQuestionChanged = 'anamnesis_question_changed_notify',
  AnamnesisTriggerChanged = 'anamnesis_trigger_changed_notify',
  OrderFormsChanged = 'order_form_changed_notify',
  GroupFormsDoctorsChanged = 'group_forms_doctors_changed_notify',
  GroupFormChanged = 'group_form_changed_notify',
  GroupFormRequirementsChanged = 'group_form_requirements_changed_notify',
  GroupFormSubGroupChanged = 'group_form_subgroup_changed_notify',
  GroupFormCategoryChanged = 'group_form_category_changed_notify',
  OrderAggregatorRequirementsChanged = 'oa_aggregator_requirements_changed',
  OrderAggregatorFavoritesChanged = 'oa_aggregator_favorites_changes',
  OrderAggregatorProfilesChanged = 'oa_aggregator_profiles_changes',
  LaboratoryGroupBarcodeSettingsChanged = 'oa_aggregator_barcode_changed',
  LabstoreCategoriesChanged = 'ls_categories_changed_notify',
  LabstoreCategoryPermissionsChanged = 'ls_category_permissions_changed_notify',
  LabstoreManufacturersChanged = 'ls_manufacturers_changed_notify',
  LabstoreProductsChanged = 'ls_products_changed_notify',
  LabstoreProductCategoriesChanged = 'ls_product_categories_changed_notify',
  LabstoreProductPermissionsChanged = 'ls_product_permissions_changed_notify',
  LabstoreOrdersChanged = 'ls_orders_changed_notify',
  LabstoreOrderProductsChanged = 'ls_order_products_changed_notify'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SortingDirection.Asc | 0 |
 * | SortingDirection.Desc | 1 |
 */

export enum SortingDirection {
  Asc = 0,
  Desc = 1
}

export interface DataUserStatistics {
  totalCount?: number;
  okCount?: number;
  initialCount?: number;
  inactiveCount?: number;
  autoLockedCount?: number;
  manualLockedCount?: number;
  queuedCount?: number;
}

export interface DataStatistics {
  cpuCount?: number;
  memoryTotal?: number;
  platform?: string;
  orderFormsCount?: number;
  samplesCount?: number;
  clinicalRequirementCount?: number;
  clinicalAnalysesCount?: number;
  clinicalMaterialCount?: number;
  mibiRequirementCount?: number;
  mibiAnalysesCount?: number;
  mibiMaterialCount?: number;
  versions?: SystemVersions;
  patients?: DataUserStatistics;
  doctors?: DataUserStatistics;
  laboratoryUsers?: DataUserStatistics;
}

export interface NumberValue {
  /**
   * Value Label
   */
  label?: string;
  /**
   * Count
   */
  value?: number;
}

export interface NumberMinMaxRangeValue {
  /**
   * Value Label
   */
  label?: string;
  /**
   * Count
   */
  value?: number;
  /**
   * Minimum of range
   */
  min?: number;
  /**
   * Maximum of range
   */
  max?: number;
}

export interface SystemMetrics {
  cpuUsage?: number;
  memoryUsed?: number;
}

export interface SystemVersions {
  lab7?: string;
  node?: string;
  redis?: string;
  postgres?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | TokenSubject.PasswordReset | password_reset |
 * | TokenSubject.AcceptToc | accept_toc |
 * | TokenSubject.Authentication | authentication |
 * | TokenSubject.QuickAccessNotification | quick_access_notification |
 * | TokenSubject.LcRegistration | lc_registration |
 * | TokenSubject.TwoFactorAuthentication | two_factor_authentication |
 */

export enum TokenSubject {
  PasswordReset = 'password_reset',
  AcceptToc = 'accept_toc',
  Authentication = 'authentication',
  QuickAccessNotification = 'quick_access_notification',
  LcRegistration = 'lc_registration',
  TwoFactorAuthentication = 'two_factor_authentication'
}

export interface TokenUserProperties {
  /**
   * User ID (from x_user)
   */
  id?: number;
  /**
   * Entity User ID
   */
  entityId?: number;
  type?: UserType;
}

export interface TokenProperties {
  /**
   * Issued at
   */
  iat?: number;
  /**
   * Expires at
   */
  exp?: number;
  user?: TokenUserProperties;
}

export interface TokenCreationSettingsProperties {
  /**
   * Token expiration time in hours
   */
  expiration?: number;
}

export interface UserDetailProperties {
  /**
   * User ID (from x_user)
   */
  id?: number;
  /**
   * Entity User ID
   */
  entityId?: number;
  /**
   * User Name
   */
  kennung?: string;
  /**
   * Display Name
   */
  displayName?: string;
  /**
   * User E-Mail
   */
  email?: string;
  debugEnabled?: boolean;
  enabled2fa?: boolean;
  labors?: Laboratory[];
  type?: UserType;
  products?: ProductProperties[];
  features?: FeatureProperties[];
  preferences?: PreferencesProperties;
  permissions?: UserPermissionsProperties;
  locale?: LanguageCode;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | CaseEventType.A01 | A01 |
 * | CaseEventType.A02 | A02 |
 * | CaseEventType.A03 | A03 |
 * | CaseEventType.A04 | A04 |
 * | CaseEventType.A05 | A05 |
 * | CaseEventType.A06 | A06 |
 * | CaseEventType.A07 | A07 |
 * | CaseEventType.A08 | A08 |
 */

export enum CaseEventType {
  A01 = 'A01',
  A02 = 'A02',
  A03 = 'A03',
  A04 = 'A04',
  A05 = 'A05',
  A06 = 'A06',
  A07 = 'A07',
  A08 = 'A08'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | CaseListMode.Alphabetical | 0 |
 * | CaseListMode.Rooms | 1 |
 */

export enum CaseListMode {
  Alphabetical = 0,
  Rooms = 1
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | CaseStatus.Initial | 0 |
 * | CaseStatus.Active | 1 |
 * | CaseStatus.Closed | 2 |
 */

export enum CaseStatus {
  Initial = 0,
  Active = 1,
  Closed = 2
}

export interface CasesConfig {
  preferences?: CasesPreferencesProperties;
}

export interface CasesGeneralConfig {
  preferences?: CasesPreferencesProperties;
}

export interface CasesUserConfig {
  preferences?: CasesPreferencesProperties;
}

export interface GlobalConfigProperties {
  /**
   * Server ID
   */
  serverId?: string;
  passwordPolicyPattern?: string;
  allowedFileEndings?: string;
  svnrLogin?: boolean;
  doctorSmsVerification?: boolean;
  twoFactorAuthEnabled?: boolean;
  twoFactorShowInUserList?: boolean;
  disableUserServerId?: boolean;
  customUsernamePrefix?: string;
  /**
   * is allowed to activate patients
   */
  canActivatePatients?: boolean;
  /**
   * when active, doctor can delete all orders of any state
   */
  softDeleteOrders?: boolean;
  /**
   * default lc registration token expiration time in hours
   */
  lcRegistrationTokenDefaultExpiryTime?: number;
  hideEmailFields?: boolean;
  doctorFirstLoginEmail?: boolean;
  enablePasswordVisibilityToggleButton?: boolean;
  useExchangeTokenShortForm?: boolean;
  recoveryUrl?: string;
  privilegeMode?: LaboratoryUserPrivilegeMode;
  filterProfiles?: FilterProfile[];
  links?: LinkSettingProperties;
  system?: SystemSettingProperties;
  preferences?: PreferencesProperties;
  quickAccess?: ReportsQuickAccessConfig;
  quickScan?: ReportsQuickScanConfig;
  orderLocalization?: OrderWizardLocalization;
}

export interface ReportsQuickAccessPathsConfig {
  path?: string;
  target?: string;
  enableMailNotification?: boolean;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportsQuickAccessConfigOrderNumberType.OrderNumber | 0 |
 * | ReportsQuickAccessConfigOrderNumberType.ExternalNumber | 1 |
 * | ReportsQuickAccessConfigOrderNumberType.Both | 2 |
 */

export enum ReportsQuickAccessConfigOrderNumberType {
  OrderNumber = 0,
  ExternalNumber = 1,
  Both = 2
}

export interface LevenshteinConfig {
  distance?: number;
  insertCost?: number;
  deleteCost?: number;
  substituteCost?: number;
}

export interface ReportsQuickAccessConfig {
  enabled?: boolean;
  enabledOnOtherDomain?: boolean;
  path?: string;
  privacyUrl?: string;
  multiPathsEnabled?: boolean;
  reportMaxAge?: number;
  orderNumberLength?: number;
  orderNumberNumericOnly?: boolean;
  orderNumberPrefix?: string;
  orderNumberSuffix?: string;
  useOrderNumberPrefix?: boolean;
  useOrderNumberSuffix?: boolean;
  partialReportsEnabled?: boolean;
  lockedReportsEnabled?: boolean;
  enableMailNotification?: boolean;
  multiDownloadEnabled?: boolean;
  multiDownloadMaximumCount?: number;
  fields?: ReportAccessFields[];
  paths?: ReportsQuickAccessPathsConfig[];
  reportMaxAgeInterval?: Interval;
  orderNumberType?: ReportsQuickAccessConfigOrderNumberType;
  levenshtein?: LevenshteinConfig | null;
}

export interface ReportsQuickScanTargetConfig {
  target?: string;
  manualEntry?: boolean;
  partialReportsEnabled?: boolean;
}

export interface ReportsQuickScanExplicitAnalysesConfig {
  analysis?: string;
  value?: string;
  highlight?: GuidReportValueHighlightColor;
}

export interface ReportsQuickScanConfig {
  enabled?: boolean;
  multiTargetsEnabled?: boolean;
  manualEntry?: boolean;
  partialReportsEnabled?: boolean;
  highlightPathological?: boolean;
  reportMaxAge?: number;
  targets?: ReportsQuickScanTargetConfig[];
  reportMaxAgeInterval?: Interval;
  explicitAnalyses?: ReportsQuickScanExplicitAnalysesConfig[];
}

export interface HandlerLogEntry {
  baseUrl?: string;
  path?: string;
  url?: string;
  method?: string;
  params?: {
    [k: string]: any;
  };
  query?: {
    [k: string]: any;
  };
  body?: {
    [k: string]: any;
  };
  requestHeaders?: {
    [k: string]: any;
  };
  responseHeaders?: {
    [k: string]: any;
  };
  statusCode?: number;
  slowDown?: {
    [k: string]: any;
  };
}

export interface JobLogEntry {
  name?: string;
  params?: {
    [k: string]: any;
  };
}

export interface LinkSettingProperties {
  hasToc?: boolean;
  hasPrivacy?: boolean;
  config?: LinksConfigProperties;
  entries?: Link[];
  pdfs?: PdfLink[];
}

export interface GlobalSearchConfigProperties {
  autocompleteEntries?: number;
}

export interface RegexSearchConfigProperties {
  tnrEnabled?: boolean;
  tnrRegex?: string;
  exttnrEnabled?: boolean;
  exttnrRegex?: string;
  caseNumberEnabled?: boolean;
  caseNumberRegex?: string;
  insuranceNumberEnabled?: boolean;
  insuranceNumberRegex?: string;
  patientNameOrEmailEnabled?: boolean;
  patientNameOrEmailRegex?: string;
}

export interface FreeBarcodesPolicyProperties {
  enabled?: boolean;
  regex?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | FormatPriceDecimalType.LocalString | local |
 * | FormatPriceDecimalType.Comma | comma |
 * | FormatPriceDecimalType.Dot | dot |
 */

export enum FormatPriceDecimalType {
  LocalString = 'local',
  Comma = 'comma',
  Dot = 'dot'
}

export interface SystemSettingProperties {
  acceptedMaintenanceAt?: boolean;
  extendedGenderSelection?: boolean;
  maintenance?: MaintenanceConfigProperties;
  banner?: BannerConfigProperties;
  search?: GlobalSearchConfigProperties;
  freeBarcodesPolicy?: FreeBarcodesPolicyProperties;
  regexSearch?: RegexSearchConfigProperties;
  formatPriceDecimalType?: FormatPriceDecimalType;
}

export interface DistinctAnalysisProperties {
  shortName?: string;
  longName?: string;
}

export interface UserShortInfoProperties {
  /**
   * User ID
   */
  xuser_id?: number;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * User avatar
   */
  avatar?: string;
  userType?: UserType;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MessageThreadType.General | 0 |
 * | MessageThreadType.Report | 1 |
 * | MessageThreadType.Order | 2 |
 * | MessageThreadType.Store | 3 |
 * | MessageThreadType.CourierService | 4 |
 * | MessageThreadType.News | 5 |
 */

export enum MessageThreadType {
  General = 0,
  Report = 1,
  Order = 2,
  Store = 3,
  CourierService = 4,
  News = 5
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AppointmentConfirmStatus.Undecided | 0 |
 * | AppointmentConfirmStatus.Confirmed | 1 |
 * | AppointmentConfirmStatus.Declined | 2 |
 */

export enum AppointmentConfirmStatus {
  Undecided = 0,
  Confirmed = 1,
  Declined = 2
}

export interface SharedSampleProperties {
  /**
   * Sample Name
   */
  name?: string;
  /**
   * Image URL
   */
  img?: string;
  /**
   * Small Image Content
   */
  imageLarge?: string;
  /**
   * Large Image Content
   */
  imageSmall?: string;
  /**
   * Unit
   */
  unit?: string;
  /**
   * Quantity
   */
  quantity?: number;
  /**
   * Order
   */
  sortingOrder?: number;
  /**
   * Short Code of the Laboratory Group
   */
  laboratoryGroupShortCode?: string;
  /**
   * GBO ID
   */
  gboidentifier?: string;
  /**
   * Sample Type
   */
  sampleType?: string;
  /**
   * Sample Type on Label
   */
  sampleTypeLabel?: string;
}

export interface OrderAnimalProperties {
  name?: string;
  birthday?: string;
  species?: string;
  speciesManual?: string;
  chipId?: string;
  height?: string;
  weight?: string;
  sex?: number;
  breed?: string;
  ownerName?: string;
  ownerAmendment?: string;
  ownerStreet?: string;
  ownerHouseNumber?: string;
  ownerZipCode?: string;
  ownerCity?: string;
}

export interface OrderSwitzerlandProperties {
  billToOther1?: string | null;
  billToOther2?: string | null;
  billToOther3?: string | null;
  reportCopyTo?: string | null;
  reportCopyToEmail?: string | null;
  reportCopyToSalutation?: string | null;
  reportCopyToFirstName?: string | null;
  reportCopyToLastName?: string | null;
  reportCopyToStreet?: string | null;
  reportCopyToZip?: string | null;
  reportCopyToCity?: string | null;
  notes?: string | null;
  chTreatmentReason?: string | null;
  accidentDate?: string | null;
}

export interface ProfessionalAssociationProperties {
  professionalAssociation?: boolean;
  paEmployer?: string | null;
  paAccidentLocation?: string | null;
  paFileOrDisease?: string | null;
  paAccidentDate?: string | null;
}

export interface OrderHungaryProperties {
  motherLastName?: string | null;
  motherFirstName?: string | null;
  treatmentReason?: string | null;
  invoice?: string | null;
  icd1?: string | null;
  icd2?: string | null;
  icd3?: string | null;
  insuranceNumber?: string | null;
  citizen?: string | null;
}

export interface OrderMetaProperties {
  freeText?: string;
  /**
   * "Nachricht ans Labor"
   */
  message?: string;
  infectious?: boolean;
  urgent?: string;
  pregnancy?: boolean;
  checkup?: boolean;
  /**
   * Cost unit (e.g. Privat/Kasse)
   */
  costUnit?: string;
  insuranceName?: string;
  controlCheckupKnownInfection?: boolean;
  dz116b?: boolean | null;
  dz16b?: boolean;
  accident?: boolean;
  accidentTime?: string;
  documentReferenceNumber?: string;
  anamnesticInfo?: string;
  printedMaterials?: string | null;
  printQuickReportBarcode?: boolean;
  urgentNotificationPhone?: string | null;
  urgentNotificationFax?: string | null;
  urgentNotificationMail?: string | null;
  urgentNotificationNumberEmail?: string | null;
  endo?: boolean;
  urgentNotificationMode?: UrgentNotificationMode | null;
}

export interface ComposedOrderStringsProperties {
  materials?: string;
  requirements?: string;
  journal?: string;
  costUnits?: string;
  orderFormNames?: string;
}

export interface ComposedOrderProperties {
  /**
   * ID of order or pool
   */
  id?: number;
  isPool?: boolean;
  isPoct?: boolean;
  isSoftDeleted?: boolean;
  /**
   * Concatenated patient names
   */
  displayName?: string;
  selectedDoctor?: string;
  tnr?: string;
  created_at?: string;
  orderCount?: number;
  scheduled_at?: string[];
  executed_at?: string;
  sampled_at?: string;
  updated_at?: string;
  sample_in_lab?: boolean;
  hospitalRoomNumber?: string;
  hospitalStation?: string;
  urgent?: boolean;
  emergency?: boolean;
  hasMissingAggregation?: boolean;
  hasReportCopyInfo?: boolean;
  orders?: Order[];
  status?: OrderStatus[];
  doctor?: Doctor;
  materials?: OrderSample[];
  digitalSignature?: OrderDigitalSignature[];
  reportStatus?: ReportStatus[];
  reportPathological?: ReportCharacteristic[];
  meta_strings?: ComposedOrderStringsProperties;
  ebm?: Ebm;
}

export interface ComposedOrderRulesProperties {
  order?: ComposedOrderProperties;
  rules?: OrderRule[];
}

export interface ReRequestOrderProperties {
  /**
   * ID of order or pool
   */
  id?: number;
  isPool?: boolean;
  isPoct?: boolean;
  isSoftDeleted?: boolean;
  /**
   * Concatenated patient names
   */
  displayName?: string;
  selectedDoctor?: string;
  tnr?: string;
  created_at?: string;
  orderCount?: number;
  scheduled_at?: string[];
  executed_at?: string;
  sampled_at?: string;
  updated_at?: string;
  sample_in_lab?: boolean;
  hospitalRoomNumber?: string;
  hospitalStation?: string;
  urgent?: boolean;
  emergency?: boolean;
  hasMissingAggregation?: boolean;
  hasReportCopyInfo?: boolean;
  orders?: Order[];
  status?: OrderStatus[];
  doctor?: Doctor;
  materials?: OrderSample[];
  digitalSignature?: OrderDigitalSignature[];
  reportStatus?: ReportStatus[];
  reportPathological?: ReportCharacteristic[];
  meta_strings?: ComposedOrderStringsProperties;
  ebm?: Ebm;
  reRequestedRequirements?: ReRequestOrderRequirement[];
  reRequestHistory?: ReRequestHistory;
}

export interface ComposedOrderPoolWithRulesProperties {
  order?: ComposedOrderProperties;
  actions?: OrderRuleActionProperties[];
}

export interface OrderFrontendLogEntry {
  data?: {
    [k: string]: any;
  };
  info?: string;
  created_at?: string;
  action?: OrderChangeAction;
}

export interface WriteableOrderProperties {
  freeText?: string;
  /**
   * "Nachricht ans Labor"
   */
  message?: string;
  infectious?: boolean;
  urgent?: string;
  pregnancy?: boolean;
  checkup?: boolean;
  /**
   * Cost unit (e.g. Privat/Kasse)
   */
  costUnit?: string;
  insuranceName?: string;
  controlCheckupKnownInfection?: boolean;
  dz116b?: boolean | null;
  dz16b?: boolean;
  accident?: boolean;
  accidentTime?: string;
  documentReferenceNumber?: string;
  anamnesticInfo?: string;
  printedMaterials?: string | null;
  printQuickReportBarcode?: boolean;
  urgentNotificationPhone?: string | null;
  urgentNotificationFax?: string | null;
  urgentNotificationMail?: string | null;
  urgentNotificationNumberEmail?: string | null;
  endo?: boolean;
  /**
   * ID of order
   */
  id?: number;
  /**
   * Order Pool ID
   */
  poolId?: number;
  /**
   * ID of doctor
   */
  aid?: number;
  /**
   * number of the report/order in the laboratory, also called barcode or laboratory number
   */
  tnr?: string;
  externalId2?: string;
  lanr?: string;
  bsnr?: string;
  selectedDoctor?: string;
  apid?: number | null;
  executed_at?: string;
  scheduled_at?: string | null;
  sampleDate?: string;
  sampleTime?: string;
  /**
   * if set, an employee has to validate and confirm the order first before it can be exported
   */
  mustValidate?: boolean;
  hospitalStation?: string;
  requirementDiagnosisFreeText?: string;
  externalOrderInfo?: string | null;
  selectedDiagnoses?: {
    [k: string]: any;
  };
  selectedDiagnosesChecked?: boolean;
  workstationChecked?: boolean;
  sample_in_lab?: boolean;
  clientname?: string;
  printerAliasName?: string;
  overridePrinterName?: string | null;
  overrideBarcodePrinterName?: string | null;
  reportCopyAid?: number | null;
  aisIdentifier?: string | null;
  patientAisNumber?: string;
  urgentNotificationMode?: UrgentNotificationMode | null;
  status?: OrderStatus;
  executionStatus?: OrderStatus;
  patient?: OrderPatientMetaProperties;
  requirements?: OrderWizardRequirement[];
  anamnesis?: OrderAnamnesis[];
  animal?: OrderAnimalProperties;
  switzerland?: OrderSwitzerlandProperties;
  hungary?: OrderHungaryProperties;
  covid?: KbvCovidFormProperties;
  splitProperties?: OrderWizardSplitProperties[];
  beforeAfterDialysis?: BeforeAfterDialysis | null;
  reportCopyDoctor?: Doctor | null;
  bloodCollectionType?: BloodCollectionType | null;
  professionalAssociation?: ProfessionalAssociationProperties;
}

export interface OrderBasketFormProperties {
  formId?: number;
  splitIndex?: number;
  tnr?: string;
  isPoolSplit?: boolean;
  requirements?: OrderRequirement[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderStatus.Canceled | -4 |
 * | OrderStatus.CanceledPool | -2 |
 * | OrderStatus.Incorrect | -1 |
 * | OrderStatus.ExportErrorLDT | -3 |
 * | OrderStatus.Saved | 0 |
 * | OrderStatus.Queued | 1 |
 * | OrderStatus.Executed | 2 |
 * | OrderStatus.Processing | 3 |
 * | OrderStatus.PrePrint | 4 |
 * | OrderStatus.Amendment | 5 |
 * | OrderStatus.AmendmentQueued | 6 |
 * | OrderStatus.AmendmentApproved | 7 |
 * | OrderStatus.PreExecution | 11 |
 */

export enum OrderStatus {
  Canceled = -4,
  CanceledPool = -2,
  Incorrect = -1,
  ExportErrorLDT = -3,
  Saved = 0,
  Queued = 1,
  Executed = 2,
  Processing = 3,
  PrePrint = 4,
  Amendment = 5,
  AmendmentQueued = 6,
  AmendmentApproved = 7,
  PreExecution = 11
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderReason.Cure | 1 |
 * | OrderReason.Preventive | 2 |
 * | OrderReason.ESS | 3 |
 * | OrderReason.Receipt | 4 |
 */

export enum OrderReason {
  Cure = '1',
  Preventive = '2',
  ESS = '3',
  Receipt = '4'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ChTreatmentReason.Disease | KH |
 * | ChTreatmentReason.Pregnancy | VS |
 * | ChTreatmentReason.Accident | UN |
 * | ChTreatmentReason.Prevention | PV |
 * | ChTreatmentReason.Disability | IV |
 */

export enum ChTreatmentReason {
  Disease = 'KH',
  Pregnancy = 'VS',
  Accident = 'UN',
  Prevention = 'PV',
  Disability = 'IV'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderExportTrigger.WaitForLaboratory | waitforlis |
 * | OrderExportTrigger.TimeInMinutes | time |
 */

export enum OrderExportTrigger {
  WaitForLaboratory = 'waitforlis',
  TimeInMinutes = 'time'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ShortCodeMode.ShortName | name1kurz |
 * | ShortCodeMode.InternalCode | anfid |
 * | ShortCodeMode.ExternalCode | kuerzelextern |
 */

export enum ShortCodeMode {
  ShortName = 'name1kurz',
  InternalCode = 'anfid',
  ExternalCode = 'kuerzelextern'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SampleImageType.Large | large |
 * | SampleImageType.Small | small |
 */

export enum SampleImageType {
  Large = 'large',
  Small = 'small'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | BarcodeRangeType.Central | central |
 * | BarcodeRangeType.LaboratoryGroup | laboratoryGroup |
 * | BarcodeRangeType.Laboratory | laboratory |
 * | BarcodeRangeType.Doctor | doctor |
 */

export enum BarcodeRangeType {
  Central = 'central',
  LaboratoryGroup = 'laboratoryGroup',
  Laboratory = 'laboratory',
  Doctor = 'doctor'
}

export interface OrderExportSettingsProperties {
  settings?: OrderExportSettings;
  furtherFields?: OrderFurtherFields;
  austriaFields?: OrderAustriaFields;
  microbiologyFields?: OrderMicrobiologyFields;
  optionalFields?: OrderOptionalFields[];
  freeTextFields?: OrderFreeTextFields;
  che_bags?: OrderSwitzerlandBag[];
  che_bag_to_glns?: OrderSwitzerlandBagToGln[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DoctorBarcodeMode.NoBarcodePrint | 0 |
 * | DoctorBarcodeMode.PrintBarcodes | 1 |
 * | DoctorBarcodeMode.PrintGboBarcodes | 2 |
 * | DoctorBarcodeMode.PrintFreeBarcodes | 3 |
 * | DoctorBarcodeMode.CentralBarcodes | 4 |
 */

export enum DoctorBarcodeMode {
  NoBarcodePrint = 0,
  PrintBarcodes = 1,
  PrintGboBarcodes = 2,
  PrintFreeBarcodes = 3,
  CentralBarcodes = 4
}

export interface BarcodeSettingsProperties {
  /**
   * Current print position
   */
  printPosition?: string | null;
  /**
   * Print range minimum
   */
  printRangeMin?: string | null;
  /**
   * Print range maximum
   */
  printRangeMax?: string | null;
  /**
   * Print prefix
   */
  printPrefix?: string | null;
  overwrite?: boolean;
  resetCentralBarcodes?: boolean;
}

export interface AdvancedAlphanumericBarcodeSettingProperties {
  /**
   * Current print position 1
   */
  printPosition1?: string;
  /**
   * Print range minimum 1
   */
  printRangeMin1?: string;
  /**
   * Print range maximum 1
   */
  printRangeMax1?: string;
  /**
   * Current print position 2
   */
  printPosition2?: string;
  /**
   * Print range minimum 2
   */
  printRangeMin2?: string;
  /**
   * Print range maximum 2
   */
  printRangeMax2?: string;
  /**
   * Current print position 3
   */
  printPosition3?: string;
  /**
   * Print range minimum 3
   */
  printRangeMin3?: string;
  /**
   * Print range maximum 3
   */
  printRangeMax3?: string;
  overwrite1?: boolean;
  overwrite2?: boolean;
  overwrite3?: boolean;
  useState?: AdvancedAlphanumericBarcodeUseState;
}

export interface LaboratoryGroupBarcodeSettingsProperties {
  id?: number;
  groupName?: string;
  groupShortname?: string;
  /**
   * Current print position
   */
  printPosition?: string | null;
  /**
   * Print range minimum
   */
  printRangeMin?: string | null;
  /**
   * Print range maximum
   */
  printRangeMax?: string | null;
  /**
   * Print prefix
   */
  printPrefix?: string | null;
  overwrite?: boolean;
  resetCentralBarcodes?: boolean;
}

export interface AdvancedAlphanumericBarcodeProperties {
  id?: number;
  groupName?: string;
  groupShortname?: string;
  /**
   * Current print position 1
   */
  printPosition1?: string;
  /**
   * Print range minimum 1
   */
  printRangeMin1?: string;
  /**
   * Print range maximum 1
   */
  printRangeMax1?: string;
  /**
   * Current print position 2
   */
  printPosition2?: string;
  /**
   * Print range minimum 2
   */
  printRangeMin2?: string;
  /**
   * Print range maximum 2
   */
  printRangeMax2?: string;
  /**
   * Current print position 3
   */
  printPosition3?: string;
  /**
   * Print range minimum 3
   */
  printRangeMin3?: string;
  /**
   * Print range maximum 3
   */
  printRangeMax3?: string;
  overwrite1?: boolean;
  overwrite2?: boolean;
  overwrite3?: boolean;
  useState?: AdvancedAlphanumericBarcodeUseState;
}

export interface OrderFieldWithPreValueProperties {
  /**
   * Field ID in LDT File
   */
  fieldId?: string;
  /**
   * Pre append value in LDT File
   */
  preValue?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | EmailsBlockType.RealReRequestMadeMail | ena_made |
 */

export enum EmailsBlockType {
  RealReRequestMadeMail = 'ena_made'
}

export interface RealReRequestSettingsProperties {
  mtlMail?: string;
  /**
   * Use laboratory group mail address if a different lab group is set on the form
   */
  useLgMail?: boolean;
  /**
   * Limit in days from order created date
   */
  orderTimeframe?: number;
  /**
   * Limit in hours from order executed timestamp
   */
  reRequestLimit?: number;
  /**
   * The buffer in minutes after begin of the procedure within the doctor may make the additional requests
   */
  reRequestBuffer?: number;
  /**
   * Option to approve the additional requests by the laboratory; if false the they will be turned directly into regular requests
   */
  reRequestApprove?: boolean;
  /**
   * No further export due to real re-requests
   */
  reRequestNoExport?: boolean;
  /**
   * Calculate the real re-request time limit based on the sample date
   */
  reRequestLimitSampleDate?: boolean;
  /**
   * No email sending for Additional requirements are not to be approved
   */
  blockRealReRequestMadeMails?: boolean;
}

export interface OrderRealReRequestConditionsProperties {
  /**
   * Real re-request is allowed
   */
  allowed?: boolean;
  /**
   * IDs of available forms
   */
  formIds?: number[];
  /**
   * IDs of available requirements
   */
  requirementIds?: number[];
  /**
   * IDs of available profiles
   */
  profileIds?: number[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementRealReRequestState.Applied | 1 |
 * | RequirementRealReRequestState.Accepted | 0 |
 * | RequirementRealReRequestState.Rejected | -1 |
 */

export enum RequirementRealReRequestState {
  Applied = 1,
  Accepted = 0,
  Rejected = -1
}

export interface RealReRequestInformationProperties {
  appliedRequirementInfo?: string;
  appliedRequirementInfoWithFreeText?: string;
  appliedRequirementShortInfo?: string;
  appliedRequirementExternalInfo?: string;
  appliedRequirementInfoWithForm?: string;
  appliedRequirementExternalInfoWithForm?: string;
  acceptedRequirementInfo?: string;
  acceptedRequirementInfoWithFreeText?: string;
  acceptedRequirementShortInfo?: string;
  acceptedRequirementExternalInfo?: string;
  acceptedRequirementInfoWithForm?: string;
  acceptedRequirementExternalInfoWithForm?: string;
  approvedRequirementInfo?: string;
  approvedRequirementInfoWithFreeText?: string;
  approvedRequirementShortInfo?: string;
  approvedRequirementExternalInfo?: string;
  approvedRequirementInfoWithForm?: string;
  approvedRequirementExternalInfoWithForm?: string;
  rejectedRequirementInfo?: string;
  rejectedRequirementInfoWithFreeText?: string;
  rejectedRequirementShortInfo?: string;
  rejectedRequirementExternalInfo?: string;
  rejectedRequirementInfoWithForm?: string;
  rejectedRequirementExternalInfoWithForm?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ImageUseType.Sample | tube |
 */

export enum ImageUseType {
  Sample = 'tube'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderDigitalSignature.NotDefined | -1 |
 * | OrderDigitalSignature.Unsigned | 0 |
 * | OrderDigitalSignature.Signed | 1 |
 * | OrderDigitalSignature.None | 2 |
 */

export enum OrderDigitalSignature {
  NotDefined = -1,
  Unsigned = 0,
  Signed = 1,
  None = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderListMode.Transport | 0 |
 * | OrderListMode.Completion | 1 |
 * | OrderListMode.Journal | 2 |
 * | OrderListMode.DigitalSignature | 3 |
 * | OrderListMode.Bookmarked | 4 |
 * | OrderListMode.MassOrders | 5 |
 * | OrderListMode.RealReRequest | 6 |
 */

export enum OrderListMode {
  Transport = 0,
  Completion = 1,
  Journal = 2,
  DigitalSignature = 3,
  Bookmarked = 4,
  MassOrders = 5,
  RealReRequest = 6
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderListType.SingleOrders | single |
 * | OrderListType.PoolOrders | pool |
 */

export enum OrderListType {
  SingleOrders = 'single',
  PoolOrders = 'pool'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderFormPrintMode.SendToBrowser | sendtobrowser |
 * | OrderFormPrintMode.PrintToDb | printtodb |
 * | OrderFormPrintMode.PrintToOaAnswer | printtooaanswer |
 * | OrderFormPrintMode.SaveToDir | savetodir |
 */

export enum OrderFormPrintMode {
  SendToBrowser = 'sendtobrowser',
  PrintToDb = 'printtodb',
  PrintToOaAnswer = 'printtooaanswer',
  SaveToDir = 'savetodir'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | BookmarkedMode.Today | 0 |
 * | BookmarkedMode.Old | 1 |
 * | BookmarkedMode.Coming | 2 |
 * | BookmarkedMode.Custom | 3 |
 */

export enum BookmarkedMode {
  Today = 0,
  Old = 1,
  Coming = 2,
  Custom = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RealReRequestMode.Applied | 0 |
 * | RealReRequestMode.History | 1 |
 */

export enum RealReRequestMode {
  Applied = 0,
  History = 1
}

export interface OrderPatientGroupProps {
  displayName?: string;
  updated_at?: string;
  apgids?: number[];
  patients?: PatientGDT[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderPatientType.LIS | lis |
 * | OrderPatientType.AIS | ais |
 * | OrderPatientType.Manual | manual |
 */

export enum OrderPatientType {
  LIS = 'lis',
  AIS = 'ais',
  Manual = 'manual'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SplitMode.Never | none |
 * | SplitMode.Conditional | cond |
 * | SplitMode.Always | always |
 */

export enum SplitMode {
  Never = 'none',
  Conditional = 'cond',
  Always = 'always'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | StdPayer.Doctor | arzt |
 * | StdPayer.Patient | patient |
 */

export enum StdPayer {
  Doctor = 'arzt',
  Patient = 'patient'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DigitalSignatureMode.None | 0 |
 * | DigitalSignatureMode.Unsigned | 1 |
 * | DigitalSignatureMode.Signed | 2 |
 */

export enum DigitalSignatureMode {
  None = 0,
  Unsigned = 1,
  Signed = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | FormType.General | 0 |
 * | FormType.KBVMuster10 | 1 |
 * | FormType.KBVMuster10A | 2 |
 * | FormType.KBVMuster10C | 3 |
 * | FormType.KBVMusterOegd | 4 |
 * | FormType.Custom | 5 |
 */

export enum FormType {
  General = 0,
  KBVMuster10 = 1,
  KBVMuster10A = 2,
  KBVMuster10C = 3,
  KBVMusterOegd = 4,
  Custom = 5
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | FormPrintType.None | 0 |
 * | FormPrintType.Client | 1 |
 * | FormPrintType.Server | 2 |
 */

export enum FormPrintType {
  None = 0,
  Client = 1,
  Server = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | CustomFieldType.Checkbox | checkbox |
 * | CustomFieldType.Text | text |
 */

export enum CustomFieldType {
  Checkbox = 'checkbox',
  Text = 'text'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | TimeFrameUnit.Day | d |
 * | TimeFrameUnit.Month | m |
 * | TimeFrameUnit.Quarter | q |
 * | TimeFrameUnit.Year | y |
 */

export enum TimeFrameUnit {
  Day = 'd',
  Month = 'm',
  Quarter = 'q',
  Year = 'y'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DzOrderReason.Cure | 1 |
 * | DzOrderReason.Preventive | 2 |
 * | DzOrderReason.Ess | 3 |
 * | DzOrderReason.Receipt | 4 |
 */

export enum DzOrderReason {
  Cure = 1,
  Preventive = 2,
  Ess = 3,
  Receipt = 4
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MicrobiologicalLeftRight.Left | l |
 * | MicrobiologicalLeftRight.Right | r |
 */

export enum MicrobiologicalLeftRight {
  Left = 'l',
  Right = 'r'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementsSourceFilterValue.AllRequirements | 0 |
 * | RequirementsSourceFilterValue.SelfDefinedRequirements | 1 |
 * | RequirementsSourceFilterValue.ImportedRequirements | 2 |
 */

export enum RequirementsSourceFilterValue {
  AllRequirements = 0,
  SelfDefinedRequirements = 1,
  ImportedRequirements = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementsAdminConsistencyFilterValue.AllRequirements | 0 |
 * | RequirementsAdminConsistencyFilterValue.RequirementsOfAnalysesWithoutMaterial | 1 |
 * | RequirementsAdminConsistencyFilterValue.RequirementsOfAnalysesWithoutMainMaterial | 2 |
 * | RequirementsAdminConsistencyFilterValue.RequirementsOfAnalysesWithoutFormAssigned | 3 |
 */

export enum RequirementsAdminConsistencyFilterValue {
  AllRequirements = 0,
  RequirementsOfAnalysesWithoutMaterial = 1,
  RequirementsOfAnalysesWithoutMainMaterial = 2,
  RequirementsOfAnalysesWithoutFormAssigned = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AnalysesAdminBuiltinFilterValue.AllAnalyses | 0 |
 * | AnalysesAdminBuiltinFilterValue.AnalysesWithoutMaterial | 1 |
 * | AnalysesAdminBuiltinFilterValue.AnalysesWithoutMainMaterial | 2 |
 */

export enum AnalysesAdminBuiltinFilterValue {
  AllAnalyses = 0,
  AnalysesWithoutMaterial = 1,
  AnalysesWithoutMainMaterial = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MaterialsAdminBuiltinFilterValue.AllMaterials | 0 |
 * | MaterialsAdminBuiltinFilterValue.MaterialsWithSampleType | 1 |
 * | MaterialsAdminBuiltinFilterValue.MaterialsWithoutSampleType | 2 |
 */

export enum MaterialsAdminBuiltinFilterValue {
  AllMaterials = 0,
  MaterialsWithSampleType = 1,
  MaterialsWithoutSampleType = 2
}

export interface RequirementLaboratoryGroupsProperties {
  /**
   * The short name of the laboratory group
   */
  laboratoryGroup?: string;
}

export interface RequirementSubmittersProperties {
  /**
   * The short name of the submitters
   */
  submitter?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderWizardLocalization.GER | GER |
 * | OrderWizardLocalization.AUT | AUT |
 * | OrderWizardLocalization.CHE | CHE |
 * | OrderWizardLocalization.HUN | HUN |
 * | OrderWizardLocalization.VDE | VDE |
 * | OrderWizardLocalization.FEN | 1F |
 * | OrderWizardLocalization.KIS | KIS |
 * | OrderWizardLocalization.AIR | AIR |
 */

export enum OrderWizardLocalization {
  GER = 'GER',
  AUT = 'AUT',
  CHE = 'CHE',
  HUN = 'HUN',
  VDE = 'VDE',
  FEN = '1F',
  KIS = 'KIS',
  AIR = 'AIR'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementFieldName.AllHorizontal | all_horizontal |
 * | RequirementFieldName.AllVertical | all_vertical |
 * | RequirementFieldName.AllHorizontalWithPrice | all_horizontal_with_price |
 * | RequirementFieldName.AllVerticalWithPrice | all_vertical_with_price |
 * | RequirementFieldName.Horizontal1 | horizontal_1 |
 */

export enum RequirementFieldName {
  AllHorizontal = 'all_horizontal',
  AllVertical = 'all_vertical',
  AllHorizontalWithPrice = 'all_horizontal_with_price',
  AllVerticalWithPrice = 'all_vertical_with_price',
  Horizontal1 = 'horizontal_1'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementFieldLayout.HORIZONTAL | horizontal |
 * | RequirementFieldLayout.VERTICAL | vertical |
 */

export enum RequirementFieldLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export interface PoolOrOrderIds {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrderWizardSwitches {
  urgent?: boolean;
  emergency?: boolean;
  infectious?: boolean;
  hzv?: boolean;
  pregnancy?: boolean;
  checkup?: boolean;
  quickReportBarcode?: boolean;
  accident?: boolean;
  endo?: boolean;
}

export interface OrderWizardSettings {
  costUnits?: string[];
  manufacturer?: string;
  splitBak?: boolean;
  phoneNumber?: string;
  faxNumber?: string;
  email?: string;
  forms?: OrderForm[];
  groupForms?: GroupForm[];
  filters?: OrderRequirementsFilter[];
  anamnesis?: AnamnesisQuestion[];
  localisation?: OrderWizardLocalization;
  preferences?: OrdersPreferencesProperties;
  switches?: OrderWizardSwitches;
  che_bags?: OrderSwitzerlandBag[];
  che_bag_to_glns?: OrderSwitzerlandBagToGln[];
}

export interface OrderWizardSplitProperties {
  diagnosis?: string;
  freeText?: string;
  message?: string;
  anamnesticInfo?: string;
  externalOrderInfo?: string | null;
  printedMaterials?: string | null;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DmpType.Dmp01 | 01 |
 * | DmpType.Dmp02 | 02 |
 * | DmpType.Dmp03 | 03 |
 * | DmpType.Dmp04 | 04 |
 * | DmpType.Dmp05 | 05 |
 * | DmpType.Dmp06 | 06 |
 * | DmpType.Dmp07 | 07 |
 * | DmpType.Dmp08 | 08 |
 * | DmpType.Dmp09 | 09 |
 * | DmpType.Dmp10 | 10 |
 * | DmpType.Dmp11 | 11 |
 * | DmpType.Dmp12 | 12 |
 * | DmpType.Dmp30 | 30 |
 * | DmpType.Dmp31 | 31 |
 * | DmpType.Dmp32 | 32 |
 * | DmpType.Dmp33 | 33 |
 * | DmpType.Dmp34 | 34 |
 * | DmpType.Dmp35 | 35 |
 * | DmpType.Dmp36 | 36 |
 * | DmpType.Dmp37 | 37 |
 * | DmpType.Dmp38 | 38 |
 * | DmpType.Dmp39 | 39 |
 * | DmpType.Dmp40 | 40 |
 * | DmpType.Dmp41 | 41 |
 * | DmpType.Dmp42 | 42 |
 * | DmpType.Dmp43 | 43 |
 * | DmpType.Dmp44 | 44 |
 * | DmpType.Dmp45 | 45 |
 * | DmpType.Dmp46 | 46 |
 * | DmpType.Dmp47 | 47 |
 * | DmpType.Dmp48 | 48 |
 * | DmpType.Dmp49 | 49 |
 * | DmpType.Dmp50 | 50 |
 * | DmpType.Dmp51 | 51 |
 * | DmpType.Dmp52 | 52 |
 * | DmpType.Dmp53 | 53 |
 * | DmpType.Dmp54 | 54 |
 * | DmpType.Dmp55 | 55 |
 * | DmpType.Dmp56 | 56 |
 * | DmpType.Dmp57 | 57 |
 * | DmpType.Dmp58 | 58 |
 */

export enum DmpType {
  Dmp01 = '01',
  Dmp02 = '02',
  Dmp03 = '03',
  Dmp04 = '04',
  Dmp05 = '05',
  Dmp06 = '06',
  Dmp07 = '07',
  Dmp08 = '08',
  Dmp09 = '09',
  Dmp10 = '10',
  Dmp11 = '11',
  Dmp12 = '12',
  Dmp30 = '30',
  Dmp31 = '31',
  Dmp32 = '32',
  Dmp33 = '33',
  Dmp34 = '34',
  Dmp35 = '35',
  Dmp36 = '36',
  Dmp37 = '37',
  Dmp38 = '38',
  Dmp39 = '39',
  Dmp40 = '40',
  Dmp41 = '41',
  Dmp42 = '42',
  Dmp43 = '43',
  Dmp44 = '44',
  Dmp45 = '45',
  Dmp46 = '46',
  Dmp47 = '47',
  Dmp48 = '48',
  Dmp49 = '49',
  Dmp50 = '50',
  Dmp51 = '51',
  Dmp52 = '52',
  Dmp53 = '53',
  Dmp54 = '54',
  Dmp55 = '55',
  Dmp56 = '56',
  Dmp57 = '57',
  Dmp58 = '58'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DmpSpecialGroup.Special04 | 04 |
 * | DmpSpecialGroup.Special06 | 06 |
 * | DmpSpecialGroup.Special07 | 07 |
 * | DmpSpecialGroup.Special08 | 08 |
 * | DmpSpecialGroup.Special09 | 09 |
 */

export enum DmpSpecialGroup {
  Special04 = '04',
  Special06 = '06',
  Special07 = '07',
  Special08 = '08',
  Special09 = '09'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AccountingArea.Area00 | 00 |
 * | AccountingArea.Area01 | 01 |
 * | AccountingArea.Area02 | 02 |
 * | AccountingArea.Area03 | 03 |
 * | AccountingArea.Area04 | 04 |
 * | AccountingArea.Area05 | 05 |
 * | AccountingArea.Area06 | 06 |
 * | AccountingArea.Area07 | 07 |
 * | AccountingArea.Area08 | 08 |
 * | AccountingArea.Area09 | 09 |
 */

export enum AccountingArea {
  Area00 = '00',
  Area01 = '01',
  Area02 = '02',
  Area03 = '03',
  Area04 = '04',
  Area05 = '05',
  Area06 = '06',
  Area07 = '07',
  Area08 = '08',
  Area09 = '09'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ExceptionalIndication.ExceptionalIndication87777 | 87777 |
 */

export enum ExceptionalIndication {
  ExceptionalIndication87777 = '87777'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | InsuranceType.Type1 | 1 |
 * | InsuranceType.Type3 | 3 |
 * | InsuranceType.Type5 | 5 |
 */

export enum InsuranceType {
  Type1 = '1',
  Type3 = '3',
  Type5 = '5'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | CostUnit.Private | Privat |
 * | CostUnit.Insurance | Kasse |
 * | CostUnit.Internal | Intern |
 */

export enum CostUnit {
  Private = 'Privat',
  Insurance = 'Kasse',
  Internal = 'Intern'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvMusterOegdMandatoryFieldMapping.LegalOrderBasis | covid.legalOrderBasis |
 * | KbvMusterOegdMandatoryFieldMapping.LegalReason | covid.legalReason |
 * | KbvMusterOegdMandatoryFieldMapping.KvSpecial | covid.kvSpecial |
 */

export enum KbvMusterOegdMandatoryFieldMapping {
  LegalOrderBasis = 'covid.legalOrderBasis',
  LegalReason = 'covid.legalReason',
  KvSpecial = 'covid.kvSpecial'
}

export interface FormNotFoundRequirementProperties {
  longName?: string;
  shortName?: string;
}

export interface FormNotFoundProperties {
  costUnit?: string;
  requirement?: FormNotFoundRequirementProperties;
}

export interface ProfileNameCollisionProperties {
  profileName?: string;
  costUnit?: string;
  newName?: string;
}

export interface MappingConfirmProperties {
  formNotFound?: FormNotFoundProperties[];
  nameCollision?: ProfileNameCollisionProperties[];
}

export interface ProfileNameConfirmProperties {
  profileName?: string;
  costUnit?: string;
  replace?: boolean;
  newName?: string | null;
}

export interface CopyProfilesConfirmProperties {
  confirmedNames?: ProfileNameConfirmProperties[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | CompletionListOrderBy.OrderDate | 0 |
 * | CompletionListOrderBy.ScheduleDate | 1 |
 */

export enum CompletionListOrderBy {
  OrderDate = 0,
  ScheduleDate = 1
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | BeforeAfterDialysis.Before | b |
 * | BeforeAfterDialysis.After | a |
 */

export enum BeforeAfterDialysis {
  Before = 'b',
  After = 'a'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | BakTextType.AdditionalText | 0 |
 * | BakTextType.Hint | 1 |
 */

export enum BakTextType {
  AdditionalText = 0,
  Hint = 1
}

export interface UnifiedMicrobiologicalMaterial {
  main?: MicrobiologicalMaterial;
  subs?: MicrobiologicalMaterial[];
}

export interface UnifiedMicrobiologicalAnalysis {
  main?: MicrobiologicalAnalysis;
  subs?: MicrobiologicalAnalysis[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | BloodCollectionType.Fasting | 1 |
 * | BloodCollectionType.Capillary | 2 |
 * | BloodCollectionType.Postprandial | 3 |
 */

export enum BloodCollectionType {
  Fasting = 1,
  Capillary = 2,
  Postprandial = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SpecialZplType.ForForms | 0 |
 * | SpecialZplType.ForDoctors | 1 |
 * | SpecialZplType.PatientLabel | 40 |
 */

export enum SpecialZplType {
  ForForms = 0,
  ForDoctors = 1,
  PatientLabel = 40
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UrgentEmergencyValue.Normal | 0 |
 * | UrgentEmergencyValue.Urgent | 1 |
 * | UrgentEmergencyValue.Emergency | 2 |
 */

export enum UrgentEmergencyValue {
  Normal = '0',
  Urgent = '1',
  Emergency = '2'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UrgentNotificationMode.AsAgreedWithLabor | 0 |
 * | UrgentNotificationMode.Call | 1 |
 * | UrgentNotificationMode.Fax | 2 |
 * | UrgentNotificationMode.Email | 3 |
 */

export enum UrgentNotificationMode {
  AsAgreedWithLabor = 0,
  Call = 1,
  Fax = 2,
  Email = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AnamnesisQuestionType.Text | textfield |
 * | AnamnesisQuestionType.Checkbox | checkboxes |
 * | AnamnesisQuestionType.Radio | radiobuttons |
 * | AnamnesisQuestionType.Infotext | pseudo_infotext |
 */

export enum AnamnesisQuestionType {
  Text = 'textfield',
  Checkbox = 'checkboxes',
  Radio = 'radiobuttons',
  Infotext = 'pseudo_infotext'
}

export interface OrdersConfig {
  costUnits?: string[];
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  laboratoryGroupBarcodeSettings?: LaboratoryGroupBarcodeSettingsProperties[];
  advancedAlphanumericBarcodeSettings?: AdvancedAlphanumericBarcodeProperties[];
  realReRequestSettings?: RealReRequestSettingsProperties;
  diagnoses?: AggregatedDiagnosisProperties[];
  localisation?: OrderWizardLocalization;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
}

export interface OrdersGeneralConfig {
  preferences?: OrdersPreferencesProperties;
  printSettings?: BarcodeSettingsProperties;
  laboratoryGroupBarcodeSettings?: LaboratoryGroupBarcodeSettingsProperties[];
  advancedAlphanumericBarcodeSettings?: AdvancedAlphanumericBarcodeProperties[];
  realReRequestSettings?: RealReRequestSettingsProperties;
  diagnoses?: AggregatedDiagnosisProperties[];
  localisation?: OrderWizardLocalization;
}

export interface OrdersPrintSettings {
  printRangeMin?: string | null;
  printRangeMax?: string | null;
  printPrefix?: string | null;
  printPosition?: string | null;
  useOwnNummernkreis?: boolean;
  useLgNummernkreis?: boolean;
  internalPrintUser?: boolean;
  socketPrint?: boolean;
  overwrite?: boolean;
  resetCentralBarcodes?: boolean;
  barcodeMode?: DoctorBarcodeMode;
}

export interface OrdersUserConfig {
  localisation?: OrderWizardLocalization;
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
}

export interface OrdersCentralBarcodeStatus {
  threshold?: number | null;
  availableCount?: number | null;
  nextBarcode?: string | null;
}

export interface OrdersFormLabelPrinterMapping {
  mapping?: FormLabelPrinterMapping[];
  forms?: OrderForm[];
  workstations?: Workstation[];
}

export interface KbvCovidFormProperties {
  agreed?: boolean | null;
  healthServiceZipCode?: string | null;
  /**
   * Record reference of public health service (Öffentlicher Gesundheitsdienst)
   */
  healthService?: string | null;
  kvSpecial?: string | null;
  /**
   * PCR confirmation test
   */
  confirmationDiagnostics?: boolean | null;
  /**
   * Variant PCR
   */
  variantDiagnostics?: boolean | null;
  oegdInvoiceTo?: string | null;
  /**
   * Barcode for Covid warn application used
   */
  covidWarnAppBarcode?: boolean | null;
  testNumber?: KbvCovidTest | null;
  testReason?: KbvCovidTestReason | null;
  riskCharacteristicsLocation?: KbvCovidLocation | null;
  riskCharacteristicsLocationType?: KbvCovidLocationFacility | null;
  legalReason?: KbvCovidRvo | null;
  legalOrderBasis?: KbvCovidLegalBasis | null;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | PatQrDataVersion.CovidV1 | 1 |
 * | PatQrDataVersion.CovidHnrV2 | 2 |
 */

export enum PatQrDataVersion {
  CovidV1 = 1,
  CovidHnrV2 = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidAgreement.Yes | 1 |
 */

export enum KbvCovidAgreement {
  Yes = 1
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidLocation.CaredFor | betreut |
 * | KbvCovidLocation.Working | taetig |
 */

export enum KbvCovidLocation {
  CaredFor = 'betreut',
  Working = 'taetig'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidLocationFacility.Medical | med |
 * | KbvCovidLocationFacility.Community | gemeinsch |
 * | KbvCovidLocationFacility.CareOrLiving | pflege |
 * | KbvCovidLocationFacility.Other | sonst |
 */

export enum KbvCovidLocationFacility {
  Medical = 'med',
  Community = 'gemeinsch',
  CareOrLiving = 'pflege',
  Other = 'sonst'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidTest.First | 1 |
 * | KbvCovidTest.Further | 2 |
 */

export enum KbvCovidTest {
  First = 1,
  Further = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidTestReason.WarnAppIncreasedRisk | 1 |
 * | KbvCovidTestReason.DiagnosticConfirmation | 2 |
 */

export enum KbvCovidTestReason {
  WarnAppIncreasedRisk = 1,
  DiagnosticConfirmation = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidRvo.ContactPerson | 1 |
 * | KbvCovidRvo.WarnAppIncreasedRisk | 2 |
 * | KbvCovidRvo.Outbreak | 3 |
 * | KbvCovidRvo.DistributionPrevention | 4 |
 * | KbvCovidRvo.RiskArea | 5 |
 * | KbvCovidRvo.ForeignRiskArea | 6 |
 */

export enum KbvCovidRvo {
  ContactPerson = 1,
  WarnAppIncreasedRisk = 2,
  Outbreak = 3,
  DistributionPrevention = 4,
  RiskArea = 5,
  ForeignRiskArea = 6
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidRvoManualOptions.ContactPerson | 1 |
 * | KbvCovidRvoManualOptions.DistributionPrevention | 4 |
 * | KbvCovidRvoManualOptions.Outbreak | 3 |
 */

export enum KbvCovidRvoManualOptions {
  ContactPerson = 1,
  DistributionPrevention = 4,
  Outbreak = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvCovidLegalBasis.RvoTestV | 1 |
 * | KbvCovidLegalBasis.RegionalSpecialAgreement | 2 |
 * | KbvCovidLegalBasis.DirectPayer | 3 |
 */

export enum KbvCovidLegalBasis {
  RvoTestV = 1,
  RegionalSpecialAgreement = 2,
  DirectPayer = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderChangeAction.AddRequirement | add_requirement |
 * | OrderChangeAction.AddProfileRequirement | add_profile_requirement |
 * | OrderChangeAction.RemoveRequirement | remove_requirement |
 * | OrderChangeAction.RemoveProfileRequirement | remove_profile_requirement |
 * | OrderChangeAction.OrderChanged | order_changed |
 * | OrderChangeAction.GlobalSettingsChanged | global_settings_changed |
 * | OrderChangeAction.CostUnitChanged | cost_unit_changed |
 * | OrderChangeAction.OfficeDoctorChanged | office_doctor_changed |
 * | OrderChangeAction.ParametersChanged | parameters_changed |
 * | OrderChangeAction.GroupForm | group_form |
 * | OrderChangeAction.GroupBy | group_by |
 * | OrderChangeAction.SetLastUsedWorkstation | set_last_used_workstation |
 * | OrderChangeAction.SetCostUnitFromPatient | set_cost_unit_from_patient |
 * | OrderChangeAction.SetCostUnitFromPatientHvCode | set_cost_unit_from_patient_hv_code |
 * | OrderChangeAction.SetInsuranceNameFromPatient | set_insurance_name_from_patient |
 * | OrderChangeAction.Gdt2OA | gdt2oa |
 * | OrderChangeAction.Url | url |
 * | OrderChangeAction.LoadedGdtPatient | loaded_gdt_patient |
 * | OrderChangeAction.LoadedGdtPatients | loaded_gdt_patients |
 * | OrderChangeAction.LoadedGdtPatientByTimestamp | loaded_gdt_patient_by_timestamp |
 * | OrderChangeAction.LoadedPatientByCaseNumber | loaded_patient_by_case_number |
 * | OrderChangeAction.Saved | saved |
 * | OrderChangeAction.StartEdit | start_edit |
 * | OrderChangeAction.CancelEdit | cancel_edit |
 * | OrderChangeAction.CancelOrders | cancel_orders |
 * | OrderChangeAction.ExportWithoutPDF | export_without_pdf |
 * | OrderChangeAction.DeleteOrders | delete_orders |
 * | OrderChangeAction.SoftDeleteOrders | soft_delete_orders |
 * | OrderChangeAction.CancelRequirement | cancel_requirement |
 * | OrderChangeAction.UncancelRequirement | uncancel_requirement |
 * | OrderChangeAction.Executed | executed |
 * | OrderChangeAction.PrePrinted | pre_printed |
 * | OrderChangeAction.Signed | signed |
 * | OrderChangeAction.StartReRequest | start_rerequest |
 * | OrderChangeAction.AcceptReRequest | accept_rerequest |
 * | OrderChangeAction.CancelReRequest | cancel_rerequest |
 * | OrderChangeAction.SendOffReRequest | send_off_rerequest |
 * | OrderChangeAction.LDTExport | ldt_export |
 * | OrderChangeAction.HL7Export | hl7_export |
 * | OrderChangeAction.AutoOrderClose | auto_order_close |
 * | OrderChangeAction.Sent_wpusername | sent_wpusername |
 */

export enum OrderChangeAction {
  AddRequirement = 'add_requirement',
  AddProfileRequirement = 'add_profile_requirement',
  RemoveRequirement = 'remove_requirement',
  RemoveProfileRequirement = 'remove_profile_requirement',
  OrderChanged = 'order_changed',
  GlobalSettingsChanged = 'global_settings_changed',
  CostUnitChanged = 'cost_unit_changed',
  OfficeDoctorChanged = 'office_doctor_changed',
  ParametersChanged = 'parameters_changed',
  GroupForm = 'group_form',
  GroupBy = 'group_by',
  SetLastUsedWorkstation = 'set_last_used_workstation',
  SetCostUnitFromPatient = 'set_cost_unit_from_patient',
  SetCostUnitFromPatientHvCode = 'set_cost_unit_from_patient_hv_code',
  SetInsuranceNameFromPatient = 'set_insurance_name_from_patient',
  Gdt2OA = 'gdt2oa',
  Url = 'url',
  LoadedGdtPatient = 'loaded_gdt_patient',
  LoadedGdtPatients = 'loaded_gdt_patients',
  LoadedGdtPatientByTimestamp = 'loaded_gdt_patient_by_timestamp',
  LoadedPatientByCaseNumber = 'loaded_patient_by_case_number',
  Saved = 'saved',
  StartEdit = 'start_edit',
  CancelEdit = 'cancel_edit',
  CancelOrders = 'cancel_orders',
  ExportWithoutPDF = 'export_without_pdf',
  DeleteOrders = 'delete_orders',
  SoftDeleteOrders = 'soft_delete_orders',
  CancelRequirement = 'cancel_requirement',
  UncancelRequirement = 'uncancel_requirement',
  Executed = 'executed',
  PrePrinted = 'pre_printed',
  Signed = 'signed',
  StartReRequest = 'start_rerequest',
  AcceptReRequest = 'accept_rerequest',
  CancelReRequest = 'cancel_rerequest',
  SendOffReRequest = 'send_off_rerequest',
  LDTExport = 'ldt_export',
  HL7Export = 'hl7_export',
  AutoOrderClose = 'auto_order_close',
  Sent_wpusername = 'sent_wpusername'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementType.Clinical | 0 |
 * | RequirementType.Microbiological | 1 |
 */

export enum RequirementType {
  Clinical = 0,
  Microbiological = 1
}

export interface MaterialProperties {
  id?: number;
  sampleId?: number;
  sampleType?: string;
  barcodeCount?: number;
  manufacturer?: string;
  name?: string;
  materialId?: string;
  externalMaterialId?: string | null;
  limit?: number | null;
  substitutes?: SubstituteMaterialProperties | null[];
}

export interface SubstituteMaterialProperties {
  id?: number;
  sampleId?: number;
  sampleType?: string;
  manufacturer?: string;
  name?: string;
  materialId?: string;
  externalMaterialId?: string | null;
  limit?: number | null;
}

export interface AnalysisProperties {
  longName?: string;
  shortName?: string;
  examinationDuration?: number;
  id?: number;
  externalId?: string | null;
  materialId?: string;
  sampleType?: string;
  name?: string;
  alias?: string | null;
  amount?: number;
  hint?: string | null;
  askAnalysisFreeText?: boolean;
  askAnalysisFreeTextOptional?: boolean;
  examinationDurationInterval?: Interval;
  ebm?: Ebm;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementAggregationType.Name | 0 |
 * | RequirementAggregationType.Category | 1 |
 * | RequirementAggregationType.Indication | 2 |
 * | RequirementAggregationType.OrderForm | 3 |
 * | RequirementAggregationType.Materials | 4 |
 * | RequirementAggregationType.ProfilesOnly | 5 |
 * | RequirementAggregationType.GroupFormCategory | 6 |
 * | RequirementAggregationType.Requirement | 7 |
 * | RequirementAggregationType.Analysis | 8 |
 */

export enum RequirementAggregationType {
  Name = 0,
  Category = 1,
  Indication = 2,
  OrderForm = 3,
  Materials = 4,
  ProfilesOnly = 5,
  GroupFormCategory = 6,
  Requirement = 7,
  Analysis = 8
}

export interface OrderRequirementsFilter {
  id?: number;
  name?: string;
  count?: number;
  background?: string;
  doubleWidth?: boolean;
  type?: RequirementAggregationType;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderRequirementsSpecialFilterId.Unassigned | -1 |
 * | OrderRequirementsSpecialFilterId.Profiles | -2 |
 * | OrderRequirementsSpecialFilterId.Favorites | -3 |
 * | OrderRequirementsSpecialFilterId.TopRequests | -4 |
 * | OrderRequirementsSpecialFilterId.Microbiological | -5 |
 */

export enum OrderRequirementsSpecialFilterId {
  Unassigned = -1,
  Profiles = -2,
  Favorites = -3,
  TopRequests = -4,
  Microbiological = -5
}

export interface AggregatedFormProperties {
  id?: number;
  aid?: number[];
  name?: string;
  costUnit?: string;
  invoiceToChangeable?: boolean;
  defaultInvoiceTo?: string;
  isPrivate?: boolean;
  isPool?: boolean;
  requirementPrice?: number;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AggregatedProfileType.Personal | 0 |
 * | AggregatedProfileType.Global | 1 |
 * | AggregatedProfileType.RequirementCollection | 2 |
 */

export enum AggregatedProfileType {
  Personal = 0,
  Global = 1,
  RequirementCollection = 2
}

export interface SharedMicrobiological {
  formId?: number;
  selectedAnalyses?: string[];
  intraoperative?: boolean;
  origin?: string;
  analysisFreeText?: string;
  dynamicMaterials?: OrderRequirementDynamicMaterial[];
  leftRight?: MicrobiologicalLeftRight;
  selectedLocalizations?: MicrobiologicalLocalization[];
}

export interface AggregatedProfileId {
  profileId?: number;
  profileType?: AggregatedProfileType;
}

export interface AggregatedProfileProperties {
  profileId?: number;
  aid?: number;
  longName?: string;
  shortName?: string;
  examinationDuration?: number;
  formId?: number;
  order?: number;
  color?: string;
  costUnit?: string;
  diagnosis?: string;
  freeText?: string;
  orderReason?: string;
  selectedDiagnoses?: {
    [k: string]: any;
  };
  profileType?: AggregatedProfileType;
  examinationDurationInterval?: Interval;
  flags?: AggregatedRequirementFlags[];
  filter?: OrderRequirementsFilter;
  dynamicMaterials?: OrderRequirementDynamicMaterial[];
  requirementIds?: AggregatedProfileRequirementIds[];
}

export interface AggregatedCategoryProperties {
  id?: number;
  name?: string;
}

export interface AggregatedIndicationProperties {
  id?: number;
  name?: string;
}

export interface AggregatedNameProperties {
  id?: number;
  name?: string;
}

export interface AggregatedDiagnosisProperties {
  id?: number;
  key?: string;
  text?: string;
  costUnit?: string;
  onlyAdultFemale?: boolean;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AggregatedRequirementFlags.IsNew | 0 |
 * | AggregatedRequirementFlags.IsPinned | 1 |
 * | AggregatedRequirementFlags.IsProfile | 2 |
 * | AggregatedRequirementFlags.AskIntraoperative | 3 |
 * | AggregatedRequirementFlags.AskLeftRight | 4 |
 * | AggregatedRequirementFlags.AskOrigin | 5 |
 * | AggregatedRequirementFlags.AskOriginOptional | 6 |
 * | AggregatedRequirementFlags.MultiAnalysisSelect | 7 |
 * | AggregatedRequirementFlags.IsSuperRequirement | 8 |
 * | AggregatedRequirementFlags.HideInProfileAggregation | 9 |
 * | AggregatedRequirementFlags.AskAnalysisFreeText | 10 |
 */

export enum AggregatedRequirementFlags {
  IsNew = 0,
  IsPinned = 1,
  IsProfile = 2,
  AskIntraoperative = 3,
  AskLeftRight = 4,
  AskOrigin = 5,
  AskOriginOptional = 6,
  MultiAnalysisSelect = 7,
  IsSuperRequirement = 8,
  HideInProfileAggregation = 9,
  AskAnalysisFreeText = 10
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | RequirementMandatoryFieldsFlags.PatientWeight | patient.weight |
 * | RequirementMandatoryFieldsFlags.PatientHeight | patient.height |
 * | RequirementMandatoryFieldsFlags.PregnancyWeek | patient.pregnancyWeek |
 * | RequirementMandatoryFieldsFlags.Diagnosis | patient.diagnosis |
 * | RequirementMandatoryFieldsFlags.SamplePeriod | patient.samplePeriod |
 * | RequirementMandatoryFieldsFlags.SampleAmount | patient.sampleAmount |
 */

export enum RequirementMandatoryFieldsFlags {
  PatientWeight = 'patient.weight',
  PatientHeight = 'patient.height',
  PregnancyWeek = 'patient.pregnancyWeek',
  Diagnosis = 'patient.diagnosis',
  SamplePeriod = 'patient.samplePeriod',
  SampleAmount = 'patient.sampleAmount'
}

export interface DoctorTopRequirementsProperties {
  aid?: number;
  requirements?: string[];
}

export interface OrderRequirementsIncompatibleProperties {
  costUnit?: string;
  shortName?: string;
  incompatible?: string;
  makeCostUnitNot?: boolean;
}

export interface AggregatedRequirementId {
  entityId?: number;
  submitter?: string | null;
  entityType?: RequirementType;
}

export interface AggregatedProfileRequirementIds {
  entityId?: number;
  submitter?: string | null;
  formId?: number;
  selectedAnalyses?: string[];
  intraoperative?: boolean;
  origin?: string;
  analysisFreeText?: string;
  /**
   * Aggregated and filtered form ids
   */
  forms?: number[];
  freeText?: string | null;
  entityType?: RequirementType;
  dynamicMaterials?: OrderRequirementDynamicMaterial[];
  leftRight?: MicrobiologicalLeftRight;
  selectedLocalizations?: MicrobiologicalLocalization[];
}

export interface AggregatedMaterialIds {
  id?: number;
  materialId?: string;
}

export interface AggregatedAnalyseIds {
  id?: number;
  shortName?: string;
  longName?: string;
}

export interface FormLevelPrice {
  formId?: number | null;
  price?: string | null;
}

export interface AlternateFormCostUnit {
  costUnit?: string;
  formId?: number;
  isPool?: boolean;
}

export interface AlternateMibiFormCostUnit {
  costUnit?: string;
  formId?: number;
  isPool?: boolean;
  id?: string;
  entityId?: number;
}

export interface BaseOrderRequirementProperties {
  entityId?: number;
  submitter?: string;
  longName?: string;
  shortName?: string;
  examinationDuration?: number;
  id?: string;
  externalName?: string;
  entityKey?: string;
  hint?: string | null;
  alias?: string | null;
  isPrivate?: boolean;
  isCharged?: boolean;
  price?: string;
  rank?: number;
  ruleInfoText?: string;
  laboratoryGroup?: string;
  freeText?: string | null;
  freeTextDate?: string | null;
  freeTextAllowed?: boolean | null;
  freeTextMandatory?: boolean | null;
  freeTextAsDate?: boolean | null;
  limit?: number | null;
  materialLimitOverrule?: number | null;
  infoUrl?: string | null;
  additionalText?: string | null;
  duplicateRequirements?: string[];
  subRequirements?: string[];
  entityType?: RequirementType;
  examinationDurationInterval?: Interval;
  analyses?: AnalysisProperties[];
  materials?: MaterialProperties[];
  flags?: AggregatedRequirementFlags[];
  diagnoses?: AggregatedDiagnosisProperties[];
  localizations?: MicrobiologicalLocalization[];
  alternateGroupFormCostUnits?: AlternateFormCostUnit[];
  alternateMicrobiologicalFormCostUnits?: AlternateMibiFormCostUnit[];
}

export interface OrderRequirementDynamicMaterial {
  text: string;
  index?: number;
  shortName?: string;
  longName?: string;
}

export interface OrderRequirement {
  entityId?: number;
  submitter?: string;
  longName?: string;
  shortName?: string;
  examinationDuration?: number;
  id?: string;
  externalName?: string;
  entityKey?: string;
  hint?: string | null;
  alias?: string | null;
  isPrivate?: boolean;
  isCharged?: boolean;
  price?: string;
  rank?: number;
  ruleInfoText?: string;
  laboratoryGroup?: string;
  freeText?: string | null;
  freeTextDate?: string | null;
  freeTextAllowed?: boolean | null;
  freeTextMandatory?: boolean | null;
  freeTextAsDate?: boolean | null;
  limit?: number | null;
  materialLimitOverrule?: number | null;
  infoUrl?: string | null;
  additionalText?: string | null;
  duplicateRequirements?: string[];
  subRequirements?: string[];
  profileId?: number;
  formId?: number;
  selectedAnalyses?: string[];
  intraoperative?: boolean;
  origin?: string;
  analysisFreeText?: string;
  cancelled_at?: string | null;
  invoiceTo?: string | null;
  externalMaterialId?: string;
  missingAggregation?: boolean;
  entityType?: RequirementType;
  examinationDurationInterval?: Interval;
  analyses?: AnalysisProperties[];
  materials?: MaterialProperties[];
  flags?: AggregatedRequirementFlags[];
  diagnoses?: AggregatedDiagnosisProperties[];
  localizations?: MicrobiologicalLocalization[];
  alternateGroupFormCostUnits?: AlternateFormCostUnit[];
  alternateMicrobiologicalFormCostUnits?: AlternateMibiFormCostUnit[];
  profileType?: AggregatedProfileType;
  dynamicMaterials?: OrderRequirementDynamicMaterial[];
  leftRight?: MicrobiologicalLeftRight;
  selectedLocalizations?: MicrobiologicalLocalization[];
  formLevelPrices?: FormLevelPrice[];
  form?: OrderForm;
  reRequested?: ReRequestOrderRequirement | null;
}

export interface AggregatedRequirementProperties {
  entityId?: number;
  submitter?: string;
  longName?: string;
  shortName?: string;
  examinationDuration?: number;
  id?: string;
  externalName?: string;
  entityKey?: string;
  hint?: string | null;
  alias?: string | null;
  isPrivate?: boolean;
  isCharged?: boolean;
  price?: string;
  rank?: number;
  ruleInfoText?: string;
  laboratoryGroup?: string;
  freeText?: string | null;
  freeTextDate?: string | null;
  freeTextAllowed?: boolean | null;
  freeTextMandatory?: boolean | null;
  freeTextAsDate?: boolean | null;
  limit?: number | null;
  materialLimitOverrule?: number | null;
  infoUrl?: string | null;
  additionalText?: string | null;
  duplicateRequirements?: string[];
  subRequirements?: string[];
  lid?: number;
  occurrence?: number;
  pinnedByDoctors?: number[];
  topFromDoctors?: number[];
  blacklist?: number[];
  whitelist?: number[];
  blocked?: boolean;
  order?: number;
  entityType?: RequirementType;
  examinationDurationInterval?: Interval;
  analyses?: AnalysisProperties[];
  materials?: MaterialProperties[];
  flags?: AggregatedRequirementFlags[];
  diagnoses?: AggregatedDiagnosisProperties[];
  localizations?: MicrobiologicalLocalization[];
  alternateGroupFormCostUnits?: AlternateFormCostUnit[];
  alternateMicrobiologicalFormCostUnits?: AlternateMibiFormCostUnit[];
  categories?: AggregatedCategoryProperties[];
  indications?: AggregatedIndicationProperties[];
  name?: AggregatedNameProperties[];
  forms?: AggregatedFormProperties[];
  costUnits?: OrderRequirementsFilter[];
}

export interface OrderWizardRequirement {
  entityId?: number;
  submitter?: string;
  longName?: string;
  shortName?: string;
  examinationDuration?: number;
  id?: string;
  externalName?: string;
  entityKey?: string;
  hint?: string | null;
  alias?: string | null;
  isPrivate?: boolean;
  isCharged?: boolean;
  price?: string;
  rank?: number;
  ruleInfoText?: string;
  laboratoryGroup?: string;
  freeText?: string | null;
  freeTextDate?: string | null;
  freeTextAllowed?: boolean | null;
  freeTextMandatory?: boolean | null;
  freeTextAsDate?: boolean | null;
  limit?: number | null;
  materialLimitOverrule?: number | null;
  infoUrl?: string | null;
  additionalText?: string | null;
  duplicateRequirements?: string[];
  subRequirements?: string[];
  profileId?: number;
  formId?: number;
  selectedAnalyses?: string[];
  intraoperative?: boolean;
  origin?: string;
  analysisFreeText?: string;
  cancelled_at?: string | null;
  invoiceTo?: string | null;
  externalMaterialId?: string;
  missingAggregation?: boolean;
  /**
   * Aggregated and filtered form ids
   */
  forms?: number[];
  entityType?: RequirementType;
  examinationDurationInterval?: Interval;
  analyses?: AnalysisProperties[];
  materials?: MaterialProperties[];
  flags?: AggregatedRequirementFlags[];
  diagnoses?: AggregatedDiagnosisProperties[];
  localizations?: MicrobiologicalLocalization[];
  alternateGroupFormCostUnits?: AlternateFormCostUnit[];
  alternateMicrobiologicalFormCostUnits?: AlternateMibiFormCostUnit[];
  profileType?: AggregatedProfileType;
  dynamicMaterials?: OrderRequirementDynamicMaterial[];
  leftRight?: MicrobiologicalLeftRight;
  selectedLocalizations?: MicrobiologicalLocalization[];
  formLevelPrices?: FormLevelPrice[];
  form?: OrderForm;
  reRequested?: ReRequestOrderRequirement | null;
  filter?: OrderRequirementsFilter;
  filters?: OrderRequirementsFilter[];
}

export interface OrderPatientMetaProperties {
  /**
   * Patient Last Name
   */
  lastName?: string;
  /**
   * Patient First Name
   */
  firstName?: string;
  suffix?: string;
  /**
   * Patient Title
   */
  title?: string;
  /**
   * Patient Birthday
   */
  birthday?: string;
  /**
   * Patient City
   */
  city?: string;
  /**
   * Patient Zip Code
   */
  zipCode?: string;
  address?: string;
  houseNumber?: string | null;
  addressAdditional?: string;
  country?: string;
  /**
   * Patient Insurance Number
   */
  insuranceNumber?: string;
  /**
   * Patient Email Address
   */
  email?: string | null;
  /**
   * Patient Phone Number
   */
  phone?: string | null;
  costUnit?: string;
  medication?: string;
  cycleDay?: string;
  weight?: string;
  diagnosis?: string;
  exttnr?: string | null;
  /**
   * LANR (German Doctor's ID) of the primary care physician ("Erstveranlasser", The healthcare provider that holds primary responsibility for the overall care of a patient)
   */
  primaryCarePhysicianLanr?: string;
  /**
   * BSNR (German Doctor Office's ID) of the primary care physician ("Erstveranlasser", The healthcare provider that holds primary responsibility for the overall care of a patient)
   */
  primaryCarePhysicianBsnr?: string;
  /**
   * DMP
   */
  diseaseManagementProgram?: string;
  dmpSpecialGroup?: string;
  insuranceStart?: string;
  insuranceEnd?: string;
  insuranceType?: string;
  exceptionalIndication?: string;
  /**
   * SKT (Sonstiger Kostenträger)
   */
  sktIdentifier?: string;
  /**
   * WOP (Wohnortprinzip)
   */
  wopIdentifier?: string;
  accountingArea?: string | null;
  hvcode?: string;
  vkat?: string;
  secondName?: string | null;
  canton?: string | null;
  ahvNr?: string | null;
  aisNumber?: string;
  insurance?: number | null;
  bag?: string | null;
  cardNumber?: string | null;
  patientIdentification?: string;
  globalLocationNumber?: string | null;
  chTreatmentReason?: string | null;
  externalOrderInfo?: string | null;
  hospitalBedNumber?: string;
  hospitalRoomNumber?: string;
  hospitalProfession?: string | null;
  /**
   * Case ID
   */
  caseId?: number | null;
  caseNumber?: string;
  insuranceName?: string;
  /**
   * billing type of the order, who will pay for it / get invoice for it (for example patient, doctor or insurance
   */
  invoiceTo?: string;
  dz116b?: boolean | null;
  autInsuranceTitle?: string;
  autInsuranceFirstName?: string;
  autInsuranceLastName?: string;
  autInsuranceInsuranceNumber?: string;
  autInsuranceBirthday?: string;
  autInsuranceAddress?: string;
  autInsuranceHouseNumber?: string;
  autInsuranceCountry?: string;
  autInsuranceZip?: string;
  autInsuranceCity?: string;
  /**
   * ID of patient of our system, is -1 if the patient is from doctors system or manually created
   */
  pid?: number;
  /**
   * ID of patient of AIS
   */
  apgid?: number | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  prefix?: string;
  height?: string;
  pregnancyWeek?: string;
  pregnancyWeekDay?: string;
  cycleLength?: string | null;
  dayOfCycle?: string;
  fetalCrownRumpLength?: string | null;
  fetalNuchalTranslucency?: string | null;
  hasInsuranceNumber?: boolean | null;
  sampleAmount?: string;
  samplePeriod?: string;
  sampleDate?: string;
  sampleTime?: string;
  passportNumber?: string | null;
  idcardNumber?: string | null;
  /**
   * either a digit or an empty string (digits mean: 1=cure, 2=preventive, 3=ESS, 4=receipt)
   */
  orderReason?: string;
  costUnitIdentification?: string;
  autInsuranceEmployer?: string;
  autInsuranceEmployerCity?: string;
  autInsuranceAddressAdditional?: string;
  gender?: Gender;
}

export interface OrderPatientGDTSharedProperties {
  medication?: string;
  cycleDay?: string;
  weight?: string;
  diagnosis?: string;
  exttnr?: string | null;
  /**
   * LANR (German Doctor's ID) of the primary care physician ("Erstveranlasser", The healthcare provider that holds primary responsibility for the overall care of a patient)
   */
  primaryCarePhysicianLanr?: string;
  /**
   * BSNR (German Doctor Office's ID) of the primary care physician ("Erstveranlasser", The healthcare provider that holds primary responsibility for the overall care of a patient)
   */
  primaryCarePhysicianBsnr?: string;
  /**
   * DMP
   */
  diseaseManagementProgram?: string;
  dmpSpecialGroup?: string;
  insuranceStart?: string;
  insuranceEnd?: string;
  insuranceType?: string;
  exceptionalIndication?: string;
  /**
   * SKT (Sonstiger Kostenträger)
   */
  sktIdentifier?: string;
  /**
   * WOP (Wohnortprinzip)
   */
  wopIdentifier?: string;
  accountingArea?: string | null;
  hvcode?: string;
  vkat?: string;
  secondName?: string | null;
  canton?: string | null;
  ahvNr?: string | null;
  aisNumber?: string;
  insurance?: number | null;
  bag?: string | null;
  cardNumber?: string | null;
  patientIdentification?: string;
  globalLocationNumber?: string | null;
  chTreatmentReason?: string | null;
  externalOrderInfo?: string | null;
  hospitalBedNumber?: string;
  hospitalRoomNumber?: string;
  hospitalProfession?: string | null;
  /**
   * Case ID
   */
  caseId?: number | null;
  caseNumber?: string;
  insuranceName?: string;
  invoiceTo?: string;
  dz116b?: boolean | null;
  autInsuranceTitle?: string;
  autInsuranceFirstName?: string;
  autInsuranceLastName?: string;
  autInsuranceInsuranceNumber?: string;
  autInsuranceBirthday?: string;
  autInsuranceAddress?: string;
  autInsuranceHouseNumber?: string;
  autInsuranceCountry?: string;
  autInsuranceZip?: string;
  autInsuranceCity?: string;
}

export interface OrderRuleActionProperties {
  options: {
    [k: string]: any;
  } | null;
  type?: OrderRuleActionType;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderRuleActionType.ShowHint | show_hint |
 * | OrderRuleActionType.ShowText | show_text |
 * | OrderRuleActionType.AddRequirement | add_requirement |
 * | OrderRuleActionType.IncompatibleRequirement | incompatible_requirement |
 * | OrderRuleActionType.RemoveRequirement | remove_requirement |
 * | OrderRuleActionType.MandatoryField | mandatory_field |
 * | OrderRuleActionType.StatusPrevention | status_prevention |
 * | OrderRuleActionType.Anamnesis | anamnesis |
 * | OrderRuleActionType.FreetextMandatory | freetext_mandatory |
 * | OrderRuleActionType.MaxRequirements | max_requirements |
 * | OrderRuleActionType.DiagnosisMandatory | diagnosis_mandatory |
 * | OrderRuleActionType.WorkstationMandatory | workstation_mandatory |
 * | OrderRuleActionType.DialysisMandatory | dialysis_mandatory |
 */

export enum OrderRuleActionType {
  ShowHint = 'show_hint',
  ShowText = 'show_text',
  AddRequirement = 'add_requirement',
  IncompatibleRequirement = 'incompatible_requirement',
  RemoveRequirement = 'remove_requirement',
  MandatoryField = 'mandatory_field',
  StatusPrevention = 'status_prevention',
  Anamnesis = 'anamnesis',
  FreetextMandatory = 'freetext_mandatory',
  MaxRequirements = 'max_requirements',
  DiagnosisMandatory = 'diagnosis_mandatory',
  WorkstationMandatory = 'workstation_mandatory',
  DialysisMandatory = 'dialysis_mandatory'
}

export interface OrderRuleConditionProperties {
  notOperator?: boolean;
  options: {
    [k: string]: any;
  } | null;
  type?: OrderRuleConditionType;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderRuleConditionType.CostUnit | cost_unit |
 * | OrderRuleConditionType.DoctorGroups | doctor_groups |
 * | OrderRuleConditionType.Doctors | doctors |
 * | OrderRuleConditionType.Feature | feature |
 * | OrderRuleConditionType.Form | form |
 * | OrderRuleConditionType.FormType | form_type |
 * | OrderRuleConditionType.Gender | gender |
 * | OrderRuleConditionType.Localization | localization |
 * | OrderRuleConditionType.Product | product |
 * | OrderRuleConditionType.Requirement | requirement |
 * | OrderRuleConditionType.Status | status |
 * | OrderRuleConditionType.UserType | user_type |
 */

export enum OrderRuleConditionType {
  CostUnit = 'cost_unit',
  DoctorGroups = 'doctor_groups',
  Doctors = 'doctors',
  Feature = 'feature',
  Form = 'form',
  FormType = 'form_type',
  Gender = 'gender',
  Localization = 'localization',
  Product = 'product',
  Requirement = 'requirement',
  Status = 'status',
  UserType = 'user_type'
}

export interface OrderRulesListQueryProperties {
  query?: string;
  autoGenerated?: boolean;
}

export interface OrderRulesResultProperties {
  errors?: {
    [k: string]: any;
  }[];
  order?: WriteableOrderProperties;
  verifiedRules?: OrderRule[];
}

export interface OrderRulesAddRequirementActionOptions {
  shortName?: string;
  laboratoryGroup?: string;
}

export interface OrderRulesAnamnesisActionOptions {
  questionId?: number;
}

export interface OrderRulesDiagnosisMandatoryActionOptions {
  enabled?: boolean;
}

export interface OrderRulesFreetextMandatoryActionOptions {
  shortName?: string;
}

export interface OrderRulesIncompatibleRequirementActionOptions {
  shortName?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderRulesMandatoryField.FirstName | patient.firstName |
 * | OrderRulesMandatoryField.LastName | patient.lastName |
 * | OrderRulesMandatoryField.Gender | patient.gender |
 * | OrderRulesMandatoryField.PatientWeight | patient.weight |
 * | OrderRulesMandatoryField.PatientHeight | patient.height |
 * | OrderRulesMandatoryField.Birthday | patient.birthday |
 * | OrderRulesMandatoryField.Country | patient.country |
 * | OrderRulesMandatoryField.ZipCode | patient.zipCode |
 * | OrderRulesMandatoryField.City | patient.city |
 * | OrderRulesMandatoryField.Address | patient.address |
 * | OrderRulesMandatoryField.HouseNumber | patient.houseNumber |
 * | OrderRulesMandatoryField.InsuranceNumber | patient.insuranceNumber |
 * | OrderRulesMandatoryField.InsuranceName | insuranceName |
 * | OrderRulesMandatoryField.Diagnosis | patient.diagnosis |
 * | OrderRulesMandatoryField.CostUnitIdentification | patient.costUnitIdentification |
 * | OrderRulesMandatoryField.AccountingArea | patient.accountingArea |
 * | OrderRulesMandatoryField.InsuranceType | patient.insuranceType |
 * | OrderRulesMandatoryField.WopIdentifier | patient.wopIdentifier |
 * | OrderRulesMandatoryField.AhvNr | patient.ahvNr |
 * | OrderRulesMandatoryField.AisNumber | patient.aisNumber |
 * | OrderRulesMandatoryField.Phone | patient.phone |
 * | OrderRulesMandatoryField.Email | patient.email |
 * | OrderRulesMandatoryField.HvCode | patient.hvcode |
 * | OrderRulesMandatoryField.VKat | patient.vkat |
 * | OrderRulesMandatoryField.LegalOrderBasis | covid.legalOrderBasis |
 * | OrderRulesMandatoryField.KvSpecial | covid.kvSpecial |
 * | OrderRulesMandatoryField.TestNumber | covid.testNumber |
 * | OrderRulesMandatoryField.LegalReason | covid.legalReason |
 * | OrderRulesMandatoryField.ConfirmationDiagnostics | covid.confirmationDiagnostics |
 * | OrderRulesMandatoryField.RiskCharacteristicsLocation | covid.riskCharacteristicsLocation |
 * | OrderRulesMandatoryField.HealthService | covid.healthService |
 * | OrderRulesMandatoryField.HealthServiceZipCode | covid.healthServiceZipCode |
 * | OrderRulesMandatoryField.PregnancyWeek | patient.pregnancyWeek |
 * | OrderRulesMandatoryField.PregnancyWeekDay | patient.pregnancyWeekDay |
 * | OrderRulesMandatoryField.SamplePeriod | patient.samplePeriod |
 * | OrderRulesMandatoryField.SampleAmount | patient.sampleAmount |
 * | OrderRulesMandatoryField.SampleDate | patient.sampleDate |
 * | OrderRulesMandatoryField.SampleTime | patient.sampleTime |
 * | OrderRulesMandatoryField.InvoiceTo | patient.invoiceTo |
 * | OrderRulesMandatoryField.Tnr | tnr |
 * | OrderRulesMandatoryField.ScheduleDate | scheduled_at |
 * | OrderRulesMandatoryField.CostUnit | costUnit |
 */

export enum OrderRulesMandatoryField {
  FirstName = 'patient.firstName',
  LastName = 'patient.lastName',
  Gender = 'patient.gender',
  PatientWeight = 'patient.weight',
  PatientHeight = 'patient.height',
  Birthday = 'patient.birthday',
  Country = 'patient.country',
  ZipCode = 'patient.zipCode',
  City = 'patient.city',
  Address = 'patient.address',
  HouseNumber = 'patient.houseNumber',
  InsuranceNumber = 'patient.insuranceNumber',
  InsuranceName = 'insuranceName',
  Diagnosis = 'patient.diagnosis',
  CostUnitIdentification = 'patient.costUnitIdentification',
  AccountingArea = 'patient.accountingArea',
  InsuranceType = 'patient.insuranceType',
  WopIdentifier = 'patient.wopIdentifier',
  AhvNr = 'patient.ahvNr',
  AisNumber = 'patient.aisNumber',
  Phone = 'patient.phone',
  Email = 'patient.email',
  HvCode = 'patient.hvcode',
  VKat = 'patient.vkat',
  LegalOrderBasis = 'covid.legalOrderBasis',
  KvSpecial = 'covid.kvSpecial',
  TestNumber = 'covid.testNumber',
  LegalReason = 'covid.legalReason',
  ConfirmationDiagnostics = 'covid.confirmationDiagnostics',
  RiskCharacteristicsLocation = 'covid.riskCharacteristicsLocation',
  HealthService = 'covid.healthService',
  HealthServiceZipCode = 'covid.healthServiceZipCode',
  PregnancyWeek = 'patient.pregnancyWeek',
  PregnancyWeekDay = 'patient.pregnancyWeekDay',
  SamplePeriod = 'patient.samplePeriod',
  SampleAmount = 'patient.sampleAmount',
  SampleDate = 'patient.sampleDate',
  SampleTime = 'patient.sampleTime',
  InvoiceTo = 'patient.invoiceTo',
  Tnr = 'tnr',
  ScheduleDate = 'scheduled_at',
  CostUnit = 'costUnit'
}

export interface OrderRulesMandatoryFieldActionOptions {
  field?: OrderRulesMandatoryField;
}

export interface OrderRulesRemoveRequirementActionOptions {
  shortName?: string;
}

export interface OrderRulesShowHintActionOptions {
  title?: string;
  text?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderRulesShowTextActionType.Info | info |
 * | OrderRulesShowTextActionType.Warning | warning |
 * | OrderRulesShowTextActionType.Error | error |
 * | OrderRulesShowTextActionType.Success | success |
 */

export enum OrderRulesShowTextActionType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success'
}

export interface OrderRulesShowTextActionOptions {
  text?: string;
  type?: OrderRulesShowTextActionType;
}

export interface OrderRulesStatusPreventionActionOptions {
  status?: OrderStatus;
}

export interface OrderRulesWorkstationMandatoryActionOptions {
  enabled?: boolean;
}

export interface OrderRulesDialysisMandatoryActionOptions {
  enabled?: boolean;
}

export interface OrderRulesMaxRequirementsActionOptions {
  maxRequirements?: number;
  formId?: number;
}

export interface OrderRulesCostUnitConditionOptions {
  costUnit?: string;
}

export interface OrderRulesDoctorGroupsConditionOptions {
  doctorGroups?: DoctorGroup[];
}

export interface OrderRulesDoctorsConditionOptions {
  doctors?: Doctor[];
}

export interface OrderRulesFeatureConditionOptions {
  key?: Feature;
}

export interface OrderRulesFormConditionOptions {
  formId?: number;
}

export interface OrderRulesFormTypeConditionOptions {
  type?: FormType;
}

export interface OrderRulesGenderConditionOptions {
  gender?: Gender;
}

export interface OrderRulesLocalizationConditionOptions {
  localisation?: OrderWizardLocalization;
}

export interface OrderRulesRequirementConditionOptions {
  shortName?: string;
  laboratoryGroup?: string;
}

export interface OrderRulesProductConditionOptions {
  key?: Product;
}

export interface OrderRulesStatusConditionOptions {
  status?: OrderStatus;
}

export interface OrderRulesUserTypeConditionOptions {
  type?: UserType;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReadStatusType.Unread | 1 |
 * | ReadStatusType.Read | 0 |
 */

export enum ReadStatusType {
  Unread = 1,
  Read = 0
}


/**
 * <p style="margin-top: 16px;">Send '' (empty string) or 'U' for the default value. Defines the comment position relative to the result. In general: U=below the result. X=grey text color, below the result. O=above the result, printed bold (like a subheading). The comment type gets a different meaning in microbiological context, the comments are just printed below and in normal format (O does not mean above/bold here). Also, depending on the current vtable type, depending on this type the comment may not get rendered and the position may change (rules were defined by European Idexx team).</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | ReportValueCommentType.Default |  |
 * | ReportValueCommentType.Below | U |
 * | ReportValueCommentType.BelowGrey | X |
 * | ReportValueCommentType.Above | O |
 */

export enum ReportValueCommentType {
  Default = '',
  Below = 'U',
  BelowGrey = 'X',
  Above = 'O'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportPrintJobTypes.Mail | mail |
 */

export enum ReportPrintJobTypes {
  Mail = 'mail'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportPrintJobStatus.ToPrint | -1 |
 * | ReportPrintJobStatus.Intermediate | 0 |
 * | ReportPrintJobStatus.Printed | 1 |
 */

export enum ReportPrintJobStatus {
  ToPrint = -1,
  Intermediate = 0,
  Printed = 1
}

export interface LoadAisImportProperties {
  loadAisFirstname?: string;
  loadAisLastname?: string;
  loadAisBirthdate?: string;
  loadAisShowReport?: boolean;
}

export interface OriginalReportProperties {
  /**
   * HL7 PDF Export
   */
  f_ob_opt1?: number;
  /**
   * Original PDF Export
   */
  f_ob_opt2?: number;
  /**
   * HL7 Display
   */
  f_ob_opt3?: number;
  f_ob_opt4?: number;
}

export interface OriginalReportSettings {
  settings?: OriginalReportProperties;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OriginalReportDisplayValue.ShowIfAvailable | 0 |
 * | OriginalReportDisplayValue.NeverShow | 1 |
 * | OriginalReportDisplayValue.ShowIfNothingElse | 2 |
 */

export enum OriginalReportDisplayValue {
  ShowIfAvailable = 0,
  NeverShow = 1,
  ShowIfNothingElse = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | GuidReportValueHighlightColor.Positive | -1 |
 * | GuidReportValueHighlightColor.Unknown | 0 |
 * | GuidReportValueHighlightColor.Negative | 1 |
 */

export enum GuidReportValueHighlightColor {
  Positive = -1,
  Unknown = 0,
  Negative = 1
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | GuidPdfProtectionMode.Password | 1 |
 * | GuidPdfProtectionMode.BirthdayAndName | 2 |
 * | GuidPdfProtectionMode.BirthdayAndZipCode | 4 |
 * | GuidPdfProtectionMode.BirthdayOnly | 6 |
 */

export enum GuidPdfProtectionMode {
  Password = 1,
  BirthdayAndName = 2,
  BirthdayAndZipCode = 4,
  BirthdayOnly = 6
}

export interface GuidPdfConfig {
  available?: boolean;
  askFirstName?: boolean;
  askPassport?: boolean;
  askIdCard?: boolean;
  protection?: GuidPdfProtectionMode;
}

export interface ReportsPdfDownloadAnalyseSetting {
  name?: string;
  pathological?: boolean;
}

export interface ReportsPdfDownloadAnalysesSettings {
  enabledMode?: boolean;
  analyses?: ReportsPdfDownloadAnalyseSetting[];
}

export interface ReportBaseProperties {
  /**
   * Show in BDW, also important for reportdesigner
   */
  material?: string;
}


/**
 * <p style="margin-top: 16px;">Defines the footnote position (legacy value B is now removed, use F instead)</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | ReportNoteType.InterpretationOrComment | F |
 * | ReportNoteType.ClinicalStatements | K |
 */

export enum ReportNoteType {
  InterpretationOrComment = 'F',
  ClinicalStatements = 'K'
}


/**
 * <p style="margin-top: 16px;">Defines segment dividers, the Albumin,Alpha-1,Alpha-2,.. sections are described by those dividers. The descriptions 'Albumin,Alpha-1,Alpha-2,..' are rendered at the peak of the segments. Typically there are 5 segments (i.e. 6 SegmentDividers are used), if there are 6 segments, 7 SegmentDividers should be used.</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | ReportElphoLine.Normal |  |
 * | ReportElphoLine.SegmentDivider | x |
 */

export enum ReportElphoLine {
  Normal = '',
  SegmentDivider = 'x'
}


/**
 * <p style="margin-top: 16px;">Defines the description, 0 means 'Albumin','Alpha-1','Alpha-2','Beta','Gamma','', 1 means 'Pre-Albumin','Albumin','Alpha','Beta','Gamma',''. If there are 6, not 5 segments (i.e. 7 SegmentDividers are used), instead of 'Beta', 'Beta-1' and 'Beta-2' is printed. This value will be Type0 most of the time.</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | ReportElphoType.Type0 | 0 |
 * | ReportElphoType.Type1 | 1 |
 */

export enum ReportElphoType {
  Type0 = 0,
  Type1 = 1
}

export interface ReportAccessStats {
  total?: number;
  today?: number;
  last7?: number;
  last30?: number;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SettlementType.Insurance | K |
 * | SettlementType.Private | P |
 * | SettlementType.SubmittingDoctor | E |
 */

export enum SettlementType {
  Insurance = 'K',
  Private = 'P',
  SubmittingDoctor = 'E'
}

export interface ExportMeSettingsStatusFlags {
  preliminary?: boolean;
  partial?: boolean;
  final?: boolean;
}

export interface ExportMeSettingsCharacteristicFlags {
  notPathological?: boolean;
  pathological?: boolean;
  extreme?: boolean;
  deltaCheck?: boolean;
}

export interface ReportsDoctorSettings {
  canActivatePatients?: boolean;
  isSuperDoctor?: boolean;
  disableViewDiagnosis?: boolean;
  emailReports?: boolean;
  speciality?: string | null;
  exportMe?: boolean;
  exportMeReceiver?: string | null;
  reportsLanguageSetting?: LanguageCode | null;
  exportMeSettingsStatusFlags?: ExportMeSettingsStatusFlags;
  exportMeSettingsCharacteristicFlags?: ExportMeSettingsCharacteristicFlags;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AdvancedAnalysisFilterType.Exists | exists |
 * | AdvancedAnalysisFilterType.Equals | = |
 * | AdvancedAnalysisFilterType.LessOrEquals | <= |
 * | AdvancedAnalysisFilterType.GreaterOrEquals | >= |
 * | AdvancedAnalysisFilterType.Text | t |
 * | AdvancedAnalysisFilterType.Patho | p |
 * | AdvancedAnalysisFilterType.Extreme | e |
 */

export enum AdvancedAnalysisFilterType {
  Exists = 'exists',
  Equals = '=',
  LessOrEquals = '<=',
  GreaterOrEquals = '>=',
  Text = 't',
  Patho = 'p',
  Extreme = 'e'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | GeneralReportFilterType.Microbiological | 1 |
 * | GeneralReportFilterType.Chemical | 2 |
 * | GeneralReportFilterType.Poct | 3 |
 */

export enum GeneralReportFilterType {
  Microbiological = 1,
  Chemical = 2,
  Poct = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportAccessAction.View | 0 |
 * | ReportAccessAction.ExportPdf | 1 |
 * | ReportAccessAction.History | 2 |
 * | ReportAccessAction.CumulativeView | 3 |
 * | ReportAccessAction.LexicalView | 4 |
 * | ReportAccessAction.QuickAccessView | 5 |
 * | ReportAccessAction.QuickScanView | 6 |
 * | ReportAccessAction.ReportPushPathological | 10 |
 * | ReportAccessAction.ReportPushAll | 11 |
 * | ReportAccessAction.ReportPushExtreme | 12 |
 * | ReportAccessAction.ReportPushDelta | 13 |
 * | ReportAccessAction.ReportPushCustom | 14 |
 * | ReportAccessAction.DoctorEmailNotification | 15 |
 * | ReportAccessAction.PatientEmailNotification | 16 |
 * | ReportAccessAction.DoctorSmsNotification | 17 |
 * | ReportAccessAction.PatientSmsNotification | 18 |
 * | ReportAccessAction.ReportSendFax | 19 |
 * | ReportAccessAction.ReportSendEmail | 20 |
 * | ReportAccessAction.QuickReportPush | 21 |
 */

export enum ReportAccessAction {
  View = 0,
  ExportPdf = 1,
  History = 2,
  CumulativeView = 3,
  LexicalView = 4,
  QuickAccessView = 5,
  QuickScanView = 6,
  ReportPushPathological = 10,
  ReportPushAll = 11,
  ReportPushExtreme = 12,
  ReportPushDelta = 13,
  ReportPushCustom = 14,
  DoctorEmailNotification = 15,
  PatientEmailNotification = 16,
  DoctorSmsNotification = 17,
  PatientSmsNotification = 18,
  ReportSendFax = 19,
  ReportSendEmail = 20,
  QuickReportPush = 21
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportChangeAction.NewReport | 1 |
 * | ReportChangeAction.UpdatedReport | 2 |
 * | ReportChangeAction.NewDoctor | 3 |
 * | ReportChangeAction.NewPatient | 4 |
 * | ReportChangeAction.LockedReport | 5 |
 * | ReportChangeAction.ImportELPHO | 17 |
 * | ReportChangeAction.EmailToLabor | 18 |
 * | ReportChangeAction.MarkCorrectReport | 19 |
 * | ReportChangeAction.ImportPDF | 22 |
 * | ReportChangeAction.ReRequest | 23 |
 * | ReportChangeAction.UnlockedReport | 24 |
 * | ReportChangeAction.DeletedAttachment | 26 |
 * | ReportChangeAction.CreateComment | 27 |
 * | ReportChangeAction.DeleteComment | 28 |
 * | ReportChangeAction.ManualRead | 29 |
 * | ReportChangeAction.UploadedAttachment | 30 |
 * | ReportChangeAction.ManualUnread | 31 |
 * | ReportChangeAction.WpusernameSent | 32 |
 */

export enum ReportChangeAction {
  NewReport = 1,
  UpdatedReport = 2,
  NewDoctor = 3,
  NewPatient = 4,
  LockedReport = 5,
  ImportELPHO = 17,
  EmailToLabor = 18,
  MarkCorrectReport = 19,
  ImportPDF = 22,
  ReRequest = 23,
  UnlockedReport = 24,
  DeletedAttachment = 26,
  CreateComment = 27,
  DeleteComment = 28,
  ManualRead = 29,
  UploadedAttachment = 30,
  ManualUnread = 31,
  WpusernameSent = 32
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportCharacteristic.NotPathological | 0 |
 * | ReportCharacteristic.Pathological | 1 |
 * | ReportCharacteristic.Extreme | 2 |
 * | ReportCharacteristic.DeltaCheck | 3 |
 */

export enum ReportCharacteristic {
  NotPathological = 0,
  Pathological = 1,
  Extreme = 2,
  DeltaCheck = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportMibiType.None |  |
 * | ReportMibiType.Mixed | J |
 * | ReportMibiType.Only | O |
 */

export enum ReportMibiType {
  None = '',
  Mixed = 'J',
  Only = 'O'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportStatus.Preliminary | A |
 * | ReportStatus.Partial | T |
 * | ReportStatus.Final | E |
 * | ReportStatus.OrderReceived | O |
 * | ReportStatus.SpecimenReceived | I |
 * | ReportStatus.IDEXXCertificateRequest | V |
 * | ReportStatus.IDEXXXCertificateFinal | X |
 */

export enum ReportStatus {
  Preliminary = 'A',
  Partial = 'T',
  Final = 'E',
  OrderReceived = 'O',
  SpecimenReceived = 'I',
  IDEXXCertificateRequest = 'V',
  IDEXXXCertificateFinal = 'X'
}


/**
 * <p style="margin-top: 16px;">Send 'J' for microbiologic results (defaults to 'N' which means no microbiologic result), works only if the template supports microbiological values (example: VB). </p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | ReportValueMibiType.IsMibiValue | J |
 * | ReportValueMibiType.NoMibiValue | N |
 */

export enum ReportValueMibiType {
  IsMibiValue = 'J',
  NoMibiValue = 'N'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ResultFormat.Result | CE |
 * | ResultFormat.Description | TX |
 */

export enum ResultFormat {
  Result = 'CE',
  Description = 'TX'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ResultGraphicFormat.NoGraphic | 0 |
 * | ResultGraphicFormat.ReferenceAreaGraphicColor | 1 |
 * | ResultGraphicFormat.AllergyGraphic | 2 |
 * | ResultGraphicFormat.ReferenceAreaGraphicGreyScale | 3 |
 */

export enum ResultGraphicFormat {
  NoGraphic = 0,
  ReferenceAreaGraphicColor = 1,
  AllergyGraphic = 2,
  ReferenceAreaGraphicGreyScale = 3
}

export interface ReportsGeneralConfig {
  allergyClasses?: number[];
  preferences?: ReportsPreferencesProperties;
  reportDisplay?: ReportDisplaySettings;
  patients?: ReportsGeneralPatientsConfig;
}

export interface ReportsUserConfig {
  preferences?: ReportsPreferencesProperties;
  lanr?: LanrPreferenceProperties;
  doctor?: ReportsDoctorSettings;
}

export interface ReportsGeneralPatientsConfig {
  defaultPatientCanViewPartialResults?: boolean;
  defaultPatientCanViewPreliminaryResults?: boolean;
}

export interface ReportsNotificationSettings {
  custom?: boolean;
  characteristics?: ReportCharacteristic[];
  status?: ReportStatus[];
}

export interface ReportsConfig {
  allergyClasses?: number[];
  preferences?: ReportsPreferencesProperties;
  reportDisplay?: ReportDisplaySettings;
  patients?: ReportsGeneralPatientsConfig;
  lanr?: LanrPreferenceProperties;
  doctor?: ReportsDoctorSettings;
}

export interface AdvancedAnalysisFilter {
  name?: string;
  value?: string;
  filterType?: AdvancedAnalysisFilterType;
}

export interface FilterProfileProperties {
  locked?: boolean;
  dateFrom?: string;
  dateTo?: string;
  doctorName?: string;
  status?: ReportStatus;
  gender?: Gender;
  characteristic?: ReportCharacteristic;
  reportType?: GeneralReportFilterType;
  advancedAnalysisFilter?: AdvancedAnalysisFilter[];
  unread?: ReadStatusType;
}

export interface ReportHistoryValue {
  /**
   * Report value name
   */
  name?: string;
  /**
   * Report value description
   */
  description?: string;
  /**
   * Report value description
   */
  group?: string;
  /**
   * POCT value
   */
  poct?: string | null;
  values?: ReportValueProperties[];
}

export interface ReportValueProperties {
  /**
   * ID of report
   */
  bid?: number;
  /**
   * Case ID
   */
  caseId?: number | null;
  caseNumber?: string | null;
  /**
   * Number of report in laboratory
   */
  btnr?: string;
  /**
   * laboratory group of report
   */
  blg?: string;
  /**
   * Laboratory date and time of report
   */
  date?: string;
  /**
   * Value of Result
   */
  result?: string;
  /**
   * Is used for longer text results, triggers a different result line (without columns from_to_display, pathologic_code and without reference area graphic) in report rendering
   */
  textResult?: string;
  /**
   * Unit of Result
   */
  unit?: string;
  /**
   * if not null or not empty, then the current result is pathological. contains the pathological code
   */
  pathological?: string;
  /**
   * POCT value
   */
  poct?: boolean | null;
  /**
   * laboratory group which was responsible for the analysis
   */
  laboratoryGroup?: string;
  reference?: ReportValueReference;
  graphicFormat?: ResultGraphicFormat;
}

export interface ReportValueReference {
  /**
   * Reference area - starting value
   */
  from?: string;
  /**
   * Reference area - ending value
   */
  to?: string;
  /**
   * Display Value of the reference area
   */
  text?: string;
}


/**
 * <p style="margin-top: 16px;">Different IDEXX laboratory locations, triggers report templates. Most layouts are similar, exceptions: VB (microbiology) and HI (histology)</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | IdexxLaboratoryGroup.IT | IT |
 * | IdexxLaboratoryGroup.PL | PL |
 * | IdexxLaboratoryGroup.SK | SK |
 * | IdexxLaboratoryGroup.FI | FI |
 * | IdexxLaboratoryGroup.LZ | LZ |
 * | IdexxLaboratoryGroup.HU | HU |
 * | IdexxLaboratoryGroup.VB | VB |
 * | IdexxLaboratoryGroup.GE | GE |
 * | IdexxLaboratoryGroup.HI | HI |
 * | IdexxLaboratoryGroup.MA | MA |
 * | IdexxLaboratoryGroup.HY | HY |
 * | IdexxLaboratoryGroup.TE | TE |
 * | IdexxLaboratoryGroup.VM | VM |
 * | IdexxLaboratoryGroup.VX | VX |
 * | IdexxLaboratoryGroup.VY | VY |
 * | IdexxLaboratoryGroup.BR | BR |
 * | IdexxLaboratoryGroup.HF | HF |
 * | IdexxLaboratoryGroup.TW | TW |
 * | IdexxLaboratoryGroup.VF | VF |
 * | IdexxLaboratoryGroup.BF | BF |
 * | IdexxLaboratoryGroup.CF | CF |
 * | IdexxLaboratoryGroup.GF | GF |
 * | IdexxLaboratoryGroup.TF | TF |
 * | IdexxLaboratoryGroup.PF | PF |
 * | IdexxLaboratoryGroup.AT | AT |
 */

export enum IdexxLaboratoryGroup {
  IT = 'IT',
  PL = 'PL',
  SK = 'SK',
  FI = 'FI',
  LZ = 'LZ',
  HU = 'HU',
  VB = 'VB',
  GE = 'GE',
  HI = 'HI',
  MA = 'MA',
  HY = 'HY',
  TE = 'TE',
  VM = 'VM',
  VX = 'VX',
  VY = 'VY',
  BR = 'BR',
  HF = 'HF',
  TW = 'TW',
  VF = 'VF',
  BF = 'BF',
  CF = 'CF',
  GF = 'GF',
  TF = 'TF',
  PF = 'PF',
  AT = 'AT'
}


/**
 * <p style="margin-top: 16px;">Language code for the report (we are translating placeholders depending on this value, this value itself is not printed on the report)</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | IdexxLangCode.Cs | cs |
 * | IdexxLangCode.En | en |
 * | IdexxLangCode.It | it |
 * | IdexxLangCode.De | de |
 * | IdexxLangCode.Pl | pl |
 * | IdexxLangCode.Sk | sk |
 * | IdexxLangCode.Fr | fr |
 * | IdexxLangCode.Nl | nl |
 */

export enum IdexxLangCode {
  Cs = 'cs',
  En = 'en',
  It = 'it',
  De = 'de',
  Pl = 'pl',
  Sk = 'sk',
  Fr = 'fr',
  Nl = 'nl'
}


/**
 * <p style="margin-top: 16px;">IDEXX Legal Entity = invoicing party, which sells the reporting; triggers report header with laboratory information (lab name, address, phone number, ...), rendered at the first (top) section of the report</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | IdexxLegalEntity.DK | DK |
 * | IdexxLegalEntity.IT | IT |
 * | IdexxLegalEntity.AU | AU |
 * | IdexxLegalEntity.FI | FI |
 * | IdexxLegalEntity.HU | HU |
 * | IdexxLegalEntity.SE | SE |
 * | IdexxLegalEntity.NL | NL |
 * | IdexxLegalEntity.PL | PL |
 * | IdexxLegalEntity.NO | NO |
 * | IdexxLegalEntity.CZ | CZ |
 * | IdexxLegalEntity.BE | BE |
 * | IdexxLegalEntity.EN | EN |
 * | IdexxLegalEntity.ES | ES |
 * | IdexxLegalEntity.SK | SK |
 * | IdexxLegalEntity.VF | VF |
 * | IdexxLegalEntity.VM | VM |
 */

export enum IdexxLegalEntity {
  DK = 'DK',
  IT = 'IT',
  AU = 'AU',
  FI = 'FI',
  HU = 'HU',
  SE = 'SE',
  NL = 'NL',
  PL = 'PL',
  NO = 'NO',
  CZ = 'CZ',
  BE = 'BE',
  EN = 'EN',
  ES = 'ES',
  SK = 'SK',
  VF = 'VF',
  VM = 'VM'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | IdexxPdfAttachment.None |  |
 * | IdexxPdfAttachment.AutovakzineAU | Autovakzine_au.pdf |
 * | IdexxPdfAttachment.InsektenVM | Insekten_vm.pdf |
 * | IdexxPdfAttachment.RezeptAU | Rezept_au.pdf |
 * | IdexxPdfAttachment.RezeptCZ | Rezept_cz.pdf |
 * | IdexxPdfAttachment.RezeptIC | Rezept_ic.pdf |
 * | IdexxPdfAttachment.RezeptINT | Rezept_int.pdf |
 * | IdexxPdfAttachment.RezeptIT | Rezept_it.pdf |
 * | IdexxPdfAttachment.RezeptITOld | Rezept_it_old.pdf |
 * | IdexxPdfAttachment.RezeptN6 | Rezept_n6.pdf |
 * | IdexxPdfAttachment.RezeptNL | Rezept_nl.pdf |
 * | IdexxPdfAttachment.RezeptPL | Rezept_pl.pdf |
 * | IdexxPdfAttachment.RezeptPLOld | Rezept_pl_old.pdf |
 * | IdexxPdfAttachment.RezeptPLOld2 | Rezept_pl_old2.pdf |
 * | IdexxPdfAttachment.RezeptVM | Rezept_vm.pdf |
 */

export enum IdexxPdfAttachment {
  None = '',
  AutovakzineAU = 'Autovakzine_au.pdf',
  InsektenVM = 'Insekten_vm.pdf',
  RezeptAU = 'Rezept_au.pdf',
  RezeptCZ = 'Rezept_cz.pdf',
  RezeptIC = 'Rezept_ic.pdf',
  RezeptINT = 'Rezept_int.pdf',
  RezeptIT = 'Rezept_it.pdf',
  RezeptITOld = 'Rezept_it_old.pdf',
  RezeptN6 = 'Rezept_n6.pdf',
  RezeptNL = 'Rezept_nl.pdf',
  RezeptPL = 'Rezept_pl.pdf',
  RezeptPLOld = 'Rezept_pl_old.pdf',
  RezeptPLOld2 = 'Rezept_pl_old2.pdf',
  RezeptVM = 'Rezept_vm.pdf'
}


/**
 * <p style="margin-top: 16px;">Most of the time this value will be 'E' (Final). We will render the PDF no matter which value this field has. Will be displayed in the footer section only. As of Sep. 2021, IDEXX only uses T (partial report) and E (final report), V and X are in the scope of an unfinished project (certificate creation) and may be needed in the future, whereas A (no results yet, just the order), O and I are currently not used.</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | IdexxReportStatus.Partial | T |
 * | IdexxReportStatus.Final | E |
 * | IdexxReportStatus.IDEXXCertificateRequest | V |
 * | IdexxReportStatus.IDEXXXCertificateFinal | X |
 * | IdexxReportStatus.Preliminary | A |
 * | IdexxReportStatus.OrderReceived | O |
 * | IdexxReportStatus.SpecimenReceived | I |
 */

export enum IdexxReportStatus {
  Partial = 'T',
  Final = 'E',
  IDEXXCertificateRequest = 'V',
  IDEXXXCertificateFinal = 'X',
  Preliminary = 'A',
  OrderReceived = 'O',
  SpecimenReceived = 'I'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | IdexxAnimalPicture.Cat | 1 |
 * | IdexxAnimalPicture.Dog | 2 |
 * | IdexxAnimalPicture.Horse | 3 |
 * | IdexxAnimalPicture.Default | 4 |
 */

export enum IdexxAnimalPicture {
  Cat = 1,
  Dog = 2,
  Horse = 3,
  Default = 4
}


/**
 * <p style="margin-top: 16px;">Rendered on the report, see enum values. There is no file for input, a generic picture is chosen by the species of this enum. If not or default value is set, the picture will be chosen by the value of animal species. (opt.)</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | AnimalPicture.Dog | 1 |
 * | AnimalPicture.Cat | 2 |
 * | AnimalPicture.Horse | 3 |
 * | AnimalPicture.Default | 4 |
 */

export enum AnimalPicture {
  Dog = 1,
  Cat = 2,
  Horse = 3,
  Default = 4
}


/**
 * <p style="margin-top: 16px;">Only rendered on the report (opt.)</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | AnimalAgeUnit.Years | J |
 * | AnimalAgeUnit.Months | M |
 * | AnimalAgeUnit.Days | T |
 * | AnimalAgeUnit.Weeks | W |
 * | AnimalAgeUnit.None |  |
 */

export enum AnimalAgeUnit {
  Years = 'J',
  Months = 'M',
  Days = 'T',
  Weeks = 'W',
  None = ''
}


/**
 * <p style="margin-top: 16px;">Only rendered on the report, will be translated by us (opt.)</p>
 *
 * | Enum | Value |
 * | --- | --- |
 * | AnimalSex.Female | F |
 * | AnimalSex.Male | M |
 * | AnimalSex.Unknown | U |
 */

export enum AnimalSex {
  Female = 'F',
  Male = 'M',
  Unknown = 'U'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | TestStatusLdt.Finished |  |
 * | TestStatusLdt.Unfinished | F |
 * | TestStatusLdt.AlreadyReported | B |
 * | TestStatusLdt.Corrected | K |
 */

export enum TestStatusLdt {
  Finished = '',
  Unfinished = 'F',
  AlreadyReported = 'B',
  Corrected = 'K'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | TestStatusApi.Finished | F |
 * | TestStatusApi.Unfinished |  |
 * | TestStatusApi.AlreadyReported | B |
 * | TestStatusApi.Corrected | K |
 */

export enum TestStatusApi {
  Finished = 'F',
  Unfinished = '',
  AlreadyReported = 'B',
  Corrected = 'K'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | FileGenerationMode.DoNotGenerate | 0 |
 * | FileGenerationMode.Generate | 1 |
 */

export enum FileGenerationMode {
  DoNotGenerate = 0,
  Generate = 1
}

export interface ReportCreateDoctorProperties {
  /**
   * IDEXX customer code; can be any doctor-unique string, usually a 4-6 char long shortcode of the Doctor, e.g. "HOVW", "OEST 9", "DOKO", "SBAR"; rendered on top left of the report header
   */
  short_form: string;
  /**
   * Doctor/Customer name/last name, rendered in the header section below the doctor shortcode
   */
  name?: string;
  /**
   * Doctor/Customer first name, rendered in the header section below the doctor shortcode (opt.)
   */
  first_name?: string;
  /**
   * Only rendered on the report, rendered in the header section below the doctor shortcode (opt.)
   */
  salutation?: string;
  /**
   * Only rendered on the report, rendered in the header section below the doctor shortcode (opt.)
   */
  phone_number?: string;
  /**
   * Only rendered on the report, rendered in the header section below the doctor shortcode (opt.)
   */
  fax_number?: string;
  /**
   * Only rendered on the report, rendered in the header section below the doctor shortcode (opt.)
   */
  address?: string;
  /**
   * Only rendered on the report, rendered in the header section below the doctor shortcode (opt.)
   */
  city?: string;
  /**
   * Only rendered on the report, rendered in the header section below the doctor shortcode (opt.)
   */
  zip?: string;
  /**
   * Displayed on the report, rendered in the header section below the doctor shortcode (opt.)
   */
  country_code?: string;
  /**
   * Used for ldt generation, bsnr name/doctors office name (opt.)
   */
  office_name?: string;
}

export interface ReportIdexxCreatePatientProperties {
  /**
   * Pet's owner's last name, rendered as first element in the header section (in the middle) (opt.)
   */
  owner_name?: string;
  /**
   * Pet's owner's first name, will be appended to owner_name (rendered in the middle header section) (opt.)
   */
  owner_first_name?: string;
  /**
   * (unused) Not rendered on the report in any template (as of Sep. 2021), for future use (in new or existing templates).
   */
  animal_breed?: string;
  /**
   * Send in the target language. Only rendered on the report (opt.), rendered in the middle header section
   */
  animal_species?: string;
  /**
   * Only rendered on the report (opt.), rendered in the middle header section
   */
  animal_name?: string;
  /**
   * (unused) Not rendered on the report in any current template. Is rendered on planned report certificates which are not finished yet, may be a field of interest in future. (opt.)
   */
  animal_birth_date?: string;
  /**
   * Only rendered on the report (opt.), rendered in the middle header section
   */
  animal_age?: string;
  animal_age_unit?: AnimalAgeUnit;
  animal_sex?: AnimalSex;
  animal_picture?: IdexxAnimalPicture;
}

export interface ReportIdexxCreateResultProperties {
  /**
   * Groups results, results with the same result_group will be displayed in a single table - with result_group as header (printed in bold/strong). In case multiple such sections are needed, the value "{*}" can be appended to the result_group value, e.g. "Klinische Chemie{0}" for the first section, "Klinische Chemie{1}" for the second section (use ascending values for this). If no heading is wanted, grouping the results can be done by sending "{0}", "{1}" and so on. For automatic translation, also the special translaction placeholder {material} can be used. Optional: If you want to print the header without a result line (headers were printed like this when instructed from the LDT files), dummy values can be set as result/text_result (use the exact string values: result="DUMMYUNT"/result_name="DUMMYWERT").
   */
  result_group?: string;
  /**
   * Description of result (test name)
   */
  result_name?: string;
  /**
   * Optional field that is associated with an assay. The methodology value will be displayed next to the assay name, in gray font
   */
  methodology?: string;
  /**
   * Optional field that is associated with an assay. All consecutive assays with the same sub_category will be grouped, and the sub_category value will display above the first assay in bold
   */
  sub_category?: string;
  /**
   * True indicates that the test is an add-on
   */
  add_on?: boolean;
  /**
   * True on the “result_group” record would indicate that the test has been cancelled
   */
  cancel?: boolean;
  /**
   * Result, can be any string
   */
  result?: string;
  /**
   * Triggers a different result line/table layout (without columns from_to_display, pathologic_code and without reference area graphic) on report; is used for longer text results. Repeat the whole object with 80 character parts if you want to display more than 80 characters (line-wise).
   */
  text_result?: string;
  /**
   * Lower reference area border, for reference area graphic calculation. Used as a border indicator in the reference area graphic, if set to "" or omitted, no lower border is set. Note that 0 is semantically different from null (or the empty string "" which can be used as an alternative to null). Null cannot be sent directly (see note on top of API description, omit value to use the default null).
   */
  from?: string;
  /**
   * Upper reference area border, for reference area graphic calculation. Used as a border indicator in the reference area graphic, if set to "" or omitted, no upper border is set. Note that 0 is semantically different from null (or the empty string "" which can be used as an alternative to null). Null cannot be sent directly (see note on top of API description, omit value to use the default null).
   */
  to?: string;
  /**
   * Result unit, displayed beside the result. Gets printed as-is.
   */
  unit?: string;
  /**
   * Reference area string to display near the reference area graphic. Gets printed as-is.
   */
  from_to_display?: string;
  /**
   * If empty, everything is fine (typically means that result is within recommended reference area). If not empty, we color the line red (pathologic). E.g. "+", "++", "-", "H". This behavior may vary by template: This text also gets displayed near the reference area graphic. Does not display (only makes the line red) on VB and HI templates or if the result does not have a reference area graphic.
   */
  pathologic_code?: string;
  /**
   * Gets rendered in the antibiogram comment column. e.g. "Einsatz nur nach strenger Indikation". Is used only when flag_for_microbiologic is set to microbiologic.
   */
  comment?: string;
  /**
   * Unique identifying shortcode for test, if not provided by LIS the test name can be used. This is currently not used on the reports, but can be in future if IDEXX decides to add this in some templates. (opt.)
   */
  shortcode?: string;
  /**
   * Table index for microbiological reports. Combines (groups) all results for each microbiologic table, just needed for microbiologic reports. For example, all results with 0 will be displayed in a single table, all 1 results are displayed in the next (separate) table.
   */
  anti_tab_counter?: number;
  /**
   * Reference text for the result-footnote, e.g. "2)" or "2),3)". Add the actual comments in the "comments" section.
   */
  comment_reference_result?: string;
  /**
   * Reference text for the result_group (heading text) footnote, e.g. "1)". Add the actual comments in the "comments" section.
   */
  comment_reference_result_group?: string;
  /**
   * Short code of the material which is used for the test
   */
  sample_material_id: string;
  flag_for_microbiologic?: ReportValueMibiType;
  textcomment_for_result?: ReportIdexxCreateTextCommentForResultProperties[];
  status?: TestStatusApi;
}

export interface ReportIdexxCreateTextCommentForResultProperties {
  /**
   * Comment text
   */
  textline: string;
  comment_type?: ReportValueCommentType;
}

export interface ReportIdexxCreateCommentsProperties {
  /**
   * Comment text, is the only field which supports basic HTML tags as &lt;b&gt; and &lt;i&gt;, UTF-8 characters should be supported in general, special ones like angle brackets have to be html encoded.
   */
  textline: string;
  /**
   * Reference text for the footnote, shown at the reference, e.g. "2)" or "2),3)"
   */
  reference: string;
  type?: ReportNoteType;
}

export interface ReportIdexxCreateElphoProperties {
  /**
   * Numerical values between 0 and 1000, X axis
   */
  x_value: number;
  /**
   * Numerical values between 0 and 1000, Y axis
   */
  y_value: number;
  line?: ReportElphoLine;
  type?: ReportElphoType;
}

export interface ReportIdexxCreateBudgetProperties {
  /**
   * Line index, e.g. 1; higher indices are rendered below, i.e. position_number 2 comes after position_number 1. Starts with 1.
   */
  position_number: number;
  /**
   * Position shorttext (order code, e.g. an internal billing/invoicing position short code), e.g. "PK" (opt.), doesn't get rendered (as of Oct. 2021)
   */
  billing_position_id?: string;
  /**
   * Position name as displayed, e.g. "Bakteriologische Untersuchung", typically this will not be longer than 30 characters, if longer, text size will get smaller
   */
  billing_position_name?: string;
  /**
   * Position price as displayed, excl. VAT, e.g. "28.28"
   */
  billing_position_price?: string;
  /**
   * VAT rate as displayed, e.g. "19.00" (on report level only, not position-level, has to be repeated for all items)
   */
  vat_percentage?: string;
  /**
   * Total amount excl. VAT as displayed, e.g. "111.86" (on report level only, not position-level, has to be repeated for all items)
   */
  billing_sum_excl_vat?: string;
  /**
   * Total amount incl. VAT as displayed, e.g. "133.11" (on report level only, not position-level, has to be repeated for all items)
   */
  billing_sum_incl_vat?: string;
  /**
   * VAT amount as displayed, e.g. "21.25" (on report level only, not position-level, has to be repeated for all items)
   */
  billing_vat_sum?: string;
  /**
   * Currency symbol or shortcode as displayed, e.g. "EUR" (on report level only, not position-level, has to be repeated for all items)
   */
  billing_currency?: string;
}

export interface ReportIdexxCreateReportCommentProperties {
  /**
   * Text content for this comment
   */
  textline?: string;
}

export interface ReportIdexxCreateProperties {
  /**
   * Unique transaction ID from Idexx system, 128-bit UUID as defined in RFC 4122, e.g. 123e4567-e89b-12d3-a456-426614174000 (not printed on the report, part of the report rendered filename)
   */
  transaction_uuid: string;
  /**
   * ID from Idexx system, unique per order, 128-bit UUID as defined in RFC 4122, e.g. 123e4567-e89b-12d3-a456-426614174000 (not printed on the report, part of the report rendered filename)
   */
  order_uuid: string;
  /**
   * Order Creation DateTime from LIS System, Format YYYYMMDDHHMMSS, will not be rendered on the report, is part of LABUNIQ internal report identifiers
   */
  order_created_date: string;
  /**
   * (does not have to be provided, omit this value, internal purpose)
   */
  order_import_date?: string;
  /**
   * Name of the doctor in the laboratory, which was validating the report (mostly at the end of the reports, used like a signature)
   */
  validating_doctor: string;
  /**
   * Additional information of the doctor in the laboratory, which was validating the report; this field is shown after the validating doctors name on the report, will be appended without any modification (no parenthesis add) (opt.)
   */
  validating_doctor_addition?: string;
  /**
   * Comma separated list of samples to show on the report (displayed on the header of the rendered report), will be cut off if it's larger than a certain length
   */
  sample_names: string;
  /**
   * Primary lab order number (ex: Accession number), part of the report filename, displayed on the header of the rendered report
   */
  lab_accessionNumber: string;
  /**
   * Second lab barcode number, just for display purpose (ex: Barcode), displayed on the header of the rendered report
   */
  lab_barcodeId: string;
  /**
   * Idexx special fields for rendering (creation datetime of the report in Idexx systems), DateTime; Format DDMMYYYYHHMMSS, rendered in the footer section (on the left)
   */
  create_lis_date?: string;
  /**
   * Set to true or leave empty to invoke pdf report generation
   */
  create_as_pdf?: boolean;
  /**
   * Set to true to invoke ldt report generation
   */
  create_as_ldt?: boolean;
  order_status?: IdexxReportStatus;
  laboratory_group?: IdexxLaboratoryGroup;
  customer_language_code?: IdexxLangCode;
  legal_entity?: IdexxLegalEntity;
  doctor?: ReportCreateDoctorProperties;
  patient?: ReportIdexxCreatePatientProperties;
  results?: ReportIdexxCreateResultProperties[];
  comments?: ReportIdexxCreateCommentsProperties[];
  electrophoresis?: ReportIdexxCreateElphoProperties[];
  budget?: ReportIdexxCreateBudgetProperties[];
  general_report_comments?: ReportIdexxCreateReportCommentProperties[];
  pdf_attachment?: IdexxPdfAttachment;
  settlement_type?: SettlementType;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AisListStatus.Ok | ok |
 * | AisListStatus.Exhausted | exhausted |
 */

export enum AisListStatus {
  Ok = 'ok',
  Exhausted = 'exhausted'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AisReadStatus.Ok | ok |
 * | AisReadStatus.Accessed | already_accessed |
 */

export enum AisReadStatus {
  Ok = 'ok',
  Accessed = 'already_accessed'
}

export interface AisReportIdListProperties {
  /**
   * Timestamp of last report change
   */
  lastupdate: string;
  /**
   * Report ID
   */
  bid: number;
  status?: ReportStatus;
}

export interface AisReportProperties {
  /**
   * Report ID
   */
  reportId?: number;
  /**
   * Physician ID
   */
  physicianId?: number;
  /**
   * Internal laboratory ID
   */
  laboratoryId?: number;
  /**
   * Number of report in laboratory
   */
  accessionNumber?: string;
  /**
   * Arrival timestamp
   */
  createdAt?: string;
  /**
   * Timestamp of report import in LABUNIQ system
   */
  importedAt?: string;
  /**
   * Registration timestamp
   */
  registeredAt?: string;
  /**
   * Timestamp of report validation
   */
  validatedAt?: string;
  /**
   * Sampling timestamp
   */
  sampledAt?: string;
  /**
   * Timestamp of last report change
   */
  lastUpdate?: string;
  /**
   * Diagnosis of the report
   */
  diagnosis?: string;
  /**
   * Validation doctors name
   */
  validatingDoctor?: string;
  /**
   * Formatted patient name
   */
  patientName?: string;
  /**
   * Formatted name of Doctor at the time of the report import
   */
  physicianName?: string;
  /**
   * complete name of Laboratory at the time of the report import
   */
  laboratoryName?: string;
  /**
   * shortcode of laboratory group , display information of that group if it is set
   */
  laboratoryGroup?: string;
  /**
   * additional number of report , display if it is there
   */
  externalAccessionNumber?: string;
  status?: ReportStatus;
  pathological?: ReportCharacteristic;
  microbiological?: ReportMibiType;
  values?: AisReportValueProperties[];
}

export interface AisReportValueProperties {
  /**
   * Short code
   */
  shortCode?: string;
  /**
   * Group name
   */
  groupName?: string;
  /**
   * Description
   */
  description?: string;
  /**
   * Value of Result
   */
  result?: string;
  /**
   * Is used for longer text results
   */
  textResult?: string;
  /**
   * Unit of Result
   */
  unit?: string;
  /**
   * if not null or not empty, then the current result is pathological. contains the pathological code
   */
  pathological?: string;
  /**
   * POCT value
   */
  poct?: boolean;
  /**
   * analyzed material
   */
  material?: string;
  /**
   * machine the analyse was made on
   */
  labTestDevice?: string;
  /**
   * Microbiological comment field
   */
  comment?: string;
  /**
   * Short code of the material
   */
  materialId?: string;
  /**
   * Whether the test is finished yet
   */
  status?: string;
  /**
   * is associated with an assay and should be displayed next to the assay name
   */
  methodology?: string;
  /**
   * Shortcode of ResultGroup
   */
  catalog?: string;
  /**
   * Whether the test has been cancelled
   */
  canceled?: boolean;
  /**
   * Whether the test is part of the additional order
   */
  addOn?: boolean;
  reference?: ReportValueReference;
  microbiological?: ReportValueMibiType;
  format?: ResultFormat;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | Feature.CorrectReport | f_korrekturbefund |
 * | Feature.OriginalReport | f_originalbefund |
 * | Feature.OriginalReportExclusive | originalbefund_exklusiv |
 * | Feature.AutoLockReport | f_befdeflock |
 * | Feature.LanrSearch | f_lanrsuche |
 * | Feature.ExportMEFormat | f_export_me |
 * | Feature.UnrealRerequest | f_nachanfordernspanne |
 * | Feature.ReportAccessLog | f_befprotokoll |
 * | Feature.ReportChangeLog | f_befakte |
 * | Feature.CumulativeReport | f_kumulativ |
 * | Feature.CumulativeExportAll | kumulativ_print_allvalues |
 * | Feature.GraphicalBDW | f_gbdw |
 * | Feature.ObBakModule | ob_bak |
 * | Feature.Elpho | f_elpho |
 * | Feature.ReportComments | f_report_comments |
 * | Feature.IpRestriction | f_ipbeschraenkung |
 * | Feature.DeviceIdAuth | f_deviceidauth |
 * | Feature.ShortApiAuthToken | short_api_auth_token |
 * | Feature.PdfPassword | pwd_pdf |
 * | Feature.ShowAccountingType | show_accounting_type |
 * | Feature.ReportValuesCustomSort | report_values_custom_sort |
 * | Feature.EndlessScrollReports | endless_scroll_reports |
 * | Feature.MailTemplateGroups | sonder_mailtemplates |
 * | Feature.AutoActivatePatients | auto_pat_massactivation |
 * | Feature.PatientsSmsVerification | patient_sms_verification |
 * | Feature.DoctorsSmsVerification | doctor_sms_verification |
 * | Feature.PatientsBirthdayVerification | patient_birthday_verification |
 * | Feature.SetDownloadPdfAnalyses | set_download_pdf_analyses |
 * | Feature.ManualReadReports | manual_read_reports |
 * | Feature.GroupMultipleReports | group_multiple_reports |
 * | Feature.LaboratoryGroupReportFilter | labor_group_report_filter |
 * | Feature.Poct | poct |
 * | Feature.Blacklist | blacklist |
 * | Feature.CustomPush | custom_push |
 * | Feature.BdwNewOrder | bdw_new_order |
 * | Feature.Mails | nc_mail |
 * | Feature.DuplicateReports | duplicate_reports |
 * | Feature.PatientPdf | patient_pdf |
 * | Feature.ReportCommentRtf | reportcomment_rtf |
 * | Feature.ReportAttachments | report_attachments |
 * | Feature.DoctorGroups | doctor_groups |
 * | Feature.FilterProfiles | filter_profiles |
 * | Feature.SearchForTests | search_for_tests |
 * | Feature.ReportAisApi | report_ais_api |
 * | Feature.PoolModule | pool |
 * | Feature.BakModule | bak |
 * | Feature.MessageToLaboratory | MTL |
 * | Feature.GdtImport | GDTImport |
 * | Feature.DigitalSignatureModule | digitale_signatur |
 * | Feature.SuperRequirement | supanf |
 * | Feature.Journal | laborbuch |
 * | Feature.TransportList | transportliste |
 * | Feature.RealRerequest | echte_nachanforderung |
 * | Feature.ClosureList | abschlussliste |
 * | Feature.GroupForms | gruppenschein |
 * | Feature.PriceManagement | privatpreis |
 * | Feature.MaximumPriceManagement | maxprice |
 * | Feature.PriceGroupManagement | pricegroups |
 * | Feature.IndicationSearch | anfindi |
 * | Feature.DynamicProbeType | dyn_probtyp |
 * | Feature.FormSplit | split |
 * | Feature.RequestGroups | anfordgrp |
 * | Feature.EditRequirements | anfedit |
 * | Feature.EbmPrice | ebm_gop |
 * | Feature.OrderFreeText | freitext |
 * | Feature.RequirementFreeText | requirement_freetext |
 * | Feature.ExportNoteToFile | export_schein_as_file |
 * | Feature.MaterialZone | matzone_kurz |
 * | Feature.AnamnesticInfo | anamnestischeangaben |
 * | Feature.SpecialBardcodes | sonderbarcodes |
 * | Feature.LaboratoryGroupBarcodes | labgrp_barcoderange |
 * | Feature.InternalPrintUsers | internal_print_users |
 * | Feature.LabelPrintSettings | label_print_setting |
 * | Feature.PrintPatientLabel | print_patient_label |
 * | Feature.OrderOvertaker | f_oaovertaker |
 * | Feature.GynFields | gyn_fields |
 * | Feature.OrderWizardShowPatientContact | awiz_show_pat_kontakt |
 * | Feature.OrderCourier | f_oacourier |
 * | Feature.AlphanumericBarcodes | alphanumeric_barcode |
 * | Feature.AdvancedAlphanumericBarcodes | advanced_alphanumeric_barcode |
 * | Feature.KBVMuster10C | muster10c |
 * | Feature.KBVMusterOegd | muster_oegd |
 * | Feature.MassOrders | mass_orders |
 * | Feature.KisModusEdit | kismodus_edit |
 * | Feature.RequirementDiagnoses | anfdiag |
 * | Feature.PdfExportRequirementProfiles | anfprofilinfo |
 * | Feature.Anamnesis | anamnesis |
 * | Feature.FormLevelPrices | anfpreise_schein |
 * | Feature.CentralBarcodes | zentrale_nummernabfrage |
 * | Feature.WizardScanPatient | scan_pat_qr_in_wizard |
 * | Feature.HzvField | hzv_field |
 * | Feature.ProfessionalAssociation | berufsgenossenschaft |
 * | Feature.CountryCodeSelect | laendercode_select |
 * | Feature.UrgentNotification | eilt_benachrichtigung |
 * | Feature.CancelRequirement | oa_cancel_requirement |
 * | Feature.SampleInLaboratory | oa_sample_in_laboratory |
 * | Feature.RequirementsAdditionalText | oa_requirements_additional_text |
 * | Feature.RequirementsHint | oa_requirements_hint |
 * | Feature.Airport | airport |
 * | Feature.Vetorders | vetorders |
 * | Feature.ExternalOrderInfo | ext_order_info |
 * | Feature.SwitzerlandInsuranceMapping | switzerland_insurance_mapping |
 * | Feature.SwitzerlandSurroundingSystem | ch_umsystem |
 * | Feature.OrderChangeLog | order_change_log |
 * | Feature.BarcodeFactor | barcode_factor |
 * | Feature.PrinterMapping | printer_mapping |
 * | Feature.ReportCopy | oa_report_copy |
 * | Feature.QuickReport | schnellbefund |
 * | Feature.SpecialFormType | special_formtype |
 * | Feature.QuickReportManualCode | schnellbefund_manual_code |
 * | Feature.Endo | endo |
 * | Feature.DynamicMaterials | dynmat |
 * | Feature.PoctOrderForms | poct_order_forms |
 * | Feature.IndividualReportCopyTo | individual_report_copy |
 * | Feature.BloodCollectionType | blood_collection_type |
 * | Feature.DoctorGroupOffice | arztgruppenpraxis |
 * | Feature.GlobalBarcodeRange | globaler_nummernkreis |
 * | Feature.SearchDirectInput | search_direct_input |
 * | Feature.MultiplePatientOrdersCheck | multiple_patient_orders_check |
 * | Feature.RequirementCategories | requirement_categories |
 * | Feature.ClinicalRequirementsSplit | clinical_requirements_split |
 * | Feature.TreatmentReasonDropdown | treatment_reason_dropdown |
 * | Feature.BakSettingSplitBak | bak_setting_split_bak |
 * | Feature.BakSettingMultipleBakBarcodes | bak_setting_multiple_bak_barcodes |
 * | Feature.RequirementProfiles | requirement_profiles |
 * | Feature.ExportCommonData | export_common_data |
 * | Feature.OrderAisApi | order_ais_api |
 * | Feature.MultipleWorkplaces | multiarbeitsplatz_kurz |
 * | Feature.ClientCertificates | client_certificates_kurz |
 * | Feature.RememberLastWorkstation | remember_last_workstation |
 * | Feature.ExchangeViewFile | exchange_view_file |
 * | Feature.ExchangeDownloadFile | exchange_download_file |
 * | Feature.DoctorHierarchy | doctor_hierarchy |
 * | Feature.MvzVoucherApiGeneration | voucher_generation_mvz_api |
 * | Feature.LaboratoryImportES | laboratory_import_es |
 * | Feature.ReportNotificationFilters | report_notification_filters |
 * | Feature.LabstoreCommissioning | labstore_commissioning |
 */

export enum Feature {
  CorrectReport = 'f_korrekturbefund',
  OriginalReport = 'f_originalbefund',
  OriginalReportExclusive = 'originalbefund_exklusiv',
  AutoLockReport = 'f_befdeflock',
  LanrSearch = 'f_lanrsuche',
  ExportMEFormat = 'f_export_me',
  UnrealRerequest = 'f_nachanfordernspanne',
  ReportAccessLog = 'f_befprotokoll',
  ReportChangeLog = 'f_befakte',
  CumulativeReport = 'f_kumulativ',
  CumulativeExportAll = 'kumulativ_print_allvalues',
  GraphicalBDW = 'f_gbdw',
  ObBakModule = 'ob_bak',
  Elpho = 'f_elpho',
  ReportComments = 'f_report_comments',
  IpRestriction = 'f_ipbeschraenkung',
  DeviceIdAuth = 'f_deviceidauth',
  ShortApiAuthToken = 'short_api_auth_token',
  PdfPassword = 'pwd_pdf',
  ShowAccountingType = 'show_accounting_type',
  ReportValuesCustomSort = 'report_values_custom_sort',
  EndlessScrollReports = 'endless_scroll_reports',
  MailTemplateGroups = 'sonder_mailtemplates',
  AutoActivatePatients = 'auto_pat_massactivation',
  PatientsSmsVerification = 'patient_sms_verification',
  DoctorsSmsVerification = 'doctor_sms_verification',
  PatientsBirthdayVerification = 'patient_birthday_verification',
  SetDownloadPdfAnalyses = 'set_download_pdf_analyses',
  ManualReadReports = 'manual_read_reports',
  GroupMultipleReports = 'group_multiple_reports',
  LaboratoryGroupReportFilter = 'labor_group_report_filter',
  Poct = 'poct',
  Blacklist = 'blacklist',
  CustomPush = 'custom_push',
  BdwNewOrder = 'bdw_new_order',
  Mails = 'nc_mail',
  DuplicateReports = 'duplicate_reports',
  PatientPdf = 'patient_pdf',
  ReportCommentRtf = 'reportcomment_rtf',
  ReportAttachments = 'report_attachments',
  DoctorGroups = 'doctor_groups',
  FilterProfiles = 'filter_profiles',
  SearchForTests = 'search_for_tests',
  ReportAisApi = 'report_ais_api',
  PoolModule = 'pool',
  BakModule = 'bak',
  MessageToLaboratory = 'MTL',
  GdtImport = 'GDTImport',
  DigitalSignatureModule = 'digitale_signatur',
  SuperRequirement = 'supanf',
  Journal = 'laborbuch',
  TransportList = 'transportliste',
  RealRerequest = 'echte_nachanforderung',
  ClosureList = 'abschlussliste',
  GroupForms = 'gruppenschein',
  PriceManagement = 'privatpreis',
  MaximumPriceManagement = 'maxprice',
  PriceGroupManagement = 'pricegroups',
  IndicationSearch = 'anfindi',
  DynamicProbeType = 'dyn_probtyp',
  FormSplit = 'split',
  RequestGroups = 'anfordgrp',
  EditRequirements = 'anfedit',
  EbmPrice = 'ebm_gop',
  OrderFreeText = 'freitext',
  RequirementFreeText = 'requirement_freetext',
  ExportNoteToFile = 'export_schein_as_file',
  MaterialZone = 'matzone_kurz',
  AnamnesticInfo = 'anamnestischeangaben',
  SpecialBardcodes = 'sonderbarcodes',
  LaboratoryGroupBarcodes = 'labgrp_barcoderange',
  InternalPrintUsers = 'internal_print_users',
  LabelPrintSettings = 'label_print_setting',
  PrintPatientLabel = 'print_patient_label',
  OrderOvertaker = 'f_oaovertaker',
  GynFields = 'gyn_fields',
  OrderWizardShowPatientContact = 'awiz_show_pat_kontakt',
  OrderCourier = 'f_oacourier',
  AlphanumericBarcodes = 'alphanumeric_barcode',
  AdvancedAlphanumericBarcodes = 'advanced_alphanumeric_barcode',
  KBVMuster10C = 'muster10c',
  KBVMusterOegd = 'muster_oegd',
  MassOrders = 'mass_orders',
  KisModusEdit = 'kismodus_edit',
  RequirementDiagnoses = 'anfdiag',
  PdfExportRequirementProfiles = 'anfprofilinfo',
  Anamnesis = 'anamnesis',
  FormLevelPrices = 'anfpreise_schein',
  CentralBarcodes = 'zentrale_nummernabfrage',
  WizardScanPatient = 'scan_pat_qr_in_wizard',
  HzvField = 'hzv_field',
  ProfessionalAssociation = 'berufsgenossenschaft',
  CountryCodeSelect = 'laendercode_select',
  UrgentNotification = 'eilt_benachrichtigung',
  CancelRequirement = 'oa_cancel_requirement',
  SampleInLaboratory = 'oa_sample_in_laboratory',
  RequirementsAdditionalText = 'oa_requirements_additional_text',
  RequirementsHint = 'oa_requirements_hint',
  Airport = 'airport',
  Vetorders = 'vetorders',
  ExternalOrderInfo = 'ext_order_info',
  SwitzerlandInsuranceMapping = 'switzerland_insurance_mapping',
  SwitzerlandSurroundingSystem = 'ch_umsystem',
  OrderChangeLog = 'order_change_log',
  BarcodeFactor = 'barcode_factor',
  PrinterMapping = 'printer_mapping',
  ReportCopy = 'oa_report_copy',
  QuickReport = 'schnellbefund',
  SpecialFormType = 'special_formtype',
  QuickReportManualCode = 'schnellbefund_manual_code',
  Endo = 'endo',
  DynamicMaterials = 'dynmat',
  PoctOrderForms = 'poct_order_forms',
  IndividualReportCopyTo = 'individual_report_copy',
  BloodCollectionType = 'blood_collection_type',
  DoctorGroupOffice = 'arztgruppenpraxis',
  GlobalBarcodeRange = 'globaler_nummernkreis',
  SearchDirectInput = 'search_direct_input',
  MultiplePatientOrdersCheck = 'multiple_patient_orders_check',
  RequirementCategories = 'requirement_categories',
  ClinicalRequirementsSplit = 'clinical_requirements_split',
  TreatmentReasonDropdown = 'treatment_reason_dropdown',
  BakSettingSplitBak = 'bak_setting_split_bak',
  BakSettingMultipleBakBarcodes = 'bak_setting_multiple_bak_barcodes',
  RequirementProfiles = 'requirement_profiles',
  ExportCommonData = 'export_common_data',
  OrderAisApi = 'order_ais_api',
  MultipleWorkplaces = 'multiarbeitsplatz_kurz',
  ClientCertificates = 'client_certificates_kurz',
  RememberLastWorkstation = 'remember_last_workstation',
  ExchangeViewFile = 'exchange_view_file',
  ExchangeDownloadFile = 'exchange_download_file',
  DoctorHierarchy = 'doctor_hierarchy',
  MvzVoucherApiGeneration = 'voucher_generation_mvz_api',
  LaboratoryImportES = 'laboratory_import_es',
  ReportNotificationFilters = 'report_notification_filters',
  LabstoreCommissioning = 'labstore_commissioning'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | Product.OB | ob |
 * | Product.OA | oa |
 * | Product.LE | le |
 * | Product.LS | ls |
 * | Product.LL | ll |
 * | Product.LC | lc |
 * | Product.DD | dd |
 * | Product.MC | mc |
 * | Product.NC | nc |
 * | Product.OF | of |
 * | Product.LW | lw |
 * | Product.ADMIN | admin |
 * | Product.DASHBOARD | dashboard |
 */

export enum Product {
  OB = 'ob',
  OA = 'oa',
  LE = 'le',
  LS = 'ls',
  LL = 'll',
  LC = 'lc',
  DD = 'dd',
  MC = 'mc',
  NC = 'nc',
  OF = 'of',
  LW = 'lw',
  ADMIN = 'admin',
  DASHBOARD = 'dashboard'
}

export interface AuthConfigProperties {
  authTokenExpiryTime?: number;
  resetTokenExpiryTime?: number;
  smsVerificationTokenExpiryTime?: number;
  lcRegistrationTokenDefaultExpiryTime?: number;
  throttleLoginCount?: number;
  throttleLoginTime?: number;
  passwordPolicyPattern?: string;
  enableAlternateLogin?: boolean;
  disableUserServerId?: boolean;
  customUsernamePrefix?: string;
  logWrongPasswordCleartext?: boolean;
  deviceApproveBrowsers?: boolean;
  enableAcceptToc?: boolean;
  hideEmailFields?: boolean;
  doctorFirstLoginEmail?: boolean;
  identifierLength?: number;
  twoFactorAuthEnabled?: boolean;
  twoFactorAppDisabled?: boolean;
  twoFactorAuthIdentifier?: string;
  twoFactorAuthMandatory?: boolean;
  twoFactorShowInUserList?: boolean;
  enablePasswordVisibilityToggleButton?: boolean;
  recoveryUrl?: string;
  recoveryUrlActive?: boolean;
  authTokenExpiryTimeInterval?: Interval;
  resetTokenExpiryTimeInterval?: Interval;
  smsVerificationTokenExpiryTimeInterval?: Interval;
  lcRegistrationTokenDefaultExpiryTimeInterval?: Interval;
  throttleLoginTimeInterval?: Interval;
}

export interface LinksConfigProperties {
  showToc?: boolean;
  showPrivacy?: boolean;
  showContact?: boolean;
  showContactForm?: boolean;
  showWhatsappQR?: boolean;
  showFooterInWizard?: boolean;
}

export interface DoctorGroupMemberProperties {
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * External Doctor ID (unique identification for laboratory system)
   */
  kurzform?: string;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Is Group Admin
   */
  isAdmin?: boolean;
  /**
   * Is Group Member
   */
  isMember?: boolean;
  /**
   * Notify report info
   */
  bengrp?: boolean;
  doctor?: Doctor;
}

export interface ProductProperties {
  /**
   * Product ID
   */
  id?: number;
  /**
   * Short name
   */
  key?: string;
  /**
   * Available for doctors
   */
  forDoctors?: boolean;
  /**
   * Available for patients
   */
  forPatients?: boolean;
  /**
   * Product is enabled for lab
   */
  enabled?: boolean;
  doctorsEnabled?: boolean;
  patientsEnabled?: boolean;
  appEnabled?: boolean;
  explicitLock?: boolean;
}

export interface FeatureProperties {
  /**
   * Available for doctors
   */
  forDoctors?: boolean;
  /**
   * Available for patients
   */
  forPatients?: boolean;
  /**
   * Feature is enabled
   */
  enabled?: boolean;
  key?: Feature;
  product?: Product;
  doctorsSetting?: FeatureSetting;
  patientsSetting?: FeatureSetting;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | FeatureSetting.All | all |
 * | FeatureSetting.None | none |
 * | FeatureSetting.ExplicitLock | allbut |
 * | FeatureSetting.ExplicitUnlock | nonebut |
 */

export enum FeatureSetting {
  All = 'all',
  None = 'none',
  ExplicitLock = 'allbut',
  ExplicitUnlock = 'nonebut'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserFeatureSetting.Add | add |
 * | UserFeatureSetting.Remove | remove |
 */

export enum UserFeatureSetting {
  Add = 'add',
  Remove = 'remove'
}

export interface LanrPreferenceProperties {
  enabled?: boolean;
  defaultLanr?: string;
  doctors?: LanrSearch[];
}

export interface AidToGroupOffice {
  /**
   * Doctor ID
   */
  aid: number;
  assignment?: DoctorGroupOffice[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LinkProduct.All | 0 |
 * | LinkProduct.OB | 1 |
 * | LinkProduct.OA | 2 |
 * | LinkProduct.LE | 3 |
 * | LinkProduct.LS | 4 |
 * | LinkProduct.LC | 5 |
 * | LinkProduct.DD | 6 |
 * | LinkProduct.Login | 7 |
 * | LinkProduct.ReportQuickAccess | 8 |
 */

export enum LinkProduct {
  All = 0,
  OB = 1,
  OA = 2,
  LE = 3,
  LS = 4,
  LC = 5,
  DD = 6,
  Login = 7,
  ReportQuickAccess = 8
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ServerConfigKey.ReportAccessFields | report_access_fields |
 * | ServerConfigKey.ReportsQuickAccessConfig | reports_quick_access_config |
 * | ServerConfigKey.ReportsQuickScanConfig | reports_quick_scan_config |
 * | ServerConfigKey.OrderAggregatedMaterialIds | order_aggregated_material_ids |
 * | ServerConfigKey.OrderAggregatedAnalyseIds | order_aggregated_analyse_ids |
 * | ServerConfigKey.Authentication | authentication |
 * | ServerConfigKey.Links | links |
 * | ServerConfigKey.SystemSettings | system_settings |
 * | ServerConfigKey.DoctorTopRequirements | doctor_top_requirements |
 * | ServerConfigKey.OrderRequirementsIncompatible | order_requirements_incompatible |
 * | ServerConfigKey.Analyses | analyses |
 */

export enum ServerConfigKey {
  ReportAccessFields = 'report_access_fields',
  ReportsQuickAccessConfig = 'reports_quick_access_config',
  ReportsQuickScanConfig = 'reports_quick_scan_config',
  OrderAggregatedMaterialIds = 'order_aggregated_material_ids',
  OrderAggregatedAnalyseIds = 'order_aggregated_analyse_ids',
  Authentication = 'authentication',
  Links = 'links',
  SystemSettings = 'system_settings',
  DoctorTopRequirements = 'doctor_top_requirements',
  OrderRequirementsIncompatible = 'order_requirements_incompatible',
  Analyses = 'analyses'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportAccessFields.Firstname | firstname |
 * | ReportAccessFields.Lastname | lastname |
 * | ReportAccessFields.Birthday | birthday |
 * | ReportAccessFields.OrderNumber | order_number |
 * | ReportAccessFields.Zip | zip |
 */

export enum ReportAccessFields {
  Firstname = 'firstname',
  Lastname = 'lastname',
  Birthday = 'birthday',
  OrderNumber = 'order_number',
  Zip = 'zip'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LinkPlatform.All | 0 |
 * | LinkPlatform.Web | 1 |
 * | LinkPlatform.App | 2 |
 */

export enum LinkPlatform {
  All = 0,
  Web = 1,
  App = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AdvancedAlphanumericBarcodeUseState.USE | use |
 * | AdvancedAlphanumericBarcodeUseState.REG | reg |
 */

export enum AdvancedAlphanumericBarcodeUseState {
  USE = 'use',
  REG = 'reg'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportPathoHighlight.Row | ZEILE |
 * | ReportPathoHighlight.Cell | WERT |
 */

export enum ReportPathoHighlight {
  Row = 'ZEILE',
  Cell = 'WERT'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LegalInfoType.Terms | agb |
 * | LegalInfoType.Privacy | datenschutz |
 */

export enum LegalInfoType {
  Terms = 'agb',
  Privacy = 'datenschutz'
}

export interface MaintenanceConfigProperties {
  enabled?: boolean;
  title?: string;
  message?: string;
}

export interface BannerConfigProperties {
  enabled?: boolean;
  message?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | Interval.Year | year |
 * | Interval.Month | month |
 * | Interval.Week | week |
 * | Interval.Day | day |
 * | Interval.Hour | hour |
 * | Interval.Minute | minute |
 * | Interval.Second | second |
 */

export enum Interval {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ReportDefaultOrder.ReportDate | e |
 * | ReportDefaultOrder.ImportDate | b |
 */

export enum ReportDefaultOrder {
  ReportDate = 'e',
  ImportDate = 'b'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderDetailDefaultGroupBy.Forms | forms |
 * | OrderDetailDefaultGroupBy.Materials | materials |
 */

export enum OrderDetailDefaultGroupBy {
  Forms = 'forms',
  Materials = 'materials'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | OrderDetailDefaultSortBy.Order | order |
 * | OrderDetailDefaultSortBy.Name | name |
 */

export enum OrderDetailDefaultSortBy {
  Order = 'order',
  Name = 'name'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | PatientNameDisplay.FirstLast | {firstName} {lastName} |
 * | PatientNameDisplay.LastFirst | {lastName} {firstName} |
 * | PatientNameDisplay.FirstCommaLast | {firstName}, {lastName} |
 * | PatientNameDisplay.LastCommaFirst | {lastName}, {firstName} |
 * | PatientNameDisplay.TitleFirstLast | {title} {firstName} {lastName} |
 * | PatientNameDisplay.LastFirstTitle | {lastName} {firstName} {title} |
 * | PatientNameDisplay.TitleFirstCommaLast | {title} {firstName}, {lastName} |
 * | PatientNameDisplay.LastCommaFirstTitle | {lastName}, {firstName} {title} |
 * | PatientNameDisplay.LastCommaTitleFirst | {lastName}, {title} {firstName} |
 */

export enum PatientNameDisplay {
  FirstLast = '{firstName} {lastName}',
  LastFirst = '{lastName} {firstName}',
  FirstCommaLast = '{firstName}, {lastName}',
  LastCommaFirst = '{lastName}, {firstName}',
  TitleFirstLast = '{title} {firstName} {lastName}',
  LastFirstTitle = '{lastName} {firstName} {title}',
  TitleFirstCommaLast = '{title} {firstName}, {lastName}',
  LastCommaFirstTitle = '{lastName}, {firstName} {title}',
  LastCommaTitleFirst = '{lastName}, {title} {firstName}'
}

export interface SharedPreferenceProperties {
  pref_name?: string;
  pref_value?: string | null;
}

export interface ReportsFavoriteAnalyses {
  shortName?: string;
}

export interface OrderBarcodeMaterialFactor {
  materialId?: number;
  factor?: number;
}

export interface NotificationPreferencesProperties {
  enabled?: boolean;
  mail?: boolean;
  push?: boolean;
  sms?: boolean;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserAccessibilityFontSize.Small | small |
 * | UserAccessibilityFontSize.Normal | normal |
 * | UserAccessibilityFontSize.Large | large |
 */

export enum UserAccessibilityFontSize {
  Small = 'small',
  Normal = 'normal',
  Large = 'large'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserAccessibilityContrast.Low | low |
 * | UserAccessibilityContrast.Normal | normal |
 * | UserAccessibilityContrast.High | high |
 */

export enum UserAccessibilityContrast {
  Low = 'low',
  Normal = 'normal',
  High = 'high'
}

export interface UserPreferencesProperties {
  startProduct?: Product;
  fontSize?: UserAccessibilityFontSize;
  contrast?: UserAccessibilityContrast;
}

export interface NotificationsPreferencesProperties {
  newExchangeFileNotification?: NotificationPreferencesProperties;
  newReportNotification?: NotificationPreferencesProperties;
  newReportNotificationSettings?: ReportsNotificationSettings;
}

export interface ReportsPreferencesProperties {
  /**
   * Patient name sorting (a#b#c#d): a = title position, b = first name position, c = last name position, d = show title 0/1
   */
  reportsPatientNameSorting?: string;
  reportsPatientNameDisplayEnabled?: boolean;
  reportsDefaultFilterProfile?: number;
  reportsPinnedOnTop?: boolean;
  reportsInitialOpenCumulative?: boolean;
  reportsCumulativeMergeDays?: boolean;
  reportsCumulativeSingleReport?: boolean;
  reportsPdfDownloadAnalysesEnabledMode?: boolean;
  reportsPdfDownloadAnalyses?: string[];
  reportsPdfDownloadPathoAnalyses?: string[];
  enableAdvancedAnalysesFilter?: boolean;
  reportsBarcodeEnableCustomization?: boolean;
  reportsBarcodePrefixLength?: number;
  reportsBarcodeLength?: number;
  reportsBarcodeSuffixLength?: number;
  reportsPatientsListShowInsuranceNumber?: boolean;
  reportsPatientsListShowPatientIdentification?: boolean;
  reportsPatientsListShowEmail?: boolean;
  reportsPatientsListShowBirthday?: boolean;
  reportsCustomSortBdwEnabled?: boolean;
  reportsCustomSortCumulativeEnabled?: boolean;
  reportsCustomSortCumulativeGroupsEnabled?: boolean;
  reportsDeltaCheckFilter?: boolean;
  reportsValueFollowsEnabled?: boolean;
  reportsValueFollowsText?: string;
  reportsValueFollowsHideNteEmpty?: boolean;
  reportsUseRealReRequest?: boolean;
  reportsDefaultOrder?: ReportDefaultOrder;
  reportsPatientNameDisplay?: PatientNameDisplay;
  reportsFavoriteAnalyses?: ReportsFavoriteAnalyses[];
}

export interface OrdersPreferencesProperties {
  ordersRealCancellation?: boolean;
  ordersRealCancellationTimeRange?: number;
  ordersShowPrePrint?: boolean;
  ordersPrePrintIsC2A?: boolean;
  ordersMaxPoolSize?: number;
  ordersShowBeforeAfterDialysis?: boolean;
  ordersBeforeAfterDialysisMustBeSpecified?: boolean;
  ordersScheduleTimeEnabled?: boolean;
  ordersScheduleTimeInterval?: number;
  ordersScheduleTimeEnableDefault?: boolean;
  ordersScheduleTimeDefault?: string;
  ordersDefaultOrderModeEnabled?: boolean;
  ordersBookmarkedPoolMode?: boolean;
  ordersShowRoomNumber?: boolean;
  ordersHideDiagnosisFields?: boolean;
  ordersPreferScheduleDateMode?: boolean;
  ordersSampleInLaboratoryButton?: boolean;
  ordersSampleInLaboratoryButtonFirst?: boolean;
  ordersSelectWorkstationSplitButton?: boolean;
  ordersSampleDateTimeButton?: boolean;
  ordersAnamnesisShrinkMode?: boolean;
  ordersDynamicProbeTypeStaticReprint?: boolean;
  orderWizardCompactView?: boolean;
  orderWizardUltraCompactView?: boolean;
  orderWizardDisablePopovers?: boolean;
  orderWizardDisableTopRequests?: boolean;
  orderWizardEnableBasketPopovers?: boolean;
  orderWizardDisableAllPopovers?: boolean;
  orderWizardDefaultGroupForm?: {
    [k: string]: any;
  };
  orderWizardProfilesInGroupForm?: boolean;
  orderWizardTopRequestsInGroupForm?: boolean;
  orderWizardFavoritesInGroupForm?: boolean;
  orderWizardWrapPopoverHeader?: boolean;
  orderWizardUnwrapHeader?: boolean;
  orderWizardUnwrapListTitle?: boolean;
  orderWizardWiderColumns?: boolean;
  orderAutoRequirementHintRule?: boolean;
  orderWizardHideAdditionalInfo?: boolean;
  orderWizardPatientHideFindingsMedications?: boolean;
  orderWizardPatientTopBarFindingsMedications?: boolean;
  orderWizardNewOrderShowSelectPatient?: boolean;
  orderWizardShowCostUnitSelect?: boolean;
  orderWizardDisableMasonryView?: boolean;
  orderWizardHideInsuranceInInvoiceTo?: boolean;
  orderWizardDefaultReportCopySetting?: string | null;
  orderWizardPopoverInvoiceTo?: boolean;
  orderWizardPopoverHideAnalyses?: boolean;
  orderWizardReportsTimeRange?: number;
  orderWizardSearchDirectInput?: boolean;
  orderWizardBakModalBlockButtons?: boolean;
  orderWizardDisableHelgaImage?: boolean;
  orderWizardShowWorkstationSelect?: boolean;
  orderWizardShowOfflineWorkstationSelect?: boolean;
  orderWizardHideOfflineWorkstations?: boolean;
  orderWizardAlwaysShowWorkstationSelect?: boolean;
  orderWizardRememberLastWorkstation?: boolean;
  orderWizardRememberLastDeviceWorkstation?: boolean;
  orderWizardLastWorkstationId?: number;
  orderWizardAutocompleteEntries?: number;
  orderWizardBakColor?: string;
  orderWizardBakFontColor?: string;
  orderWizardHideBakMaterialGroupInfo?: boolean;
  orderWizardAlwaysShowBlocks?: boolean;
  orderWizardBlockSize?: number;
  orderWizardDifferentBlockSizeOnHome?: boolean;
  orderWizardBlockSizeOnHome?: number;
  orderWizardCopyToPatientActivationSwitch?: boolean;
  orderWizardSeparateInvoiceTo?: boolean;
  orderBarcodeFactorDefault?: number;
  orderBarcodeMaterialFactorShowOriginalCount?: boolean;
  orderSortPoolPatientsByName?: boolean;
  orderSortPatientsDropdownByName?: boolean;
  orderWizardSubRequirementsInBasket?: boolean;
  orderWizardInfectiousSwitch?: boolean;
  orderWizardPregnancySwitch?: boolean;
  orderWizardCheckupSwitch?: boolean;
  orderWizardEnableCreateEditProfiles?: boolean;
  orderWizardProfilesDefaultToAllOthers?: boolean;
  orderWizardProfilesSetDiagnoseText?: boolean;
  orderWizardProfilesSetFreeText?: boolean;
  orderWizardShowExttnr?: boolean;
  orderWizardShowExttnrLastUnderline?: boolean;
  orderWizardShowUrgentSwitch?: boolean;
  orderWizardShowEmergencySwitch?: boolean;
  orderWizardShowAccidentSwitch?: boolean;
  orderWizardFillSampleDateFromScheduleDate?: boolean;
  orderWizardOpenDuplicatePatientOrdersModal?: boolean;
  orderWizardUrgentNotificationModeCall?: boolean;
  orderWizardUrgentNotificationModeFax?: boolean;
  orderWizardUrgentNotificationModeEmail?: boolean;
  orderWizardUrgentNotificationDeprecatedMode?: boolean;
  orderWizardChTreatmentReasonDropdown?: boolean;
  orderWizardInsuranceNumberMask?: string;
  orderWizardShowProfileAlreadyInBasketWarning?: boolean;
  orderWizardReportValueAllAnalyses?: boolean;
  orderMultiHeaderMoreInfo?: boolean;
  orderWizardAdditionalTextInPopover?: boolean;
  orderWizardAdditionalTextInCompactView?: boolean;
  orderWizardBasketUnshiftRequirements?: boolean;
  ordersShowEbmOnExecute?: boolean;
  ordersDetailDefaultGroupBy?: OrderDetailDefaultGroupBy;
  ordersDetailDefaultSortBy?: OrderDetailDefaultSortBy;
  ordersRealCancellationTimeRangeInterval?: Interval;
  ordersDefaultOrderMode?: OrderListMode;
  ordersDefaultStatusInBookmarked?: BookmarkedMode;
  ordersDefaultSortingInBookmarked?: SortingDirection;
  ordersDefaultCompletionOrderBy?: CompletionListOrderBy;
  ordersDefaultTreatmentReason?: ChTreatmentReason;
  orderWizardStartFilters?: OrderRequirementsFilter[];
  orderWizardReportsTimeRangeInterval?: Interval;
  orderBarcodeMaterialFactor?: OrderBarcodeMaterialFactor[];
  orderWizardDuplicatePatientStatus?: OrderStatus[];
}

export interface CasesPreferencesProperties {
  casesOrderListOpened?: boolean;
  casesDisabledReportLaboratoryGroups?: string[];
}

export interface StorePreferencesProperties {
  storeOrderNumberCurrent?: number;
  storeOrderNumberFormat?: string;
}

export interface PreferencesProperties {
  /**
   * Patient name sorting (a#b#c#d): a = title position, b = first name position, c = last name position, d = show title 0/1
   */
  reportsPatientNameSorting?: string;
  reportsPatientNameDisplayEnabled?: boolean;
  reportsDefaultFilterProfile?: number;
  reportsPinnedOnTop?: boolean;
  reportsInitialOpenCumulative?: boolean;
  reportsCumulativeMergeDays?: boolean;
  reportsCumulativeSingleReport?: boolean;
  reportsPdfDownloadAnalysesEnabledMode?: boolean;
  reportsPdfDownloadAnalyses?: string[];
  reportsPdfDownloadPathoAnalyses?: string[];
  enableAdvancedAnalysesFilter?: boolean;
  reportsBarcodeEnableCustomization?: boolean;
  reportsBarcodePrefixLength?: number;
  reportsBarcodeLength?: number;
  reportsBarcodeSuffixLength?: number;
  reportsPatientsListShowInsuranceNumber?: boolean;
  reportsPatientsListShowPatientIdentification?: boolean;
  reportsPatientsListShowEmail?: boolean;
  reportsPatientsListShowBirthday?: boolean;
  reportsCustomSortBdwEnabled?: boolean;
  reportsCustomSortCumulativeEnabled?: boolean;
  reportsCustomSortCumulativeGroupsEnabled?: boolean;
  reportsDeltaCheckFilter?: boolean;
  reportsValueFollowsEnabled?: boolean;
  reportsValueFollowsText?: string;
  reportsValueFollowsHideNteEmpty?: boolean;
  reportsUseRealReRequest?: boolean;
  ordersRealCancellation?: boolean;
  ordersRealCancellationTimeRange?: number;
  ordersShowPrePrint?: boolean;
  ordersPrePrintIsC2A?: boolean;
  ordersMaxPoolSize?: number;
  ordersShowBeforeAfterDialysis?: boolean;
  ordersBeforeAfterDialysisMustBeSpecified?: boolean;
  ordersScheduleTimeEnabled?: boolean;
  ordersScheduleTimeInterval?: number;
  ordersScheduleTimeEnableDefault?: boolean;
  ordersScheduleTimeDefault?: string;
  ordersDefaultOrderModeEnabled?: boolean;
  ordersBookmarkedPoolMode?: boolean;
  ordersShowRoomNumber?: boolean;
  ordersHideDiagnosisFields?: boolean;
  ordersPreferScheduleDateMode?: boolean;
  ordersSampleInLaboratoryButton?: boolean;
  ordersSampleInLaboratoryButtonFirst?: boolean;
  ordersSelectWorkstationSplitButton?: boolean;
  ordersSampleDateTimeButton?: boolean;
  ordersAnamnesisShrinkMode?: boolean;
  ordersDynamicProbeTypeStaticReprint?: boolean;
  orderWizardCompactView?: boolean;
  orderWizardUltraCompactView?: boolean;
  orderWizardDisablePopovers?: boolean;
  orderWizardDisableTopRequests?: boolean;
  orderWizardEnableBasketPopovers?: boolean;
  orderWizardDisableAllPopovers?: boolean;
  orderWizardDefaultGroupForm?: {
    [k: string]: any;
  };
  orderWizardProfilesInGroupForm?: boolean;
  orderWizardTopRequestsInGroupForm?: boolean;
  orderWizardFavoritesInGroupForm?: boolean;
  orderWizardWrapPopoverHeader?: boolean;
  orderWizardUnwrapHeader?: boolean;
  orderWizardUnwrapListTitle?: boolean;
  orderWizardWiderColumns?: boolean;
  orderAutoRequirementHintRule?: boolean;
  orderWizardHideAdditionalInfo?: boolean;
  orderWizardPatientHideFindingsMedications?: boolean;
  orderWizardPatientTopBarFindingsMedications?: boolean;
  orderWizardNewOrderShowSelectPatient?: boolean;
  orderWizardShowCostUnitSelect?: boolean;
  orderWizardDisableMasonryView?: boolean;
  orderWizardHideInsuranceInInvoiceTo?: boolean;
  orderWizardDefaultReportCopySetting?: string | null;
  orderWizardPopoverInvoiceTo?: boolean;
  orderWizardPopoverHideAnalyses?: boolean;
  orderWizardReportsTimeRange?: number;
  orderWizardSearchDirectInput?: boolean;
  orderWizardBakModalBlockButtons?: boolean;
  orderWizardDisableHelgaImage?: boolean;
  orderWizardShowWorkstationSelect?: boolean;
  orderWizardShowOfflineWorkstationSelect?: boolean;
  orderWizardHideOfflineWorkstations?: boolean;
  orderWizardAlwaysShowWorkstationSelect?: boolean;
  orderWizardRememberLastWorkstation?: boolean;
  orderWizardRememberLastDeviceWorkstation?: boolean;
  orderWizardLastWorkstationId?: number;
  orderWizardAutocompleteEntries?: number;
  orderWizardBakColor?: string;
  orderWizardBakFontColor?: string;
  orderWizardHideBakMaterialGroupInfo?: boolean;
  orderWizardAlwaysShowBlocks?: boolean;
  orderWizardBlockSize?: number;
  orderWizardDifferentBlockSizeOnHome?: boolean;
  orderWizardBlockSizeOnHome?: number;
  orderWizardCopyToPatientActivationSwitch?: boolean;
  orderWizardSeparateInvoiceTo?: boolean;
  orderBarcodeFactorDefault?: number;
  orderBarcodeMaterialFactorShowOriginalCount?: boolean;
  orderSortPoolPatientsByName?: boolean;
  orderSortPatientsDropdownByName?: boolean;
  orderWizardSubRequirementsInBasket?: boolean;
  orderWizardInfectiousSwitch?: boolean;
  orderWizardPregnancySwitch?: boolean;
  orderWizardCheckupSwitch?: boolean;
  orderWizardEnableCreateEditProfiles?: boolean;
  orderWizardProfilesDefaultToAllOthers?: boolean;
  orderWizardProfilesSetDiagnoseText?: boolean;
  orderWizardProfilesSetFreeText?: boolean;
  orderWizardShowExttnr?: boolean;
  orderWizardShowExttnrLastUnderline?: boolean;
  orderWizardShowUrgentSwitch?: boolean;
  orderWizardShowEmergencySwitch?: boolean;
  orderWizardShowAccidentSwitch?: boolean;
  orderWizardFillSampleDateFromScheduleDate?: boolean;
  orderWizardOpenDuplicatePatientOrdersModal?: boolean;
  orderWizardUrgentNotificationModeCall?: boolean;
  orderWizardUrgentNotificationModeFax?: boolean;
  orderWizardUrgentNotificationModeEmail?: boolean;
  orderWizardUrgentNotificationDeprecatedMode?: boolean;
  orderWizardChTreatmentReasonDropdown?: boolean;
  orderWizardInsuranceNumberMask?: string;
  orderWizardShowProfileAlreadyInBasketWarning?: boolean;
  orderWizardReportValueAllAnalyses?: boolean;
  orderMultiHeaderMoreInfo?: boolean;
  orderWizardAdditionalTextInPopover?: boolean;
  orderWizardAdditionalTextInCompactView?: boolean;
  orderWizardBasketUnshiftRequirements?: boolean;
  ordersShowEbmOnExecute?: boolean;
  casesOrderListOpened?: boolean;
  casesDisabledReportLaboratoryGroups?: string[];
  storeOrderNumberCurrent?: number;
  storeOrderNumberFormat?: string;
  startProduct?: Product;
  fontSize?: UserAccessibilityFontSize;
  contrast?: UserAccessibilityContrast;
  newExchangeFileNotification?: NotificationPreferencesProperties;
  newReportNotification?: NotificationPreferencesProperties;
  newReportNotificationSettings?: ReportsNotificationSettings;
  reportsDefaultOrder?: ReportDefaultOrder;
  reportsPatientNameDisplay?: PatientNameDisplay;
  reportsFavoriteAnalyses?: ReportsFavoriteAnalyses[];
  ordersDetailDefaultGroupBy?: OrderDetailDefaultGroupBy;
  ordersDetailDefaultSortBy?: OrderDetailDefaultSortBy;
  ordersRealCancellationTimeRangeInterval?: Interval;
  ordersDefaultOrderMode?: OrderListMode;
  ordersDefaultStatusInBookmarked?: BookmarkedMode;
  ordersDefaultSortingInBookmarked?: SortingDirection;
  ordersDefaultCompletionOrderBy?: CompletionListOrderBy;
  ordersDefaultTreatmentReason?: ChTreatmentReason;
  orderWizardStartFilters?: OrderRequirementsFilter[];
  orderWizardReportsTimeRangeInterval?: Interval;
  orderBarcodeMaterialFactor?: OrderBarcodeMaterialFactor[];
  orderWizardDuplicatePatientStatus?: OrderStatus[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | AdministratorType.ADM | ADM |
 * | AdministratorType.SAD | SAD |
 */

export enum AdministratorType {
  ADM = 'ADM',
  SAD = 'SAD'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DoctorHierarchyStatus.NormalDoctor | 0 |
 * | DoctorHierarchyStatus.HierarchyDoctor | 1 |
 */

export enum DoctorHierarchyStatus {
  NormalDoctor = 0,
  HierarchyDoctor = 1
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DoctorHierarchyType.Member | 0 |
 * | DoctorHierarchyType.Owner | 1 |
 */

export enum DoctorHierarchyType {
  Member = 0,
  Owner = 1
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DeviceStatus.Pending | 0 |
 * | DeviceStatus.Approved | 1 |
 * | DeviceStatus.Denied | 2 |
 * | DeviceStatus.Revoked | 3 |
 * | DeviceStatus.SoftDeleted | 4 |
 */

export enum DeviceStatus {
  Pending = 0,
  Approved = 1,
  Denied = 2,
  Revoked = 3,
  SoftDeleted = 4
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | Gender.Unknown | 0 |
 * | Gender.Male | 1 |
 * | Gender.Female | 2 |
 * | Gender.Undetermined | 3 |
 * | Gender.Diverse | 4 |
 */

export enum Gender {
  Unknown = 0,
  Male = 1,
  Female = 2,
  Undetermined = 3,
  Diverse = 4
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LaboratoryUserPrivilegeMode.Full | 0 |
 * | LaboratoryUserPrivilegeMode.Orders | 1 |
 * | LaboratoryUserPrivilegeMode.Reports | 2 |
 * | LaboratoryUserPrivilegeMode.Activations | 3 |
 * | LaboratoryUserPrivilegeMode.Labwebshop | 4 |
 * | LaboratoryUserPrivilegeMode.MobileRecorder | 5 |
 */

export enum LaboratoryUserPrivilegeMode {
  Full = 0,
  Orders = 1,
  Reports = 2,
  Activations = 3,
  Labwebshop = 4,
  MobileRecorder = 5
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserLogAction.Login | 0 |
 * | UserLogAction.Logout | 1 |
 * | UserLogAction.WrongPassword | 2 |
 * | UserLogAction.LegacyLogin | 3 |
 * | UserLogAction.LockedAccount | 4 |
 * | UserLogAction.PasswordReset | 5 |
 * | UserLogAction.Activated | 10 |
 * | UserLogAction.Reactivated | 11 |
 * | UserLogAction.ResentWelcomeMail | 12 |
 * | UserLogAction.Deactivated | 13 |
 * | UserLogAction.EmailChanged | 20 |
 * | UserLogAction.UserNameChanged | 21 |
 * | UserLogAction.PasswordChanged | 22 |
 * | UserLogAction.PhoneChanged | 23 |
 * | UserLogAction.CanViewDiagnosis | 30 |
 * | UserLogAction.CannotViewDiagnosis | 31 |
 * | UserLogAction.DeviceRegistered | 40 |
 * | UserLogAction.DeviceApproved | 41 |
 * | UserLogAction.GeneratedDeviceToken | 42 |
 * | UserLogAction.Initialized2fa | 50 |
 * | UserLogAction.Enabled2fa | 51 |
 * | UserLogAction.Disabled2fa | 52 |
 * | UserLogAction.Rejected2fa | 54 |
 * | UserLogAction.Notified | 60 |
 */

export enum UserLogAction {
  Login = 0,
  Logout = 1,
  WrongPassword = 2,
  LegacyLogin = 3,
  LockedAccount = 4,
  PasswordReset = 5,
  Activated = 10,
  Reactivated = 11,
  ResentWelcomeMail = 12,
  Deactivated = 13,
  EmailChanged = 20,
  UserNameChanged = 21,
  PasswordChanged = 22,
  PhoneChanged = 23,
  CanViewDiagnosis = 30,
  CannotViewDiagnosis = 31,
  DeviceRegistered = 40,
  DeviceApproved = 41,
  GeneratedDeviceToken = 42,
  Initialized2fa = 50,
  Enabled2fa = 51,
  Disabled2fa = 52,
  Rejected2fa = 54,
  Notified = 60
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserPermission.PermissionChangePasswordDoctor | permissionChangePasswordDoctor |
 * | UserPermission.PermissionChangeUsernameDoctor | permissionChangeUsernameDoctor |
 * | UserPermission.PermissionDeleteReport | permissionDeleteReport |
 * | UserPermission.PermissionModifyDoctor | permissionModifyDoctor |
 * | UserPermission.PermissionModifyPatient | permissionModifyPatient |
 * | UserPermission.PermissionReactivateDoctor | permissionReactivateDoctor |
 * | UserPermission.PermissionReactivatePatient | permissionReactivatePatient |
 * | UserPermission.PermissionLockDoctor | permissionLockDoctor |
 * | UserPermission.PermissionLockPatient | permissionLockPatient |
 * | UserPermission.PermissionDeactivateDoctor | permissionDeactivateDoctor |
 * | UserPermission.PermissionDeactivatePatient | permissionDeactivatePatient |
 * | UserPermission.PermissionPasswordResetMailDoctor | permissionPasswordResetMailDoctor |
 * | UserPermission.PermissionPasswordResetMailPatient | permissionPasswordResetMailPatient |
 * | UserPermission.PermissionAccessUserLogPatient | permissionAccessUserLogPatient |
 * | UserPermission.PermissionAccessUserlogDoctor | permissionAccessUserlogDoctor |
 * | UserPermission.PermissionChangeUsernamePatient | permissionChangeUsernamePatient |
 * | UserPermission.PermissionChangePasswordPatient | permissionChangePasswordPatient |
 * | UserPermission.PermissionDeleteDoctor | permissionDeleteDoctor |
 * | UserPermission.PermissionDeletePatient | permissionDeletePatient |
 * | UserPermission.PermissionActivateDoctor | permissionActivateDoctor |
 * | UserPermission.PermissionActivatePatient | permissionActivatePatient |
 * | UserPermission.PermissionViewMails | permissionViewMails |
 * | UserPermission.PermissionViewasDoctor | permissionViewasDoctor |
 * | UserPermission.PermissionViewasPatient | permissionViewasPatient |
 * | UserPermission.PermissionPushtest | permissionPushtest |
 * | UserPermission.PermissionViewReportprotocol | permissionViewReportprotocol |
 */

export enum UserPermission {
  PermissionChangePasswordDoctor = 'permissionChangePasswordDoctor',
  PermissionChangeUsernameDoctor = 'permissionChangeUsernameDoctor',
  PermissionDeleteReport = 'permissionDeleteReport',
  PermissionModifyDoctor = 'permissionModifyDoctor',
  PermissionModifyPatient = 'permissionModifyPatient',
  PermissionReactivateDoctor = 'permissionReactivateDoctor',
  PermissionReactivatePatient = 'permissionReactivatePatient',
  PermissionLockDoctor = 'permissionLockDoctor',
  PermissionLockPatient = 'permissionLockPatient',
  PermissionDeactivateDoctor = 'permissionDeactivateDoctor',
  PermissionDeactivatePatient = 'permissionDeactivatePatient',
  PermissionPasswordResetMailDoctor = 'permissionPasswordResetMailDoctor',
  PermissionPasswordResetMailPatient = 'permissionPasswordResetMailPatient',
  PermissionAccessUserLogPatient = 'permissionAccessUserLogPatient',
  PermissionAccessUserlogDoctor = 'permissionAccessUserlogDoctor',
  PermissionChangeUsernamePatient = 'permissionChangeUsernamePatient',
  PermissionChangePasswordPatient = 'permissionChangePasswordPatient',
  PermissionDeleteDoctor = 'permissionDeleteDoctor',
  PermissionDeletePatient = 'permissionDeletePatient',
  PermissionActivateDoctor = 'permissionActivateDoctor',
  PermissionActivatePatient = 'permissionActivatePatient',
  PermissionViewMails = 'permissionViewMails',
  PermissionViewasDoctor = 'permissionViewasDoctor',
  PermissionViewasPatient = 'permissionViewasPatient',
  PermissionPushtest = 'permissionPushtest',
  PermissionViewReportprotocol = 'permissionViewReportprotocol'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserStatus.ManLocked | -4 |
 * | UserStatus.Queued | -3 |
 * | UserStatus.AutoLocked | -2 |
 * | UserStatus.Inactive | -1 |
 * | UserStatus.Ok | 0 |
 * | UserStatus.Initial | 1 |
 */

export enum UserStatus {
  ManLocked = -4,
  Queued = -3,
  AutoLocked = -2,
  Inactive = -1,
  Ok = 0,
  Initial = 1
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserType.PAT | 0 |
 * | UserType.ARZ | 1 |
 * | UserType.LAB | 2 |
 * | UserType.ADM | 3 |
 * | UserType.SAD | 4 |
 * | UserType.LAU | 5 |
 */

export enum UserType {
  PAT = 0,
  ARZ = 1,
  LAB = 2,
  ADM = 3,
  SAD = 4,
  LAU = 5
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | UserTypeString.PAT | PAT |
 * | UserTypeString.ARZ | ARZ |
 * | UserTypeString.LAB | LAB |
 * | UserTypeString.ADM | ADM |
 * | UserTypeString.SAD | SAD |
 * | UserTypeString.LAU | LAU |
 */

export enum UserTypeString {
  PAT = 'PAT',
  ARZ = 'ARZ',
  LAB = 'LAB',
  ADM = 'ADM',
  SAD = 'SAD',
  LAU = 'LAU'
}

export interface DoctorCreateProperties {
  email?: string;
  externalId: string;
  salutation?: string;
  title?: string;
  lastName?: string;
  firstName?: string;
  smsNumber?: string;
}

export interface LaboratoryPermissionsProperties {
  /**
   * allow changing password of doctor
   */
  permissionChangePasswordDoctor?: boolean;
  /**
   * allow changing username / login of doctor
   */
  permissionChangeUsernameDoctor?: boolean;
  /**
   * allow deleting reports
   */
  permissionDeleteReport?: boolean;
  /**
   * allow modifying physicians
   */
  permissionModifyDoctor?: boolean;
  /**
   * allow modifying patients
   */
  permissionModifyPatient?: boolean;
  /**
   * allow reactivating doctors
   */
  permissionReactivateDoctor?: boolean;
  /**
   * allow reactivating patients
   */
  permissionReactivatePatient?: boolean;
  /**
   * allow locking doctors
   */
  permissionLockDoctor?: boolean;
  /**
   * allow locking patients
   */
  permissionLockPatient?: boolean;
  /**
   * allow deactivating doctors
   */
  permissionDeactivateDoctor?: boolean;
  /**
   * allow deactivating patients
   */
  permissionDeactivatePatient?: boolean;
  /**
   * allow sending password reset mail to doctors
   */
  permissionPasswordResetMailDoctor?: boolean;
  /**
   * allow sending password rest mail to patients
   */
  permissionPasswordResetMailPatient?: boolean;
  /**
   * allow access to user log of patient
   */
  permissionAccessUserLogPatient?: boolean;
  /**
   * allow access to user log ("Benutzerakte")
   */
  permissionAccessUserlogDoctor?: boolean;
  /**
   * allow changing username of patient
   */
  permissionChangeUsernamePatient?: boolean;
  /**
   * allow changing password of patients
   */
  permissionChangePasswordPatient?: boolean;
  /**
   * allows user to delete doctors
   */
  permissionDeleteDoctor?: boolean;
  /**
   * allow laboratory to delete patients
   */
  permissionDeletePatient?: boolean;
  /**
   * allow activating doctors
   */
  permissionActivateDoctor?: boolean;
  /**
   * allow activating patients
   */
  permissionActivatePatient?: boolean;
  /**
   * Bestimmt, ob ein Labor in den Befundakten den Mailversand sehen kann
   */
  permissionViewMails?: boolean;
  /**
   * allow "Ansicht übernehmen" of doctors
   */
  permissionViewasDoctor?: boolean;
  /**
   * allow "Ansicht übernehmen" of patients
   */
  permissionViewasPatient?: boolean;
  /**
   * allow laboratory to perform push test
   */
  permissionPushtest?: boolean;
  /**
   * allow laboratory to view report protocol
   */
  permissionViewReportprotocol?: boolean;
}

export interface LaboratoryUserPermissionsProperties {
  /**
   * allow changing password of doctor
   */
  permissionChangePasswordDoctor?: boolean;
  /**
   * allow changing username / login of doctor
   */
  permissionChangeUsernameDoctor?: boolean;
  /**
   * allow deleting reports
   */
  permissionDeleteReport?: boolean;
  /**
   * allow modifying physicians
   */
  permissionModifyDoctor?: boolean;
  /**
   * allow modifying patients
   */
  permissionModifyPatient?: boolean;
  /**
   * allow reactivating doctors
   */
  permissionReactivateDoctor?: boolean;
  /**
   * allow reactivating patients
   */
  permissionReactivatePatient?: boolean;
  /**
   * allow locking doctors
   */
  permissionLockDoctor?: boolean;
  /**
   * allow locking patients
   */
  permissionLockPatient?: boolean;
  /**
   * allow deactivating doctors
   */
  permissionDeactivateDoctor?: boolean;
  /**
   * allow deactivating patients
   */
  permissionDeactivatePatient?: boolean;
  /**
   * allow sending password reset mail to doctors
   */
  permissionPasswordResetMailDoctor?: boolean;
  /**
   * allow sending password rest mail to patients
   */
  permissionPasswordResetMailPatient?: boolean;
  /**
   * allow access to user log of patient
   */
  permissionAccessUserLogPatient?: boolean;
  /**
   * allow access to user log ("Benutzerakte")
   */
  permissionAccessUserlogDoctor?: boolean;
  /**
   * allow changing username of patient
   */
  permissionChangeUsernamePatient?: boolean;
  /**
   * allow changing password of patients
   */
  permissionChangePasswordPatient?: boolean;
  /**
   * allows user to delete doctors
   */
  permissionDeleteDoctor?: boolean;
  /**
   * allow laboratory to delete patients
   */
  permissionDeletePatient?: boolean;
  /**
   * allow activating doctors
   */
  permissionActivateDoctor?: boolean;
  /**
   * allow activating patients
   */
  permissionActivatePatient?: boolean;
  /**
   * Bestimmt, ob ein Labor in den Befundakten den Mailversand sehen kann
   */
  permissionViewMails?: boolean;
  /**
   * allow "Ansicht übernehmen" of doctors
   */
  permissionViewasDoctor?: boolean;
  /**
   * allow "Ansicht übernehmen" of patients
   */
  permissionViewasPatient?: boolean;
  /**
   * allow laboratory to perform push test
   */
  permissionPushtest?: boolean;
  /**
   * allow laboratory to view report protocol
   */
  permissionViewReportprotocol?: boolean;
}

export interface UserPermissionsProperties {
  /**
   * allow changing password of doctor
   */
  permissionChangePasswordDoctor?: boolean;
  /**
   * allow changing username / login of doctor
   */
  permissionChangeUsernameDoctor?: boolean;
  /**
   * allow deleting reports
   */
  permissionDeleteReport?: boolean;
  /**
   * allow modifying physicians
   */
  permissionModifyDoctor?: boolean;
  /**
   * allow modifying patients
   */
  permissionModifyPatient?: boolean;
  /**
   * allow reactivating doctors
   */
  permissionReactivateDoctor?: boolean;
  /**
   * allow reactivating patients
   */
  permissionReactivatePatient?: boolean;
  /**
   * allow locking doctors
   */
  permissionLockDoctor?: boolean;
  /**
   * allow locking patients
   */
  permissionLockPatient?: boolean;
  /**
   * allow deactivating doctors
   */
  permissionDeactivateDoctor?: boolean;
  /**
   * allow deactivating patients
   */
  permissionDeactivatePatient?: boolean;
  /**
   * allow sending password reset mail to doctors
   */
  permissionPasswordResetMailDoctor?: boolean;
  /**
   * allow sending password rest mail to patients
   */
  permissionPasswordResetMailPatient?: boolean;
  /**
   * allow access to user log of patient
   */
  permissionAccessUserLogPatient?: boolean;
  /**
   * allow access to user log ("Benutzerakte")
   */
  permissionAccessUserlogDoctor?: boolean;
  /**
   * allow changing username of patient
   */
  permissionChangeUsernamePatient?: boolean;
  /**
   * allow changing password of patients
   */
  permissionChangePasswordPatient?: boolean;
  /**
   * allows user to delete doctors
   */
  permissionDeleteDoctor?: boolean;
  /**
   * allow laboratory to delete patients
   */
  permissionDeletePatient?: boolean;
  /**
   * allow activating doctors
   */
  permissionActivateDoctor?: boolean;
  /**
   * allow activating patients
   */
  permissionActivatePatient?: boolean;
  /**
   * Bestimmt, ob ein Labor in den Befundakten den Mailversand sehen kann
   */
  permissionViewMails?: boolean;
  /**
   * allow "Ansicht übernehmen" of doctors
   */
  permissionViewasDoctor?: boolean;
  /**
   * allow "Ansicht übernehmen" of patients
   */
  permissionViewasPatient?: boolean;
  /**
   * allow laboratory to perform push test
   */
  permissionPushtest?: boolean;
  /**
   * allow laboratory to view report protocol
   */
  permissionViewReportprotocol?: boolean;
}

export interface DoctorGroupMembershipProperties {
  /**
   * Group Name
   */
  name?: string;
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * DoctorGroup ID
   */
  gid: number;
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Is Group Admin
   */
  isAdmin?: boolean;
  /**
   * Is Group Member
   */
  isMember?: boolean;
  /**
   * Notify report info
   */
  bengrp?: boolean;
}

export interface DoctorHierarchyGroupProperties {
  owner?: DoctorHierarchy;
  descendants?: DoctorHierarchy[];
}

export interface DoctorHierarchyPathDetailProperties {
  id?: number;
  aid?: number;
  name?: string;
  type?: DoctorHierarchyType;
}

export interface DoctorHierarchyPathProperties {
  path?: DoctorHierarchyPathDetailProperties[];
}

export interface PatientMetaProperties {
  /**
   * Patient Last Name
   */
  lastName?: string;
  /**
   * Patient First Name
   */
  firstName?: string;
  /**
   * Suffix
   */
  suffix?: string;
  /**
   * Patient Title
   */
  title?: string;
  /**
   * Patient Birthday
   */
  birthday?: string;
  /**
   * Patient City
   */
  city?: string;
  /**
   * Patient Zip Code
   */
  zipCode?: string;
  /**
   * street name
   */
  address?: string;
  /**
   * street number
   */
  houseNumber?: string;
  addressAdditional?: string;
  /**
   * country code, e.g. AT, DE
   */
  country?: string;
  /**
   * Patient Insurance Number
   */
  insuranceNumber?: string;
  /**
   * Patient Email Address
   */
  email?: string;
  /**
   * Patient Phone Number
   */
  phone?: string;
  costUnit?: string;
  gender?: Gender;
}

export interface UserEntityProperties {
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * User Password (old password md5 hashed)
   */
  passwort?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Normalized salutation to display
   */
  salutation?: string;
  /**
   * Normalized email
   */
  email?: string;
  /**
   * Legacy last used locale
   */
  lastlang?: string;
  userDebugEnabled?: boolean;
  status?: UserStatus;
  locale?: LanguageCode;
}

export interface UserLogProperties {
  id?: number;
  xuser_id?: number;
  user_agent_id?: number;
  user_agent?: string;
  /**
   * Normalized name to display
   */
  user_name?: string;
  lid?: number;
  /**
   * Additional info
   */
  info?: string;
  ip?: string;
  created_at?: string;
}

export interface UserProductsProperties {
  /**
   * Product OA explicitly enabled/disabled
   */
  oa?: boolean;
  /**
   * Product OB explicitly enabled/disabled
   */
  ob?: boolean;
  /**
   * Product OC explicitly enabled/disabled
   */
  of?: boolean;
  /**
   * Product LL explicitly enabled/disabled
   */
  ll?: boolean;
  /**
   * Product LE explicitly enabled/disabled
   */
  le?: boolean;
  /**
   * Product LS explicitly enabled/disabled
   */
  ls?: boolean;
  /**
   * Product LC explicitly enabled/disabled
   */
  lc?: boolean;
  /**
   * Product DD explicitly enabled/disabled
   */
  dd?: boolean;
  /**
   * Product MC explicitly enabled/disabled
   */
  mc?: boolean;
  /**
   * Product NC explicitly enabled/disabled
   */
  nc?: boolean;
  /**
   * Product LW explicitly enabled/disabled
   */
  lw?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  admin?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  dashboard?: boolean;
  /**
   * { [feature key]: enabled }
   */
  features?: {
    [k: string]: any;
  };
}

export interface UserProfileProperties {
  /**
   * User E-Mail
   */
  email?: string;
  /**
   * User SMS Number
   */
  smsNumber?: string;
  /**
   * User Website
   */
  www?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | WebshopOrderStatus.PaymentNeeded | 0 |
 * | WebshopOrderStatus.PaymentSucceeded | 1 |
 * | WebshopOrderStatus.PaymentFailed | 2 |
 */

export enum WebshopOrderStatus {
  PaymentNeeded = 0,
  PaymentSucceeded = 1,
  PaymentFailed = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | PaymentMethod.Stripe | stripe |
 * | PaymentMethod.Voucher | voucher |
 */

export enum PaymentMethod {
  Stripe = 'stripe',
  Voucher = 'voucher'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | KbvFormType.None | 0 |
 * | KbvFormType.Muster1 | 1 |
 * | KbvFormType.Muster2 | 2 |
 * | KbvFormType.Muster3 | 3 |
 * | KbvFormType.Muster4 | 4 |
 * | KbvFormType.Muster5 | 5 |
 * | KbvFormType.Muster6 | 6 |
 * | KbvFormType.Muster7 | 7 |
 * | KbvFormType.Muster8 | 8 |
 * | KbvFormType.Muster8A | 9 |
 * | KbvFormType.Muster9 | 10 |
 * | KbvFormType.Muster10 | 11 |
 * | KbvFormType.Muster10A | 12 |
 * | KbvFormType.Muster10C | 13 |
 * | KbvFormType.Muster11 | 14 |
 * | KbvFormType.Muster12 | 15 |
 * | KbvFormType.Muster13 | 16 |
 * | KbvFormType.Muster15 | 17 |
 * | KbvFormType.Muster16 | 18 |
 * | KbvFormType.Muster19 | 19 |
 * | KbvFormType.Muster20 | 20 |
 * | KbvFormType.Muster21 | 21 |
 * | KbvFormType.Muster22 | 22 |
 * | KbvFormType.Muster25 | 23 |
 * | KbvFormType.Muster26 | 24 |
 * | KbvFormType.Muster27 | 25 |
 * | KbvFormType.Muster28 | 26 |
 * | KbvFormType.Muster36 | 27 |
 * | KbvFormType.Muster39 | 28 |
 * | KbvFormType.Muster40 | 29 |
 * | KbvFormType.Muster50 | 30 |
 * | KbvFormType.Muster51 | 31 |
 * | KbvFormType.Muster52 | 32 |
 * | KbvFormType.Muster53 | 33 |
 * | KbvFormType.Muster55 | 34 |
 * | KbvFormType.Muster56 | 35 |
 * | KbvFormType.Muster61 | 36 |
 * | KbvFormType.Muster63 | 37 |
 * | KbvFormType.Muster64 | 38 |
 * | KbvFormType.Muster65 | 39 |
 * | KbvFormType.Muster70 | 40 |
 * | KbvFormType.Muster70A | 41 |
 * | KbvFormType.MusterOEGD | 42 |
 */

export enum KbvFormType {
  None = 0,
  Muster1 = 1,
  Muster2 = 2,
  Muster3 = 3,
  Muster4 = 4,
  Muster5 = 5,
  Muster6 = 6,
  Muster7 = 7,
  Muster8 = 8,
  Muster8A = 9,
  Muster9 = 10,
  Muster10 = 11,
  Muster10A = 12,
  Muster10C = 13,
  Muster11 = 14,
  Muster12 = 15,
  Muster13 = 16,
  Muster15 = 17,
  Muster16 = 18,
  Muster19 = 19,
  Muster20 = 20,
  Muster21 = 21,
  Muster22 = 22,
  Muster25 = 23,
  Muster26 = 24,
  Muster27 = 25,
  Muster28 = 26,
  Muster36 = 27,
  Muster39 = 28,
  Muster40 = 29,
  Muster50 = 30,
  Muster51 = 31,
  Muster52 = 32,
  Muster53 = 33,
  Muster55 = 34,
  Muster56 = 35,
  Muster61 = 36,
  Muster63 = 37,
  Muster64 = 38,
  Muster65 = 39,
  Muster70 = 40,
  Muster70A = 41,
  MusterOEGD = 42
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MvzMartinsriedVoucherPurpose.PcrStandard | 1 |
 * | MvzMartinsriedVoucherPurpose.Antigen | 2 |
 * | MvzMartinsriedVoucherPurpose.PcrExpress | 3 |
 * | MvzMartinsriedVoucherPurpose.PcrInstant | 4 |
 */

export enum MvzMartinsriedVoucherPurpose {
  PcrStandard = 1,
  Antigen = 2,
  PcrExpress = 3,
  PcrInstant = 4
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MvzMartinsriedVoucherApiPurpose.PcrStandard | PC |
 * | MvzMartinsriedVoucherApiPurpose.PC | PcrStandard |
 * | MvzMartinsriedVoucherApiPurpose.Antigen | AG |
 * | MvzMartinsriedVoucherApiPurpose.AG | Antigen |
 * | MvzMartinsriedVoucherApiPurpose.PcrExpress | EP |
 * | MvzMartinsriedVoucherApiPurpose.EP | PcrExpress |
 * | MvzMartinsriedVoucherApiPurpose.PcrInstant | PO |
 * | MvzMartinsriedVoucherApiPurpose.PO | PcrInstant |
 */

export enum MvzMartinsriedVoucherApiPurpose {
  PcrStandard = 'PC',
  PC = 'PcrStandard',
  Antigen = 'AG',
  AG = 'Antigen',
  PcrExpress = 'EP',
  EP = 'PcrExpress',
  PcrInstant = 'PO',
  PO = 'PcrInstant'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MvzMartinsriedVoucherOption.DisableInvoice | 1 |
 * | MvzMartinsriedVoucherOption.LhApiCreated | 6 |
 */

export enum MvzMartinsriedVoucherOption {
  DisableInvoice = 1,
  LhApiCreated = 6
}

export interface MvzMartinsriedHealthCertificateApiVoucherCreateProperties {
  vouchers?: MvzMartinsriedVoucher[];
}

export interface MvzMartinsriedVoucher {
  /**
   * The code itself
   */
  voucherCode: string;
  /**
   * (opt.) Proprietary operator code
   */
  operator?: string;
  /**
   * (opt.) Proprietary function group
   */
  functionGroup?: string;
  purpose?: MvzMartinsriedVoucherApiPurpose | null;
}

export interface KbvFormContent {
  freeText?: string;
  /**
   * "Nachricht ans Labor"
   */
  message?: string;
  infectious?: boolean;
  urgent?: string;
  pregnancy?: boolean;
  checkup?: boolean;
  /**
   * Cost unit (e.g. Privat/Kasse)
   */
  costUnit?: string;
  insuranceName?: string;
  controlCheckupKnownInfection?: boolean;
  dz116b?: boolean | null;
  dz16b?: boolean;
  accident?: boolean;
  accidentTime?: string;
  documentReferenceNumber?: string;
  anamnesticInfo?: string;
  printedMaterials?: string | null;
  printQuickReportBarcode?: boolean;
  urgentNotificationPhone?: string | null;
  urgentNotificationFax?: string | null;
  urgentNotificationMail?: string | null;
  urgentNotificationNumberEmail?: string | null;
  endo?: boolean;
  urgentNotificationMode?: UrgentNotificationMode | null;
  patient?: OrderPatientMetaProperties;
  covid?: KbvCovidFormProperties;
  kbvFormType?: KbvFormType;
}

export interface PaymentInstructions {
  sessionId?: string;
  publicKey?: string;
}

export interface PreparePaymentProperties {
  paymentInstructions?: PaymentInstructions;
  status?: WebshopOrderStatus;
}

export interface VoucherPaymentInput {
  voucherCode?: string;
}

export interface StripePaymentInput {
  sessionId?: string;
}

export interface PaymentSpecificInput {
  sessionId?: string;
  voucherCode?: string;
}

export interface AdditionalPerson {
  firstName: string;
  lastName: string;
  dob: string;
  sex: number;
}

export interface WebshopOrderProperties {
  houseNr?: string;
  insuranceNumber?: string;
  /**
   * base64 of form image
   */
  formImage?: string;
  /**
   * base64 of healthcard image
   */
  healthcardImage?: string;
  /**
   * base64 of id card/passport front
   */
  idFrontImage?: string;
  /**
   * base64 of id card/passport back
   */
  idBackImage?: string;
  /**
   * json string of id card/passport data
   */
  idCardData?: string;
  /**
   * id card number
   */
  idCardNumber?: string;
  /**
   * content of the pdf417 barcode of the used order form
   */
  kbvFormPdf417Content?: string;
  basicOrder?: WebshopOrder;
  additionalPersons?: AdditionalPerson[];
}

export interface WebshopOrderIdcardProperties {
  address?: string;
  age?: number;
  birthDate?: string;
  expiryDate?: string;
  issuingDate?: string;
  cardNumber?: string;
  expired?: boolean;
  faceImage?: {
    [k: string]: any;
  };
  firstName?: string;
  fullName?: string;
  issuingAuthority?: string;
  lastNamePart?: string;
  lastName?: string;
  nationality?: string;
  placeOfBirth?: string;
  sex?: string;
}

export interface CrudListQueryDto {
  query?: string;
}

export interface CrudPaginatedListQueryDto {
  query?: string;
  limit?: number;
  offset?: number;
}

export interface UserEntityTypeDto {
  entityId?: number;
  type?: UserType;
  preferences?: StorePreferencesProperties;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | DigitalSignatureStatus.Initial | 0 |
 * | DigitalSignatureStatus.Ready | 1 |
 * | DigitalSignatureStatus.InProcess | 2 |
 * | DigitalSignatureStatus.Received | 3 |
 * | DigitalSignatureStatus.Success | 4 |
 */

export enum DigitalSignatureStatus {
  Initial = 0,
  Ready = 1,
  InProcess = 2,
  Received = 3,
  Success = 4
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | WorkstationSpecialClientVersion.Manual | manuell |
 */

export enum WorkstationSpecialClientVersion {
  Manual = 'manuell'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | WorkstationStatus.Deactivated | -2 |
 * | WorkstationStatus.ConnectionError | -1 |
 * | WorkstationStatus.Disconnected | 0 |
 * | WorkstationStatus.Connected | 1 |
 * | WorkstationStatus.Registration | 2 |
 */

export enum WorkstationStatus {
  Deactivated = -2,
  ConnectionError = -1,
  Disconnected = 0,
  Connected = 1,
  Registration = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ExchangeEncodings.ANSI | ANSI |
 * | ExchangeEncodings.UTF8 | UTF8 |
 * | ExchangeEncodings.IBM437 | IBM437 |
 * | ExchangeEncodings.ISO8859 | ISO-8859-1 |
 */

export enum ExchangeEncodings {
  ANSI = 'ANSI',
  UTF8 = 'UTF8',
  IBM437 = 'IBM437',
  ISO8859 = 'ISO-8859-1'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ExchangeFileStatus.PartialReport | 0 |
 * | ExchangeFileStatus.FinalReport | 1 |
 * | ExchangeFileStatus.Rerequest | 2 |
 * | ExchangeFileStatus.PreliminaryReport | 3 |
 */

export enum ExchangeFileStatus {
  PartialReport = 0,
  FinalReport = 1,
  Rerequest = 2,
  PreliminaryReport = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ExchangeFileType.LDT | 0 |
 * | ExchangeFileType.PDF | 1 |
 * | ExchangeFileType.OTHER | 2 |
 * | ExchangeFileType.HL7 | 3 |
 */

export enum ExchangeFileType {
  LDT = 0,
  PDF = 1,
  OTHER = 2,
  HL7 = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LDTFileVersion.LDT2 | 2 |
 * | LDTFileVersion.LDT3 | 3 |
 */

export enum LDTFileVersion {
  LDT2 = 2,
  LDT3 = 3
}

export interface ExchangeFileIdsDto {
  ids?: number[];
}

export interface ExchangeListQueryDto {
  query?: string;
  limit?: number;
  offset?: number;
  aid?: number;
  dateFrom?: string;
  dateTo?: string;
  ldtFileVersion?: number | null;
  showDeleted?: boolean;
  fileType?: ExchangeFileType;
}

export interface ExchangeUploadFileDto {
  aids?: number[];
  file?: {
    [k: string]: any;
  };
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | ExchangeFileUpdateModes.NoFileUpdate | 0 |
 * | ExchangeFileUpdateModes.UpdateDependingTNR | 1 |
 * | ExchangeFileUpdateModes.UpdateDependingTNRandLG | 2 |
 */

export enum ExchangeFileUpdateModes {
  NoFileUpdate = 0,
  UpdateDependingTNR = 1,
  UpdateDependingTNRandLG = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LDT2ModeLaboratoryGroupIdentificationTypes.None | 0 |
 * | LDT2ModeLaboratoryGroupIdentificationTypes.Ident8000 | 1 |
 * | LDT2ModeLaboratoryGroupIdentificationTypes.Ident8300 | 2 |
 */

export enum LDT2ModeLaboratoryGroupIdentificationTypes {
  None = 0,
  Ident8000 = 1,
  Ident8300 = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | LDT3ModeLaboratoryGroupIdentificationTypes.None | 0 |
 * | LDT3ModeLaboratoryGroupIdentificationTypes.Ident7266 | 1 |
 * | LDT3ModeLaboratoryGroupIdentificationTypes.DynObj | 2 |
 */

export enum LDT3ModeLaboratoryGroupIdentificationTypes {
  None = 0,
  Ident7266 = 1,
  DynObj = 2
}

export interface ExchangeExportSettingsDto {
  own?: ExchangeExportSettings;
  others?: ExchangeExportSettings[];
}

export interface ExchangeImportSettingsResultDto {
  settingsLDT?: ExchangeImportSettingsLdt;
  settingsPDF?: ExchangeImportSettingsPdf;
  directories?: ExchangeImportDirectories[];
}

export interface StoreCategoryPathReferenceDto {
  id?: number;
  name?: string;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | StoreProductUnitType.Pieces | pieces |
 * | StoreProductUnitType.Milliliter | milliliter |
 * | StoreProductUnitType.Liter | liter |
 */

export enum StoreProductUnitType {
  Pieces = 'pieces',
  Milliliter = 'milliliter',
  Liter = 'liter'
}

export interface StoreProductUploadImageDto {
  id?: number;
  image?: {
    [k: string]: any;
  };
}

export interface StoreProductsListQueryDto {
  query?: string;
  limit?: number;
  offset?: number;
  aid?: number;
  categoryId?: number;
  manufacturerId?: number;
  pinned?: boolean;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | StoreOrderStatus.Cancelled | -1 |
 * | StoreOrderStatus.Draft | 0 |
 * | StoreOrderStatus.Ordered | 1 |
 * | StoreOrderStatus.Commissioning | 2 |
 * | StoreOrderStatus.Completed | 3 |
 */

export enum StoreOrderStatus {
  Cancelled = -1,
  Draft = 0,
  Ordered = 1,
  Commissioning = 2,
  Completed = 3
}

export interface StoreOrdersListQueryDto {
  query?: string;
  limit?: number;
  offset?: number;
  aid?: number;
  status?: StoreOrderStatus;
}

export interface StoreConfigDto {
  preferences?: StorePreferencesProperties;
}

export interface StoreUserConfigDto {
  entityId?: number;
  type?: UserType;
  preferences?: StorePreferencesProperties;
}

export interface CacheDumpDto {
  key?: string;
  count?: any;
  size?: any;
  items?: CacheDumpDto[];
}

export interface LogsListResultDto {
  hasMore?: boolean;
  indexStart?: number;
  indexEnd?: number;
  results?: LogEntryDto[];
}

export interface LogsQueryDto {
  query?: string;
  timestampFrom?: string;
  timestampTo?: string;
  offsetTimestamp?: string;
  limit?: number;
  offset?: any;
  level?: LogLevel;
  tag?: LogTags;
}

export interface LogEntryDto {
  id?: string;
  pid?: any;
  timestamp?: string;
  message?: string;
  stack?: string;
  duration?: any;
  auth?: any;
  meta?: string;
  handler?: any;
  job?: any;
  statusCode?: any;
  rateLimiter?: any;
  kennung?: string;
  level?: LogLevel;
  tags?: LogTags[];
  subEntries?: LogEntryDto[];
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MetricAggregation.Avg | avg |
 * | MetricAggregation.Twa | twa |
 * | MetricAggregation.Sum | sum |
 * | MetricAggregation.Count | count |
 * | MetricAggregation.Min | min |
 * | MetricAggregation.Max | max |
 */

export enum MetricAggregation {
  Avg = 'avg',
  Twa = 'twa',
  Sum = 'sum',
  Count = 'count',
  Min = 'min',
  Max = 'max'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MetricsInterval.Live | live |
 * | MetricsInterval.Hour | hour |
 * | MetricsInterval.Day | day |
 * | MetricsInterval.Week | week |
 * | MetricsInterval.Month | month |
 */

export enum MetricsInterval {
  Live = 'live',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MetricsKey.Cpu | cpu |
 * | MetricsKey.Memory | memory |
 * | MetricsKey.Requests | requests |
 * | MetricsKey.Database | database |
 */

export enum MetricsKey {
  Cpu = 'cpu',
  Memory = 'memory',
  Requests = 'requests',
  Database = 'database'
}

export interface MetricsResponseDto {
  key?: MetricsKey;
  data?: MetricSeriesDto[];
}

export interface MetricSeriesDto {
  timestamp?: any;
  avg?: any;
  min?: any;
  max?: any;
  sum?: any;
  count?: any;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MailCategory.MailTemplate | MLMail |
 */

export enum MailCategory {
  MailTemplate = 'MLMail'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | PushSettingDeviceType.Android | a |
 * | PushSettingDeviceType.Ios | i |
 */

export enum PushSettingDeviceType {
  Android = 'a',
  Ios = 'i'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SendMailEncoding.Utf8 | UTF8 |
 */

export enum SendMailEncoding {
  Utf8 = 'UTF8'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SendMailType.Html | html |
 * | SendMailType.Text | text |
 */

export enum SendMailType {
  Html = 'html',
  Text = 'text'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | MailTemplateType.ActivatePatient | activate_patient |
 * | MailTemplateType.ActivateDoctor | activate_doctor |
 * | MailTemplateType.DoctorFirstLogin | doctor_first_login |
 * | MailTemplateType.Reactivate | reactivate |
 * | MailTemplateType.ReactivatePatient | reactivate_patient |
 * | MailTemplateType.ReactivateDoctor | reactivate_doctor |
 * | MailTemplateType.ResetPassword | reset_password |
 * | MailTemplateType.ResetPasswordMissingSmsNumber | reset_password_missing_sms |
 * | MailTemplateType.NewPassword | new_pwd |
 * | MailTemplateType.ResendWelcome | reactivate_text |
 * | MailTemplateType.ResendWelcomePatient | reactivate_text_patient |
 * | MailTemplateType.ResendWelcomeDoctor | reactivate_text_doctor |
 * | MailTemplateType.NewDeviceId | new_deviceid |
 * | MailTemplateType.DeviceIdActivated | deviceid_activated |
 * | MailTemplateType.DeviceIdDeactivated | deviceid_deactivated |
 * | MailTemplateType.NewReport | new_report |
 * | MailTemplateType.PathologicalResult | pathological_result |
 * | MailTemplateType.CriticalResult | critical_result |
 * | MailTemplateType.CustomPush | custom_push |
 * | MailTemplateType.SendReport | send_report |
 * | MailTemplateType.SendReportCopyToMe | copy_to_me |
 * | MailTemplateType.AutomaticReportSend | automatic_report_send |
 * | MailTemplateType.PasswordPdf | pwd_pdf |
 * | MailTemplateType.AcceptedOrder | acepted_order |
 * | MailTemplateType.NewOrderLS | new_order |
 * | MailTemplateType.MessageToLab | message_to_lab |
 * | MailTemplateType.ChangeOrderRequest | request_for_req |
 * | MailTemplateType.WebshopOrderConfirmation | webshop_order_confirmation |
 * | MailTemplateType.SampleInLaborNotification | sample_in_labor_notification |
 * | MailTemplateType.RealRerequestApplied | ena_applied |
 * | MailTemplateType.RealRerequestMade | ena_made |
 * | MailTemplateType.RealRerequestStatement | ena_statement |
 * | MailTemplateType.NewStoreOrderInfo | new_store_order |
 * | MailTemplateType.NewStoreOrderConfirmation | new_store_order_confirmation |
 * | MailTemplateType.CompletedStoreOrder | completed_store_order |
 * | MailTemplateType.ContactForm | contact_form |
 * | MailTemplateType.Notification | notification |
 * | MailTemplateType.RequestToLab | request_to_lab |
 * | MailTemplateType.RequestToLabMobile | request_to_lab_mobile |
 * | MailTemplateType.RequestToLabMobileConfirmation | request_to_lab_mobile_authenticate |
 */

export enum MailTemplateType {
  ActivatePatient = 'activate_patient',
  ActivateDoctor = 'activate_doctor',
  DoctorFirstLogin = 'doctor_first_login',
  Reactivate = 'reactivate',
  ReactivatePatient = 'reactivate_patient',
  ReactivateDoctor = 'reactivate_doctor',
  ResetPassword = 'reset_password',
  ResetPasswordMissingSmsNumber = 'reset_password_missing_sms',
  NewPassword = 'new_pwd',
  ResendWelcome = 'reactivate_text',
  ResendWelcomePatient = 'reactivate_text_patient',
  ResendWelcomeDoctor = 'reactivate_text_doctor',
  NewDeviceId = 'new_deviceid',
  DeviceIdActivated = 'deviceid_activated',
  DeviceIdDeactivated = 'deviceid_deactivated',
  NewReport = 'new_report',
  PathologicalResult = 'pathological_result',
  CriticalResult = 'critical_result',
  CustomPush = 'custom_push',
  SendReport = 'send_report',
  SendReportCopyToMe = 'copy_to_me',
  AutomaticReportSend = 'automatic_report_send',
  PasswordPdf = 'pwd_pdf',
  AcceptedOrder = 'acepted_order',
  NewOrderLS = 'new_order',
  MessageToLab = 'message_to_lab',
  ChangeOrderRequest = 'request_for_req',
  WebshopOrderConfirmation = 'webshop_order_confirmation',
  SampleInLaborNotification = 'sample_in_labor_notification',
  RealRerequestApplied = 'ena_applied',
  RealRerequestMade = 'ena_made',
  RealRerequestStatement = 'ena_statement',
  NewStoreOrderInfo = 'new_store_order',
  NewStoreOrderConfirmation = 'new_store_order_confirmation',
  CompletedStoreOrder = 'completed_store_order',
  ContactForm = 'contact_form',
  Notification = 'notification',
  RequestToLab = 'request_to_lab',
  RequestToLabMobile = 'request_to_lab_mobile',
  RequestToLabMobileConfirmation = 'request_to_lab_mobile_authenticate'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | PushResult.Success | 0 |
 * | PushResult.GeneralError | 1 |
 * | PushResult.DeviceRegistrationExpired | 2 |
 * | PushResult.DataError | 3 |
 */

export enum PushResult {
  Success = 0,
  GeneralError = 1,
  DeviceRegistrationExpired = 2,
  DataError = 3
}

export interface NotificationGeneralConfigDTO {
  preferences?: NotificationsPreferencesProperties;
  smsSettings?: SendSmsSettings;
}

export interface NotificationUserConfigDTO {
  preferences?: NotificationsPreferencesProperties;
}

export interface ListNotificationsDto {
  results?: Notification[];
}

export interface MessenteDeliveryReportDTO {
  stat?: string;
  err?: string;
}

export interface NotificationCountDTO {
  /**
   * Unread notification count
   */
  count?: number;
}

export interface ListNotificationLogsQueryDto {
  /**
   * Query string
   */
  query?: string;
  limit?: number;
  offset?: number;
  timestampFrom?: string;
  timestampTo?: string;
  type?: NotificationType;
  method?: NotificationMethod;
}

export interface ListNotificationLogsDto {
  id?: number;
  title?: string;
  body?: string;
  data?: any;
  type?: NotificationType;
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | NotificationMethod.Mail | 0 |
 * | NotificationMethod.Push | 1 |
 * | NotificationMethod.Sms | 2 |
 */

export enum NotificationMethod {
  Mail = 0,
  Push = 1,
  Sms = 2
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | NotificationStatus.Unread | 0 |
 * | NotificationStatus.Read | 1 |
 * | NotificationStatus.Invalid | 2 |
 * | NotificationStatus.Dismissed | 3 |
 */

export enum NotificationStatus {
  Unread = 0,
  Read = 1,
  Invalid = 2,
  Dismissed = 3
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | NotificationType.NewExchangeFile | NewExchangeFile |
 * | NotificationType.NewReport | NewReport |
 * | NotificationType.NewReportPathologicalResult | NewReportPathologicalResult |
 * | NotificationType.NewReportExtremeResult | NewReportExtremeResult |
 * | NotificationType.NewMessage | NewMessage |
 */

export enum NotificationType {
  NewExchangeFile = 'NewExchangeFile',
  NewReport = 'NewReport',
  NewReportPathologicalResult = 'NewReportPathologicalResult',
  NewReportExtremeResult = 'NewReportExtremeResult',
  NewMessage = 'NewMessage'
}


/**
 * | Enum | Value |
 * | --- | --- |
 * | SendSmsStatus.Failed | -1 |
 * | SendSmsStatus.Initialized | 0 |
 * | SendSmsStatus.Sent | 1 |
 * | SendSmsStatus.Delivered | 2 |
 */

export enum SendSmsStatus {
  Failed = -1,
  Initialized = 0,
  Sent = 1,
  Delivered = 2
}

export interface AuthenticationLoginRequest {
  /**
   * User Name
   */
  kennung: string | null;
  /**
   * User Password
   */
  password: string;
}

export interface AuthenticationCreateTokenResponse {
  /**
   * Issued at
   */
  iat?: number;
  /**
   * Expires at
   */
  exp?: number;
  user?: TokenUserProperties;
}

export interface AuthenticationCreateTokenRequest {
  /**
   * User Name
   */
  kennung: string | null;
  /**
   * User Password
   */
  password: string;
}

export interface AuthenticationVerifyTokenRequest {
  /**
   * The JSON Web Token to verify
   */
  token: string;
}

export interface AuthenticationCreateLegacyTokenRequest {
  aid: string;
  key: string;
}

export interface AuthenticationExchangeTokenRequest {
  /**
   * The JSON Web Token to verify
   */
  token: string;
  token2fa?: string;
}

export interface AuthenticationCreateLabconnectRegistrationTokenResponse {
  /**
   * Issued at
   */
  iat?: number;
  /**
   * Expires at
   */
  exp?: number;
  user?: TokenUserProperties;
}

export interface AuthenticationCreateLabconnectRegistrationTokenRequest {
  /**
   * Token expiration time in hours
   */
  expiration?: number;
  /**
   * UserID for specific Meta Data Table
   */
  entityId: number;
  entityType?: UserType;
}

export interface AuthenticationRequestPasswordResetRequest {
  /**
   * User Name
   */
  kennung: string | null;
}

export interface AuthenticationChangePasswordRequest {
  /**
   * User Password
   */
  oldPassword: string;
  /**
   * User Password
   */
  newPassword: string;
}

export interface AuthenticationResetPasswordRequest {
  /**
   * Password reset token
   */
  token: string;
  /**
   * User Password
   */
  password: string;
  code?: string;
  birthday?: string;
}

export interface AuthenticationCheckPasswordRequest {
  /**
   * User Password
   */
  password: string;
}

export interface AuthenticationInitializeTwoFactorAuthenticationResponse {
  secret?: string;
  qrcode?: string;
}

export interface AuthenticationEnableTwoFactorAuthenticationRequest {
  token2fa?: string;
  secret?: string;
}

export interface AuthenticationDisableTwoFactorAuthenticationRequest {
  token2fa?: string;
  secret?: string;
}

export interface GlobalsUpdateConfigRequest {
  system?: SystemSettingProperties;
  locale?: LanguageCode;
  preferences?: PreferencesProperties;
}

export interface GlobalsGetAutocompleteResponse {
  patients?: Patient[];
  doctors?: Doctor[];
  analyses?: DistinctAnalysisProperties[];
}

export interface GlobalsGetAutocompleteRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * List patients
   */
  patients?: boolean;
  /**
   * List doctors
   */
  doctors?: boolean;
  /**
   * List analyses
   */
  analysesShort?: boolean;
  /**
   * List analyses
   */
  analysesAll?: boolean;
  lanr?: boolean;
}

export interface GlobalsGetServerEnvResponse {
  env?: {
    [k: string]: any;
  };
}

export interface GlobalsGetVersionBadgeRequest {
  /**
   * Compare with version
   */
  compare?: string;
}

export interface GlobalsGetWhatsappQrCodeRequest {
  id: number;
}

export interface GlobalsSendContactFormRequest {
  /**
   * Subject
   */
  subject?: string;
  /**
   * Message
   */
  message?: string;
}

export interface OrdersDashboardGetDevelopmentResponse {
  orderDevelopment?: NumberValue[];
}

export interface OrdersDashboardGetDevelopmentRequest {
  interval?: Interval;
}

export interface OrdersDashboardGetTopRequestsResponse {
  topRequests?: NumberValue[];
}

export interface OrdersDashboardGetTopRequestsRequest {
  /**
   * Interval in days
   */
  interval?: number;
}

export interface OrdersDashboardGetTopUsersResponse {
  topUsers?: NumberValue[];
}

export interface OrdersDashboardGetTopUsersRequest {
  /**
   * Interval in days
   */
  interval?: number;
}

export interface ReportsDashboardGetDemographicResponse {
  male?: NumberMinMaxRangeValue[];
  female?: NumberMinMaxRangeValue[];
}

export interface ReportsDashboardGetTopGroupsResponse {
  groups?: NumberValue[];
}

export interface ReportsDashboardGetTopGroupsRequest {
  /**
   * Interval in days
   */
  days?: number;
}

export interface ReportsDashboardGetTypesResponse {
  /**
   * Extreme reports
   */
  extreme?: number;
  /**
   * Pathological reports
   */
  pathological?: number;
  /**
   * Normal reports
   */
  normal?: number;
}

export interface ReportsDashboardGetTypesRequest {
  /**
   * Interval in days
   */
  days?: number;
}

export interface ExchangeDashboardGetFileTypesOverviewRequest {
  /**
   * Interval in days
   */
  days?: number;
}

export interface ExchangeDashboardGetExportHistoryRequest {
  /**
   * Interval in days
   */
  days?: number;
}

export interface ExchangeDashboardGetLaboratoryGroupDistributionRequest {
  /**
   * Interval in days
   */
  days?: number;
}

export interface DashboardGetDefaultDashboardDataResponse {
  reportCount?: number;
  orderCount?: number;
  topReports?: Report[];
  topNotifications?: Notification[];
  topMessageThreads?: MessageThread[];
  topOrders?: ComposedOrderProperties[];
}

export interface UsersActivateUserRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  type?: UserType;
}

export interface UsersReactivateUserRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  type?: UserType;
}

export interface UsersDeactivateUserRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  type?: UserType;
}

export interface UsersResendWelcomeMailRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  type?: UserType;
}

export interface UsersEnableDebugForUserRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  type?: UserType;
}

export interface UsersSetUserApiDocsRequest {
  id?: number;
  enabled?: boolean;
}

export interface UsersDisableTwoFactorAuthForUserRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  type?: UserType;
}

export interface UsersChangeUsernameRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  /**
   * User Name
   */
  kennung?: string | null;
  type?: UserType;
}

export interface UsersChangeUserPasswordRequest {
  /**
   * ID of the User (type specific)
   */
  id?: number;
  /**
   * User Password
   */
  password?: string;
  type?: UserType;
}

export interface UsersGetUserLogRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * ID of the User (type specific)
   */
  entityId?: number;
  offset?: number;
  limit?: number;
  action?: UserLogAction;
  entityType?: UserType;
}

export interface ProfileUpdateUserProfileRequest {
  /**
   * User E-Mail
   */
  email?: string;
  /**
   * User SMS Number
   */
  smsNumber?: string;
  /**
   * User Website
   */
  www?: string;
}

export interface ProfileUpdateAvatarUserProfileRequest {
  /**
   * Avatar Image
   */
  avatar?: {
    [k: string]: any;
  } | null;
}

export interface ProfileGetUserAvatarRequest {
  id: number;
}

export interface ProfileGetVcardQrCodeRequest {
  id: number;
  type?: UserType;
}

export interface ProfileGetVcardRequest {
  id: number;
  type?: UserType;
}

export interface DevicesListDevicesRequest {
  /**
   * Query string
   */
  query?: string;
  entityId?: number;
  offset?: number;
  limit?: number;
  status?: DeviceStatus;
  entityType?: UserType;
}

export interface DevicesCreateDeviceRequest {
  name: string;
  entityId: number;
  entityType?: UserType;
}

export interface DevicesDeleteDeviceRequest {
  id: number;
}

export interface DevicesApproveDeviceRequest {
  id: number;
}

export interface DevicesDenyDeviceRequest {
  id: number;
}

export interface DevicesRevokeDeviceRequest {
  id: number;
}

export interface DevicesSetDeviceNoteRequest {
  note?: string;
  id: number;
}

export interface DevicesSetPushTokenResponse {}

export interface DevicesSetPushTokenRequest {
  pushToken: string;
}

export interface DevicesCreateDeviceTokenResponse {
  token?: string;
}

export interface AdministratorsListAdministratorsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Excluded IDs
   */
  excluded?: number[];
  offset?: number;
  limit?: number;
  status?: UserStatus;
}

export interface AdministratorsCreateAdministratorRequest {
  /**
   * User Name
   */
  kennung: string | null;
  /**
   * User Password
   */
  password: string;
  /**
   * E-Mail
   */
  email?: string;
}

export interface AdministratorsReadAdministratorRequest {
  /**
   * Administrator ID
   */
  suid: number;
}

export interface AdministratorsUpdateAdministratorRequest {
  /**
   * E-Mail
   */
  email?: string;
  /**
   * Administrator ID
   */
  suid: number;
}

export interface AdministratorsRemoveAdministratorRequest {
  /**
   * Administrator ID
   */
  suid: number;
}

export interface DoctorHierarchyGetDoctorHierarchyListRequest {
  parent?: number | null;
}

export interface DoctorHierarchyAssignDoctorHierarchyUserRequest {
  parent?: number | null;
  doctor?: Doctor;
  type?: DoctorHierarchyType;
}

export interface DoctorHierarchyCreateDoctorHierarchyUserRequest {
  parent?: number | null;
  doctor?: DoctorCreateProperties;
  type?: DoctorHierarchyType;
}

export interface DoctorHierarchyGetDoctorHierarchyTreeRequest {
  aid?: number;
}

export interface DoctorHierarchyReadDoctorHierarchyRequest {
  id: number;
}

export interface DoctorHierarchyRemoveDoctorHierarchyUserRequest {
  id: number;
}

export interface DoctorHierarchyUpdateDoctorHierarchyUserRequest {
  id: number;
  type?: DoctorHierarchyType;
}

export interface DoctorsListDoctorsRequest {
  /**
   * Query string
   */
  query?: string;
  hierarchyStatus?: number | null;
  /**
   * Excluded IDs
   */
  excluded?: number[];
  excludeInactive?: boolean;
  aid?: number;
  lanr?: string;
  public?: boolean;
  offset?: number;
  limit?: number;
  status?: UserStatus;
  product?: Product;
}

export interface DoctorsCreateDoctorResponse {}

export interface DoctorsCreateDoctorRequest {
  kennung?: string | null;
  sendMail?: boolean;
  doctor?: DoctorCreateProperties;
}

export interface DoctorsReadDoctorRequest {
  /**
   * Doctor ID
   */
  aid: number;
  /**
   * if true, include number of reports of doctor
   */
  reportCount?: boolean;
}

export interface DoctorsUpdateDoctorRequest {
  /**
   * External Doctor ID (unique identification for laboratory system)
   */
  externalId?: string | null;
  email?: string | null;
  /**
   * Salutation
   */
  salutation?: string | null;
  /**
   * Title
   */
  title?: string | null;
  /**
   * Last Name
   */
  lastName?: string;
  /**
   * First Name
   */
  firstName?: string | null;
  /**
   * Fax Number
   */
  faxNumber?: string | null;
  /**
   * Phone Number
   */
  phoneNumber?: string | null;
  /**
   * Phone Number
   */
  smsNumber?: string | null;
  address?: string | null;
  houseNumber?: string | null;
  zipCode?: string | null;
  city?: string | null;
  /**
   * Website
   */
  www?: string | null;
  welcomeLabel?: string | null;
  logoutLabel?: string | null;
  /**
   * Physician identifier in Germany - "Lebenslange Arztnummer"
   */
  lanr?: string | null;
  /**
   * BSNR from OA
   */
  bsnr?: string | null;
  exportMeReceiver?: string | null;
  /**
   * Product OA explicitly enabled/disabled
   */
  oa?: boolean;
  /**
   * Product OB explicitly enabled/disabled
   */
  ob?: boolean;
  /**
   * Product OC explicitly enabled/disabled
   */
  of?: boolean;
  /**
   * Product LL explicitly enabled/disabled
   */
  ll?: boolean;
  /**
   * Product LE explicitly enabled/disabled
   */
  le?: boolean;
  /**
   * Product LS explicitly enabled/disabled
   */
  ls?: boolean;
  /**
   * Product LC explicitly enabled/disabled
   */
  lc?: boolean;
  /**
   * Product DD explicitly enabled/disabled
   */
  dd?: boolean;
  /**
   * Product MC explicitly enabled/disabled
   */
  mc?: boolean;
  /**
   * Product NC explicitly enabled/disabled
   */
  nc?: boolean;
  /**
   * Product LW explicitly enabled/disabled
   */
  lw?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  admin?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  dashboard?: boolean;
  /**
   * { [feature key]: enabled }
   */
  features?: {
    [k: string]: any;
  };
  /**
   * Doctor´s group office name
   */
  officeName?: string;
  /**
   * Doctor ID
   */
  aid: number;
  localisation?: OrderWizardLocalization | null;
  defaultInvoiceTo?: StdPayer | null;
  hierarchyStatus?: DoctorHierarchyStatus | null;
}

export interface DoctorsDeleteDoctorRequest {
  /**
   * Doctor ID
   */
  aid: number;
}

export interface DoctorsGetDoctorgroupsForDoctorRequest {
  /**
   * Doctor ID
   */
  aid: number;
  /**
   * Query string
   */
  query?: string;
}

export interface DoctorsSetDoctorgroupsForDoctorRequest {
  aid: number;
  membership?: DoctorGroupMembershipProperties[];
}

export interface DoctorDevicesListDoctorDevicesRequest {
  aid: number;
  offset?: number;
  limit?: number;
}

export interface DoctorDevicesUpdateDoctorDevicesRequest {
  /**
   * Doctor ID
   */
  aid: number;
  items?: DoctorDevice[];
}

export interface DoctorDevicesUpdateDoctorDeviceRequest {
  /**
   * Device ID
   */
  deviceId?: string;
  active?: boolean;
  /**
   * Date of registration
   */
  registeredAt?: string;
  /**
   * Device Note
   */
  note?: string;
  user_agent_id?: number;
  id: number;
  /**
   * Doctor ID
   */
  aid: number;
}

export interface DoctorDevicesDeleteDoctorDeviceRequest {
  /**
   * Doctor ID
   */
  aid: number;
  id: number;
}

export interface LaboratoriesListLaboratoriesRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Excluded IDs
   */
  excluded?: number[];
  excludeInactive?: boolean;
  offset?: number;
  limit?: number;
  status?: UserStatus;
  product?: Product;
}

export interface LaboratoriesCreateLaboratoryRequest {
  /**
   * HL7-Name (short code for import / export)
   */
  hl7Name: string;
  /**
   * Normalized name to display
   */
  displayName: string;
  /**
   * User Name
   */
  kennung: string | null;
  /**
   * User Password
   */
  password: string;
  /**
   * App target id (de.labuniq....)
   */
  target?: string | null;
  /**
   * Normalized email
   */
  email?: string;
}

export interface LaboratoriesReadLaboratoryRequest {
  /**
   * Laboratory ID
   */
  lid: number;
}

export interface LaboratoriesUpdateLaboratoryRequest {
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * App target id (de.labuniq....)
   */
  target?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Normalized email
   */
  email?: string;
  /**
   * Email for OrderRequests
   */
  rerequestMail?: string | null;
  /**
   * Login Site
   */
  loginSite?: string | null;
  /**
   * Product OA explicitly enabled/disabled
   */
  oa?: boolean;
  /**
   * Product OB explicitly enabled/disabled
   */
  ob?: boolean;
  /**
   * Product OC explicitly enabled/disabled
   */
  of?: boolean;
  /**
   * Product LL explicitly enabled/disabled
   */
  ll?: boolean;
  /**
   * Product LE explicitly enabled/disabled
   */
  le?: boolean;
  /**
   * Product LS explicitly enabled/disabled
   */
  ls?: boolean;
  /**
   * Product LC explicitly enabled/disabled
   */
  lc?: boolean;
  /**
   * Product DD explicitly enabled/disabled
   */
  dd?: boolean;
  /**
   * Product MC explicitly enabled/disabled
   */
  mc?: boolean;
  /**
   * Product NC explicitly enabled/disabled
   */
  nc?: boolean;
  /**
   * Product LW explicitly enabled/disabled
   */
  lw?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  admin?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  dashboard?: boolean;
  logo?: string;
  fontColor?: string;
  backgroundColor?: string;
  highlightColor?: string;
  /**
   * which barcode algorithm to use e.g. I25, EAN8, POSTNET...
   */
  barcodeAlgorithm?: string;
  /**
   * length of the barcode
   */
  barcodeLength?: number;
  /**
   * width of barcode in mm
   */
  barcodeWidth?: number;
  /**
   * height of barcode in mm
   */
  barcodeHeight?: number;
  /**
   * width of barcode sticker in mm
   */
  labelWidth?: number;
  /**
   * height of barcode sticker in mm
   */
  labelHeight?: number;
  /**
   * Laboratory ID
   */
  lid: number;
  orderLocalization?: OrderWizardLocalization;
}

export interface LaboratoriesUploadLaboratoryLogoRequest {
  logo?: {
    [k: string]: any;
  };
  lid: number;
}

export interface LaboratoriesGetLaboratoryDetailsResponse {
  details?: Laboratory;
}

export interface LaboratoriesGetLaboratoryDetailsRequest {
  /**
   * Laboratory ID
   */
  lid: number;
}

export interface LaboratoriesUpdateLaboratoryDetailsResponse {}

export interface LaboratoriesUpdateLaboratoryDetailsRequest {
  /**
   * HL7-Name (short code for import / export)
   */
  hl7Name?: string;
  /**
   * First Name
   */
  firstName?: string | null;
  /**
   * Title
   */
  title?: string | null;
  /**
   * Suffix
   */
  suffix?: string | null;
  /**
   * Phone number
   */
  phone?: string | null;
  /**
   * Whatsapp kontakt
   */
  whatsapp?: string | null;
  /**
   * Fax number
   */
  fax?: string | null;
  /**
   * Website
   */
  www?: string | null;
  /**
   * Address
   */
  address?: string | null;
  /**
   * Zip + City
   */
  city?: string | null;
  /**
   * Normalized email
   */
  email?: string;
  /**
   * Email for OrderRequests
   */
  rerequestMail?: string | null;
  /**
   * Login Site
   */
  loginSite?: string | null;
  /**
   * Additional Detail 1
   */
  additional1?: string | null;
  /**
   * Additional Detail 2
   */
  additional2?: string | null;
  /**
   * Additional Detail 3
   */
  additional3?: string | null;
  /**
   * Postal Code
   */
  zipCode?: string | null;
  /**
   * Contact Information
   */
  contactInfo?: string | null;
  /**
   * Laboratory ID
   */
  lid: number;
}

export interface LaboratoriesGetLaboratoryPermissionsResponse {
  permissions?: LaboratoryPermissionsProperties;
}

export interface LaboratoriesGetLaboratoryPermissionsRequest {
  /**
   * Laboratory ID
   */
  lid: number;
}

export interface LaboratoriesUpdateLaboratoryPermissionsResponse {
  /**
   * Laboratory ID
   */
  lid?: number;
  permissions?: LaboratoryPermissionsProperties;
}

export interface LaboratoriesUpdateLaboratoryPermissionsRequest {
  /**
   * Laboratory ID
   */
  lid: number;
  permissions?: LaboratoryPermissionsProperties;
}

export interface LaboratoryUsersListLaboratoryUsersRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Excluded IDs
   */
  excluded?: number[];
  excludeInactive?: boolean;
  offset?: number;
  limit?: number;
  status?: UserStatus;
  product?: Product;
}

export interface LaboratoryUsersCreateLaboratoryUserRequest {
  /**
   * Normalized name to display
   */
  displayName: string;
  /**
   * User Name
   */
  kennung: string | null;
  /**
   * User Password
   */
  password: string;
}

export interface LaboratoryUsersReadLaboratoryUserRequest {
  /**
   * LaboratoryUser ID
   */
  luid: number;
}

export interface LaboratoryUsersUpdateLaboratoryUserRequest {
  /**
   * User Name
   */
  kennung?: string | null;
  /**
   * Normalized name to display
   */
  displayName?: string;
  /**
   * Laboratory ID
   */
  luid: number;
  privilegeMode?: LaboratoryUserPrivilegeMode;
}

export interface LaboratoryUsersDeleteLaboratoryUserRequest {
  /**
   * LaboratoryUser ID
   */
  luid: number;
}

export interface LaboratoryUsersGetLaboratoryUserPermissionsResponse {
  permissions?: LaboratoryPermissionsProperties;
}

export interface LaboratoryUsersGetLaboratoryUserPermissionsRequest {
  /**
   * LaboratoryUser ID
   */
  luid: number;
}

export interface LaboratoryUsersUpdateLaboratoryUserPermissionsResponse {
  /**
   * Laboratory ID
   */
  luid?: number;
  permissions?: LaboratoryPermissionsProperties;
}

export interface LaboratoryUsersUpdateLaboratoryUserPermissionsRequest {
  /**
   * LaboratoryUser ID
   */
  luid: number;
  permissions?: LaboratoryPermissionsProperties;
}

export interface PatientsListPatientsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Excluded IDs
   */
  excluded?: number[];
  aid?: number;
  pid?: number;
  excludeInactive?: boolean;
  insuranceNumber?: string;
  birthday?: string;
  offset?: number;
  limit?: number;
  status?: UserStatus;
  product?: Product;
}

export interface PatientsCreatePatientRequest {
  /**
   * Patient Last Name
   */
  lastName: string;
  /**
   * Patient First Name
   */
  firstName: string;
  /**
   * Patient Email Address
   */
  email: string;
  /**
   * Patient Title
   */
  title?: string;
  /**
   * Patient Birthday
   */
  birthdate?: string;
  /**
   * Patient Zip Code
   */
  zipCode?: string;
  /**
   * street name
   */
  address?: string;
  /**
   * street number
   */
  houseNumber?: string;
  /**
   * Patient City
   */
  city?: string;
  /**
   * Patient Insurance Number
   */
  insuranceNumber?: string;
  sex?: Gender;
}

export interface PatientsReadPatientRequest {
  /**
   * Patient ID
   */
  pid: number;
}

export interface PatientsUpdatePatientRequest {
  /**
   * Patient Email Address
   */
  email?: string;
  /**
   * Patient Phone Number
   */
  phone?: string;
  /**
   * "befundsprache"
   */
  resultLanguage?: string;
  /**
   * whether the patient can view partial results
   */
  canViewPartialResults?: boolean;
  /**
   * whether the patient can view preliminary results
   */
  canViewPreliminaryResults?: boolean;
  /**
   * Product OB explicitly enabled/disabled
   */
  ob?: boolean;
  /**
   * Product OC explicitly enabled/disabled
   */
  of?: boolean;
  /**
   * Product LL explicitly enabled/disabled
   */
  ll?: boolean;
  /**
   * Product LE explicitly enabled/disabled
   */
  le?: boolean;
  /**
   * Product LS explicitly enabled/disabled
   */
  ls?: boolean;
  /**
   * Product MC explicitly enabled/disabled
   */
  mc?: boolean;
  /**
   * Product NC explicitly enabled/disabled
   */
  nc?: boolean;
  /**
   * Product LW explicitly enabled/disabled
   */
  lw?: boolean;
  /**
   * Product Admin explicitly enabled/disabled
   */
  dashboard?: boolean;
  /**
   * { [feature key]: enabled }
   */
  features?: {
    [k: string]: any;
  };
  /**
   * Patient ID
   */
  pid: number;
}

export interface PatientsRemovePatientRequest {
  /**
   * Patient ID
   */
  pid: number;
}

export interface PatientsReadPatientByIdentificationRequest {
  /**
   * patient ID
   */
  pident: string;
}

export interface ReportsListReportsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  laboratoryGroup?: string;
  withoutLaboratoryGroups?: string[];
  /**
   * Patient ID
   */
  pid?: number;
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * Number of report in laboratory
   */
  tnr?: string;
  /**
   * additional number of report , display if it is there
   */
  exttnr?: string;
  /**
   * Show only pathological reports
   */
  pathological?: boolean;
  /**
   * Show only corrected reports
   */
  corrected?: boolean;
  /**
   * Show only pinned reports
   */
  pinned?: boolean;
  /**
   * Show only reports of today
   */
  today?: boolean;
  locked?: boolean;
  lanr?: string;
  /**
   * KH Mode fields
   */
  caseNumber?: string;
  insuranceNumber?: string;
  caseId?: number;
  dateFrom?: string;
  dateTo?: string;
  doctorName?: string;
  loadAisFirstname?: string;
  loadAisLastname?: string;
  loadAisBirthdate?: string;
  loadAisShowReport?: boolean;
  birthday?: string;
  created_at?: string;
  imported_at?: string;
  sampled_at?: string;
  registered_at?: string;
  offset?: number;
  limit?: number;
  unread?: ReadStatusType;
  gender?: Gender;
  characteristic?: ReportCharacteristic;
  reportType?: GeneralReportFilterType;
  advancedAnalysisFilter?: AdvancedAnalysisFilter[];
  requirement?: AdvancedAnalysisFilter;
  status?: ReportStatus;
}

export interface ReportsDeleteAllReportsForAUserRequest {
  /**
   * Doctor ID
   */
  aid: number;
}

export interface ReportsGetReportsConfigResponse {
  allergyClasses?: number[];
  preferences?: ReportsPreferencesProperties;
  reportDisplay?: ReportDisplaySettings;
  patients?: ReportsGeneralPatientsConfig;
  lanr?: LanrPreferenceProperties;
  doctor?: ReportsDoctorSettings;
}

export interface ReportsGetQuickAccessPdfRequest {
  firstname?: string;
  lastname?: string;
  order_number?: string;
  zip?: string;
  birthday?: string;
  index?: number;
}

export interface ReportsRegisterQuickAccessEmailRequest {
  email?: string;
  /**
   * The JSON Web Token to verify
   */
  token: string;
}

export interface ReportsReadReportByGuidResponse {
  headerValue?: string;
  key?: string;
  report?: Report;
  highlight?: GuidReportValueHighlightColor;
  pdfConfig?: GuidPdfConfig;
}

export interface ReportsReadReportByGuidRequest {
  /**
   * Access Key
   */
  key?: string;
  /**
   * Report Globally Unique Identifier
   */
  guid: string;
}

export interface ReportsNotifyReportByGuidRequest {
  /**
   * Access Key
   */
  key?: string;
  /**
   * Device ID or email address
   */
  deviceId: string;
  /**
   * Report Globally Unique Identifier
   */
  guid: string;
}

export interface ReportsGetReportPdfByGuidRequest {
  /**
   * Access Key
   */
  key?: string;
  /**
   * Formatted patient name
   */
  lastName?: string;
  /**
   * First name of Patient at the time of the report import
   */
  firstName?: string;
  /**
   * First name of Patient at the time of the report import
   */
  zipCode?: string;
  /**
   * Patient Birthday
   */
  birthday?: string;
  idCardNumber?: string;
  passportNumber?: string;
  /**
   * Order password for quick access
   */
  password?: string;
  /**
   * Report Globally Unique Identifier
   */
  guid: string;
}

export interface ReportsImportIdexxReportRequest {
  /**
   * Unique transaction ID from Idexx system, 128-bit UUID as defined in RFC 4122, e.g. 123e4567-e89b-12d3-a456-426614174000 (not printed on the report, part of the report rendered filename)
   */
  transaction_uuid: string;
  /**
   * ID from Idexx system, unique per order, 128-bit UUID as defined in RFC 4122, e.g. 123e4567-e89b-12d3-a456-426614174000 (not printed on the report, part of the report rendered filename)
   */
  order_uuid: string;
  /**
   * Order Creation DateTime from LIS System, Format YYYYMMDDHHMMSS, will not be rendered on the report, is part of LABUNIQ internal report identifiers
   */
  order_created_date: string;
  /**
   * (does not have to be provided, omit this value, internal purpose)
   */
  order_import_date?: string;
  /**
   * Name of the doctor in the laboratory, which was validating the report (mostly at the end of the reports, used like a signature)
   */
  validating_doctor: string;
  /**
   * Additional information of the doctor in the laboratory, which was validating the report; this field is shown after the validating doctors name on the report, will be appended without any modification (no parenthesis add) (opt.)
   */
  validating_doctor_addition?: string;
  /**
   * Comma separated list of samples to show on the report (displayed on the header of the rendered report), will be cut off if it's larger than a certain length
   */
  sample_names: string;
  /**
   * Primary lab order number (ex: Accession number), part of the report filename, displayed on the header of the rendered report
   */
  lab_accessionNumber: string;
  /**
   * Second lab barcode number, just for display purpose (ex: Barcode), displayed on the header of the rendered report
   */
  lab_barcodeId: string;
  /**
   * Idexx special fields for rendering (creation datetime of the report in Idexx systems), DateTime; Format DDMMYYYYHHMMSS, rendered in the footer section (on the left)
   */
  create_lis_date?: string;
  /**
   * Set to true or leave empty to invoke pdf report generation
   */
  create_as_pdf?: boolean;
  /**
   * Set to true to invoke ldt report generation
   */
  create_as_ldt?: boolean;
  order_status?: IdexxReportStatus;
  laboratory_group?: IdexxLaboratoryGroup;
  customer_language_code?: IdexxLangCode;
  legal_entity?: IdexxLegalEntity;
  doctor?: ReportCreateDoctorProperties;
  patient?: ReportIdexxCreatePatientProperties;
  results?: ReportIdexxCreateResultProperties[];
  comments?: ReportIdexxCreateCommentsProperties[];
  electrophoresis?: ReportIdexxCreateElphoProperties[];
  budget?: ReportIdexxCreateBudgetProperties[];
  general_report_comments?: ReportIdexxCreateReportCommentProperties[];
  pdf_attachment?: IdexxPdfAttachment;
  settlement_type?: SettlementType;
}

export interface ReportsListReportsForAisResponse {
  status?: AisListStatus;
  reportIds?: AisReportIdListProperties[];
}

export interface ReportsReadReportForAisResponse {
  reportHl7?: string;
  reportPdf?: string;
  status?: AisReadStatus;
  report?: AisReportProperties;
}

export interface ReportsReadReportForAisRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsSetAllReportsReadRequest {
  endDate?: string;
  /**
   * Doctor ID
   */
  aid: number;
}

export interface ReportsSettingsReadGeneralReportsConfigResponse {
  allergyClasses?: number[];
  preferences?: ReportsPreferencesProperties;
  reportDisplay?: ReportDisplaySettings;
  patients?: ReportsGeneralPatientsConfig;
}

export interface ReportsSettingsUpdateGeneralReportsConfigRequest {
  allergyClasses?: number[];
  preferences?: ReportsPreferencesProperties;
  reportDisplay?: ReportDisplaySettings;
  patients?: ReportsGeneralPatientsConfig;
}

export interface ReportsSettingsReadUserReportsConfigResponse {
  preferences?: ReportsPreferencesProperties;
  lanr?: LanrPreferenceProperties;
  doctor?: ReportsDoctorSettings;
}

export interface ReportsSettingsReadUserReportsConfigRequest {
  entityId: number;
  type?: UserType;
}

export interface ReportsSettingsUpdateUserReportsConfigRequest {
  entityId: number;
  preferences?: ReportsPreferencesProperties;
  lanr?: LanrPreferenceProperties;
  doctor?: ReportsDoctorSettings;
  type?: UserType;
}

export interface ReportsSettingsResetUserReportsPreferencesRequest {
  entityId: number;
  type?: UserType;
}

export interface ReportsSettingsResetUserTypeReportsPreferencesResponse {
  localisation?: OrderWizardLocalization;
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
}

export interface ReportsSettingsResetUserTypeReportsPreferencesRequest {
  entityId?: number;
  type?: UserType;
}

export interface ReportsSettingsUpdateReportAnalysesSettingsRequest {
  settings?: ReportsPdfDownloadAnalysesSettings;
}

export interface ReportsSettingsGetBlacklistSettingsResponse {
  /**
   * Automatic report lock
   */
  autoLockReport?: boolean;
  items?: Blacklist[];
}

export interface ReportsSettingsUpdateBlacklistSettingsResponse {
  /**
   * Automatic report lock
   */
  autoLockReport?: boolean;
  items?: Blacklist[];
}

export interface ReportsSettingsUpdateBlacklistSettingsRequest {
  /**
   * Automatic report lock
   */
  autoLockReport?: boolean;
  items?: Blacklist[];
}

export interface ReportsSettingsGetCustomPushSettingsResponse {
  items?: CustomPush[];
}

export interface ReportsSettingsUpdateCustomPushSettingsResponse {
  items?: CustomPush[];
}

export interface ReportsSettingsUpdateCustomPushSettingsRequest {
  items?: CustomPush[];
}

export interface ReportsSettingsUpdateOriginalReportSettingsRequest {
  settings?: OriginalReportProperties;
}

export interface ReportsSettingsListCustomSortCsvRequest {
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
}

export interface ReportsSettingsImportCustomSortCsvResponse {
  inserted?: number;
  duplicates?: string[];
}

export interface ReportsSettingsImportCustomSortCsvRequest {
  /**
   * Custom Sort Csv File
   */
  file: {
    [k: string]: any;
  };
  /**
   * Input contains header as first line
   */
  withHeader?: boolean;
}

export interface ReportsSettingsReadReportQuickAccessConfigResponse {
  levenshteinAvailable?: boolean;
  config?: ReportsQuickAccessConfig;
  stats?: ReportAccessStats;
}

export interface ReportsSettingsUpdateReportQuickAccessConfigRequest {
  config?: ReportsQuickAccessConfig;
}

export interface ReportsSettingsReadReportQuickScanConfigResponse {
  config?: ReportsQuickScanConfig;
  stats?: ReportAccessStats;
}

export interface ReportsSettingsUpdateReportQuickScanConfigRequest {
  config?: ReportsQuickScanConfig;
}

export interface ReportsCreateFilterProfileRequest {
  /**
   * Filter name
   */
  name?: string;
  settings?: FilterProfileProperties;
}

export interface ReportsUpdateFilterProfileRequest {
  /**
   * Filter name
   */
  name?: string;
  /**
   * Primary key
   */
  pid: number;
  settings?: FilterProfileProperties;
}

export interface ReportsDeleteFilterProfileRequest {
  /**
   * Primary key
   */
  pid: number;
}

export interface ReportsToggleFilterProfileFavoriteRequest {
  /**
   * Primary key
   */
  pid: number;
}

export interface ReportsReadReportRequest {
  /**
   * Report ID
   */
  bid: number;
  wpusername?: string;
}

export interface ReportsDeleteReportRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsGetReportPdfRequest {
  /**
   * Report ID
   */
  bid: number;
  forcePatient?: boolean;
  forceOriginal?: boolean;
  forceOriginalAdditional?: number;
  forceOriginalExclusive?: boolean;
  cumulative?: boolean;
  cumulativeAll?: boolean;
}

export type ReportsGetReportOriginalPdfImagesResponse = string[];

export interface ReportsGetReportOriginalPdfImagesRequest {
  bid: number;
}

export interface ReportsSetReadReportRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsGetReportElphoRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsGetReportHistoryRequest {
  bid: number;
}

export interface ReportsResetReportViewCountRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsSendReportRequest {
  /**
   * Send to patient (email parameter will be ignored)
   */
  sendToPatient?: boolean;
  /**
   * E-Mail Address to send to
   */
  email?: string | null;
  /**
   * Message
   */
  input?: string;
  /**
   * PDF password
   */
  pdfPassword?: string;
  /**
   * Send a copy to the user
   */
  copyToUser?: boolean;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsSendReportApiRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsContactLaboratoryRequest {
  /**
   * Subject
   */
  subject?: string;
  /**
   * Message
   */
  message?: string;
  /**
   * Send a copy to the user
   */
  copyToUser?: boolean;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsUnrealRerequestRequest {
  /**
   * Message
   */
  message?: string;
  /**
   * Send a copy to the user
   */
  copyToUser?: boolean;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsPinReportRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsUnpinReportRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsListReportCommentsResponse {
  comments?: ReportComment[];
}

export interface ReportsListReportCommentsRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsCreateReportCommentResponse {
  comments?: ReportComment[];
}

export interface ReportsCreateReportCommentRequest {
  /**
   * Comment
   */
  data?: string;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsDeleteReportCommentResponse {
  comments?: ReportComment[];
}

export interface ReportsDeleteReportCommentRequest {
  /**
   * Primary key
   */
  id: number;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsListReportAttachmentsResponse {
  attachments?: ReportAttachment[];
}

export interface ReportsListReportAttachmentsRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsUploadAttachmentResponse {
  attachments?: ReportAttachment[];
}

export interface ReportsUploadAttachmentRequest {
  /**
   * Attachment File
   */
  attachment: {
    [k: string]: any;
  };
  /**
   * Description for the file
   */
  description?: string;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsDownloadAttachmentRequest {
  /**
   * Attachment ID
   */
  anid: number;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsDeleteAttachmentResponse {
  attachments?: ReportAttachment[];
}

export interface ReportsDeleteAttachmentRequest {
  /**
   * Attachment ID
   */
  anid: number;
  /**
   * Report ID
   */
  bid: number;
}

export interface ReportsGetReportAccessLogRequest {
  /**
   * Report ID
   */
  bid: number;
  offset?: number;
  limit?: number;
}

export interface ReportsGetReportChangeLogRequest {
  /**
   * Report ID
   */
  bid: number;
  offset?: number;
  limit?: number;
}

export interface ReportsUnlockReportRequest {
  /**
   * Report ID
   */
  bid: number;
}

export interface OrdersGetOrdersConfigResponse {
  costUnits?: string[];
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  laboratoryGroupBarcodeSettings?: LaboratoryGroupBarcodeSettingsProperties[];
  advancedAlphanumericBarcodeSettings?: AdvancedAlphanumericBarcodeProperties[];
  realReRequestSettings?: RealReRequestSettingsProperties;
  diagnoses?: AggregatedDiagnosisProperties[];
  localisation?: OrderWizardLocalization;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
}

export interface OrdersListOrdersRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * ID of doctor
   */
  aid?: number;
  lanr?: string;
  dateFrom?: string;
  dateTo?: string;
  created_at?: string;
  executed_at?: string;
  sampled_at?: string;
  scheduled_at?: string;
  scheduledFrom?: string;
  scheduledTo?: string;
  birthday?: string;
  /**
   * Filter orders by transportation history id
   */
  transportationHistoryId?: number;
  /**
   * number of the report/order in the laboratory, also called barcode or laboratory number
   */
  tnr?: string;
  exttnr?: string | null;
  caseNumber?: string;
  insuranceNumber?: string;
  caseId?: number;
  sampleInLab?: boolean;
  poct?: boolean;
  offset?: number;
  limit?: number;
  status?: OrderStatus;
  mode?: OrderListMode;
  type?: OrderListType;
  digitalSignature?: OrderDigitalSignature;
  bookmarkedMode?: BookmarkedMode;
  bookmarkedSorting?: SortingDirection;
  realReRequestMode?: RealReRequestMode;
  completionOrder?: CompletionListOrderBy;
}

export interface OrdersUpsertOrdersRequest {
  aid: number;
  execute?: boolean;
  prePrint?: boolean;
  ignorePool?: boolean;
  orders?: WriteableOrderProperties[];
  logs?: OrderFrontendLogEntry[];
}

export interface OrdersDeleteOrderRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersScheduleOrdersRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
  scheduled_at?: string | null;
}

export interface OrdersExecuteOrdersRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersPreprintOrdersRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersStartEditOrdersRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersCancelEditOrdersRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersCancelOrdersRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersCancelOrderRequirementRequest {
  orderId: number;
  requirement?: AggregatedRequirementId;
}

export interface OrdersUncancelOrderRequirementRequest {
  orderId: number;
  requirement?: AggregatedRequirementId;
}

export interface OrdersSoftDeleteOrderRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersSignOrdersRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
  workstationId?: number;
}

export interface OrdersExportWithoutPdfRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface OrdersGetOrderByCaseNumberRequest {
  caseNumber: string;
}

export interface OrdersGetOrderByExttnrRequest {
  exttnr: string;
}

export interface OrdersGetOrdersByPatientRequest {
  id: number;
  limit?: number;
  type?: OrderPatientType;
}

export interface TransportationListCreateTransportationRequest {
  order_ids?: number[];
}

export interface TransportationListListTransportationHistoryRequest {
  /**
   * Year of transportation
   */
  year: number;
  /**
   * Month of transportation (zero based)
   */
  month: number;
}

export interface TransportationListExportTransportationRequest {
  /**
   * Primary Key
   */
  id?: number;
}

export interface JournalExportJournalPdfRequest {
  dateFrom: string;
  dateTo: string;
}

export interface OrderSettingsReadGeneralOrdersConfigResponse {
  preferences?: OrdersPreferencesProperties;
  printSettings?: BarcodeSettingsProperties;
  laboratoryGroupBarcodeSettings?: LaboratoryGroupBarcodeSettingsProperties[];
  advancedAlphanumericBarcodeSettings?: AdvancedAlphanumericBarcodeProperties[];
  realReRequestSettings?: RealReRequestSettingsProperties;
  diagnoses?: AggregatedDiagnosisProperties[];
  localisation?: OrderWizardLocalization;
}

export interface OrderSettingsUpdateGeneralOrdersConfigRequest {
  preferences?: OrdersPreferencesProperties;
  printSettings?: BarcodeSettingsProperties;
  laboratoryGroupBarcodeSettings?: LaboratoryGroupBarcodeSettingsProperties[];
  advancedAlphanumericBarcodeSettings?: AdvancedAlphanumericBarcodeProperties[];
  realReRequestSettings?: RealReRequestSettingsProperties;
  diagnoses?: AggregatedDiagnosisProperties[];
  localisation?: OrderWizardLocalization;
}

export interface OrderSettingsReadUserOrdersConfigResponse {
  localisation?: OrderWizardLocalization;
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
}

export interface OrderSettingsReadUserOrdersConfigRequest {
  entityId: number;
  type?: UserType;
}

export interface OrderSettingsUpdateUserOrdersConfigRequest {
  entityId: number;
  localisation?: OrderWizardLocalization;
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
  type?: UserType;
}

export interface OrderSettingsResetUserOrdersPreferencesResponse {
  localisation?: OrderWizardLocalization;
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
}

export interface OrderSettingsResetUserOrdersPreferencesRequest {
  entityId: number;
  type?: UserType;
}

export interface OrderSettingsResetUserTypeOrdersPreferencesResponse {
  localisation?: OrderWizardLocalization;
  preferences?: OrdersPreferencesProperties;
  printSettings?: OrdersPrintSettings;
  printerMapping?: PrinterMapping[];
  formLabelPrinterMapping?: OrdersFormLabelPrinterMapping;
  centralBarcodes?: OrdersCentralBarcodeStatus;
}

export interface OrderSettingsResetUserTypeOrdersPreferencesRequest {
  entityId?: number;
  type?: UserType;
}

export interface OrderSettingsUpdateOrderExportSettingsRequest {
  settings?: OrderExportSettings;
  furtherFields?: OrderFurtherFields;
  austriaFields?: OrderAustriaFields;
  microbiologyFields?: OrderMicrobiologyFields;
  optionalFields?: OrderOptionalFields[];
  freeTextFields?: OrderFreeTextFields;
  che_bags?: OrderSwitzerlandBag[];
  che_bag_to_glns?: OrderSwitzerlandBagToGln[];
}

export interface OrderSettingsListSamplesRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  manufacturer?: string;
  all?: boolean;
  offset?: number;
  limit?: number;
}

export interface OrderSettingsCreateSampleRequest {
  /**
   * ID of sample
   */
  id?: number;
  /**
   * ID of laboratory
   */
  lid?: number;
  /**
   * Image ID
   */
  imageId?: number;
  /**
   * Manufacturer
   */
  manufacturer?: string;
  /**
   * GBO Type
   */
  gbo_type?: string;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Notice
   */
  notice?: string;
  /**
   * Sample Name
   */
  name?: string;
  /**
   * Image URL
   */
  img?: string;
  /**
   * Small Image Content
   */
  imageLarge?: string;
  /**
   * Large Image Content
   */
  imageSmall?: string;
  /**
   * Unit
   */
  unit?: string;
  /**
   * Quantity
   */
  quantity?: number;
  /**
   * Order
   */
  sortingOrder?: number;
  /**
   * Short Code of the Laboratory Group
   */
  laboratoryGroupShortCode?: string;
  /**
   * GBO ID
   */
  gboidentifier?: string;
  /**
   * Sample Type
   */
  sampleType?: string;
  /**
   * Sample Type on Label
   */
  sampleTypeLabel?: string;
  /**
   * Large Image Upload
   */
  imageLargeFile?: {
    [k: string]: any;
  } | null;
  /**
   * Small Image Upload
   */
  imageSmallFile?: {
    [k: string]: any;
  } | null;
  /**
   * Substitute Sample Type List
   */
  substituteSampleTypes?: string | null;
  image?: OrderImageData;
  laboratoryGroup?: LaboratoryGroup;
}

export type OrderSettingsListManufacturersResponse = string[];

export interface OrderSettingsReadSampleRequest {
  /**
   * ID of sample
   */
  id: number;
}

export interface OrderSettingsDeleteSampleRequest {
  /**
   * ID of sample
   */
  id: number;
}

export interface OrderSettingsUpdateSampleRequest {
  /**
   * ID of laboratory
   */
  lid?: number;
  /**
   * Image ID
   */
  imageId?: number;
  /**
   * Manufacturer
   */
  manufacturer?: string;
  /**
   * GBO Type
   */
  gbo_type?: string;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Notice
   */
  notice?: string;
  /**
   * Sample Name
   */
  name?: string;
  /**
   * Image URL
   */
  img?: string;
  /**
   * Small Image Content
   */
  imageLarge?: string;
  /**
   * Large Image Content
   */
  imageSmall?: string;
  /**
   * Unit
   */
  unit?: string;
  /**
   * Quantity
   */
  quantity?: number;
  /**
   * Order
   */
  sortingOrder?: number;
  /**
   * Short Code of the Laboratory Group
   */
  laboratoryGroupShortCode?: string;
  /**
   * GBO ID
   */
  gboidentifier?: string;
  /**
   * Sample Type
   */
  sampleType?: string;
  /**
   * Sample Type on Label
   */
  sampleTypeLabel?: string;
  /**
   * Large Image Upload
   */
  imageLargeFile?: {
    [k: string]: any;
  } | null;
  /**
   * Small Image Upload
   */
  imageSmallFile?: {
    [k: string]: any;
  } | null;
  /**
   * Substitute Sample Type List
   */
  substituteSampleTypes?: string | null;
  /**
   * ID of sample
   */
  id: number;
  image?: OrderImageData;
  laboratoryGroup?: LaboratoryGroup;
}

export interface OrderSettingsReadAnamnesisQuestionRequest {
  id: number;
}

export interface OrderSettingsListOrderRulesRequest {
  query?: string;
  autoGenerated?: boolean;
  offset?: number;
  limit?: number;
}

export interface OrderSettingsCreateOrderRuleRequest {
  /**
   * Primary Key
   */
  id?: number;
  name?: string;
  group?: string | null;
  description?: string;
  created_at?: string;
  autoGenerated?: boolean;
  printQuickReportBarcode?: boolean;
  printQuickReportBarcodeCovid?: boolean;
  conditions?: OrderRuleConditionProperties[];
  actions?: OrderRuleActionProperties[];
}

export interface OrderSettingsListOrderRuleGroupsResponse {
  groups?: string[];
}

export interface OrderSettingsListOrderRuleGroupsRequest {
  query?: string;
  autoGenerated?: boolean;
}

export interface OrderSettingsReadOrderRuleRequest {
  id: number;
  autoGenerated?: boolean;
}

export interface OrderSettingsDeleteOrderRuleRequest {
  id: number;
}

export interface OrderSettingsUpdateOrderRuleRequest {
  name?: string;
  group?: string | null;
  description?: string;
  created_at?: string;
  autoGenerated?: boolean;
  printQuickReportBarcode?: boolean;
  printQuickReportBarcodeCovid?: boolean;
  id: number;
  conditions?: OrderRuleConditionProperties[];
  actions?: OrderRuleActionProperties[];
}

export interface OrderSettingsListOrderFormsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
}

export interface OrderSettingsCreateOrderFormRequest {
  /**
   * ID of the order form
   */
  id?: number;
  /**
   * ID of the doctors
   */
  aid?: number[];
  /**
   * ID of the laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * Name of the order form
   */
  name?: string;
  costUnit?: string;
  /**
   * If the patient or the doctor will pay the costs
   */
  invoiceToChangeable?: boolean;
  /**
   * If the order form is private and we have therefore to calculate prices
   */
  isPrivate?: boolean;
  isDynamicMaterial?: boolean;
  isPoct?: boolean;
  isDefault?: boolean;
  isFilterHidden?: boolean;
  isDiagnosesSelect?: boolean;
  displayEbmPrice?: boolean;
  /**
   * A bite to the order form
   */
  formNote?: string;
  /**
   * If a graphic form pops up on selecting the order form within the order process
   */
  isGraphic?: boolean;
  /**
   * Barcode related comment to be saved with the order
   */
  barcodeComment?: string;
  /**
   * Export information to be saved with the order
   */
  exportInfo?: string;
  /**
   * If the order form is for microbiologic analyses
   */
  isBak?: boolean;
  satzart?: string;
  directscheindruckmode?: number;
  /**
   * Timestamp when the for was last changed; in the format YmdHis
   */
  lastChanged?: string;
  /**
   * If the form is for pool orders
   */
  isPool?: boolean;
  /**
   * If set the maximal count for requirements to be selected for one order
   */
  maximumRequirements?: number | null;
  /**
   * Base price to be added to the prices of the requirements
   */
  basePrice?: string;
  /**
   * If the order form is using dynamic probe types
   */
  hasDynProbeType?: boolean;
  /**
   * If the order form is soft deleted
   */
  isDeleted?: boolean;
  /**
   * Factor to multiply the net price to get the gross price
   */
  priceFactor?: string;
  /**
   * ID of the form group the order form is into -> a_schein_grp.sgid
   */
  formGroupID?: number | null;
  /**
   * Prefix to be prepended to the barcode
   */
  bcPrefix?: string;
  exportAsFile?: string;
  exportType?: number;
  /**
   * Short key of the labgroup; the empty string if no labgroup is assigned
   */
  laboratoryGroupShortCode?: string;
  /**
   * References a ZPL definition -> a_zpl.zpl_id
   */
  zplID?: number | null;
  image?: string;
  orientation?: number;
  height?: number;
  width?: number;
  mode?: number;
  isAdditionalPage?: string;
  height2?: number;
  width2?: number;
  orientation2?: number;
  use_status?: string;
  datamatrix?: string | null;
  defaultInvoiceTo?: StdPayer;
  splitMode?: SplitMode;
  digitalSignatureMode?: DigitalSignatureMode;
  formType?: FormType;
  laboratoryGroup?: LaboratoryGroup | null;
  requirements?: OrderFormRequirement[];
}

export interface OrderSettingsReadOrderFormRequest {
  id: number;
}

export interface OrderSettingsUpdateOrderFormRequest {
  /**
   * ID of the doctors
   */
  aid?: number[];
  /**
   * ID of the laboratory -> b_labor.lid
   */
  lid?: number;
  /**
   * Name of the order form
   */
  name?: string;
  costUnit?: string;
  /**
   * If the patient or the doctor will pay the costs
   */
  invoiceToChangeable?: boolean;
  /**
   * If the order form is private and we have therefore to calculate prices
   */
  isPrivate?: boolean;
  isDynamicMaterial?: boolean;
  isPoct?: boolean;
  isDefault?: boolean;
  isFilterHidden?: boolean;
  isDiagnosesSelect?: boolean;
  displayEbmPrice?: boolean;
  /**
   * A bite to the order form
   */
  formNote?: string;
  /**
   * If a graphic form pops up on selecting the order form within the order process
   */
  isGraphic?: boolean;
  /**
   * Barcode related comment to be saved with the order
   */
  barcodeComment?: string;
  /**
   * Export information to be saved with the order
   */
  exportInfo?: string;
  /**
   * If the order form is for microbiologic analyses
   */
  isBak?: boolean;
  satzart?: string;
  directscheindruckmode?: number;
  /**
   * Timestamp when the for was last changed; in the format YmdHis
   */
  lastChanged?: string;
  /**
   * If the form is for pool orders
   */
  isPool?: boolean;
  /**
   * If set the maximal count for requirements to be selected for one order
   */
  maximumRequirements?: number | null;
  /**
   * Base price to be added to the prices of the requirements
   */
  basePrice?: string;
  /**
   * If the order form is using dynamic probe types
   */
  hasDynProbeType?: boolean;
  /**
   * If the order form is soft deleted
   */
  isDeleted?: boolean;
  /**
   * Factor to multiply the net price to get the gross price
   */
  priceFactor?: string;
  /**
   * ID of the form group the order form is into -> a_schein_grp.sgid
   */
  formGroupID?: number | null;
  /**
   * Prefix to be prepended to the barcode
   */
  bcPrefix?: string;
  exportAsFile?: string;
  exportType?: number;
  /**
   * Short key of the labgroup; the empty string if no labgroup is assigned
   */
  laboratoryGroupShortCode?: string;
  /**
   * References a ZPL definition -> a_zpl.zpl_id
   */
  zplID?: number | null;
  image?: string;
  orientation?: number;
  height?: number;
  width?: number;
  mode?: number;
  isAdditionalPage?: string;
  height2?: number;
  width2?: number;
  orientation2?: number;
  use_status?: string;
  datamatrix?: string | null;
  /**
   * ID of the order form
   */
  id: number;
  defaultInvoiceTo?: StdPayer;
  splitMode?: SplitMode;
  digitalSignatureMode?: DigitalSignatureMode;
  formType?: FormType;
  laboratoryGroup?: LaboratoryGroup | null;
  requirements?: OrderFormRequirement[];
}

export interface OrderSettingsDeleteOrderFormRequest {
  id: number;
}

export interface OrderSettingsReadAnalysisRequest {
  id: string;
}

export interface OrderSettingsUpdateAnalysisRequest {
  id: string;
  analysis?: ClinicalAnalysis;
}

export interface OrderSettingsListAnalysesRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  /**
   * Laboratory group filter
   */
  laboratoryGroup?: string;
  offset?: number;
  limit?: number;
  filter?: AnalysesAdminBuiltinFilterValue;
}

export interface OrderSettingsUpdateAnalysesRequest {
  items?: ClinicalAnalysis[];
}

export interface OrderSettingsReadMaterialRequest {
  /**
   * ID of material
   */
  id: string;
}

export interface OrderSettingsUpdateMaterialRequest {
  id: string;
  material?: ClinicalMaterial;
}

export interface OrderSettingsListMaterialsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  /**
   * Laboratory group filter
   */
  laboratoryGroup?: string;
  offset?: number;
  limit?: number;
  filter?: MaterialsAdminBuiltinFilterValue;
}

export interface OrderSettingsUpdateMaterialsRequest {
  items?: ClinicalMaterial[];
}

export interface OrderSettingsReadRequirementRequest {
  id: number;
}

export interface OrderSettingsDeleteRequirementRequest {
  /**
   * ID of order requirement
   */
  id: number;
}

export interface OrderSettingsUpdateRequirementRequest {
  /**
   * Short name of requirements
   */
  shortName?: string;
  longName?: string;
  /**
   * External name of requirements
   */
  externalName?: string;
  duration?: string;
  /**
   * Alias name of requirements
   */
  alias?: string;
  /**
   * Execution limit
   */
  limit?: number | null;
  /**
   * Priority
   */
  priority?: number | null;
  /**
   * LIS Export
   */
  lisExport?: boolean;
  /**
   * Is price charged
   */
  isCharged?: boolean;
  /**
   * Is EBM price charged
   */
  isEbmCharged?: boolean;
  /**
   * Free text allowed
   */
  freeTextAllowed?: boolean;
  /**
   * Free text mandatory
   */
  freeTextMandatory?: boolean;
  /**
   * Free text as date
   */
  freeTextAsDate?: boolean;
  /**
   * Free text LDT key
   */
  freeTextKey?: number | null;
  /**
   * Free text preappend value
   */
  freeTextPreappendValue?: string | null;
  laboratoryGroup?: string;
  /**
   * Self defined
   */
  selfDefined?: boolean;
  /**
   * Requirement short name is editable regarding by dependencies
   */
  shortNameEditable?: boolean;
  /**
   * Requirement is deletable regarding to dependencies
   */
  deletable?: boolean;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup1?: number;
  /**
   * Price within PriceGroup 2 for this requirement if privately billed
   */
  priceGroup2?: number;
  /**
   * Price within PriceGroup 3 for this requirement if privately billed
   */
  priceGroup3?: number;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup4?: number;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup5?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup1?: number | null;
  /**
   * Maximum price within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup2?: number | null;
  /**
   * Maximum price within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup3?: number | null;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup4?: number | null;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup5?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup1?: string;
  /**
   * Maximum price comment within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup2?: string;
  /**
   * Maximum price comment within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup3?: string;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup4?: string;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup5?: string;
  /**
   * EBM price
   */
  ebmPrice?: number;
  infoUrl?: string | null;
  additionalText?: string | null;
  hint?: string | null;
  /**
   * Duration of the examination in seconds
   */
  examinationDuration?: number | null;
  /**
   * ID of order requirement
   */
  id: number;
  requirementFieldSettings?: RequirementFieldSetting[];
  examinationDurationInterval?: Interval;
}

export interface OrderSettingsListRequirementsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  /**
   * Laboratory group filter
   */
  laboratoryGroup?: string;
  /**
   * Submitter filter
   */
  submitter?: string;
  /**
   * Order form filter
   */
  formId?: number;
  offset?: number;
  limit?: number;
  source?: RequirementsSourceFilterValue;
  filter?: RequirementsAdminConsistencyFilterValue;
}

export interface OrderSettingsCreateRequirementRequest {
  /**
   * ID of order requirement
   */
  id?: number;
  /**
   * ID of laboratory
   */
  lid?: number;
  formId?: number;
  /**
   * Short name of requirements
   */
  shortName?: string;
  /**
   * External name of requirements
   */
  externalName?: string;
  /**
   * Alias name of requirements
   */
  alias?: string;
  longName?: string;
  /**
   * Execution limit
   */
  limit?: number | null;
  /**
   * Re-request material limit
   */
  materialLimitOverrule?: number | null;
  /**
   * Is super requirement of a requirement set
   */
  superRequirement?: boolean | null;
  subRequirements?: string;
  infoUrl?: string | null;
  additionalText?: string | null;
  hint?: string | null;
  analysesString?: string;
  /**
   * Occurrence of the requirement, generated value
   */
  occurrence?: number;
  /**
   * List of indications
   */
  indicationsString?: string[];
  pinnedByDoctors?: number[];
  topFromDoctors?: number[];
  firstImportDate?: string;
  laboratoryGroup?: string;
  submitter?: string;
  duration?: string;
  /**
   * Self defined
   */
  selfDefined?: boolean;
  /**
   * Requirement is deletable regarding to dependencies
   */
  deletable?: boolean;
  /**
   * Requirement is blocked
   */
  blocked?: boolean;
  /**
   * Requirement short name is editable regarding by dependencies
   */
  shortNameEditable?: boolean;
  /**
   * EBM price
   */
  ebmPrice?: number;
  /**
   * Priority
   */
  priority?: number | null;
  /**
   * LIS Export
   */
  lisExport?: boolean;
  /**
   * Is price charged
   */
  isCharged?: boolean;
  /**
   * Is EBM price charged
   */
  isEbmCharged?: boolean;
  /**
   * Free text allowed
   */
  freeTextAllowed?: boolean;
  /**
   * Free text mandatory
   */
  freeTextMandatory?: boolean;
  /**
   * Free text as date
   */
  freeTextAsDate?: boolean;
  /**
   * Free text LDT key
   */
  freeTextKey?: number | null;
  /**
   * Free text preappend value
   */
  freeTextPreappendValue?: string | null;
  /**
   * Duration of the examination in seconds
   */
  examinationDuration?: number | null;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup1?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup1?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup1?: string;
  /**
   * Price within PriceGroup 2 for this requirement if privately billed
   */
  priceGroup2?: number;
  /**
   * Maximum price within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup2?: number | null;
  /**
   * Maximum price comment within PriceGroup 2 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup2?: string;
  /**
   * Price within PriceGroup 3 for this requirement if privately billed
   */
  priceGroup3?: number;
  /**
   * Maximum price within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup3?: number | null;
  /**
   * Maximum price comment within PriceGroup 3 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup3?: string;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup4?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup4?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup4?: string;
  /**
   * Price within PriceGroup 1 for this requirement if privately billed
   */
  priceGroup5?: number;
  /**
   * Maximum price within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceGroup5?: number | null;
  /**
   * Maximum price comment within PriceGroup 1 for this requirement, if is set then the price is shown as price range
   */
  maxPriceCommentGroup5?: string;
  analyses?: ClinicalAnalysis[];
  samples?: Sample[];
  materials?: ClinicalMaterial[];
  forms?: OrderForm[];
  categories?: RequirementCategory[];
  profiles?: RequirementProfile[];
  globalProfiles?: RequirementGlobalProfile[];
  requirementFieldSettings?: RequirementFieldSetting[];
  examinationDurationInterval?: Interval;
}

export interface OrderSettingsUpdateRequirementsRequest {
  items?: ClinicalRequirement[];
}

export interface OrderSettingsReplaceRequirementAssociationsRequest {
  /**
   * Short name of requirements
   */
  newShortName: string;
  /**
   * ID of order requirement
   */
  id: number;
}

export interface OrderSettingsListRequirementLaboratoryGroupsResponse {
  laboratoryGroups?: RequirementLaboratoryGroupsProperties[];
}

export interface OrderSettingsListRequirementSubmittersResponse {
  submitters?: RequirementSubmittersProperties[];
}

export interface OrderSettingsOvertakeOaSettingsForDoctorRequest {
  /**
   * Doctor ID
   */
  aid?: number;
  /**
   * DoctorGroup ID
   */
  gid?: number;
  overtakeCertificateAssignment?: boolean;
  overtakeGrpCertificateAssignment?: boolean;
  overtakeAwizButtons?: boolean;
  overtakeRequirementProfiles?: boolean;
  /**
   * Doctor ID
   */
  templateAid: number;
}

export interface OrderSettingsUpdateGroupFormsOrderRequest {
  aid: number;
  groupFormOrders?: GroupFormOrder[];
}

export interface OrderSettingsReadBakAnalysisRequest {
  shortName: string;
}

export interface OrderSettingsUpdateBakAnalysisRequest {
  shortName: string;
  analysis?: UnifiedMicrobiologicalAnalysis;
}

export interface OrderSettingsListBakAnalysesRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  offset?: number;
  limit?: number;
}

export interface OrderSettingsListBakMaterialsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  offset?: number;
  limit?: number;
}

export interface OrderSettingsReadBakMaterialRequest {
  /**
   * ID of material
   */
  matid: string;
}

export interface OrderSettingsUpdateBakMaterialRequest {
  /**
   * ID of material
   */
  matid: string;
  material?: UnifiedMicrobiologicalMaterial;
}

export interface GdtPatientListGdtPatientsRequest {
  aid: number;
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  /**
   * Excluded IDs
   */
  excluded?: number[];
  offset?: number;
  limit?: number;
}

export interface GdtPatientCreateOrUpdateGdtPatientResponse {
  apgid?: number;
}

export interface GdtPatientCreateOrUpdateGdtPatientRequest {
  patient?: PatientGDT;
}

export interface GdtPatientReadGdtPatientsRequest {
  aid: number;
  apgids?: number[];
}

export interface GdtPatientReadGdtPatientRequest {
  aid: number;
  apgid: number;
}

export interface GdtPatientReadGdtPatientFromTimestampRequest {
  aid: number;
  updated_at: string;
}

export interface GdtPatientListGdtPatientGroupsRequest {
  aid: number;
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
}

export interface GdtPatientGetPatientReportsResponse {
  reports?: Report[];
  history?: ReportHistoryValue[];
}

export interface GdtPatientGetPatientReportsRequest {
  id: number;
  limit?: number;
  type?: OrderPatientType;
}

export interface RequirementsListDoctorRequirementsResponse {
  result?: OrderWizardRequirement[];
  requirements?: OrderWizardRequirement[];
  filters?: OrderRequirementsFilter[];
  anamnesis?: AnamnesisQuestion[];
}

export interface RequirementsListDoctorRequirementsRequest {
  aid: number;
  query?: string;
  /**
   * Load all results, ignore group by
   */
  all?: boolean;
  /**
   * Only list pinned requirements
   */
  pinned?: boolean;
  /**
   * Only list top requirements
   */
  top?: boolean;
  /**
   * Only list bak requirements
   */
  bak?: boolean;
  pool?: boolean;
  groupFormId?: number;
  costUnit?: string;
  profileName?: string;
  orderId?: number;
  groupBy?: RequirementAggregationType;
  filters?: OrderRequirementsFilter[];
  orderStatus?: OrderStatus;
}

export interface RequirementsPinRequirementRequest {
  id?: number;
  aid?: number;
  type?: RequirementType;
}

export interface RequirementsUnpinRequirementRequest {
  id?: number;
  aid?: number;
  aggregatedId?: number;
  type?: RequirementType;
}

export interface RequirementsGetRequirementsAutocompleteResponse {
  requirements?: OrderWizardRequirement[];
  filters?: OrderRequirementsFilter[];
  subRequirements?: OrderWizardRequirement[];
}

export interface RequirementsGetRequirementsAutocompleteRequest {
  aid: number;
  /**
   * Query string
   */
  query: string;
  /**
   * Query string
   */
  entityKey?: string;
  costUnit?: string;
  laboratoryGroup?: string;
  orderId?: number;
  pool?: boolean;
  orderStatus?: OrderStatus;
}

export interface RequirementsReadRequirementRequest {
  id: number;
  aid: number;
  orderFormId?: number;
}

export interface SamplesGetSampleImageRequest {
  id: number;
  type?: SampleImageType;
}

export interface OrderWizardGetAnamnesisQuestionTreeRequest {
  ids?: number[];
  patient?: OrderPatientMetaProperties;
}

export interface OrderWizardGetBasketResponse {
  errors?: {
    [k: string]: any;
  };
  basket?: OrderBasketFormProperties[];
  profiles?: AggregatedProfileProperties[];
  subRequirements?: OrderWizardRequirement[];
  samples?: OrderSample[];
  rules?: OrderRule[];
  ebm?: Ebm[];
}

export interface OrderWizardGetBasketRequest {
  aid: number;
  orders?: WriteableOrderProperties[];
}

export interface OrderWizardGetSettingsRequest {
  aid?: number;
}

export interface OrderWizardAustriaSettingsGetAustriaHvCodeRequest {
  code: string;
}

export interface ProfilesCopyProfilesResponse {
  done?: boolean;
  profiles?: AggregatedProfileProperties[];
  confirm?: MappingConfirmProperties;
}

export interface ProfilesCopyProfilesRequest {
  profileIds: number[];
  targetCostUnits?: (string | null)[];
  toAllOthers?: boolean | null;
  confirmed?: boolean | null;
  aid: number;
  confirm?: CopyProfilesConfirmProperties | null;
}

export interface ProfilesPdfExportProfilesRequest {
  aid: number;
}

export interface ProfilesCreateProfileRequest {
  name: string;
  color?: string | null;
  costUnit: string | null;
  diagnosis?: string | null;
  freeText?: string | null;
  selectedDiagnoses?: {
    [k: string]: any;
  };
  orderReason?: string;
  toAllOthers?: boolean | null;
  withFreeTexts?: boolean | null;
  aid: number;
  requirements?: OrderRequirement[];
}

export interface ProfilesListDoctorProfilesRequest {
  aid: number;
}

export interface ProfilesBatchUpdateProfilesRequest {
  aid: number;
  profiles?: RequirementProfile[];
}

export interface ProfilesReadProfileRequest {
  id: number;
  aid: number;
}

export interface ProfilesUpdateProfileRequest {
  name: string;
  color?: string | null;
  diagnosis?: string | null;
  freeText?: string | null;
  selectedDiagnoses?: {
    [k: string]: any;
  };
  orderReason?: string;
  toAllOthers?: boolean | null;
  withFreeTexts?: boolean | null;
  id: number;
  aid: number;
  requirements?: OrderRequirement[];
}

export interface CompletionCompleteRequest {
  order_ids?: number[];
}

export interface RealReRequestReadReRequestDetailsRequest {
  /**
   * ID of order
   */
  id: number;
}

export interface RealReRequestStartRealReRequestRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface RealReRequestSendOffRealReRequestRequest {
  aid: number;
  orders?: WriteableOrderProperties[];
}

export interface RealReRequestCancelRealReRequestRequest {
  poolId?: number;
  poolIds?: number[];
  orderId?: number;
  orderIds?: number[];
}

export interface RealReRequestAcceptAdditionalRequirementsRequest {
  reRequestedRequirements?: ReRequestOrderRequirement[];
}

export interface PrintPrintBarcodeRequest {
  language?: string;
  printer?: string;
  workstation?: number;
  barcodeNumber?: string;
  probeType?: string;
  /**
   * ID of order
   */
  id: number;
  specialZplType?: SpecialZplType;
}

export interface PrintExpendExtraBarcodeRequest {
  aid?: number;
  language?: string;
  printer?: string;
  workstation?: number;
  barcodeNumber?: string;
  sampleType?: string;
  /**
   * ID of order
   */
  id: number;
}

export interface PrintPrintOrderFormRequest {
  /**
   * ID of order
   */
  id: number;
  workstation?: number;
  autoPrint?: boolean;
  mode?: OrderFormPrintMode;
}

export interface OrdersReadOrderPoolRequest {
  /**
   * ID of order pool
   */
  id: number;
}

export interface OrdersReadOrderRequest {
  /**
   * ID of order pool
   */
  id: number;
}

export interface OrdersGetOrderChangeLogRequest {
  pool_id: number;
  offset?: number;
  limit?: number;
}

export interface OrdersChangeOrdersWorkstationRequest {
  orderIds?: number[];
  apid?: number;
}

export interface MessageCenterListMessageThreadsRequest {
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
  type?: MessageThreadType;
}

export interface MessageCenterCreateMessageThreadRequest {
  /**
   * Receiver user ID
   */
  receiver_id?: number;
  /**
   * Thread Subject
   */
  subject?: string;
  /**
   * ID of report
   */
  reportId?: number;
  /**
   * ID of order
   */
  orderId?: number;
  /**
   * Appointment Note
   */
  appointmentNote?: string;
  /**
   * Date
   */
  appointmentDate?: string;
  /**
   * Time
   */
  appointmentTime?: string;
  type?: MessageThreadType;
}

export interface MessageCenterReadMessageThreadRequest {
  /**
   * Thread ID
   */
  thread_id: number;
}

export interface MessageCenterDeleteMessageThreadRequest {
  /**
   * Thread ID
   */
  thread_id: number;
}

export interface MessageCenterListMessagesRequest {
  /**
   * Thread ID
   */
  thread_id: number;
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
}

export interface MessageCenterCreateMessageRequest {
  /**
   * Message ID
   */
  id?: number;
  /**
   * Text
   */
  text: string;
  created_at?: string;
  /**
   * Read by current user
   */
  is_read?: boolean;
  /**
   * IDs of users read
   */
  readBy?: number[];
  /**
   * UID of sender
   */
  sender_id?: number;
  /**
   * Avatar of sender
   */
  sender_avatar?: string;
  context_read_by?: any[];
  /**
   * Thread ID
   */
  thread_id: number;
  appointment?: Appointment;
}

export interface MessageCenterReadMessageRequest {
  /**
   * Thread ID
   */
  thread_id: number;
  /**
   * Message ID
   */
  message_id: number;
}

export interface MessageCenterStarMessageThreadRequest {
  /**
   * Thread ID
   */
  thread_id: number;
}

export interface MessageCenterUnstarMessageThreadRequest {
  /**
   * Thread ID
   */
  thread_id: number;
}

export interface MessageCenterListUsersToBeMessagedRequest {
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
}

export interface AuthSettingsUpdateAuthConfigRequest {
  config?: AuthConfigProperties;
}

export interface LinksSettingsUpdateLinksConfigRequest {
  settings?: LinkSettingProperties;
}

export interface PdfSettingsUpsertPdfLinkRequest {
  id?: number;
  name: string;
  fileChanged?: boolean;
  position?: number;
  /**
   * Uploaded PDF
   */
  pdfFile?: {
    [k: string]: any;
  };
  product?: LinkProduct;
}

export interface PdfSettingsDownloadPdfLinkRequest {
  id: number;
}

export interface PdfSettingsDeletePdfLinkRequest {
  id: number;
}

export interface ProductsCreateProductRequest {
  product?: Product;
}

export interface ProductsRemoveProductRequest {
  product?: Product;
}

export interface ProductsUpdateProductRequest {
  doctorsEnabled?: boolean;
  patientsEnabled?: boolean;
  appEnabled?: boolean;
  explicitLock?: boolean;
  product?: Product;
}

export interface FeaturesCreateFeatureRequest {
  feature?: Feature;
  product?: Product;
}

export interface FeaturesUpdateFeatureRequest {
  arz_meth?: FeatureSetting;
  pat_meth?: FeatureSetting;
  feature?: Feature;
  product?: Product;
}

export interface FeaturesRemoveFeatureRequest {
  feature?: Feature;
  product?: Product;
}

export interface LaboratoryGroupsListLaboratoryGroupsRequest {
  /**
   * Query string
   */
  query?: string;
}

export interface LaboratoryGroupsCreateLaboratoryGroupRequest {
  /**
   * ShortCode of laboratory group
   */
  kurz: string;
  /**
   * Titel or Name of laboratory group
   */
  name?: string;
  /**
   * Phone number
   */
  telefon?: string;
  /**
   * Fax number
   */
  fax?: string;
  /**
   * Website adress
   */
  website?: string;
  /**
   * Street adress include house number
   */
  adresse?: string;
  /**
   * town
   */
  stadt?: string;
  /**
   * mail adress
   */
  email?: string;
  /**
   * mail for notification over report detail view
   */
  rerequestMail?: string;
  /**
   * additional information line 1
   */
  zusatz1?: string;
  /**
   * additional information line 2
   */
  zusatz2?: string;
  /**
   * additional information line 3
   */
  zusatz3?: string;
  /**
   * postal code
   */
  plz?: string;
}

export interface LaboratoryGroupsReadLaboratoryGroupRequest {
  /**
   * LaboratoryGroup ID
   */
  grpid: number;
}

export interface LaboratoryGroupsDeleteLaboratoryGroupRequest {
  /**
   * LaboratoryGroup ID
   */
  grpid: number;
}

export interface LaboratoryGroupsUpdateLaboratoryGroupRequest {
  /**
   * Titel or Name of laboratory group
   */
  name?: string;
  /**
   * ShortCode of laboratory group
   */
  kurz?: string;
  /**
   * Phone number
   */
  telefon?: string;
  /**
   * Fax number
   */
  fax?: string;
  /**
   * Website adress
   */
  website?: string;
  /**
   * Street adress include house number
   */
  adresse?: string;
  /**
   * town
   */
  stadt?: string;
  /**
   * mail adress
   */
  email?: string;
  /**
   * mail for notification over report detail view
   */
  rerequestMail?: string;
  /**
   * additional information line 1
   */
  zusatz1?: string;
  /**
   * additional information line 2
   */
  zusatz2?: string;
  /**
   * additional information line 3
   */
  zusatz3?: string;
  /**
   * postal code
   */
  plz?: string;
  /**
   * LaboratoryGroup ID
   */
  grpid: number;
}

export interface LaboratoryGroupFiltersGetLaboratoryGroupFiltersResponse {
  filters?: LaboratoryGroupFilter[];
}

export interface LaboratoryGroupFiltersSetLaboratoryGroupFiltersRequest {
  filters?: LaboratoryGroupFilter[];
}

export interface DoctorGroupsListDoctorGroupsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Sort by
   */
  sort?: string;
  offset?: number;
  limit?: number;
}

export interface DoctorGroupsCreateDoctorGroupRequest {
  /**
   * Group Name
   */
  name: string;
  /**
   * Flag indicating if the group is also an Online Order Gruppenarztpraxis
   */
  oagrp?: boolean;
  /**
   * Flag indicating if the group is also an Labstore Gruppenarztpraxis
   */
  lsgrp?: boolean;
  members?: DoctorGroupMember[];
}

export interface DoctorGroupsReadDoctorGroupRequest {
  /**
   * DoctorGroup ID
   */
  gid: number;
  /**
   * Include Members in Group Response
   */
  members?: boolean;
}

export interface DoctorGroupsDeleteDoctorGroupRequest {
  /**
   * DoctorGroup ID
   */
  gid: number;
}

export interface DoctorGroupsUpdateDoctorGroupRequest {
  /**
   * Laboratory ID
   */
  lid?: number;
  /**
   * Group Name
   */
  name?: string;
  /**
   * Flag indicating if the group is also an Online Order Gruppenarztpraxis
   */
  oagrp?: boolean;
  /**
   * Flag indicating if the group is also an Labstore Gruppenarztpraxis
   */
  lsgrp?: boolean;
  members_count?: number;
  owners_count?: number;
  total_count?: number;
  /**
   * Is Group Admin
   */
  isAdmin?: boolean;
  /**
   * Is Group Member
   */
  isMember?: boolean;
  /**
   * DoctorGroup ID
   */
  gid: number;
  members?: DoctorGroupMember[];
}

export interface DoctorGroupOfficeReadDoctorGroupOfficeAssignmentsResponse {
  /**
   * The paginated results
   */
  results?: any[];
  indexStart?: number;
  indexEnd?: number;
  hasMore?: boolean;
  [k: string]: any;
}

export interface DoctorGroupOfficeReadDoctorGroupOfficeAssignmentsRequest {
  aid?: number;
  lanr?: string;
  offset?: number;
  limit?: number;
}

export interface DoctorGroupOfficeListDoctorsPerGroupOfficesRequest {
  aid?: number;
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
}

export interface SystemUpdateSystemConfigRequest {
  settings?: SystemSettingProperties;
}

export interface MailsSetMailSmtpSettingsRequest {
  settings?: SendmailSettings[];
}

export interface MailsSetMailTemplateGroupsRequest {
  groups?: MailTemplateGroup[];
}

export interface MailsSetMailTemplateGroupLogoRequest {
  logo?: {
    [k: string]: any;
  };
  mtgid: number;
}

export interface MailsGetMailTemplateResponse {
  placeholders?: string[];
  entry?: MailLanguageEntry;
}

export interface MailsGetMailTemplateRequest {
  mtgid?: number;
  locale?: LanguageCode;
  type?: MailTemplateType;
}

export interface MailsSetMailTemplateRequest {
  subject?: string;
  body?: string;
  /**
   * Mail Template Group ID
   */
  mtgid?: number | null;
  locale?: LanguageCode;
  type?: MailTemplateType;
}

export interface MailsSendMailTemplateRequest {
  email?: string;
  body?: string;
  /**
   * Mail Template Group ID
   */
  mtgid?: number | null;
  locale?: LanguageCode;
  type?: MailTemplateType;
}

export interface MailsPreviewMailTemplateRequest {
  email?: string;
  /**
   * Mail Template Group ID
   */
  mtgid?: number | null;
  locale?: LanguageCode;
  type?: MailTemplateType;
}

export interface LanrGetLanrSettingsRequest {
  aid: number;
}

export interface LanrUpdateLanrSettingsRequest {
  enabled?: boolean;
  defaultLanr?: string;
  aid: number;
  doctors?: LanrSearch[];
}

export interface SmsSettingsUpdateSmsConfigRequest {
  settings?: SendSmsSettings;
}

export interface PreferenceSettingsResetPreferenceByKeyRequest {
  key: string;
}

export interface LexicaSettingsReadArticleRequest {
  /**
   * ID of the article
   */
  id: number;
}

export interface LexicaSettingsUpdateArticleRequest {
  key?: string;
  /**
   * Name of the article
   */
  articleName?: string;
  /**
   * Second ID for special references
   */
  articleRef?: number;
  /**
   * The html content to display
   */
  displayContent?: string;
  /**
   * The main html content
   */
  mainContent?: string;
  /**
   * The custom html content for doctors
   */
  doctorCustomContent?: string;
  /**
   * The custom html content for patients
   */
  patientCustomContent?: string;
  /**
   * ID of the article
   */
  id: number;
}

export interface LexicaSettingsDeleteArticleRequest {
  /**
   * ID of the article
   */
  id: number;
}

export interface LexicaSettingsListArticlesRequest {
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
  forUserType?: UserType[];
}

export interface LexicaSettingsCreateArticleRequest {
  /**
   * ID of the article
   */
  id?: number;
  key?: string;
  /**
   * Name of the article
   */
  articleName?: string;
  /**
   * Second ID for special references
   */
  articleRef?: number;
  /**
   * The html content to display
   */
  displayContent?: string;
  /**
   * The main html content
   */
  mainContent?: string;
  /**
   * The custom html content for doctors
   */
  doctorCustomContent?: string;
  /**
   * The custom html content for patients
   */
  patientCustomContent?: string;
}

export interface LexicaSettingsCreateArticleAnalysisRequest {
  /**
   * ID of the test key to article assignment
   */
  id?: number;
  /**
   * Test key -> a_untersuchungen.untid
   */
  analysisKey?: string;
  /**
   * The (second) article ID -> ll_toc.id2
   */
  articleRef?: number;
  /**
   * ID of the lab -> b_labor.lid
   */
  lid?: number;
}

export interface LexicaSettingsDeleteArticleAnalysisRequest {
  /**
   * ID of the test key to article assignment
   */
  id: number;
}

export interface LexicaSettingsListRequirementHintsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Optional short key of a doctor -> b_arzt.kurzform; if the calling user is a doctor the short key will be overwritten by his
   */
  submitter?: string;
  offset?: number;
  limit?: number;
}

export interface LexicaSettingsCreateRequirementHintRequest {
  /**
   * ID of the requirement information
   */
  id?: number;
  /**
   * ID of
   */
  lid?: number;
  importPreanalyticId?: string;
  /**
   * Name of the requirement information
   */
  hintName?: string;
  /**
   * The information proper (html markup)
   */
  htmlContent?: string;
  /**
   * Text color to be used for the information (#RRGGBB)
   */
  htmlColor?: string;
  onlyForAid?: number;
}

export interface LexicaSettingsReadRequirementHintRequest {
  /**
   * ID of the requirement information
   */
  id: number;
}

export interface LexicaSettingsUpdateRequirementHintResponse {}

export interface LexicaSettingsUpdateRequirementHintRequest {
  /**
   * ID of
   */
  lid?: number;
  importPreanalyticId?: string;
  /**
   * Name of the requirement information
   */
  hintName?: string;
  /**
   * The information proper (html markup)
   */
  htmlContent?: string;
  /**
   * Text color to be used for the information (#RRGGBB)
   */
  htmlColor?: string;
  onlyForAid?: number;
  /**
   * ID of the requirement information
   */
  id: number;
}

export interface LexicaSettingsDeleteRequirementHintRequest {
  /**
   * ID of the requirement information
   */
  id: number;
}

export interface LexicaSettingsCreateHintRequirementRequest {
  /**
   * ID of the requirement information to requirement assignment
   */
  id?: number;
  /**
   * ID of the requirement information
   */
  hintId?: number;
  /**
   * Short key of the requirement -> a_anforderungen.anfid
   */
  requirementKey?: string;
  /**
   * Optional short key of a doctor -> b_arzt.kurzform; the empty string for all doctors
   */
  submitter?: string;
  /**
   * ID of the lab -> b_labor.lid
   */
  lid?: number;
}

export interface LexicaSettingsDeleteHintRequirementRequest {
  /**
   * ID of the requirement information to requirement assignment
   */
  id: number;
}

export interface LexicaListArticlesRequest {
  /**
   * Query string
   */
  query?: string;
  offset?: number;
  limit?: number;
  forUserType?: UserType;
}

export interface LexicaReadArticleRequest {
  /**
   * ID of the article
   */
  id: number;
  forUserType?: UserType;
}

export interface LexicaListRequirementHintsRequest {
  /**
   * Query string
   */
  query?: string;
  /**
   * Optional short key of a doctor -> b_arzt.kurzform; if the calling user is a doctor the short key will be overwritten by his
   */
  submitter?: string;
  offset?: number;
  limit?: number;
}

export interface LexicaReadRequirementHintRequest {
  /**
   * ID of the requirement information
   */
  id: number;
}

export interface WebshopProductCreateWebshopProductRequest {
  product?: WebshopProduct;
}

export interface WebshopProductListWebshopProductLimitForTodayRequest {
  productId: number;
  offset?: number;
  limit?: number;
}

export interface WebshopVoucherCreateWebshopVoucherRequest {
  voucher?: PaymentVoucher;
}

export interface WebshopVoucherCreateMvzMartinsriedWebshopVoucherRequest {
  vouchers?: MvzMartinsriedVoucher[];
}

export interface WebshopPreparePaymentForWebshopOrderRequest {
  webshopOrder?: WebshopOrderProperties;
  paymentData?: PaymentSpecificInput;
}

export interface WebshopInsertWebshopOrderRequest {
  webshopOrder?: WebshopOrderProperties;
  paymentData?: PaymentSpecificInput;
}

export interface WebshopListOrdersForDeviceIdRequest {
  deviceId: string;
  /**
   * Sort by
   */
  sort?: string;
  offset?: number;
  limit?: number;
}

export interface WebshopCompleteWebshopOrderRequest {
  orderId?: number;
  guid: string;
  deviceId: string;
  webshopOrderId: number;
}

export interface CasesGetCasesConfigResponse {
  preferences?: CasesPreferencesProperties;
}

export interface CasesSettingsReadGeneralCasesConfigResponse {
  preferences?: CasesPreferencesProperties;
}

export interface CasesSettingsUpdateGeneralCasesConfigRequest {
  preferences?: CasesPreferencesProperties;
}

export interface CasesSettingsReadUserCasesConfigResponse {
  preferences?: CasesPreferencesProperties;
}

export interface CasesSettingsReadUserCasesConfigRequest {
  entityId: number;
  type?: UserType;
}

export interface CasesSettingsUpdateUserCasesConfigRequest {
  entityId: number;
  preferences?: CasesPreferencesProperties;
  type?: UserType;
}

export interface CasesListCasesRequest {
  /**
   * Query string
   */
  query?: string;
  aid?: number;
  aids?: number[];
  pid?: number;
  dateFrom?: string;
  dateTo?: string;
  caseNumber?: string;
  offset?: number;
  limit?: number;
  mode?: CaseListMode;
  status?: CaseStatus;
  eventType?: CaseEventType;
}

export interface CasesReadCaseRequest {
  id: number;
}

export interface WorkstationsGetWorkstationByAliasRequest {
  alias: string;
}

export interface WorkstationsListWorkstationsRequest {
  aid: number;
}

export type ExchangeStorageGetLTD3EmbeddedFileResponse = string[];

export interface ExchangeStorageGetLTD3EmbeddedFileRequest {
  id: number;
}

export interface ExchangeStorageDownloadFileRequest {
  id: number;
  setExported?: boolean;
}

export interface ExchangeSettingsUpdateExportSettingsParams {
  aid: number;
}

export interface ExchangeSettingsGetExportSettingsRequest {
  aid: number;
}

export interface StoreCategoriesGetStoreCategoryTreeForDoctorRequest {
  aid: number;
}

export interface StoreCategoriesDeleteStoreCategoryRequest {
  id: number;
}

export interface StoreCategoriesUpdateStoreCategoryParams {
  id: number;
}

export interface StoreCategoriesReadStoreCategoryRequest {
  id: number;
}

export interface StoreManufacturersDeleteStoreManufacturerRequest {
  id: number;
}

export interface StoreManufacturersUpdateStoreManufacturerParams {
  id: number;
}

export interface StoreManufacturersReadStoreManufacturerRequest {
  id: number;
}

export interface StoreProductsGetStoreProductThumbnailRequest {
  id: number;
}

export interface StoreProductsUnpinStoreProductParams {
  id: number;
}

export interface StoreProductsPinStoreProductParams {
  id: number;
}

export interface StoreProductsGetStoreProductImageRequest {
  id: number;
}

export interface StoreProductsUploadStoreProductImageParams {
  id: number;
}

export interface StoreProductsDeleteStoreProductRequest {
  id: number;
}

export interface StoreProductsUpdateStoreProductParams {
  id: number;
}

export interface StoreProductsReadStoreProductRequest {
  id: number;
}

export interface StoreOrdersCompleteStoreOrderRequest {
  id: number;
}

export interface StoreOrdersCommissionStoreOrderRequest {
  id: number;
}

export interface StoreOrdersCancelStoreOrderRequest {
  id: number;
}

export interface StoreOrdersDeleteStoreOrderRequest {
  id: number;
}

export interface StoreOrdersUpdateStoreOrderParams {
  id: number;
}

export interface StoreOrdersReadStoreOrderRequest {
  id: number;
}

export interface LogsReadLogEntryRequest {
  id: string;
}

export interface MetricsGetSystemMetricsRequest {
  interval: string;
}

export interface NotificationSettingsResetUserTypeNotificationsPreferencesRequest {
  type: number;
}

export interface NotificationSettingsResetUserNotificationsPreferencesRequest {
  type: number;
  entityId: number;
}

export interface NotificationSettingsUpdateUserNotificationsConfigParams {
  type: number;
  entityId: number;
}

export interface NotificationSettingsReadUserNotificationsConfigRequest {
  type: number;
  entityId: number;
}

export interface NotificationsMessenteSMSDeliveryReportParams {
  id: number;
}

export interface NotificationsGetNotificationLogRequest {
  id: number;
}

export interface NotificationsDismissNotificationRequest {
  id: number;
}

export interface NotificationsReadNotificationRequest {
  id: number;
}

export type ApiDefinition = {

  authentication: {
    getUserDetails: (data?: void, config?: ApiRequestConfig) => Promise<UserDetailProperties>,
    login: (data: AuthenticationLoginRequest, config?: ApiRequestConfig) => Promise<UserDetailProperties>,
    logout: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    createToken: (data: AuthenticationCreateTokenRequest, config?: ApiRequestConfig) => Promise<AuthenticationCreateTokenResponse>,
    invalidateToken: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    verifyToken: (data: AuthenticationVerifyTokenRequest, config?: ApiRequestConfig) => Promise<void>,
    createLegacyToken: (data: AuthenticationCreateLegacyTokenRequest, config?: ApiRequestConfig) => Promise<UserDetailProperties>,
    exchangeToken: (data: AuthenticationExchangeTokenRequest, config?: ApiRequestConfig) => Promise<UserDetailProperties>,
    createLabconnectRegistrationToken: (data: AuthenticationCreateLabconnectRegistrationTokenRequest, config?: ApiRequestConfig) => Promise<AuthenticationCreateLabconnectRegistrationTokenResponse>,
    requestPasswordReset: (data: AuthenticationRequestPasswordResetRequest, config?: ApiRequestConfig) => Promise<void>,
    changePassword: (data: AuthenticationChangePasswordRequest, config?: ApiRequestConfig) => Promise<void>,
    resetPassword: (data: AuthenticationResetPasswordRequest, config?: ApiRequestConfig) => Promise<void>,
    checkPassword: (data: AuthenticationCheckPasswordRequest, config?: ApiRequestConfig) => Promise<void>,
    initializeTwoFactorAuthentication: (data?: void, config?: ApiRequestConfig) => Promise<AuthenticationInitializeTwoFactorAuthenticationResponse>,
    enableTwoFactorAuthentication: (data?: AuthenticationEnableTwoFactorAuthenticationRequest, config?: ApiRequestConfig) => Promise<void>,
    disableTwoFactorAuthentication: (data?: AuthenticationDisableTwoFactorAuthenticationRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  globals: {
    getUserConfig: (data?: void, config?: ApiRequestConfig) => Promise<GlobalConfigProperties>,
    updateConfig: (data?: GlobalsUpdateConfigRequest, config?: ApiRequestConfig) => Promise<GlobalConfigProperties>,
    getAutocomplete: (data?: GlobalsGetAutocompleteRequest, config?: ApiRequestConfig) => Promise<GlobalsGetAutocompleteResponse>,
    getServerEnv: (data?: void, config?: ApiRequestConfig) => Promise<GlobalsGetServerEnvResponse>,
    getVersionBadge: (data?: GlobalsGetVersionBadgeRequest, config?: ApiRequestConfig) => Promise<File>,
    getWhatsappQrCode: (data: GlobalsGetWhatsappQrCodeRequest, config?: ApiRequestConfig) => Promise<File>,
    getBuildInfo: (data?: void, config?: ApiRequestConfig) => Promise<File>,
    sendContactForm: (data?: GlobalsSendContactFormRequest, config?: ApiRequestConfig) => Promise<void>,
    getContactInfo: (data?: void, config?: ApiRequestConfig) => Promise<ContactInfo[]>,
    getPrivacyStatement: (data?: void, config?: ApiRequestConfig) => Promise<LegalInfo>,
    getTermsAndConditions: (data?: void, config?: ApiRequestConfig) => Promise<LegalInfo>,
  },

  ordersDashboard: {
    getDevelopment: (data?: OrdersDashboardGetDevelopmentRequest, config?: ApiRequestConfig) => Promise<OrdersDashboardGetDevelopmentResponse>,
    getTopRequests: (data?: OrdersDashboardGetTopRequestsRequest, config?: ApiRequestConfig) => Promise<OrdersDashboardGetTopRequestsResponse>,
    getTopUsers: (data?: OrdersDashboardGetTopUsersRequest, config?: ApiRequestConfig) => Promise<OrdersDashboardGetTopUsersResponse>,
  },

  reportsDashboard: {
    getDemographic: (data?: void, config?: ApiRequestConfig) => Promise<ReportsDashboardGetDemographicResponse>,
    getTopGroups: (data?: ReportsDashboardGetTopGroupsRequest, config?: ApiRequestConfig) => Promise<ReportsDashboardGetTopGroupsResponse>,
    getTypes: (data?: ReportsDashboardGetTypesRequest, config?: ApiRequestConfig) => Promise<ReportsDashboardGetTypesResponse>,
  },

  exchangeDashboard: {
    getFileTypesOverview: (data?: ExchangeDashboardGetFileTypesOverviewRequest, config?: ApiRequestConfig) => Promise<NumberValue[]>,
    getExportHistory: (data?: ExchangeDashboardGetExportHistoryRequest, config?: ApiRequestConfig) => Promise<NumberValue[]>,
    getLaboratoryGroupDistribution: (data?: ExchangeDashboardGetLaboratoryGroupDistributionRequest, config?: ApiRequestConfig) => Promise<NumberValue[]>,
  },

  dashboard: {
    getDefaultDashboardData: (data?: void, config?: ApiRequestConfig) => Promise<DashboardGetDefaultDashboardDataResponse>,
    getAdminStats: (data?: void, config?: ApiRequestConfig) => Promise<DataStatistics>,
  },

  users: {
    activateUser: (data?: UsersActivateUserRequest, config?: ApiRequestConfig) => Promise<UserEntityProperties>,
    reactivateUser: (data?: UsersReactivateUserRequest, config?: ApiRequestConfig) => Promise<UserEntityProperties>,
    deactivateUser: (data?: UsersDeactivateUserRequest, config?: ApiRequestConfig) => Promise<UserEntityProperties>,
    resendWelcomeMail: (data?: UsersResendWelcomeMailRequest, config?: ApiRequestConfig) => Promise<UserEntityProperties>,
    enableDebugForUser: (data?: UsersEnableDebugForUserRequest, config?: ApiRequestConfig) => Promise<void>,
    setUserApiDocs: (data?: UsersSetUserApiDocsRequest, config?: ApiRequestConfig) => Promise<UserEntityProperties>,
    disableTwoFactorAuthForUser: (data?: UsersDisableTwoFactorAuthForUserRequest, config?: ApiRequestConfig) => Promise<void>,
    changeUsername: (data?: UsersChangeUsernameRequest, config?: ApiRequestConfig) => Promise<UserEntityProperties>,
    changeUserPassword: (data?: UsersChangeUserPasswordRequest, config?: ApiRequestConfig) => Promise<UserEntityProperties>,
    getUserLog: (data?: UsersGetUserLogRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<UserLog>>,
  },

  profile: {
    getUserProfile: (data?: void, config?: ApiRequestConfig) => Promise<UserProfileProperties>,
    updateUserProfile: (data?: ProfileUpdateUserProfileRequest, config?: ApiRequestConfig) => Promise<UserProfileProperties>,
    updateAvatarUserProfile: (data?: ProfileUpdateAvatarUserProfileRequest, config?: ApiRequestConfig) => Promise<void>,
    getUserAvatar: (data: ProfileGetUserAvatarRequest, config?: ApiRequestConfig) => Promise<File>,
    getVcardQrCode: (data: ProfileGetVcardQrCodeRequest, config?: ApiRequestConfig) => Promise<File>,
    getVcard: (data: ProfileGetVcardRequest, config?: ApiRequestConfig) => Promise<File>,
  },

  devices: {
    listDevices: (data?: DevicesListDevicesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Device>>,
    createDevice: (data: DevicesCreateDeviceRequest, config?: ApiRequestConfig) => Promise<Device>,
    deleteDevice: (data: DevicesDeleteDeviceRequest, config?: ApiRequestConfig) => Promise<Device>,
    approveDevice: (data: DevicesApproveDeviceRequest, config?: ApiRequestConfig) => Promise<Device>,
    denyDevice: (data: DevicesDenyDeviceRequest, config?: ApiRequestConfig) => Promise<Device>,
    revokeDevice: (data: DevicesRevokeDeviceRequest, config?: ApiRequestConfig) => Promise<Device>,
    setDeviceNote: (data: DevicesSetDeviceNoteRequest, config?: ApiRequestConfig) => Promise<void>,
    setPushToken: (data: DevicesSetPushTokenRequest, config?: ApiRequestConfig) => Promise<DevicesSetPushTokenResponse>,
    createDeviceToken: (data?: void, config?: ApiRequestConfig) => Promise<DevicesCreateDeviceTokenResponse>,
  },

  administrators: {
    listAdministrators: (data?: AdministratorsListAdministratorsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Administrator>>,
    createAdministrator: (data: AdministratorsCreateAdministratorRequest, config?: ApiRequestConfig) => Promise<Administrator>,
    readAdministrator: (data: AdministratorsReadAdministratorRequest, config?: ApiRequestConfig) => Promise<Administrator>,
    updateAdministrator: (data: AdministratorsUpdateAdministratorRequest, config?: ApiRequestConfig) => Promise<Administrator>,
    removeAdministrator: (data: AdministratorsRemoveAdministratorRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  doctorHierarchy: {
    getDoctorHierarchyList: (data?: DoctorHierarchyGetDoctorHierarchyListRequest, config?: ApiRequestConfig) => Promise<DoctorHierarchyGroupProperties>,
    assignDoctorHierarchyUser: (data: DoctorHierarchyAssignDoctorHierarchyUserRequest, config?: ApiRequestConfig) => Promise<DoctorHierarchy>,
    createDoctorHierarchyUser: (data: DoctorHierarchyCreateDoctorHierarchyUserRequest, config?: ApiRequestConfig) => Promise<DoctorHierarchy>,
    getDoctorHierarchyTree: (data?: DoctorHierarchyGetDoctorHierarchyTreeRequest, config?: ApiRequestConfig) => Promise<DoctorHierarchyPathProperties[]>,
    readDoctorHierarchy: (data: DoctorHierarchyReadDoctorHierarchyRequest, config?: ApiRequestConfig) => Promise<DoctorHierarchy[]>,
    removeDoctorHierarchyUser: (data: DoctorHierarchyRemoveDoctorHierarchyUserRequest, config?: ApiRequestConfig) => Promise<void>,
    updateDoctorHierarchyUser: (data: DoctorHierarchyUpdateDoctorHierarchyUserRequest, config?: ApiRequestConfig) => Promise<DoctorHierarchy>,
  },

  doctors: {
    listDoctors: (data?: DoctorsListDoctorsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Doctor>>,
    createDoctor: (data?: DoctorsCreateDoctorRequest, config?: ApiRequestConfig) => Promise<DoctorsCreateDoctorResponse>,
    readDoctor: (data: DoctorsReadDoctorRequest, config?: ApiRequestConfig) => Promise<Doctor>,
    updateDoctor: (data: DoctorsUpdateDoctorRequest, config?: ApiRequestConfig) => Promise<Doctor>,
    deleteDoctor: (data: DoctorsDeleteDoctorRequest, config?: ApiRequestConfig) => Promise<void>,
    getDoctorgroupsForDoctor: (data: DoctorsGetDoctorgroupsForDoctorRequest, config?: ApiRequestConfig) => Promise<DoctorGroupMembershipProperties[]>,
    setDoctorgroupsForDoctor: (data: DoctorsSetDoctorgroupsForDoctorRequest, config?: ApiRequestConfig) => Promise<DoctorGroupMembershipProperties[]>,
  },

  doctorDevices: {
    listDoctorDevices: (data: DoctorDevicesListDoctorDevicesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<DoctorDevice>>,
    updateDoctorDevices: (data: DoctorDevicesUpdateDoctorDevicesRequest, config?: ApiRequestConfig) => Promise<DoctorDevice>,
    updateDoctorDevice: (data: DoctorDevicesUpdateDoctorDeviceRequest, config?: ApiRequestConfig) => Promise<DoctorDevice>,
    deleteDoctorDevice: (data: DoctorDevicesDeleteDoctorDeviceRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  laboratories: {
    listLaboratories: (data?: LaboratoriesListLaboratoriesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Laboratory>>,
    createLaboratory: (data: LaboratoriesCreateLaboratoryRequest, config?: ApiRequestConfig) => Promise<Laboratory>,
    readLaboratory: (data: LaboratoriesReadLaboratoryRequest, config?: ApiRequestConfig) => Promise<Laboratory>,
    updateLaboratory: (data: LaboratoriesUpdateLaboratoryRequest, config?: ApiRequestConfig) => Promise<Laboratory>,
    uploadLaboratoryLogo: (data: LaboratoriesUploadLaboratoryLogoRequest, config?: ApiRequestConfig) => Promise<void>,
    getLaboratoryDetails: (data: LaboratoriesGetLaboratoryDetailsRequest, config?: ApiRequestConfig) => Promise<LaboratoriesGetLaboratoryDetailsResponse>,
    updateLaboratoryDetails: (data: LaboratoriesUpdateLaboratoryDetailsRequest, config?: ApiRequestConfig) => Promise<LaboratoriesUpdateLaboratoryDetailsResponse>,
    getLaboratoryPermissions: (data: LaboratoriesGetLaboratoryPermissionsRequest, config?: ApiRequestConfig) => Promise<LaboratoriesGetLaboratoryPermissionsResponse>,
    updateLaboratoryPermissions: (data: LaboratoriesUpdateLaboratoryPermissionsRequest, config?: ApiRequestConfig) => Promise<LaboratoriesUpdateLaboratoryPermissionsResponse>,
  },

  laboratoryUsers: {
    listLaboratoryUsers: (data?: LaboratoryUsersListLaboratoryUsersRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<LaboratoryUser>>,
    createLaboratoryUser: (data: LaboratoryUsersCreateLaboratoryUserRequest, config?: ApiRequestConfig) => Promise<LaboratoryUser>,
    readLaboratoryUser: (data: LaboratoryUsersReadLaboratoryUserRequest, config?: ApiRequestConfig) => Promise<LaboratoryUser>,
    updateLaboratoryUser: (data: LaboratoryUsersUpdateLaboratoryUserRequest, config?: ApiRequestConfig) => Promise<LaboratoryUser>,
    deleteLaboratoryUser: (data: LaboratoryUsersDeleteLaboratoryUserRequest, config?: ApiRequestConfig) => Promise<void>,
    getLaboratoryUserPermissions: (data: LaboratoryUsersGetLaboratoryUserPermissionsRequest, config?: ApiRequestConfig) => Promise<LaboratoryUsersGetLaboratoryUserPermissionsResponse>,
    updateLaboratoryUserPermissions: (data: LaboratoryUsersUpdateLaboratoryUserPermissionsRequest, config?: ApiRequestConfig) => Promise<LaboratoryUsersUpdateLaboratoryUserPermissionsResponse>,
  },

  patients: {
    listPatients: (data?: PatientsListPatientsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Patient>>,
    createPatient: (data: PatientsCreatePatientRequest, config?: ApiRequestConfig) => Promise<Patient>,
    readPatient: (data: PatientsReadPatientRequest, config?: ApiRequestConfig) => Promise<Patient>,
    updatePatient: (data: PatientsUpdatePatientRequest, config?: ApiRequestConfig) => Promise<Patient>,
    removePatient: (data: PatientsRemovePatientRequest, config?: ApiRequestConfig) => Promise<void>,
    readPatientByIdentification: (data: PatientsReadPatientByIdentificationRequest, config?: ApiRequestConfig) => Promise<Patient>,
  },

  reports: {
    listReports: (data?: ReportsListReportsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Report>>,
    deleteAllReportsForAUser: (data: ReportsDeleteAllReportsForAUserRequest, config?: ApiRequestConfig) => Promise<void>,
    getReportsConfig: (data?: void, config?: ApiRequestConfig) => Promise<ReportsGetReportsConfigResponse>,
    getQuickAccessPdf: (data?: ReportsGetQuickAccessPdfRequest, config?: ApiRequestConfig) => Promise<File>,
    registerQuickAccessEmail: (data: ReportsRegisterQuickAccessEmailRequest, config?: ApiRequestConfig) => Promise<void>,
    readReportByGuid: (data: ReportsReadReportByGuidRequest, config?: ApiRequestConfig) => Promise<ReportsReadReportByGuidResponse>,
    notifyReportByGuid: (data: ReportsNotifyReportByGuidRequest, config?: ApiRequestConfig) => Promise<void>,
    getReportPdfByGuid: (data: ReportsGetReportPdfByGuidRequest, config?: ApiRequestConfig) => Promise<File>,
    importIdexxReport: (data: ReportsImportIdexxReportRequest, config?: ApiRequestConfig) => Promise<void>,
    listReportsForAis: (data?: void, config?: ApiRequestConfig) => Promise<ReportsListReportsForAisResponse>,
    readReportForAis: (data: ReportsReadReportForAisRequest, config?: ApiRequestConfig) => Promise<ReportsReadReportForAisResponse>,
    setAllReportsRead: (data: ReportsSetAllReportsReadRequest, config?: ApiRequestConfig) => Promise<void>,
    createFilterProfile: (data?: ReportsCreateFilterProfileRequest, config?: ApiRequestConfig) => Promise<FilterProfile[]>,
    updateFilterProfile: (data: ReportsUpdateFilterProfileRequest, config?: ApiRequestConfig) => Promise<FilterProfile[]>,
    deleteFilterProfile: (data: ReportsDeleteFilterProfileRequest, config?: ApiRequestConfig) => Promise<void>,
    toggleFilterProfileFavorite: (data: ReportsToggleFilterProfileFavoriteRequest, config?: ApiRequestConfig) => Promise<void>,
    readReport: (data: ReportsReadReportRequest, config?: ApiRequestConfig) => Promise<Report>,
    deleteReport: (data: ReportsDeleteReportRequest, config?: ApiRequestConfig) => Promise<void>,
    getReportPdf: (data: ReportsGetReportPdfRequest, config?: ApiRequestConfig) => Promise<File>,
    getReportOriginalPdfImages: (data: ReportsGetReportOriginalPdfImagesRequest, config?: ApiRequestConfig) => Promise<ReportsGetReportOriginalPdfImagesResponse>,
    setReadReport: (data: ReportsSetReadReportRequest, config?: ApiRequestConfig) => Promise<void>,
    getReportElpho: (data: ReportsGetReportElphoRequest, config?: ApiRequestConfig) => Promise<File>,
    getReportHistory: (data: ReportsGetReportHistoryRequest, config?: ApiRequestConfig) => Promise<ReportHistoryValue[]>,
    resetReportViewCount: (data: ReportsResetReportViewCountRequest, config?: ApiRequestConfig) => Promise<void>,
    sendReport: (data: ReportsSendReportRequest, config?: ApiRequestConfig) => Promise<void>,
    sendReportApi: (data: ReportsSendReportApiRequest, config?: ApiRequestConfig) => Promise<void>,
    contactLaboratory: (data: ReportsContactLaboratoryRequest, config?: ApiRequestConfig) => Promise<void>,
    unrealRerequest: (data: ReportsUnrealRerequestRequest, config?: ApiRequestConfig) => Promise<void>,
    pinReport: (data: ReportsPinReportRequest, config?: ApiRequestConfig) => Promise<void>,
    unpinReport: (data: ReportsUnpinReportRequest, config?: ApiRequestConfig) => Promise<void>,
    listReportComments: (data: ReportsListReportCommentsRequest, config?: ApiRequestConfig) => Promise<ReportsListReportCommentsResponse>,
    createReportComment: (data: ReportsCreateReportCommentRequest, config?: ApiRequestConfig) => Promise<ReportsCreateReportCommentResponse>,
    deleteReportComment: (data: ReportsDeleteReportCommentRequest, config?: ApiRequestConfig) => Promise<ReportsDeleteReportCommentResponse>,
    listReportAttachments: (data: ReportsListReportAttachmentsRequest, config?: ApiRequestConfig) => Promise<ReportsListReportAttachmentsResponse>,
    uploadAttachment: (data: ReportsUploadAttachmentRequest, config?: ApiRequestConfig) => Promise<ReportsUploadAttachmentResponse>,
    downloadAttachment: (data: ReportsDownloadAttachmentRequest, config?: ApiRequestConfig) => Promise<File>,
    deleteAttachment: (data: ReportsDeleteAttachmentRequest, config?: ApiRequestConfig) => Promise<ReportsDeleteAttachmentResponse>,
    getReportAccessLog: (data: ReportsGetReportAccessLogRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<ReportAccessLog>>,
    getReportChangeLog: (data: ReportsGetReportChangeLogRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<ReportChangeLog>>,
    unlockReport: (data: ReportsUnlockReportRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  reportsSettings: {
    readGeneralReportsConfig: (data?: void, config?: ApiRequestConfig) => Promise<ReportsSettingsReadGeneralReportsConfigResponse>,
    updateGeneralReportsConfig: (data?: ReportsSettingsUpdateGeneralReportsConfigRequest, config?: ApiRequestConfig) => Promise<void>,
    readUserReportsConfig: (data: ReportsSettingsReadUserReportsConfigRequest, config?: ApiRequestConfig) => Promise<ReportsSettingsReadUserReportsConfigResponse>,
    updateUserReportsConfig: (data: ReportsSettingsUpdateUserReportsConfigRequest, config?: ApiRequestConfig) => Promise<void>,
    resetUserReportsPreferences: (data: ReportsSettingsResetUserReportsPreferencesRequest, config?: ApiRequestConfig) => Promise<void>,
    resetUserTypeReportsPreferences: (data: ReportsSettingsResetUserTypeReportsPreferencesRequest, config?: ApiRequestConfig) => Promise<ReportsSettingsResetUserTypeReportsPreferencesResponse>,
    getReportAnalysesSettings: (data?: void, config?: ApiRequestConfig) => Promise<ReportsPdfDownloadAnalysesSettings>,
    updateReportAnalysesSettings: (data?: ReportsSettingsUpdateReportAnalysesSettingsRequest, config?: ApiRequestConfig) => Promise<void>,
    getBlacklistSettings: (data?: void, config?: ApiRequestConfig) => Promise<ReportsSettingsGetBlacklistSettingsResponse>,
    updateBlacklistSettings: (data?: ReportsSettingsUpdateBlacklistSettingsRequest, config?: ApiRequestConfig) => Promise<ReportsSettingsUpdateBlacklistSettingsResponse>,
    getCustomPushSettings: (data?: void, config?: ApiRequestConfig) => Promise<ReportsSettingsGetCustomPushSettingsResponse>,
    updateCustomPushSettings: (data?: ReportsSettingsUpdateCustomPushSettingsRequest, config?: ApiRequestConfig) => Promise<ReportsSettingsUpdateCustomPushSettingsResponse>,
    getOriginalReportSettings: (data?: void, config?: ApiRequestConfig) => Promise<OriginalReportSettings>,
    updateOriginalReportSettings: (data?: ReportsSettingsUpdateOriginalReportSettingsRequest, config?: ApiRequestConfig) => Promise<OriginalReportSettings>,
    exportCustomSortCsv: (data?: void, config?: ApiRequestConfig) => Promise<File>,
    listCustomSortCsv: (data?: ReportsSettingsListCustomSortCsvRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<CustomSort>>,
    importCustomSortCsv: (data: ReportsSettingsImportCustomSortCsvRequest, config?: ApiRequestConfig) => Promise<ReportsSettingsImportCustomSortCsvResponse>,
    readReportQuickAccessConfig: (data?: void, config?: ApiRequestConfig) => Promise<ReportsSettingsReadReportQuickAccessConfigResponse>,
    updateReportQuickAccessConfig: (data?: ReportsSettingsUpdateReportQuickAccessConfigRequest, config?: ApiRequestConfig) => Promise<void>,
    readReportQuickScanConfig: (data?: void, config?: ApiRequestConfig) => Promise<ReportsSettingsReadReportQuickScanConfigResponse>,
    updateReportQuickScanConfig: (data?: ReportsSettingsUpdateReportQuickScanConfigRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  orders: {
    getOrdersConfig: (data?: void, config?: ApiRequestConfig) => Promise<OrdersGetOrdersConfigResponse>,
    listOrders: (data?: OrdersListOrdersRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<ComposedOrderProperties>>,
    upsertOrders: (data: OrdersUpsertOrdersRequest, config?: ApiRequestConfig) => Promise<ComposedOrderRulesProperties>,
    deleteOrder: (data?: OrdersDeleteOrderRequest, config?: ApiRequestConfig) => Promise<void>,
    scheduleOrders: (data?: OrdersScheduleOrdersRequest, config?: ApiRequestConfig) => Promise<void>,
    executeOrders: (data?: OrdersExecuteOrdersRequest, config?: ApiRequestConfig) => Promise<ComposedOrderRulesProperties>,
    preprintOrders: (data?: OrdersPreprintOrdersRequest, config?: ApiRequestConfig) => Promise<ComposedOrderRulesProperties>,
    startEditOrders: (data?: OrdersStartEditOrdersRequest, config?: ApiRequestConfig) => Promise<WriteableOrderProperties[]>,
    cancelEditOrders: (data?: OrdersCancelEditOrdersRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    cancelOrders: (data?: OrdersCancelOrdersRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    cancelOrderRequirement: (data: OrdersCancelOrderRequirementRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    uncancelOrderRequirement: (data: OrdersUncancelOrderRequirementRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    softDeleteOrder: (data?: OrdersSoftDeleteOrderRequest, config?: ApiRequestConfig) => Promise<void>,
    signOrders: (data?: OrdersSignOrdersRequest, config?: ApiRequestConfig) => Promise<void>,
    exportWithoutPdf: (data?: OrdersExportWithoutPdfRequest, config?: ApiRequestConfig) => Promise<void>,
    getOrderByCaseNumber: (data: OrdersGetOrderByCaseNumberRequest, config?: ApiRequestConfig) => Promise<WriteableOrderProperties>,
    getOrderByExttnr: (data: OrdersGetOrderByExttnrRequest, config?: ApiRequestConfig) => Promise<WriteableOrderProperties>,
    getOrdersByPatient: (data: OrdersGetOrdersByPatientRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties[]>,
    readOrderPool: (data: OrdersReadOrderPoolRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    readOrder: (data: OrdersReadOrderRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    getOrderChangeLog: (data: OrdersGetOrderChangeLogRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<OrderChangeLog>>,
    changeOrdersWorkstation: (data?: OrdersChangeOrdersWorkstationRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  transportationList: {
    createTransportation: (data?: TransportationListCreateTransportationRequest, config?: ApiRequestConfig) => Promise<TransportationHistory>,
    listTransportationHistory: (data: TransportationListListTransportationHistoryRequest, config?: ApiRequestConfig) => Promise<TransportationHistory[]>,
    exportTransportation: (data?: TransportationListExportTransportationRequest, config?: ApiRequestConfig) => Promise<File>,
  },

  journal: {
    exportJournalPdf: (data: JournalExportJournalPdfRequest, config?: ApiRequestConfig) => Promise<File>,
  },

  orderSettings: {
    readGeneralOrdersConfig: (data?: void, config?: ApiRequestConfig) => Promise<OrderSettingsReadGeneralOrdersConfigResponse>,
    updateGeneralOrdersConfig: (data?: OrderSettingsUpdateGeneralOrdersConfigRequest, config?: ApiRequestConfig) => Promise<void>,
    readUserOrdersConfig: (data: OrderSettingsReadUserOrdersConfigRequest, config?: ApiRequestConfig) => Promise<OrderSettingsReadUserOrdersConfigResponse>,
    updateUserOrdersConfig: (data: OrderSettingsUpdateUserOrdersConfigRequest, config?: ApiRequestConfig) => Promise<void>,
    resetUserOrdersPreferences: (data: OrderSettingsResetUserOrdersPreferencesRequest, config?: ApiRequestConfig) => Promise<OrderSettingsResetUserOrdersPreferencesResponse>,
    resetUserTypeOrdersPreferences: (data: OrderSettingsResetUserTypeOrdersPreferencesRequest, config?: ApiRequestConfig) => Promise<OrderSettingsResetUserTypeOrdersPreferencesResponse>,
    getOrderExportSettings: (data?: void, config?: ApiRequestConfig) => Promise<OrderExportSettingsProperties>,
    updateOrderExportSettings: (data?: OrderSettingsUpdateOrderExportSettingsRequest, config?: ApiRequestConfig) => Promise<OrderExportSettingsProperties>,
    listSamples: (data?: OrderSettingsListSamplesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Sample>>,
    createSample: (data?: OrderSettingsCreateSampleRequest, config?: ApiRequestConfig) => Promise<Sample>,
    listManufacturers: (data?: void, config?: ApiRequestConfig) => Promise<OrderSettingsListManufacturersResponse>,
    readSample: (data: OrderSettingsReadSampleRequest, config?: ApiRequestConfig) => Promise<Sample>,
    deleteSample: (data: OrderSettingsDeleteSampleRequest, config?: ApiRequestConfig) => Promise<void>,
    updateSample: (data: OrderSettingsUpdateSampleRequest, config?: ApiRequestConfig) => Promise<Sample>,
    listAnamnesisQuestions: (data?: void, config?: ApiRequestConfig) => Promise<AnamnesisQuestion[]>,
    readAnamnesisQuestion: (data: OrderSettingsReadAnamnesisQuestionRequest, config?: ApiRequestConfig) => Promise<AnamnesisQuestion>,
    listOrderRules: (data?: OrderSettingsListOrderRulesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<OrderRule>>,
    createOrderRule: (data?: OrderSettingsCreateOrderRuleRequest, config?: ApiRequestConfig) => Promise<OrderRule>,
    listOrderRuleGroups: (data?: OrderSettingsListOrderRuleGroupsRequest, config?: ApiRequestConfig) => Promise<OrderSettingsListOrderRuleGroupsResponse>,
    readOrderRule: (data: OrderSettingsReadOrderRuleRequest, config?: ApiRequestConfig) => Promise<OrderRule>,
    deleteOrderRule: (data: OrderSettingsDeleteOrderRuleRequest, config?: ApiRequestConfig) => Promise<void>,
    updateOrderRule: (data: OrderSettingsUpdateOrderRuleRequest, config?: ApiRequestConfig) => Promise<OrderRule>,
    listOrderForms: (data?: OrderSettingsListOrderFormsRequest, config?: ApiRequestConfig) => Promise<OrderForm[]>,
    createOrderForm: (data?: OrderSettingsCreateOrderFormRequest, config?: ApiRequestConfig) => Promise<OrderForm>,
    readOrderForm: (data: OrderSettingsReadOrderFormRequest, config?: ApiRequestConfig) => Promise<OrderForm>,
    updateOrderForm: (data: OrderSettingsUpdateOrderFormRequest, config?: ApiRequestConfig) => Promise<OrderForm>,
    deleteOrderForm: (data: OrderSettingsDeleteOrderFormRequest, config?: ApiRequestConfig) => Promise<void>,
    readAnalysis: (data: OrderSettingsReadAnalysisRequest, config?: ApiRequestConfig) => Promise<ClinicalAnalysis>,
    updateAnalysis: (data: OrderSettingsUpdateAnalysisRequest, config?: ApiRequestConfig) => Promise<ClinicalAnalysis>,
    listAnalyses: (data?: OrderSettingsListAnalysesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<ClinicalAnalysis>>,
    updateAnalyses: (data?: OrderSettingsUpdateAnalysesRequest, config?: ApiRequestConfig) => Promise<ClinicalAnalysis>,
    getEbmList: (data?: void, config?: ApiRequestConfig) => Promise<Ebm[]>,
    readMaterial: (data: OrderSettingsReadMaterialRequest, config?: ApiRequestConfig) => Promise<ClinicalMaterial>,
    updateMaterial: (data: OrderSettingsUpdateMaterialRequest, config?: ApiRequestConfig) => Promise<ClinicalMaterial>,
    listMaterials: (data?: OrderSettingsListMaterialsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<ClinicalMaterial>>,
    updateMaterials: (data?: OrderSettingsUpdateMaterialsRequest, config?: ApiRequestConfig) => Promise<ClinicalMaterial>,
    readRequirement: (data: OrderSettingsReadRequirementRequest, config?: ApiRequestConfig) => Promise<OrderAdminRequirement>,
    deleteRequirement: (data: OrderSettingsDeleteRequirementRequest, config?: ApiRequestConfig) => Promise<void>,
    updateRequirement: (data: OrderSettingsUpdateRequirementRequest, config?: ApiRequestConfig) => Promise<OrderAdminRequirement>,
    listRequirements: (data?: OrderSettingsListRequirementsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<ClinicalRequirement>>,
    createRequirement: (data?: OrderSettingsCreateRequirementRequest, config?: ApiRequestConfig) => Promise<ClinicalRequirement>,
    updateRequirements: (data?: OrderSettingsUpdateRequirementsRequest, config?: ApiRequestConfig) => Promise<ClinicalRequirement>,
    replaceRequirementAssociations: (data: OrderSettingsReplaceRequirementAssociationsRequest, config?: ApiRequestConfig) => Promise<void>,
    listRequirementLaboratoryGroups: (data?: void, config?: ApiRequestConfig) => Promise<OrderSettingsListRequirementLaboratoryGroupsResponse>,
    listRequirementSubmitters: (data?: void, config?: ApiRequestConfig) => Promise<OrderSettingsListRequirementSubmittersResponse>,
    overtakeOaSettingsForDoctor: (data: OrderSettingsOvertakeOaSettingsForDoctorRequest, config?: ApiRequestConfig) => Promise<void>,
    updateGroupFormsOrder: (data: OrderSettingsUpdateGroupFormsOrderRequest, config?: ApiRequestConfig) => Promise<void>,
    readBakAnalysis: (data: OrderSettingsReadBakAnalysisRequest, config?: ApiRequestConfig) => Promise<UnifiedMicrobiologicalAnalysis>,
    updateBakAnalysis: (data: OrderSettingsUpdateBakAnalysisRequest, config?: ApiRequestConfig) => Promise<UnifiedMicrobiologicalAnalysis>,
    listBakAnalyses: (data?: OrderSettingsListBakAnalysesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<MicrobiologicalAnalysis>>,
    listBakMaterials: (data?: OrderSettingsListBakMaterialsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<MicrobiologicalMaterial>>,
    readBakMaterial: (data: OrderSettingsReadBakMaterialRequest, config?: ApiRequestConfig) => Promise<UnifiedMicrobiologicalMaterial>,
    updateBakMaterial: (data: OrderSettingsUpdateBakMaterialRequest, config?: ApiRequestConfig) => Promise<UnifiedMicrobiologicalMaterial>,
    exportDoctorsFavorites: (data?: void, config?: ApiRequestConfig) => Promise<File>,
  },

  gdtPatient: {
    listGdtPatients: (data: GdtPatientListGdtPatientsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<PatientGDT>>,
    createOrUpdateGdtPatient: (data?: GdtPatientCreateOrUpdateGdtPatientRequest, config?: ApiRequestConfig) => Promise<GdtPatientCreateOrUpdateGdtPatientResponse>,
    readGdtPatients: (data: GdtPatientReadGdtPatientsRequest, config?: ApiRequestConfig) => Promise<PatientGDT[]>,
    readGdtPatient: (data: GdtPatientReadGdtPatientRequest, config?: ApiRequestConfig) => Promise<PatientGDT>,
    readGdtPatientFromTimestamp: (data: GdtPatientReadGdtPatientFromTimestampRequest, config?: ApiRequestConfig) => Promise<PatientGDT>,
    listGdtPatientGroups: (data: GdtPatientListGdtPatientGroupsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<OrderPatientGroupProps>>,
    getPatientReports: (data: GdtPatientGetPatientReportsRequest, config?: ApiRequestConfig) => Promise<GdtPatientGetPatientReportsResponse>,
  },

  requirements: {
    listDoctorRequirements: (data: RequirementsListDoctorRequirementsRequest, config?: ApiRequestConfig) => Promise<RequirementsListDoctorRequirementsResponse>,
    pinRequirement: (data?: RequirementsPinRequirementRequest, config?: ApiRequestConfig) => Promise<void>,
    unpinRequirement: (data?: RequirementsUnpinRequirementRequest, config?: ApiRequestConfig) => Promise<void>,
    getRequirementsAutocomplete: (data: RequirementsGetRequirementsAutocompleteRequest, config?: ApiRequestConfig) => Promise<RequirementsGetRequirementsAutocompleteResponse>,
    readRequirement: (data: RequirementsReadRequirementRequest, config?: ApiRequestConfig) => Promise<OrderWizardRequirement>,
  },

  samples: {
    getSampleImage: (data: SamplesGetSampleImageRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  orderWizard: {
    getAnamnesisQuestionTree: (data?: OrderWizardGetAnamnesisQuestionTreeRequest, config?: ApiRequestConfig) => Promise<AnamnesisQuestion[]>,
    getBasket: (data: OrderWizardGetBasketRequest, config?: ApiRequestConfig) => Promise<OrderWizardGetBasketResponse>,
    getSettings: (data?: OrderWizardGetSettingsRequest, config?: ApiRequestConfig) => Promise<OrderWizardSettings>,
  },

  orderWizardAustriaSettings: {
    getAustriaHvCodes: (data?: void, config?: ApiRequestConfig) => Promise<OrderAustriaHvCode[]>,
    getAustriaHvCode: (data: OrderWizardAustriaSettingsGetAustriaHvCodeRequest, config?: ApiRequestConfig) => Promise<OrderAustriaHvCode>,
    getAustriaInsuranceCategories: (data?: void, config?: ApiRequestConfig) => Promise<OrderAustriaInsuranceCategory[]>,
  },

  profiles: {
    copyProfiles: (data: ProfilesCopyProfilesRequest, config?: ApiRequestConfig) => Promise<ProfilesCopyProfilesResponse>,
    pdfExportProfiles: (data: ProfilesPdfExportProfilesRequest, config?: ApiRequestConfig) => Promise<File>,
    createProfile: (data: ProfilesCreateProfileRequest, config?: ApiRequestConfig) => Promise<void>,
    listDoctorProfiles: (data: ProfilesListDoctorProfilesRequest, config?: ApiRequestConfig) => Promise<RequirementProfile[]>,
    batchUpdateProfiles: (data: ProfilesBatchUpdateProfilesRequest, config?: ApiRequestConfig) => Promise<void>,
    readProfile: (data: ProfilesReadProfileRequest, config?: ApiRequestConfig) => Promise<RequirementProfile>,
    updateProfile: (data: ProfilesUpdateProfileRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  completion: {
    complete: (data?: CompletionCompleteRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  realReRequest: {
    readReRequestDetails: (data: RealReRequestReadReRequestDetailsRequest, config?: ApiRequestConfig) => Promise<ReRequestOrderProperties>,
    startRealReRequest: (data?: RealReRequestStartRealReRequestRequest, config?: ApiRequestConfig) => Promise<WriteableOrderProperties[]>,
    sendOffRealReRequest: (data: RealReRequestSendOffRealReRequestRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    cancelRealReRequest: (data?: RealReRequestCancelRealReRequestRequest, config?: ApiRequestConfig) => Promise<ComposedOrderProperties>,
    acceptAdditionalRequirements: (data?: RealReRequestAcceptAdditionalRequirementsRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  print: {
    printBarcode: (data: PrintPrintBarcodeRequest, config?: ApiRequestConfig) => Promise<void>,
    expendExtraBarcode: (data: PrintExpendExtraBarcodeRequest, config?: ApiRequestConfig) => Promise<void>,
    printOrderForm: (data: PrintPrintOrderFormRequest, config?: ApiRequestConfig) => Promise<File>,
  },

  messageCenter: {
    listMessageThreads: (data?: MessageCenterListMessageThreadsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<MessageThread>>,
    createMessageThread: (data?: MessageCenterCreateMessageThreadRequest, config?: ApiRequestConfig) => Promise<MessageThread>,
    readMessageThread: (data: MessageCenterReadMessageThreadRequest, config?: ApiRequestConfig) => Promise<MessageThread>,
    deleteMessageThread: (data: MessageCenterDeleteMessageThreadRequest, config?: ApiRequestConfig) => Promise<void>,
    listMessages: (data: MessageCenterListMessagesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Message>>,
    createMessage: (data: MessageCenterCreateMessageRequest, config?: ApiRequestConfig) => Promise<Message>,
    readMessage: (data: MessageCenterReadMessageRequest, config?: ApiRequestConfig) => Promise<Message>,
    starMessageThread: (data: MessageCenterStarMessageThreadRequest, config?: ApiRequestConfig) => Promise<void>,
    unstarMessageThread: (data: MessageCenterUnstarMessageThreadRequest, config?: ApiRequestConfig) => Promise<void>,
    listUsersToBeMessaged: (data?: MessageCenterListUsersToBeMessagedRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<UserShortInfoProperties>>,
  },

  authSettings: {
    readAuthConfig: (data?: void, config?: ApiRequestConfig) => Promise<AuthConfigProperties>,
    updateAuthConfig: (data?: AuthSettingsUpdateAuthConfigRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  linksSettings: {
    readLinksConfig: (data?: void, config?: ApiRequestConfig) => Promise<LinkSettingProperties>,
    updateLinksConfig: (data?: LinksSettingsUpdateLinksConfigRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  pdfSettings: {
    upsertPdfLink: (data: PdfSettingsUpsertPdfLinkRequest, config?: ApiRequestConfig) => Promise<void>,
    listPdfLinks: (data?: void, config?: ApiRequestConfig) => Promise<PdfLink[]>,
    downloadPdfLink: (data: PdfSettingsDownloadPdfLinkRequest, config?: ApiRequestConfig) => Promise<File>,
    deletePdfLink: (data: PdfSettingsDeletePdfLinkRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  products: {
    createProduct: (data: ProductsCreateProductRequest, config?: ApiRequestConfig) => Promise<LabProduct>,
    removeProduct: (data: ProductsRemoveProductRequest, config?: ApiRequestConfig) => Promise<void>,
    updateProduct: (data: ProductsUpdateProductRequest, config?: ApiRequestConfig) => Promise<LabProduct>,
  },

  features: {
    createFeature: (data: FeaturesCreateFeatureRequest, config?: ApiRequestConfig) => Promise<LabFeature>,
    updateFeature: (data: FeaturesUpdateFeatureRequest, config?: ApiRequestConfig) => Promise<LabFeature>,
    removeFeature: (data: FeaturesRemoveFeatureRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  laboratoryGroups: {
    listLaboratoryGroups: (data?: LaboratoryGroupsListLaboratoryGroupsRequest, config?: ApiRequestConfig) => Promise<LaboratoryGroup[]>,
    createLaboratoryGroup: (data: LaboratoryGroupsCreateLaboratoryGroupRequest, config?: ApiRequestConfig) => Promise<LaboratoryGroup>,
    readLaboratoryGroup: (data: LaboratoryGroupsReadLaboratoryGroupRequest, config?: ApiRequestConfig) => Promise<LaboratoryGroup>,
    deleteLaboratoryGroup: (data: LaboratoryGroupsDeleteLaboratoryGroupRequest, config?: ApiRequestConfig) => Promise<void>,
    updateLaboratoryGroup: (data: LaboratoryGroupsUpdateLaboratoryGroupRequest, config?: ApiRequestConfig) => Promise<LaboratoryGroup>,
  },

  laboratoryGroupFilters: {
    getLaboratoryGroupFilters: (data?: void, config?: ApiRequestConfig) => Promise<LaboratoryGroupFiltersGetLaboratoryGroupFiltersResponse>,
    setLaboratoryGroupFilters: (data?: LaboratoryGroupFiltersSetLaboratoryGroupFiltersRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  doctorGroups: {
    listDoctorGroups: (data?: DoctorGroupsListDoctorGroupsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<DoctorGroup>>,
    createDoctorGroup: (data: DoctorGroupsCreateDoctorGroupRequest, config?: ApiRequestConfig) => Promise<DoctorGroup>,
    readDoctorGroup: (data: DoctorGroupsReadDoctorGroupRequest, config?: ApiRequestConfig) => Promise<DoctorGroup>,
    deleteDoctorGroup: (data: DoctorGroupsDeleteDoctorGroupRequest, config?: ApiRequestConfig) => Promise<void>,
    updateDoctorGroup: (data: DoctorGroupsUpdateDoctorGroupRequest, config?: ApiRequestConfig) => Promise<DoctorGroup>,
  },

  doctorGroupOffice: {
    readDoctorGroupOfficeAssignments: (data?: DoctorGroupOfficeReadDoctorGroupOfficeAssignmentsRequest, config?: ApiRequestConfig) => Promise<DoctorGroupOfficeReadDoctorGroupOfficeAssignmentsResponse>,
    listDoctorsPerGroupOffices: (data?: DoctorGroupOfficeListDoctorsPerGroupOfficesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Doctor>>,
  },

  jobs: {
    importElasticsearchRequirements: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    importElasticsearchPatients: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    importElasticsearchDoctors: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    runDoctorTopRequirementsJob: (data?: void, config?: ApiRequestConfig) => Promise<void>,
  },

  system: {
    clearCache: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    readSystemConfig: (data?: void, config?: ApiRequestConfig) => Promise<SystemSettingProperties>,
    updateSystemConfig: (data?: SystemUpdateSystemConfigRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  mails: {
    getMailSmtpSettings: (data?: void, config?: ApiRequestConfig) => Promise<SendmailSettings[]>,
    setMailSmtpSettings: (data?: MailsSetMailSmtpSettingsRequest, config?: ApiRequestConfig) => Promise<void>,
    getMailTemplateGroups: (data?: void, config?: ApiRequestConfig) => Promise<MailTemplateGroup[]>,
    setMailTemplateGroups: (data?: MailsSetMailTemplateGroupsRequest, config?: ApiRequestConfig) => Promise<void>,
    setMailTemplateGroupLogo: (data: MailsSetMailTemplateGroupLogoRequest, config?: ApiRequestConfig) => Promise<void>,
    getMailTemplate: (data: MailsGetMailTemplateRequest, config?: ApiRequestConfig) => Promise<MailsGetMailTemplateResponse>,
    setMailTemplate: (data: MailsSetMailTemplateRequest, config?: ApiRequestConfig) => Promise<void>,
    sendMailTemplate: (data: MailsSendMailTemplateRequest, config?: ApiRequestConfig) => Promise<void>,
    previewMailTemplate: (data: MailsPreviewMailTemplateRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  lanr: {
    getLanrSettings: (data: LanrGetLanrSettingsRequest, config?: ApiRequestConfig) => Promise<LanrPreferenceProperties>,
    updateLanrSettings: (data: LanrUpdateLanrSettingsRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  smsSettings: {
    readSmsConfig: (data?: void, config?: ApiRequestConfig) => Promise<SendSmsSettings>,
    updateSmsConfig: (data?: SmsSettingsUpdateSmsConfigRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  preferenceSettings: {
    readAllPreferences: (data?: void, config?: ApiRequestConfig) => Promise<PreferencesProperties>,
    resetPreferenceByKey: (data: PreferenceSettingsResetPreferenceByKeyRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  lexicaSettings: {
    readArticle: (data: LexicaSettingsReadArticleRequest, config?: ApiRequestConfig) => Promise<Article>,
    updateArticle: (data: LexicaSettingsUpdateArticleRequest, config?: ApiRequestConfig) => Promise<Article>,
    deleteArticle: (data: LexicaSettingsDeleteArticleRequest, config?: ApiRequestConfig) => Promise<void>,
    listArticles: (data?: LexicaSettingsListArticlesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Article>>,
    createArticle: (data?: LexicaSettingsCreateArticleRequest, config?: ApiRequestConfig) => Promise<Article>,
    createArticleAnalysis: (data?: LexicaSettingsCreateArticleAnalysisRequest, config?: ApiRequestConfig) => Promise<ArticleAnalyses>,
    deleteArticleAnalysis: (data: LexicaSettingsDeleteArticleAnalysisRequest, config?: ApiRequestConfig) => Promise<void>,
    listRequirementHints: (data?: LexicaSettingsListRequirementHintsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Hint>>,
    createRequirementHint: (data?: LexicaSettingsCreateRequirementHintRequest, config?: ApiRequestConfig) => Promise<Hint>,
    readRequirementHint: (data: LexicaSettingsReadRequirementHintRequest, config?: ApiRequestConfig) => Promise<Hint>,
    updateRequirementHint: (data: LexicaSettingsUpdateRequirementHintRequest, config?: ApiRequestConfig) => Promise<LexicaSettingsUpdateRequirementHintResponse>,
    deleteRequirementHint: (data: LexicaSettingsDeleteRequirementHintRequest, config?: ApiRequestConfig) => Promise<void>,
    createHintRequirement: (data?: LexicaSettingsCreateHintRequirementRequest, config?: ApiRequestConfig) => Promise<HintRequirements>,
    deleteHintRequirement: (data: LexicaSettingsDeleteHintRequirementRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  lexica: {
    listArticles: (data?: LexicaListArticlesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Article>>,
    readArticle: (data: LexicaReadArticleRequest, config?: ApiRequestConfig) => Promise<Article>,
    listRequirementHints: (data?: LexicaListRequirementHintsRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Hint>>,
    readRequirementHint: (data: LexicaReadRequirementHintRequest, config?: ApiRequestConfig) => Promise<Hint>,
  },

  webshopProduct: {
    createWebshopProduct: (data: WebshopProductCreateWebshopProductRequest, config?: ApiRequestConfig) => Promise<WebshopProduct>,
    listWebshopProductLimitForToday: (data: WebshopProductListWebshopProductLimitForTodayRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  webshopVoucher: {
    createWebshopVoucher: (data: WebshopVoucherCreateWebshopVoucherRequest, config?: ApiRequestConfig) => Promise<PaymentVoucher>,
    createMvzMartinsriedWebshopVoucher: (data?: WebshopVoucherCreateMvzMartinsriedWebshopVoucherRequest, config?: ApiRequestConfig) => Promise<PaymentVoucher[]>,
  },

  webshop: {
    preparePaymentForWebshopOrder: (data: WebshopPreparePaymentForWebshopOrderRequest, config?: ApiRequestConfig) => Promise<PreparePaymentProperties>,
    insertWebshopOrder: (data: WebshopInsertWebshopOrderRequest, config?: ApiRequestConfig) => Promise<WebshopOrder>,
    listOrdersForDeviceId: (data: WebshopListOrdersForDeviceIdRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<WebshopOrder>>,
    completeWebshopOrder: (data: WebshopCompleteWebshopOrderRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  cases: {
    getCasesConfig: (data?: void, config?: ApiRequestConfig) => Promise<CasesGetCasesConfigResponse>,
    listCases: (data?: CasesListCasesRequest, config?: ApiRequestConfig) => Promise<PaginationResponse<Case>>,
    readCase: (data: CasesReadCaseRequest, config?: ApiRequestConfig) => Promise<Case>,
  },

  casesSettings: {
    readGeneralCasesConfig: (data?: void, config?: ApiRequestConfig) => Promise<CasesSettingsReadGeneralCasesConfigResponse>,
    updateGeneralCasesConfig: (data?: CasesSettingsUpdateGeneralCasesConfigRequest, config?: ApiRequestConfig) => Promise<void>,
    readUserCasesConfig: (data: CasesSettingsReadUserCasesConfigRequest, config?: ApiRequestConfig) => Promise<CasesSettingsReadUserCasesConfigResponse>,
    updateUserCasesConfig: (data: CasesSettingsUpdateUserCasesConfigRequest, config?: ApiRequestConfig) => Promise<void>,
  },

  workstations: {
    getWorkstationByAlias: (data: WorkstationsGetWorkstationByAliasRequest, config?: ApiRequestConfig) => Promise<Workstation>,
    listWorkstations: (data: WorkstationsListWorkstationsRequest, config?: ApiRequestConfig) => Promise<Workstation[]>,
  },

  exchangeStorage: {
    reExportFiles: (data: ExchangeFileIdsDto, config?: ApiRequestConfig) => Promise<void>,
    getLTD3EmbeddedFile: (data: ExchangeStorageGetLTD3EmbeddedFileRequest, config?: ApiRequestConfig) => Promise<ExchangeStorageGetLTD3EmbeddedFileResponse>,
    downloadFile: (data: ExchangeStorageDownloadFileRequest, config?: ApiRequestConfig) => Promise<File>,
    deleteFiles: (data: ExchangeFileIdsDto, config?: ApiRequestConfig) => Promise<ExchangeFile[]>,
    listFiles: (data?: ExchangeListQueryDto, config?: ApiRequestConfig) => Promise<ExchangeFile[]>,
    uploadFiles: (data: ExchangeUploadFileDto, config?: ApiRequestConfig) => Promise<ExchangeFile[]>,
  },

  exchangeSettings: {
    updateImportSettings: (data: ExchangeImportSettingsResultDto, config?: ApiRequestConfig) => Promise<ExchangeImportSettingsResultDto>,
    getImportSettings: (data?: void, config?: ApiRequestConfig) => Promise<ExchangeImportSettingsResultDto>,
    updateExportSettings: (data: Omit<ExchangeExportSettingsDto, keyof ExchangeSettingsUpdateExportSettingsParams> & ExchangeSettingsUpdateExportSettingsParams, config?: ApiRequestConfig) => Promise<ExchangeExportSettingsDto>,
    getExportSettings: (data: ExchangeSettingsGetExportSettingsRequest, config?: ApiRequestConfig) => Promise<ExchangeExportSettingsDto>,
  },

  storeCategories: {
    getStoreCategoryTreeForDoctor: (data: StoreCategoriesGetStoreCategoryTreeForDoctorRequest, config?: ApiRequestConfig) => Promise<StoreCategory[]>,
    listAllStoreCategories: (data?: void, config?: ApiRequestConfig) => Promise<StoreCategory[]>,
    deleteStoreCategory: (data: StoreCategoriesDeleteStoreCategoryRequest, config?: ApiRequestConfig) => Promise<void>,
    updateStoreCategory: (data: Omit<StoreCategory, keyof StoreCategoriesUpdateStoreCategoryParams> & StoreCategoriesUpdateStoreCategoryParams, config?: ApiRequestConfig) => Promise<StoreCategory>,
    readStoreCategory: (data: StoreCategoriesReadStoreCategoryRequest, config?: ApiRequestConfig) => Promise<StoreCategory>,
    listStoreCategories: (data?: CrudListQueryDto, config?: ApiRequestConfig) => Promise<StoreCategory[]>,
    createStoreCategory: (data: StoreCategory, config?: ApiRequestConfig) => Promise<StoreCategory>,
  },

  storeManufacturers: {
    deleteStoreManufacturer: (data: StoreManufacturersDeleteStoreManufacturerRequest, config?: ApiRequestConfig) => Promise<void>,
    updateStoreManufacturer: (data: Omit<StoreManufacturer, keyof StoreManufacturersUpdateStoreManufacturerParams> & StoreManufacturersUpdateStoreManufacturerParams, config?: ApiRequestConfig) => Promise<StoreManufacturer>,
    readStoreManufacturer: (data: StoreManufacturersReadStoreManufacturerRequest, config?: ApiRequestConfig) => Promise<StoreManufacturer>,
    listStoreManufacturers: (data?: CrudListQueryDto, config?: ApiRequestConfig) => Promise<StoreManufacturer[]>,
    createStoreManufacturer: (data: StoreManufacturer, config?: ApiRequestConfig) => Promise<StoreManufacturer>,
  },

  storeProducts: {
    getStoreProductThumbnail: (data: StoreProductsGetStoreProductThumbnailRequest, config?: ApiRequestConfig) => Promise<File>,
    unpinStoreProduct: (data: Omit<StoreProductFavorites, keyof StoreProductsUnpinStoreProductParams> & StoreProductsUnpinStoreProductParams, config?: ApiRequestConfig) => Promise<void>,
    pinStoreProduct: (data: Omit<StoreProductFavorites, keyof StoreProductsPinStoreProductParams> & StoreProductsPinStoreProductParams, config?: ApiRequestConfig) => Promise<void>,
    getStoreProductImage: (data: StoreProductsGetStoreProductImageRequest, config?: ApiRequestConfig) => Promise<File>,
    uploadStoreProductImage: (data: Omit<StoreProductUploadImageDto, keyof StoreProductsUploadStoreProductImageParams> & StoreProductsUploadStoreProductImageParams, config?: ApiRequestConfig) => Promise<void>,
    deleteStoreProduct: (data: StoreProductsDeleteStoreProductRequest, config?: ApiRequestConfig) => Promise<void>,
    updateStoreProduct: (data: Omit<StoreProduct, keyof StoreProductsUpdateStoreProductParams> & StoreProductsUpdateStoreProductParams, config?: ApiRequestConfig) => Promise<StoreProduct>,
    readStoreProduct: (data: StoreProductsReadStoreProductRequest, config?: ApiRequestConfig) => Promise<StoreProduct>,
    listStoreProducts: (data?: StoreProductsListQueryDto, config?: ApiRequestConfig) => Promise<PaginationResponse<StoreProduct>>,
    createStoreProduct: (data: StoreProduct, config?: ApiRequestConfig) => Promise<StoreProduct>,
  },

  storeOrders: {
    sendCurrentUserOrder: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    setCurrentUserOrder: (data: StoreOrder, config?: ApiRequestConfig) => Promise<StoreOrder>,
    getCurrentUserOrder: (data?: void, config?: ApiRequestConfig) => Promise<StoreOrder>,
    completeStoreOrder: (data: StoreOrdersCompleteStoreOrderRequest, config?: ApiRequestConfig) => Promise<StoreOrder>,
    commissionStoreOrder: (data: StoreOrdersCommissionStoreOrderRequest, config?: ApiRequestConfig) => Promise<StoreOrder>,
    cancelStoreOrder: (data: StoreOrdersCancelStoreOrderRequest, config?: ApiRequestConfig) => Promise<StoreOrder>,
    deleteStoreOrder: (data: StoreOrdersDeleteStoreOrderRequest, config?: ApiRequestConfig) => Promise<void>,
    updateStoreOrder: (data: Omit<StoreOrder, keyof StoreOrdersUpdateStoreOrderParams> & StoreOrdersUpdateStoreOrderParams, config?: ApiRequestConfig) => Promise<StoreOrder>,
    readStoreOrder: (data: StoreOrdersReadStoreOrderRequest, config?: ApiRequestConfig) => Promise<StoreOrder>,
    listStoreOrders: (data?: StoreOrdersListQueryDto, config?: ApiRequestConfig) => Promise<PaginationResponse<StoreOrder>>,
    createStoreOrder: (data: StoreOrder, config?: ApiRequestConfig) => Promise<StoreOrder>,
  },

  storeSettings: {
    updateUserStoreConfig: (data: StoreUserConfigDto, config?: ApiRequestConfig) => Promise<void>,
    readUserStoreConfig: (data?: UserEntityTypeDto, config?: ApiRequestConfig) => Promise<StoreConfigDto>,
    updateGeneralStoreConfig: (data: StoreConfigDto, config?: ApiRequestConfig) => Promise<void>,
    readGeneralStoreConfig: (data?: void, config?: ApiRequestConfig) => Promise<StoreConfigDto>,
    getStoreConfig: (data?: void, config?: ApiRequestConfig) => Promise<StoreConfigDto>,
  },

  cache: {
    getSystemCache: (data?: void, config?: ApiRequestConfig) => Promise<CacheDumpDto>,
  },

  logs: {
    readLogEntry: (data: LogsReadLogEntryRequest, config?: ApiRequestConfig) => Promise<LogEntryDto>,
    listLogEntries: (data?: LogsQueryDto, config?: ApiRequestConfig) => Promise<LogsListResultDto>,
  },

  metrics: {
    getSystemMetrics: (data: MetricsGetSystemMetricsRequest, config?: ApiRequestConfig) => Promise<MetricsResponseDto[]>,
    resetSystemMetrics: (data?: void, config?: ApiRequestConfig) => Promise<void>,
  },

  notificationSettings: {
    resetUserTypeNotificationsPreferences: (data: NotificationSettingsResetUserTypeNotificationsPreferencesRequest, config?: ApiRequestConfig) => Promise<void>,
    resetUserNotificationsPreferences: (data: NotificationSettingsResetUserNotificationsPreferencesRequest, config?: ApiRequestConfig) => Promise<void>,
    updateUserNotificationsConfig: (data: Omit<NotificationUserConfigDTO, keyof NotificationSettingsUpdateUserNotificationsConfigParams> & NotificationSettingsUpdateUserNotificationsConfigParams, config?: ApiRequestConfig) => Promise<NotificationUserConfigDTO>,
    readUserNotificationsConfig: (data: NotificationSettingsReadUserNotificationsConfigRequest, config?: ApiRequestConfig) => Promise<NotificationUserConfigDTO>,
    updateGeneralNotificationsConfig: (data: NotificationGeneralConfigDTO, config?: ApiRequestConfig) => Promise<void>,
    readGeneralNotificationsConfig: (data?: void, config?: ApiRequestConfig) => Promise<NotificationGeneralConfigDTO>,
  },

  notifications: {
    readAllNotifications: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    messenteSMSDeliveryReport: (data?: Omit<MessenteDeliveryReportDTO, keyof NotificationsMessenteSMSDeliveryReportParams> & NotificationsMessenteSMSDeliveryReportParams, config?: ApiRequestConfig) => Promise<void>,
    getNotificationLog: (data: NotificationsGetNotificationLogRequest, config?: ApiRequestConfig) => Promise<Notification>,
    listNotificationLogs: (data?: ListNotificationLogsQueryDto, config?: ApiRequestConfig) => Promise<Notification[]>,
    countNotifications: (data?: void, config?: ApiRequestConfig) => Promise<NotificationCountDTO>,
    clearQueue: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    dismissNotification: (data: NotificationsDismissNotificationRequest, config?: ApiRequestConfig) => Promise<void>,
    readNotification: (data: NotificationsReadNotificationRequest, config?: ApiRequestConfig) => Promise<void>,
    dismissAllNotifications: (data?: void, config?: ApiRequestConfig) => Promise<void>,
    listNotifications: (data?: CrudPaginatedListQueryDto, config?: ApiRequestConfig) => Promise<ListNotificationsDto>,
  },

};
